import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Dropdown from "../../../reusable-components/inputs/Dropdowns/Dropdown";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { button, cancel_button } from "../../../theme/lightTheme";
import Button from "../../../reusable-components/buttons/Button";

import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../authentication/hooks/useAuth";
import { CASHCOUNTER_CONFIG_URL } from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import { getFormattedDateInputDate } from "../../../utilities/dateFunctions/formatdate";

export default function AddCashCounterForm({
  setShowAddForm,
  expenseListDD,
  getCashCounterDetails,
  editCashCounterDetails,
  setEditCashCounterDetails,
}) {
  const [selectedExpenseTitle, setSelectedExpenseTitle] = useState("");

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  let filteredList = expenseListDD?.filter(
    (expense) => expense?.value === editCashCounterDetails?.expense_id
  );

  let isEdit =
    editCashCounterDetails === undefined || editCashCounterDetails === null
      ? false
      : Object?.keys(editCashCounterDetails)?.length > 0
      ? true
      : false;

  const defaultValues = {
    expense_id: !isEdit ? "" : filteredList[0],
    purchased_date: !isEdit
      ? ""
      : getFormattedDateInputDate(editCashCounterDetails?.purchased_date),

    amount: !isEdit ? "" : editCashCounterDetails?.amount,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let response = "";
      let sendDataObj = {};

      sendDataObj.expense_id = data?.expense_id?.value;
      sendDataObj.amount = data?.amount;
      sendDataObj.purchased_date = data?.purchased_date;

      if (!isEdit) {
        sendDataObj.created_by = auth.userid;

        response = await axiosPrivate.post(
          `${CASHCOUNTER_CONFIG_URL}/saveCashCounterDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      } else {
        sendDataObj.updated_by = auth.userid;
        sendDataObj.cashcounter_details_id =
          editCashCounterDetails?.cashcounter_details_id;

        response = await axiosPrivate.post(
          `${CASHCOUNTER_CONFIG_URL}/updateCashCounterDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast(
            "Expense Details  has been updated successfully",
            "success"
          );
        } else {
          showToast("Expense Details has been added successfully", "success");
        }
        getCashCounterDetails();
      } else {
        if (isEdit) {
          if (
            response.data.updateConstituencyDetails.includes(
              "unique_cashcounter"
            )
          ) {
            showToast(
              "Designation already exists. Cannot insert duplicate cash counter",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveCashCounterDetails.includes("unique_cashcounter")
          ) {
            showToast(
              "Designation already exists. Cannot insert duplicate cash counter",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          defaultName="expense_id"
          register={register}
          labelname="Expense Title"
          required={true}
          errors={errors}
          classes={`rounded-lg text-sm w-full z-40`}
          setError={setError}
          clearError={clearErrors}
          onChangeInput={null}
          control={control}
          data={expenseListDD}
          defaultValue={defaultValues.expense_id}
          setValue={setValue}
          setSelected={setSelectedExpenseTitle}
          selected={selectedExpenseTitle}
          maxMenuHeight={120}
        />

        <Input
          defaultName="purchased_date"
          register={register}
          name="Date"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter date"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="date"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.purchased_date}
          setValue={setValue}
        />

        <Input
          defaultName="amount"
          register={register}
          name="Expense Amount"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter Amount"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="number"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.amount}
          setValue={setValue}
        />

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditCashCounterDetails({});
              setValue("", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Area Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </>
  );
}
