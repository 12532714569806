import React from "react";
import { useState, useEffect } from "react";

import { BiTrash } from "react-icons/bi";
import TableWrapper from "../../../../../reusable-components/table/TableWrapper";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../../utilities/dateFunctions/formatdate";
import LabelWithDetailPrint from "./LabelWithDetailPrint";

export default function LedgerListPrint({
  ledgerList,
  addRepaymentDetails,
  principleDueWithIntData,
  totalDueAmount,
  penalTotalDue,
  ioiTotalDue,
}) {
  const [list, setList] = useState(ledgerList);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setList(ledgerList);
    }

    return () => {
      isMounted = false;
    };
  }, [ledgerList]);

  return (
    <>
      <>
        <section className="border px-3 py-2 text-[10px] my-3 grid grid-cols-3 bg-slate-100 relative">
          <LabelWithDetailPrint
            label={"Name"}
            value={addRepaymentDetails?.applicantname}
          />
          <LabelWithDetailPrint
            label={"Loan ID"}
            value={addRepaymentDetails?.loanee_id}
          />
          <LabelWithDetailPrint
            label={"Loan No"}
            value={addRepaymentDetails?.loan_number}
          />
          <LabelWithDetailPrint
            label={"Category"}
            value={addRepaymentDetails?.categoryname}
          />
          <LabelWithDetailPrint
            label={"Amount"}
            value={formatINRCurrency(addRepaymentDetails?.loanappliedamt)}
          />

          <LabelWithDetailPrint
            label="Int Rate (Scheme)"
            value={`${+principleDueWithIntData?.int_rate}  (${
              addRepaymentDetails?.loan_scheme || "---"
            }) `}
          />
          <LabelWithDetailPrint
            label="Calculation Start Date"
            value={
              getFormattedDateDDMMMYYYYDate(
                principleDueWithIntData?.last_repaymentdate
              ) || "---"
            }
          />
          <LabelWithDetailPrint
            label="No of Days"
            value={principleDueWithIntData?.calculationfordays}
          />

          <LabelWithDetailPrint
            label="Principle Outstanding"
            value={formatINRCurrency(
              principleDueWithIntData?.principle_outstanding_amount
            )}
          />
          <LabelWithDetailPrint
            label="Principle Due"
            value={formatINRCurrency(principleDueWithIntData?.principle_due)}
          />
          <LabelWithDetailPrint
            label="Interest Due"
            value={formatINRCurrency(
              principleDueWithIntData?.interest_due_amount
            )}
          />

          <LabelWithDetailPrint
            label="Total Due Amount"
            value={formatINRCurrency(totalDueAmount)}
          />

          {addRepaymentDetails?.categoryname === "OBC" && (
            <>
              <LabelWithDetailPrint
                label="Penal Due"
                value={formatINRCurrency(penalTotalDue)}
                noRecord={+penalTotalDue === -1 ? true : false}
                isApprox={true}
              />
              <LabelWithDetailPrint
                label="IOI Due"
                value={formatINRCurrency(ioiTotalDue)}
                noRecord={+ioiTotalDue === -1 ? true : false}
                isApprox={true}
              />
            </>
          )}
        </section>

        <table className="min-w-full divide-y divide-slate-200">
          <thead className="bg-slate-200 text-[11px] ">
            <tr>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                SI No
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider"
              >
                TR No
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider"
              >
                Date
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider"
              >
                Disbursment
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider"
              >
                Repayment
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider"
              >
                Particulars
              </th>
              <th
                scope="col"
                className="px-3  text-left font-medium text-slate-900  tracking-wider w-[10%]"
              >
                Mode
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-slate-100 text-slate-800 text-[10px]">
            {list?.length > 0 ? (
              list?.map((ledger, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? "bg-slate-100" : null}
                >
                  <td className="px-3 py-1 whitespace-nowrap">{+index + 1}</td>
                  <td className="px-3  whitespace-nowrap">
                    {ledger?.ledgerid}
                  </td>
                  <td className="px-3  whitespace-nowrap">
                    {getFormattedDateDDMMMYYYYDate(ledger?.tr_date)}
                  </td>
                  <td className="px-3  whitespace-nowrap">
                    {ledger?.tr_type == "DI" ? ledger?.tr_amount : 0}
                  </td>
                  <td className="px-3  whitespace-nowrap">
                    {ledger?.tr_type != "DI" ? ledger?.tr_amount : 0}
                  </td>
                  <td className="px-3  whitespace-nowrap">
                    {ledger?.tr_particulars || "---"}
                  </td>
                  <td className="px-3  whitespace-nowrap ">
                    {ledger?.tr_paymode}
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={8} className="p-4 text-center">
                {" "}
                No Record Found
              </td>
            )}
          </tbody>
        </table>
      </>
    </>
  );
}
