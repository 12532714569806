import React from "react";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  NOTICE_URL,
  REPAYMENT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import { useState } from "react";
import showToast from "../../../../utilities/notification/NotificationModal";
import { useEffect } from "react";
import LoaneeListConfig from "../../DemandNoticeV2/LoaneeListConfig";
import { Paging } from "../../../../reusable-components/paging/Paging";
import LonaeeList from "../../DemandNoticeV2/LonaeeList";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn, MdOutlineFilterAlt } from "react-icons/md";
import Button from "../../../../reusable-components/buttons/Button";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import IndirectDepartmentalNoticeList from "./IndirectDepartmentalNoticeList";
import GenerateIndirectDeptNotice from "./GenerateIndirectDeptNotice";
import { BiRefresh } from "react-icons/bi";

export default function IndirectDeptNoticeConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [isLoading, setIsLoading] = useState(false);
  const [pageSize, setPageSize] = useState();
  const [repaymentList, setRepaymentList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [showFilters, setShowFilters] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [categoryList, setCategoryList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [constituencyList, setConstituencyList] = useState([]);
  const [loaneeDetails, setLonaeeDetails] = useState();
  const [noticeList, setNoticeList] = useState();
  const [loaneeId, setLoaneeId] = useState();
  const [guarantorListForNotice, setGuarantorListForNotice] = useState();
  const [showDirectDeptNoticeList, setShowDirectDeptNoticeList] =
    useState(null);
  const [showDemandNoticeList, setShowDemandNoticeList] = useState(null);
  const [loanNumber, setLoanNumber] = useState();

  const [showIndirectDeptNoticeList, setShowIndirectDeptNoticeList] =
    useState(false);

  const [refresh, setRefresh] = useState(false);

  const [
    showGenerateIndirectDeptNoticePage,
    setShowGenerateIndirectDeptNoticePage,
  ] = useState(false);

  const [selectedDate, setSelectedDate] = useState(null);
  const [refNumber, setRefNumber] = useState();
  const [specificDate, setSpecficDate] = useState();
  const [salariedGuarantorExist, setSalariedGuarantorExist] = useState(false);
  const [viewNotice, setViewNotice] = useState(false);

  const [showOTSNoticeList, setShowOTSNoticeList] = useState(false);
  const [guarantorNamesDD, setGuarantorNamesDD] = useState();

  const [selectedGuarantor, setSelectedGuarantor] = useState();

  const getSearchedRepaymentList = async (
    page_no,
    page_size,
    search_text,
    category_type,
    districtId,
    constituencyId
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getRepaymentSearchListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type,
            decreestatus: false,
            onetimesettlementstatus: false,
            district_id: districtId || 0,
            constituency_id: constituencyId || 0,
            applicant_gender: "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setRepaymentList(response?.data?.repaymentList);

        const totalNumberOfApplicant =
          response?.data?.repaymentList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Record found in the system", "warning");
      setRepaymentList(response?.data?.repaymentList);
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getNoticeList = async (page_no, page_size) => {
    try {
      setNoticeList({});
      setSalariedGuarantorExist(false);
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${NOTICE_URL}`,
        {
          params: {
            loanee_id: loaneeId,
            pagenumber: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            notice_type: "Indirect Departmental Notice",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (
        response.status === 200 &&
        response?.data?.noticeList[0]?.get_notice_list !== null &&
        response?.data?.noticeList[0]?.get_notice_list !== undefined
      ) {
        setNoticeList(response?.data?.noticeList[0]?.get_notice_list);

        const totalNumberOfApplicant =
          response?.data?.noticeList[0]?.get_notice_list[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Indirect Departmental list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getGuarantorListForNotice = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${NOTICE_URL}/getGuarantorListForNotice`,
        {
          params: {
            loanee_id: loaneeDetails?.loanee_id || 1,
          },
        },
        {
          signal: controller.signal,
        }
      );

      let guarantor_NamesDD = [];

      if (response?.status === 200) {
        response?.data?.guarantorListForNotice?.map((guarantorDetailsObj) => {
          guarantorDetailsObj?.get_guarantor_list_for_notice?.map(
            (guarantorNamesobj) => {
              guarantor_NamesDD.push({
                label: guarantorNamesobj?.guarantorname,
                value: guarantorNamesobj?.guarantor_id,
                address: guarantorNamesobj?.guarantoraddress,
              });
            }
          );
        });
        setGuarantorNamesDD(guarantor_NamesDD);
      }

      {
        setGuarantorListForNotice(response?.data?.guarantorListForNotice[0]);
      }
      response.status === 202 &&
        showToast("No Direct Departmental list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getSearchedRepaymentList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label || "",
      selectedDistrictName?.value,
      selectedConstituencyName?.value
    );
  }, [
    pageNo,
    pageSize?.value,
    selectedCategory?.label,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
    refresh,
  ]);

  useEffect(() => {
    let isMounted = true;

    getCategoryList();

    getConstituencyList();
    getDistrictList();

    // setAddRepaymentDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  useEffect(() => {
    getNoticeList(1, 25);
    getGuarantorListForNotice();
  }, [loaneeId, refresh]);

  const handlecheckDepartmentStatus = () => {
    guarantorListForNotice?.get_guarantor_list_for_notice?.length > 0 &&
      guarantorListForNotice?.get_guarantor_list_for_notice?.map((obj) => {
        if (obj?.departmantname) {
          setSalariedGuarantorExist(true);
          return;
        }
      });
  };
  useEffect(() => {
    handlecheckDepartmentStatus();
  }, [guarantorListForNotice]);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />
        <section>
          {showGenerateIndirectDeptNoticePage ? (
            <>
              <GenerateIndirectDeptNotice
                setSelectedDate={setSelectedDate}
                onChange={(e) => setSpecficDate(e)}
                isLoading={isLoading}
                refNumber={refNumber}
                setRefNumber={setRefNumber}
                loaneeDetails={loaneeDetails}
                selectedDate={selectedDate}
                setShowGenerateIndirectDeptNoticePage={
                  setShowGenerateIndirectDeptNoticePage
                }
                guarantorListForNotice={guarantorListForNotice}
                salariedGuarantorExist={salariedGuarantorExist}
                setSalariedGuarantorExist={setSalariedGuarantorExist}
                getNoticeList={getNoticeList}
                setRefresh={setRefresh}
              />
            </>
          ) : (
            <>
              <div>
                {showIndirectDeptNoticeList ? (
                  <>
                    <IndirectDepartmentalNoticeList
                      loaneeDetails={loaneeDetails}
                      setShowIndirectDeptNoticeList={
                        setShowIndirectDeptNoticeList
                      }
                      setShowGenerateIndirectDeptNoticePage={
                        setShowGenerateIndirectDeptNoticePage
                      }
                      setRefresh={setRefresh}
                      setRefNumber={setRefNumber}
                      guarantorListForNotice={guarantorListForNotice}
                      setSelectedDate={setSelectedDate}
                      refNumber={refNumber}
                      selectedDate={selectedDate}
                      setGuarantorListForNotice={setGuarantorListForNotice}
                      setSalariedGuarantorExist={setSalariedGuarantorExist}
                      salariedGuarantorExist={salariedGuarantorExist}
                      setViewNotice={setViewNotice}
                      viewNotice={viewNotice}
                      guarantorNamesDD={guarantorNamesDD}
                      selectedGuarantor={selectedGuarantor}
                      setSelectedGuarantor={setSelectedGuarantor}
                      setNoticeList={setNoticeList}
                      noticeList={noticeList}
                    />
                  </>
                ) : (
                  <>
                    <>
                      <div className="flex items-center justify-between gap-2">
                        <H2withIcon>
                          <MdAssignmentTurnedIn />{" "}
                          <span className="pl-1.5">
                            Loanee List for Indirect Departmental Notice
                          </span>
                        </H2withIcon>

                        <>
                          <div className="flex gap-2">
                            <Button
                              className={
                                "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
                              }
                              onClick={() => {
                                setShowFilters(!showFilters);
                              }}
                            >
                              Apply Filters
                              <MdOutlineFilterAlt size={18} />
                            </Button>

                            <SearchField
                              placeholder="Search"
                              defaultValue={searchText}
                              onChange={(e) => {
                                setSearchText(e);
                              }}
                              onClick={() => {
                                setPageNo(1);
                                getSearchedRepaymentList(
                                  pageNo,
                                  pageSize?.value,
                                  searchText || "",
                                  selectedCategory?.label || "",
                                  selectedDistrictName?.value,
                                  selectedConstituencyName?.value
                                );
                              }}
                              classes={"bg-primary "}
                              onKeyDown={(e) => {
                                if (e?.key === "Enter") {
                                  getSearchedRepaymentList(
                                    pageNo,
                                    pageSize?.value,
                                    searchText || "",
                                    selectedCategory?.label || "",
                                    selectedDistrictName?.value,
                                    selectedConstituencyName?.value
                                  );
                                }
                              }}
                            />
                          </div>
                        </>
                      </div>

                      {showFilters && (
                        <section className="grid grid-cols-1 md:grid-cols-3 gap-2 bg-slate-100 p-3 mt-3">
                          <FilterDropdown
                            defaultName="filterCategory"
                            required={true}
                            classes={`text-sm w-full`}
                            placeholder={"Select Category..."}
                            data={categoryList}
                            defaultValue={null}
                            setSelected={setSelectedCategory}
                            selected={selectedCategory}
                            maxMenuHeight={256}
                          />

                          <FilterDropdown
                            defaultName="filterDistrict"
                            required={true}
                            classes={`text-sm w-full`}
                            placeholder={"Select District..."}
                            data={districtList}
                            defaultValue={null}
                            setSelected={setSelectedDistrictName}
                            selected={selectedDistrictName}
                            maxMenuHeight={256}
                          />
                          <FilterDropdown
                            defaultName="filterConstituency"
                            required={true}
                            classes={`text-sm w-full`}
                            placeholder={"Select Constituency..."}
                            data={constituencyList}
                            defaultValue={null}
                            setSelected={setSelectedConstituencyName}
                            selected={selectedConstituencyName}
                            maxMenuHeight={256}
                          />
                        </section>
                      )}

                      <div className="grid grid-cols-1 mt-3">
                        {/* This LoaneeList Component is the same component from DemandNoticeV2 making it reuseable  */}
                        <LonaeeList
                          repaymentList={repaymentList}
                          setLoaneeId={setLoaneeId}
                          setShowDirectDeptNoticeList={
                            setShowDirectDeptNoticeList
                          }
                          showDirectDeptNoticeList={showDirectDeptNoticeList}
                          setShowDemandNoticeList={setShowDemandNoticeList} // for Direct Dept notice sending empty prop
                          setLoanNumber={setLoanNumber} // for Direct Dept notice sending empty prop
                          setLonaeeDetails={setLonaeeDetails} // for Direct Dept notice sending empty prop
                          setShowOTSNoticeList={setShowOTSNoticeList} // empty prop for OTS
                          setShowIndirectDeptNoticeList={
                            setShowIndirectDeptNoticeList
                          }
                        />
                      </div>

                      <Paging
                        maxMenuHeight={100}
                        selected={pageSize}
                        setSelected={setPageSize}
                        pageNo={pageNo}
                        onPageChangeHandler={onPageChangeHandler}
                        numberOfPages={numberOfPages}
                      />
                    </>
                  </>
                )}
              </div>
            </>
          )}
        </section>
      </Dashboard>
      <ToastContainer />
    </>
  );
}
