import { useEffect } from "react";
import { useLocation, Navigate, Outlet } from "react-router-dom";
import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import useAuth from "../hooks/useAuth";
import useRefreshToken from "../hooks/useRefreshToken";

const RequireAuth = ({ allowedRoles }) => {
  const { auth, isLoading: authLoading, error: authError } = useAuth();
  const refresh = useRefreshToken();
  const location = useLocation();

  useEffect(() => {
    if (!auth && !authError) {
      const verifyRefreshToken = async () => {
        try {
          await refresh();
        } catch (err) {
          console.error(err);
        }
      };
      verifyRefreshToken();
    }
  }, [auth, authError, refresh]);

  if (authLoading) {
    return <SpinnerWithText />;
  }

  if (authError) {
    // Handle the authentication error gracefully
    return <Navigate to="/error" />;
  }

  if (!auth) {
    // Redirect to the login page if the user is not authenticated
    return <Navigate to="/login" />;
  }

  if (!allowedRoles.includes(parseInt(auth.role))) {
    // Handle unauthorized access
    return <Navigate to="/unauthorized" state={{ from: location }} replace />;
  }

  // Render the child components if everything is fine
  return <Outlet />;
};

export default RequireAuth;
