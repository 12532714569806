import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function PublicInformationOfficerDetailsPage() {
  const navigate = useNavigate();

  let applicationDetails = [
    {
      period: "2016-17",
      no_of_application: "02",
      info_provided: "02",
    },
    {
      period: "2017-18",
      no_of_application: "01",
      info_provided: "01",
    },
    {
      period: "2018-19 ",
      no_of_application: "01",
      info_provided: "01",
    },
    {
      period: "2019-20",
      no_of_application: "Nil",
      info_provided: "Nil ",
    },
    {
      period: "2020-21",
      no_of_application: "02",
      info_provided: "02",
    },
    {
      period: "2021-22",
      no_of_application: "01",
      info_provided: "01",
    },
    {
      period: "2022-23",
      no_of_application: "Nil",
      info_provided: "Nil",
    },
  ];

  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Details of Public Information Officers </H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 lg:mx-0 py-10 lg:py-0 text-xs md:text-sm">
          <p className="font-semibold  text-center pb-4 text-base md:text-xl underline underline-offset-2">
            The norms, designation and other particulars of the Public
            Information Officers
          </p>

          <div className="grid  md:grid-cols-2 gap-6 pt-4">
            <div className="bg-slate-100 border mx-6 p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 relative">
              <p className="font-semibold py-2 text-center">
                Transparency Officer
              </p>
              <p className="text-center pt-3">
                Shri Tshering Ongda, IRS, Managing Director
              </p>
              <p className="pb-3 text-center">Contact no: 9734456439</p>
            </div>

            <div className="bg-slate-100 border mx-6 p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 relative">
              <p className="font-semibold py-2 text-center">
                First Appellate Authority
              </p>
              <p className="text-center pt-3">
                Shri Tshering Ongda, IRS, Managing Director
              </p>
              <p className="pb-3 text-center">Contact no: 9734456439</p>
            </div>

            <div className="bg-slate-100 border mx-6 p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 relative">
              <p className="font-semibold py-2 text-center">SPIO</p>
              <p className="text-center pt-3">
                Mrs. Indira Pillai, Additional Legal Officer
              </p>
              <p className="pb-3 text-center">Contact no: 9434257264</p>
            </div>

            <div className="bg-slate-100 border mx-6 p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 relative">
              <p className="font-semibold py-2 text-center">ASPIO</p>
              <p className="text-center pt-3">
                Shri Durga Bdr. Manger, Dy. General Manager-II
              </p>
              <p className="pb-3 text-center">Contact no: 8348030675</p>
            </div>
          </div>
        </div>

        <div>
          <p className="font-semibold pt-10 underline underline-offset-2 text-center">
            Details of application received under RTI and information provided
          </p>
        </div>

        <div className=" my-10  ">
          <table className=" divide-y divide-slate-200 border border-slate-200">
            <thead className="bg-white text-sm ">
              <tr>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-semibold text-slate-900 "
                >
                  S.No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-semibold text-slate-900 "
                >
                  Period
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-semibold text-slate-900 "
                >
                  No of application received
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-semibold text-slate-900 "
                >
                  Information provided
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-slate-200 text-slate-800 overflow-hidden  ">
              {applicationDetails.length > 0 &&
                applicationDetails.map((applicationDetailsObj, index) => (
                  <tr key={index}>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {index + 1}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {applicationDetailsObj.period}
                    </td>
                    <td className="px-6 py-4 whitespace-nowrap text-sm">
                      {applicationDetailsObj.no_of_application}
                    </td>
                    <td className=" px-6 py-4 whitespace-nowrap lg:whitespace-normal text-sm">
                      {applicationDetailsObj.info_provided}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </section>
    </>
  );
}
