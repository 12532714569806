import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import {
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";

const AddConstituencyForm = ({
  getConstituencyList,
  editConstituencyDetails,
  setEditConstituencyDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [districtList, setDistrictList] = useState([]);
  const [selected, setSelected] = useState(null);

  const isEdit = Object.keys(editConstituencyDetails)?.length > 0;

  const defaultValues = {
    constituencyname: !isEdit ? "" : editConstituencyDetails?.constituencyname,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          for (let i = 0; i < response?.data?.districtList?.length; i++) {
            let districtObj = {
              value: response.data.districtList[i].districtname,
              label: response.data.districtList[i].districtname,
            };
            districtDD.push(districtObj);
          }
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const controller = new AbortController();

  const onSubmit = async (data) => {
    data.districtname = data?.districtname?.value;

    try {
      let response = "";
      if (!isEdit) {
        data.createdby = auth.userid;
        response = await axiosPrivate.post(CONSTITUENCY_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.constituencyid = editConstituencyDetails?.constituencyid;

        response = await axiosPrivate.post(
          `${CONSTITUENCY_CONFIG_URL}/update`,
          data,
          {
            signal: controller.signal,
          }
        );
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditConstituencyDetails({});
          showToast("Constituency has been updated successfully", "success");
        } else {
          showToast("Constituency has been added successfully", "success");
        }
        getConstituencyList();
      } else {
        if (isEdit) {
          if (
            response.data.updateConstituencyDetails.includes(
              "unique_constituency"
            )
          ) {
            showToast(
              "Constituency already exists. Cannot insert duplicate constituency",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveConstituencyDetails.includes(
              "unique_constituency"
            )
          ) {
            showToast(
              "Constituency already exists. Cannot insert duplicate constituency",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setSelected({
        value: editConstituencyDetails?.districtname,
        label: editConstituencyDetails?.districtname,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    getDistrictList();
  }, []);

  return (
    <section>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          defaultName="districtname"
          register={register}
          labelname="District"
          required={true}
          pattern={null}
          errors={errors}
          classes={`rounded-lg text-sm w-full`}
          setError={setError}
          clearError={clearErrors}
          onChangeInput={null}
          control={control}
          data={districtList}
          defaultValue={defaultValues.districtname}
          setValue={setValue}
          setSelected={setSelected}
          selected={selected}
          maxMenuHeight={120}
        />
        <Input
          defaultName="constituencyname"
          register={register}
          name="Constituency Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter constituency name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.constituencyname}
          setValue={setValue}
        />

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditConstituencyDetails({});
              setValue("constituencyname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Constituency Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddConstituencyForm;
