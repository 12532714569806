/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { useForm } from "react-hook-form";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { button } from "../../../../theme/lightTheme";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import moment from "moment";
import { CREATE_ORDER_API } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import BillDeskSdk from "./billDeskSDK.component";
import FingerprintJS from "@fingerprintjs/fingerprintjs";

export default function PaymentUAT() {
  const [orderObj, setOrderObj] = useState({});
  const [lunchSDK, setLunchSDK] = useState(false);

  const [showSDK, setShowSDK] = useState(false);

  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const [fingerprintId, setFingerprintId] = useState(null);

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const userAgent = window.navigator.userAgent;
  const language = window.navigator.language;

  // const fetchUserInfo = async () => {
  //   try {
  //     const response = await axios.get("https://api.ipify.org/?format=json", {
  //       signal: controller.signal,
  //     });
  //     console.log("response to fetch ip", response);

  //     setIpAddress(response?.data);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // };

  // console.log("userInfo", ipAddress?.ip);

  // useEffect(() => {
  //   fetchUserInfo();
  // }, [ipAddress]);

  const createOrderApi = async (data) => {
    console.log("data==", data?.repaymentamount);
    console.log("amount", parseFloat(data?.repaymentamount).toFixed(2));
    const timestamp = moment().format("YYYYMMDDHHmmss");
    // const ORDERNO = `${timestamp}8056`;
    const ORDERNO = `8056${timestamp}`;

    try {
      const payload = {
        orderid: ORDERNO,
        // amount: parseFloat(data?.repaymentamount).toFixed(2),
        amount: parseFloat(data?.repaymentamount).toFixed(2),
        currency: "356",
        ru: process.env.REACT_APP_PAYMENT_RESPONSE_URL,
        additional_info: {
          additional_info1: "8056",
          additional_info2: "Kripa Charya Chettri",
          additional_info3: "lonaee",
          additional_info4: "SABCCO8056",
          additional_info5: ORDERNO,
          additional_info6: "8976589765",
          additional_info7:
            "Development Area Below Little Pixies School GANGTOK, SIKKIM",
        },
        device: {
          init_channel: "internet",
          user_agent: userAgent,
          accept_header: "text/html",
          browser_tz: new Date().getTimezoneOffset().toString(),
          browser_color_depth: window.screen.colorDepth.toString(),
          // browser_java_enabled: navigator.javaEnabled() ? "true" : "false",
          fingerprintid: fingerprintId,
          browser_java_enabled: "false",
          browser_screen_height: window.screen.height.toString(),
          browser_screen_width: window.screen.width.toString(),
          browser_language: language,
          browser_javascript_enabled: navigator.userAgent.includes("JavaScript")
            ? "true"
            : "false",
        },
      };

      console.log({ payload });

      const response = await axiosPrivate.post(
        CREATE_ORDER_API,
        {
          payload: JSON.stringify(payload),
        },
        {
          headers: {
            "Content-Type": "application/json", // Set the content-type header
          },
          signal: controller.signal,
        }
      );
      console.log("response for CREATE_ORDER_API ", response);

      if (response.status === 200) {
        const ordObj = {
          merchantId: response?.data?.createOrderAPI?.mercid,
          bdOrderId: response?.data?.createOrderAPI?.bdorderid,
          authToken:
            response?.data?.createOrderAPI?.links[1]?.headers?.authorization,
        };
        setOrderObj(ordObj);
        setLunchSDK(true);
      }
    } catch (error) {
      console.log("error", error);
      console.error("createOrderApi", error);
      setLunchSDK(false);
      // navigation.navigate("ErrorScreen");
    }
  };

  const onSubmit = async (data) => {
    createOrderApi(data);
    console.log(orderObj);
  };

  useEffect(() => {
    const loadFingerprint = async () => {
      const fp = await FingerprintJS.load();
      const result = await fp.get();
      setFingerprintId(result.visitorId);
    };

    loadFingerprint();
  }, []);

  useEffect(() => {
    if (lunchSDK) {
      setShowSDK(true);
    }
  }, [orderObj, lunchSDK]);

  return (
    <>
      {showSDK && orderObj ? (
        <>
          <HeroBanner>
            <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
              <div className="text-center">
                <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                  Repayment
                </p>
              </div>
            </div>
          </HeroBanner>
          <div className="flex items-center justify-center w-full mx-auto bg-white">
            <BillDeskSdk
              orderObj={orderObj}
              responseUrl={process.env.REACT_APP_PAYMENT_RESPONSE_URL}
            />
          </div>
        </>
      ) : (
        <>
          <HeroBanner>
            <div className="absolute left-24 md:left-[35%] lg:left-[45%] right-10 md:right-14 lg:right-10 top-1/2">
              <div className="text-center">
                <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                  Repayment
                </p>
              </div>
            </div>
          </HeroBanner>
          {/* 
          <div className="flex items-center justify-center w-full mx-auto bg-white">
            <BillDeskSdk
              orderObj={null}
              responseUrl={process.env.REACT_APP_PAYMENT_RESPONSE_URL}
            />
          </div> */}

          <div className="my-10 mx-10 border px-6 py-4">
            {/* <div className="p-9 bg-yellow-100 ">
              <button
                className="mx-auto px-9 py-2 bg-green-800 text-white"
                onClick={() => setShowSDK((prev) => !prev)}
              >
                Try SDK
              </button>
            </div> */}
            <span className="uppercase border-b-2 border-black text-black">
              Loanee Details
            </span>
            <div className="pt-3 flex flex-col text-sm">
              <span>Name: Kripa Charya Chettri</span>
              <span>Loan Id: 2024</span>
              <span>
                Address: Development Area Below Little Pixies School GANGTOK,
                SIKKIM - 737101,India
              </span>
              <span>Phone: 89765-89765</span>
            </div>
          </div>

          <div className="h-screen flex flex-col justify-start items-center pt-6 ">
            <div className="w-11/12 md:w-4/6 lg:w-2/4 xl:w-2/6 2xl:w-2/6">
              <ContainerShadow>
                <div className="text-center pb-4">
                  <p className="text-xl font-medium text-primary ">Repayment</p>
                </div>

                <form onSubmit={handleSubmit(onSubmit)}>
                  <Input
                    defaultName="repayment_amount"
                    register={register}
                    name="Repayment Amount"
                    required={false}
                    pattern={null}
                    errors={errors}
                    placeholder="Enter an amount"
                    setError={setError}
                    clearError={clearErrors}
                    autoComplete="off"
                    type="number"
                    classes={`rounded-sm px-3 py-2 text-sm w-[100%] `}
                    onChangeInput={null}
                    defaultValue={1}
                    setValue={setValue}
                  />
                  <div className="pt-6">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      label={"Proceed to payment"}
                      className={button}
                    />
                  </div>
                </form>
              </ContainerShadow>
            </div>
          </div>
        </>
      )}
    </>
  );
}
