import React, { useEffect, useState } from "react";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { MdRemoveRedEye } from "react-icons/md";

export default function DemandNotice_List({
  demandNoticeDetails,
  setShowNotice,
  setLoaneeReferenceNumberDetails,
  setShowFinalNotice,
}) {
  const handleviewDemandNoticeList = (demandNoticeDetails) => {
    setShowNotice(true);
    setLoaneeReferenceNumberDetails(demandNoticeDetails);

    // --- Below mention if statement is for view button(Demand Notice Details) to identify  wheather it is Demand Notice or Final notice
    // change its state accordingly---

    if (demandNoticeDetails?.notice_type === "Demand Notice") {
      setShowFinalNotice(false);
    }
    if (demandNoticeDetails?.notice_type === "Final Notice") {
      setShowFinalNotice(true);
    }
  };

  return (
    <div>
      <TableWrapper>
        <thead className="bg-white text-sm sticky top-0 z-0">
          <tr className="bg-slate-200 ">
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              SL.No
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Reference Number
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Generated Date
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Notice Type
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {demandNoticeDetails?.length > 0 &&
            demandNoticeDetails?.map((demandNoticeDetails, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap text-sm  text-slate-800 font-medium ">
                  <div className=" px-3 py-1 ">{index + 1}</div>
                </td>

                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {demandNoticeDetails?.reference_number}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {demandNoticeDetails?.generated_date}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {demandNoticeDetails?.notice_type}
                </td>

                <td className="px-6 py-2 whitespace-nowrap text-sm w-24 ">
                  <span
                    className="cursor-pointer "
                    onClick={() =>
                      handleviewDemandNoticeList(demandNoticeDetails)
                    }
                  >
                    <span className="text-primary">
                      <MdRemoveRedEye size={18} />
                    </span>
                  </span>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
    </div>
  );
}
