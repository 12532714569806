import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import sabccologo from "../../assets/SABCCO Logo.png";
import AnimatedDiv from "./animatedBg.component";
import Countdown from "./counter.component";

const LaunchPageConfetti = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/", { state: { fromLaunchPage: true } });
    }, 3000);

    return () => clearTimeout(timer);
  }, [navigate]);

  return (
    <section>
      <div className="bg-white min-h-screen overflow-hidden">
        <section className="relative lg:flex flex-col justify-center items-center h-auto w-full">
          <div className="w-full">
            <AnimatedDiv />
          </div>
          <div>
            <div className="flex flex-col justify-center items-center mb-[3rem]">
              <p className="text-3xl md:text-5xl lg:text-8xl font-bold text-primary leading-relaxed animate-pulse md:ml-[2rem] pt-6">
                New Portal Launch!
              </p>
            </div>
            <div className="flex justify-center gap-x-3 items-center mb-[1rem]">
              <div className="flex justify-center w-auto h-[40vh]">
                <img src={sabccologo} alt="SABCCO Logo" />
              </div>
            </div>
            <div className="flex flex-col justify-center items-center mb-[3rem] pt-3">
              <p className="text-center md:text-3xl lg:text-5xl font-bold text-primary md:leading-relaxed border-b-2 border-slate-600 border-spacing-x-3 mb-3 mx-3 md:px-0 mt-10 md:mt-0">
                Sikkim SC,ST& OBC Development Corporation Limited
              </p>
              <p className="text-xl lg:text-4xl  tracking-[0.5rem] lg:tracking-[1rem]  font-bold">
                SABCCO
              </p>
            </div>
            <div>
              <p className="text-xl lg:text-2xl mb-[1rem] text-slate-700 font-medium font-roboto tracking-widest text-center">
                Launching SABCCO Web Portal In...
              </p>
              <div>
                <Countdown />
              </div>
            </div>
          </div>
        </section>
      </div>
    </section>
  );
};

export default LaunchPageConfetti;
