import React, { useEffect } from "react";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FaUsers } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  LOANEE_DEATH_CASE,
  NOC_APPLICATION_LIST_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { Paging } from "../../../../reusable-components/paging/Paging";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import showToast from "../../../../utilities/notification/NotificationModal";
import NocApplicationList from "./NocAplicationList";
import ViewNocApplication from "./ViewNocApplication";

export default function NocApplicationConfig() {
  const [refresh, setRefresh] = useState();
  const [viewNocApplicationDetails, setViewNocApplicationDetail] =
    useState(false);

  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState();

  const [loaneeNocApplicationList, setLoaneeNocApplicationList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const [nocApplicationDetail, setNocApplicationDetail] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();

  const getNocApplicationList = async (page_no, page_size, search_text) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${NOC_APPLICATION_LIST_URL}`,
        {
          params: {
            pgno: page_no || pageNo || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setLoaneeNocApplicationList(response?.data?.nocApplicationList);
        console.log(response?.data?.nocApplicationList);

        const totalNumberOfDeathList =
          +response?.data?.nocApplicationList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfDeathList / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Death List  found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  useEffect(() => {
    getNocApplicationList(pageNo, pageSize?.value, searchText);
  }, [pageNo, pageSize, refresh]);

  return (
    <>
      <Dashboard sidebarType={auth?.rolename}>
        <ToastContainer />

        {!viewNocApplicationDetails ? (
          <>
            <div className="flex flex-col md:flex-row md:items-center justify-between w-full ">
              <H2withIcon>
                <IconContext.Provider value={{ className: "text-secondary" }}>
                  <FaUsers />
                </IconContext.Provider>
                <span>&nbsp; NOC Application List</span>
              </H2withIcon>

              <>
                <section className="flex justify-between items-center gap-3 md:w-[30%] mb-4 z-10 text-xs  md:text-sm">
                  <SearchField
                    placeholder="Search"
                    defaultValue={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onClick={() => {
                      setPageNo(1);
                      getNocApplicationList(
                        1,
                        pageSize?.value || 10,
                        searchText || ""
                      );
                    }}
                    classes={"bg-primary"}
                    onKeyDown={(e) => {
                      if (e?.key === "Enter") {
                        getNocApplicationList(
                          1,
                          pageSize?.value,
                          searchText || ""
                        );
                      }
                    }}
                  />
                </section>
              </>
            </div>

            <div className="grid grid-cols-1">
              <NocApplicationList
                loaneeNocApplicationList={loaneeNocApplicationList}
                setViewNocApplicationDetail={setViewNocApplicationDetail}
                setNocApplicationDetail={setNocApplicationDetail}
              />

              <Paging
                maxMenuHeight={100}
                selected={pageSize}
                setSelected={setPageSize}
                pageNo={pageNo}
                onPageChangeHandler={onPageChangeHandler}
                numberOfPages={numberOfPages}
              />
            </div>
          </>
        ) : (
          <ViewNocApplication
            setViewNocApplicationDetail={setViewNocApplicationDetail}
            nocApplicationDetail={nocApplicationDetail}
            setNocApplicationDetail={setNocApplicationDetail}
            setRefresh={setRefresh}
          />
        )}
      </Dashboard>
    </>
  );
}
