import React, { Fragment } from "react";

const SearchField = ({
  defaultValue,
  placeholder,
  onChange,
  onClick,
  classes,
  onKeyDown,
}) => {
  return (
    <Fragment>
      <section className="items-center flex border w-full   justify-between">
        <input
          defaultValue={defaultValue}
          onChange={(e) => {
            if (e?.target?.value !== null && e?.target?.value !== undefined) {
              onChange(e?.target?.value);
            }
          }}
          placeholder={placeholder}
          className="outline-none w-full text-sm p-1.5"
          onKeyDown={onKeyDown}
        />
        <div
          className={`${classes} cursor-pointer h-9 w-9 flex items-center`}
          onClick={onClick}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-5 w-5 text-white m-auto"
            viewBox="0 0 20 20"
            fill="currentColor"
          >
            <path
              fillRule="evenodd"
              d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
              clipRule="evenodd"
            />
          </svg>
        </div>
      </section>
    </Fragment>
  );
};

export default SearchField;
