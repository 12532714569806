/* eslint-disable no-unused-vars */
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import {
  rePaymentFormModeDD,
  rePaymentFormModeDDforOTS,
} from "../../../../utilities/constants/constants";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import showToast from "../../../../utilities/notification/NotificationModal";
import { BsDot } from "react-icons/bs";
import { OTS_CONFIG_URL } from "../../../../api/api_routing_urls";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { FiEye } from "react-icons/fi";

import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import PrintReceipt from "../../ReportsList/Ledger/PrintReceipt";
import { ReceiptPrintReport } from "../../ReportsList/PrintReport/ReceiptPrintReport";
import OtsReceiptPrint from "./OtsReceiptPrint";
import OtsReceipt from "./OtsReceipt";

const AddOneTimeSettlementRepaymentForm = ({
  otsLoaneeDetails,
  setShowAddForm,
  loaneeData,
  setRefreshPage,
  otsLoaneeDue,
  activeTab,
  setActiveTab,
  receiptRepaymentList,
  getReceiptRepaymentDetail,
  getOTSDetail,
  getOTSDue,
  loaneeTotalRepaymentAmt,
}) => {
  const [selectedRepaymentMode, setSelectedRepaymentMode] = useState();

  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState(null);
  const [showReceiptDetails, setShowReceiptDetails] = useState(false);
  const [receiptDetails, setReceiptDetails] = useState(false);
  const [specificChequeDate, setSpecificChequeDate] = useState(false);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: otsLoaneeDetails,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async () => {
    const data = formData;
    let submitData = {
      loan_number: otsLoaneeDetails?.loan_number,
      paymentmode: data?.paymentmode?.value,
      repayment_date: data?.repaymentdate,
      repayamount: data?.repaymentamount,
      // chequeno: data?.chequeno,
      transectionid: data?.transectionid,
      particulars: data?.particulars,
      createdby: auth.userid,
      // cheque_date: data?.chequedate,
    };

    try {
      const response = await axiosPrivate.post(
        `${OTS_CONFIG_URL}/otsRepaymentLedger`,
        submitData,
        { signal: controller.signal }
      );

      if (response.status === 200) {
        showToast("Repayment detail has been added successfully", "success");
        getReceiptRepaymentDetail();
        reset();
        setReceiptDetails(false);
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator",
          "error"
        );
      }
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact the administrator",
          "error"
        );
      }
    } finally {
      setShowAddForm(true);
      setActiveTab("receipt");

      // setRefreshPage((prev) => !prev);
    }
  };

  const handleFormSubmit = (data) => {
    if (+data.repaymentamount <= +otsLoaneeDue?.topay_amt) {
      setFormData(data);
      setShowModal(true);
    } else {
      showToast(
        "Repayment Amount is more than the total due amount!!",
        "error"
      );
    }
  };

  const handleViewReceipts = (receiptDetailsObj) => {
    setShowReceiptDetails(true);
    setReceiptDetails(receiptDetailsObj);
  };

  useEffect(() => {
    getOTSDetail();
    getOTSDue();
  }, []);

  return (
    <>
      <div className="flex justify-start gap-x-3  pb-3 ">
        <div
          className={`${
            activeTab === "makeRepayment"
              ? "bg-slate-700 text-white p-1 rounded cursor-pointer"
              : "p-1 cursor-pointer "
          }`}
          onClick={() => {
            setActiveTab("makeRepayment");
            setShowModal(false);
            getOTSDetail();
            getOTSDue();
          }}
        >
          Make Repayment
        </div>
        <span className="text-2xl"> / </span>
        <div
          className={`${
            activeTab === "receipt"
              ? "bg-slate-700 text-white p-1 rounded cursor-pointer"
              : "p-1 cursor-pointer"
          }`}
          onClick={() => {
            setActiveTab("receipt");
            getReceiptRepaymentDetail();
          }}
        >
          Receipts
        </div>
      </div>

      {activeTab === "makeRepayment" && (
        <>
          <section className="bg-white border p-6 text-slate-800">
            <div className="pb-6 text-sm flex items-center flex-wrap gap-1">
              <div>
                Loan Id:{" "}
                <span className="font-semibold">{loaneeData?.loanee_id}</span>
              </div>
              <BsDot size={14} />
              <div>
                Loan Number:{" "}
                <span className="font-semibold">
                  {otsLoaneeDetails?.loan_number}
                </span>
              </div>
              <BsDot size={14} />
              <div>
                Loanee Name:{" "}
                <span className="font-semibold">
                  {loaneeData?.applicantname}
                </span>
              </div>
            </div>
            <div className="grid grid-cols-4 gap-3">
              <LabelWithDetail
                label="Principle Outstanding"
                value={formatINRCurrency(
                  otsLoaneeDetails?.principleoutstanding || 0
                )}
              />
              <LabelWithDetail
                label="Interest Due"
                value={formatINRCurrency(otsLoaneeDetails?.int_due || 0)}
              />
              <LabelWithDetail
                label="IOI Due"
                value={formatINRCurrency(otsLoaneeDetails?.ioi_due || 0)}
              />
              <LabelWithDetail
                label="Penal Due"
                value={formatINRCurrency(otsLoaneeDetails?.penal_due || 0)}
              />
              <LabelWithDetail
                label="Waiver Rate (%)"
                value={otsLoaneeDetails?.waiver_percentage || 0}
              />
              <LabelWithDetail
                label="Waiver Amount"
                value={formatINRCurrency(otsLoaneeDetails?.waiveramount || 0)}
              />
              <LabelWithDetail
                label="Interest Due after Waiver"
                value={formatINRCurrency(
                  +otsLoaneeDetails?.int_due +
                    +otsLoaneeDetails?.penal_due +
                    +otsLoaneeDetails?.ioi_due -
                    +otsLoaneeDetails?.waiveramount || 0
                )}
              />
              <LabelWithDetail
                label="Total Due Amount"
                value={formatINRCurrency(
                  +otsLoaneeDetails?.repayment_due_amt || 0
                )}
                focus={true}
              />
            </div>
            <div className="grid grid-cols-2 gap-3 mt-4 pt-4 border-t">
              <LabelWithDetail
                label="Paid Amount"
                value={formatINRCurrency(+otsLoaneeDue?.repay_amt || 0)}
                focus={true}
              />
              <LabelWithDetail
                label="Remaining Due Amount"
                value={formatINRCurrency(+otsLoaneeDue?.topay_amt || 0)}
                focus={true}
              />
            </div>

            {loaneeData?.loan_status === "Active" ||
            +otsLoaneeDue?.topay_amt > 0 ? (
              <>
                <form onSubmit={handleSubmit(handleFormSubmit)}>
                  <div className="grid grid-cols-3 gap-x-3 mt-6">
                    <fieldset className="col-span-3  p-2 bg-slate-100 border grid grid-cols-1 md:grid-cols-3 gap-x-3 mt-2">
                      <legend className="px-2 ml-3 bg-slate-700 rounded-sm text-white text-sm p-1">
                        Repayment
                      </legend>
                      <Input
                        defaultName="repaymentdate"
                        register={register}
                        name="Repayment Date"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter repayment date"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="date"
                        classes={`rounded-lg px-3 py-2 text-sm w-full`}
                        onChangeInput={(e) => {
                          if (
                            getFormattedDateInputDate(e) >=
                            getFormattedDateInputDate(
                              otsLoaneeDetails?.lastrepaymentdate
                            )
                          ) {
                            clearErrors("repaymentdate");
                            clearErrors(`repaymentdate_onChange`);
                          } else {
                            setError("repaymentdate", {
                              type: "required",
                              message: `Repayment date is invalid`,
                            });
                          }
                        }}
                        defaultValue={getFormattedDateInputDate(new Date())}
                        setValue={setValue}
                      />
                      <Input
                        defaultName="repaymentamount"
                        register={register}
                        name="Repayment Amount"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter repayment amount"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="number"
                        classes={`rounded-lg px-3 py-2 text-sm w-full`}
                        onChangeInput={(e) => {
                          console.table(e);
                        }}
                        defaultValue={+otsLoaneeDue?.topay_amt}
                        setValue={setValue}
                        max={+otsLoaneeDue?.topay_amt}
                      />

                      <Dropdown
                        defaultName="paymentmode"
                        register={register}
                        labelname="Payment Mode"
                        required={true}
                        pattern={null}
                        errors={errors}
                        classes={`rounded-lg text-sm w-full`}
                        setError={setError}
                        clearError={clearErrors}
                        onChangeInput={null}
                        control={control}
                        data={rePaymentFormModeDDforOTS}
                        defaultValue={otsLoaneeDetails?.paymentmode}
                        setValue={setValue}
                        setSelected={setSelectedRepaymentMode}
                        selected={selectedRepaymentMode}
                        maxMenuHeight={120}
                      />
                    </fieldset>

                    <fieldset className="col-span-3 px-2 grid grid-cols-1 md:grid-cols-3 gap-x-3 text-sm mt-6 ">
                      <legend className="text-slate-700  text-sm  underline underline-offset-2">
                        Other details
                      </legend>
                      <TextArea
                        defaultName="particulars"
                        register={register}
                        name="Particulars"
                        required={false}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter particulars"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full resize-none `}
                        onChangeInput={null}
                        defaultValue={null}
                        setValue={setValue}
                      />
                      {/* <Input
                        defaultName="chequeno"
                        register={register}
                        name="Cheque No"
                        required={false}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter cheque no"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="numeric"
                        classes={`rounded-lg px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={otsLoaneeDetails?.chequeno}
                        setValue={setValue}
                      /> */}
                      {/* <Input
                        defaultName="transectionid"
                        register={register}
                        name="Transaction Id"
                        required={false}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter transaction id"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-lg px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={otsLoaneeDetails?.transectionid}
                        setValue={setValue}
                      /> */}

                      {/* <Input
                        defaultName="chequedate"
                        register={register}
                        name="Cheque Date"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter cheque date"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="date"
                        classes={`rounded-lg px-3 py-2 text-sm w-full`}
                        onChangeInput={(e) => {
                          setSpecificChequeDate(e);
                        }}
                        defaultValue={getFormattedDateInputDate(new Date())}
                        setValue={setValue}
                      /> */}
                    </fieldset>
                  </div>
                  <div className="flex justify-between mt-6">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      label={"Submit"}
                      className={button}
                    />
                    <Button
                      onClick={() => {
                        setShowAddForm(false);
                      }}
                      aria-label="Cancel District Update Button"
                      className={cancel_button}
                    >
                      Cancel
                    </Button>
                  </div>
                </form>

                <GenericModal
                  open={showModal}
                  setOpen={setShowModal}
                  title={"Receipt Repayment"}
                  isLarge={true}
                  isAdd={false}
                >
                  <OtsReceipt
                    otsLoaneeDetails={otsLoaneeDetails}
                    formData={formData}
                    otsLoaneeDue={otsLoaneeDue}
                    loaneeData={loaneeData}
                    loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                  />

                  {/* <div className="border-slate-100 bg-slate-50 px-6 py-2 font-semibold text-slate-700">
                    <p>Loanee Name : {loaneeData?.applicantname}</p>
                    <p>Loanee Scheme : {loaneeData?.schemename}</p>
                    <p>
                      Repayment Amount:{" "}
                      {formatINRCurrency(formData?.repaymentamount)}
                    </p>
                    <p>
                      Repayment Date :{" "}
                      {getFormattedDateDDMMMYYYYDate(formData?.repaymentdate)}
                    </p>
                    <p>Payment Mode: {formData?.paymentmode?.label}</p>
                    <p>Particulars : {formData?.particulars}</p>
                  </div> */}
                  <div className="flex justify-end mt-6 gap-x-6">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      label={"Confirm"}
                      className={button}
                      onClick={onSubmit}
                    />
                    <Button
                      onClick={() => {
                        setShowModal(false);
                      }}
                      aria-label="Cancel Modal Button"
                      className={cancel_button}
                    >
                      Cancel
                    </Button>
                  </div>
                </GenericModal>
              </>
            ) : null}
          </section>
        </>
      )}

      {activeTab === "receipt" && (
        <section className="pt-6">
          <TableWrapper>
            <thead className="bg-slate-200 text-sm my-3 sticky top-0">
              <tr>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900 "
                >
                  Receipt No
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900 "
                >
                  Repayment Date
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900 "
                >
                  Repayment Amount
                </th>
                <th
                  scope="col"
                  className="px-3 py-2 text-left font-medium text-slate-900 "
                >
                  Mode
                </th>

                <th
                  scope="col"
                  className="px-3 py-2 font-medium text-slate-900  w-[10%]"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
              {receiptRepaymentList?.length > 0 ? (
                // Sort receiptRepaymentList by receipt_no in descending order
                receiptRepaymentList
                  .slice() // Create a copy to avoid mutating original array
                  .sort((a, b) => {
                    // Assuming receipt_no is numeric, convert to numbers for comparison
                    const receiptNoA = parseInt(a.receipt_no);
                    const receiptNoB = parseInt(b.receipt_no);
                    return receiptNoB - receiptNoA; // Sort in descending order by receipt number
                  })
                  .map((receiptDetailsObj, index) => (
                    <tr
                      key={index}
                      className={index % 2 === 1 ? "bg-slate-100" : ""}
                    >
                      <td className="px-3 py-2 whitespace-nowrap">
                        {receiptDetailsObj?.receipt_no}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        {getFormattedDateDDMMMYYYYDate(
                          receiptDetailsObj?.rp_date
                        )}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        {formatINRCurrency(receiptDetailsObj?.rp_amount)}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        {receiptDetailsObj?.rp_mode}
                      </td>
                      <td className="px-3 py-2 whitespace-nowrap">
                        <div
                          onClick={() => handleViewReceipts(receiptDetailsObj)}
                        >
                          <div className="flex items-center justify-center gap-3 text-black hover:text-primary cursor-pointer">
                            <FiEye size={16} color="green" />
                            <p className=""></p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))
              ) : (
                <tr>
                  <td colSpan={5} className="p-4 text-center">
                    No Record Found
                  </td>
                </tr>
              )}
            </tbody>
          </TableWrapper>
          <>
            {showReceiptDetails && (
              <>
                <GenericModal
                  open={showReceiptDetails}
                  setOpen={setShowReceiptDetails}
                  title={"Receipt Repayment"}
                  isLarge={true}
                  isAdd={false}
                >
                  <OtsReceiptPrint
                    receiptDetails={receiptDetails}
                    otsLoaneeDetails={otsLoaneeDetails}
                    otsLoaneeDue={otsLoaneeDue}
                    loaneeData={loaneeData}
                    loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                  />

                  <div className="flex justify-end items-center gap-x-6">
                    <ReceiptPrintReport reportTitle={"Receipt"} buttonName={""}>
                      <OtsReceiptPrint
                        receiptDetails={receiptDetails}
                        otsLoaneeDetails={otsLoaneeDetails}
                        otsLoaneeDue={otsLoaneeDue}
                        loaneeData={loaneeData}
                        loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                      />
                    </ReceiptPrintReport>
                    <Button
                      className={cancel_button}
                      onClick={() => {
                        setShowReceiptDetails(false);
                      }}
                    >
                      Close
                    </Button>
                  </div>
                </GenericModal>
              </>
            )}
          </>
        </section>
      )}
    </>
  );
};

export default AddOneTimeSettlementRepaymentForm;

const LabelWithDetail = ({ label, value, focus }) => {
  return (
    <>
      <section
        className={`py-0.5 px-3 border ${focus ? " bg-green-100" : null}`}
      >
        <span className="font-medium text-sm">{label}</span>
        <div className={` font-semibold  `}>{value}</div>
      </section>
    </>
  );
};
