import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { ToastContainer } from "react-toastify";
import { BANK_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import DistrictList from "../districtConfig/DistrictList";
import AddBankForm from "./AddBankForm";
import BankList from "./BankList";
// import DeleteModal from "../../../reusable-components/modals/DeleteModal";

import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function BankConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [showAddForm, setShowAddForm] = useState(false);
  const [editBankDetails, setEditBankDetails] = useState({});
  const [bankList, setBankList] = useState([]);
  const [bankId, setBankId] = useState();
  const [showDelete, setShowDelete] = useState(false);

  const isEdit = Object.keys(editBankDetails).length > 0 ? true : false;

  const getBankList = async () => {
    try {
      const response = await axiosPrivate.get(BANK_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setBankList(response.data.bankList);
      response.status === 202 &&
        showToast("No bank list found in the system", "error");

      
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const deleteBank = async () => {
    try {
      const data = {};
      data.bankid = bankId;
      const response = await axiosPrivate.post(
        `${BANK_CONFIG_URL}/delete`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Bank deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getBankList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    getBankList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Bank"}
          setShowAddForm={setShowAddForm}
        />

        <GenericModal
          open={showAddForm}
          setOpen={setShowAddForm}
          title={` ${isEdit ? "Update Bank" : "Add Bank"}`}
          isAdd={true}
        >
          <AddBankForm
            getBankList={getBankList}
            setShowAddForm={setShowAddForm}
            setEditBankDetails={setEditBankDetails}
            editBankDetails={editBankDetails}
          />
        </GenericModal>

        <BankList
          bankList={bankList}
          setEditBankDetails={setEditBankDetails}
          setShowAddForm={setShowAddForm}
          setBankId={setBankId}
          setShowDelete={setShowDelete}
        />

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteBank}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
