/* eslint-disable react/jsx-pascal-case */
import React, { useState, useEffect, useRef, useMemo } from "react";
import { NavLink, useLocation } from "react-router-dom";
import SystemAdminSidebar from "../area/system-admin/SystemAdminSidebar";

import DEOAdminSidebar from "../area/data-entry-operator/DEOAdminSidebar";
import DVAdminSidebar from "../area/document-verifier/DVAdminSidebar";
import GMAdminSidebar from "../area/general-manager/GMAdminSidebar";
import MDAdminSidebar from "../area/managing-director/MDAdminSidebar";
import LegalAdminSidebar from "../area/legal-role/LegalAdminSidebar";
import AccountsAdminSidebar from "../area/accounts-role/AccountsAdminSidebar";
import RecordKeeperSidebar from "../area/record-keeper/RecordKeeperSidebar";
import AssistantManagerSidebar from "../area/assistant-manager/AssistantManagerSidebar";
import ContentAdminSidebar from "../area/content-admin/ContentAdminSidebar";

import useAuth from "../authentication/hooks/useAuth";
import UserMenu from "./header/UserMenu";
import CashCounterSidebar from "../area/cash-counter/CashCounterSidebar";
import AM_II_AdminSidebar from "../area/A.M-II/AM-II_AdminSidebar";
import Sr_OA_II_AdminSidebar from "../area/Sr.O.A-II/Sr.O.A-II_AdminSidebar";
import Cashier_AdminSidebar from "../area/Cashier/Cashier_AdminSidebar";

function Sidebar({ sidebarOpen, setSidebarOpen, sidebarType }) {
  const location = useLocation();
  const { pathname } = location;

  const trigger = useRef(null);
  const sidebar = useRef(null);

  const storedSidebarExpanded = localStorage.getItem("sidebar-expanded");
  const [sidebarExpanded, setSidebarExpanded] = useState(true);
  const [nameInitial, setNameInitial] = useState();

  const { auth } = useAuth();

  const getNameInitial = () => {
    if (auth) {
      let name = auth?.fullname?.split(" ");
      let nameinit = "";
      name?.map((nameObj) => {
        nameinit += nameObj.substring(0, 1);
      });
      setNameInitial(nameinit.toUpperCase());
    }
  };

  useMemo(() => {
    getNameInitial();
  }, [auth]);

  // close if the esc key is pressed
  useEffect(() => {
    const keyHandler = ({ keyCode }) => {
      if (!sidebarOpen || keyCode !== 27) return;
      setSidebarOpen(false);
    };
    document.addEventListener("keydown", keyHandler);
    return () => document.removeEventListener("keydown", keyHandler);
  });

  useEffect(() => {
    localStorage.setItem("sidebar-expanded", sidebarExpanded);
    if (sidebarExpanded) {
      document.querySelector("body").classList.add("sidebar-expanded");
    } else {
      document.querySelector("body").classList.remove("sidebar-expanded");
    }
  }, [sidebarExpanded]);

  return (
    <section>
      {/* Sidebar backdrop (mobile only) */}
      <div
        className={`fixed inset-0 bg-slate-900 bg-opacity-30 z-40 lg:hidden lg:z-auto transition-opacity duration-200 ${
          sidebarOpen ? "opacity-100" : "opacity-0 pointer-events-none"
        }`}
        aria-hidden="true"
      ></div>

      {/* Sidebar */}
      <div
        id="sidebar"
        ref={sidebar}
        className={`flex flex-col absolute z-40 left-0 top-0 lg:static lg:left-auto lg:top-auto lg:translate-x-0 
        transform h-screen overflow-y-scroll lg:overflow-y-auto no-scrollbar w-64 lg:w-20 lg:sidebar-expanded:!w-64
        2xl:!w-64 shrink-0 bg-slate-900 p-3 transition-all duration-200 ease-in-out ${
          sidebarOpen ? "translate-x-0" : "-translate-x-64"
        }`}
      >
        {/* Sidebar header */}
        <div className="flex justify-between mb-2 pr-3 sm:px-2">
          {/* Close button */}
          <button
            ref={trigger}
            className="lg:hidden text-slate-200 hover:text-slate-300"
            onClick={() => {
              setSidebarOpen(!sidebarOpen);
            }}
            aria-controls="sidebar"
            aria-expanded={sidebarOpen}
          >
            <span className="sr-only">Close sidebar</span>
            <svg
              className="w-6 h-6 fill-current"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M10.7 18.7l1.4-1.4L7.8 13H20v-2H7.8l4.3-4.3-1.4-1.4L4 12z" />
            </svg>
          </button>
          {/* Logo */}
          {/* <NavLink end to="/" className="block">
            <div className="flex items-center">
              <img src={Logo} width="40" height="40" />
              {sidebarExpanded && (
                <p className="text-white text-sm font-medium pl-3">
                  SABCCO Ltd.
                </p>
              )}
            </div>
          </NavLink> */}
        </div>

        <div className="flex items-center justify-between text-white border-b pb-3 mb-1  border-slate-700">
          <div className=" flex items-center gap-3 rounded-sm">
            <span
              className="w-8 h-8 bg-slate-200 rounded-sm text-slate-800 
            font-bold  items-center flex justify-center pt-0.5"
            >
              {nameInitial}
            </span>
            {sidebarExpanded ? (
              <div className="text-xs flex flex-col items-start justify-end ">
                <div className="font-medium text-slate-100">
                  {auth?.fullname || "---"}{" "}
                </div>
                <div className="font-extralight text-slate-300">
                  {auth?.rolename || "--"}
                </div>
              </div>
            ) : null}
          </div>
          <div className="z-40">
            <UserMenu />
          </div>
        </div>

        {/* Links */}
        <div className="space-y-8">
          {/* Pages group */}
          <div>
            <h3 className="text-xs uppercase text-slate-100 font-semibold pl-3">
              {/* <span
                className="hidden lg:block lg:sidebar-expanded:hidden 2xl:hidden text-center w-6"
                aria-hidden="true"
              >
                •••
              </span> */}
              {/* <span className="lg:hidden lg:sidebar-expanded:block 2xl:block">
                Pages
              </span> */}
            </h3>

            {sidebarType === "Super Admin" && (
              <SystemAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}

            {sidebarType === "Data Entry Operator" && (
              <DEOAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Deputy General Manager" && (
              <DVAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "General Manager" && (
              <GMAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Managing Director" && (
              <MDAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Legal" && (
              <LegalAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Record Keeper" && (
              <RecordKeeperSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "DGM Accounts" && (
              <AccountsAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Assistant Manager" && (
              <AssistantManagerSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Content Admin" && (
              <ContentAdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Cash Counter" && (
              <CashCounterSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "A.M -II" && (
              <AM_II_AdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Sr.O.A-II" && (
              <Sr_OA_II_AdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
            {sidebarType === "Cashier" && (
              <Cashier_AdminSidebar
                pathname={pathname}
                sidebarExpanded={sidebarExpanded}
                setSidebarExpanded={setSidebarExpanded}
              />
            )}
          </div>
        </div>

        {/* Expand / collapse button */}
        <div className="pt-3 hidden lg:inline-flex 2xl:hidden justify-end mt-auto">
          <div className="px-3 ">
            <button
              onClick={() => {
                setSidebarExpanded(!sidebarExpanded);
              }}
            >
              <span className="sr-only">Expand / collapse sidebar</span>
              <svg
                className="w-6 h-6 fill-current sidebar-expanded:rotate-180"
                viewBox="0 0 24 24"
              >
                <path
                  className="text-slate-200"
                  d="M19.586 11l-5-5L16 4.586 23.414 12 16 19.414 14.586 18l5-5H7v-2z"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Sidebar;
