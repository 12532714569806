import React from "react";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import { useState } from "react";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import EmployeeDesignationForm from "./EmployeeDesignationForm";
import { useEffect } from "react";
import { EMP_DESIGNATION_URL } from "../../../../api/api_routing_urls";
import EmployeeDesignationList from "./EmployeeDesignationList";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function EmployeeDesignationConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [empDesignationId, setEmpDesignationId] = useState();

  const [empDesignationList, setEmpDesignationList] = useState([]);
  const [editEmpDesignationDetails, setEditEmpDesignationDetails] = useState();

  const getEmpDesignationList = async () => {
    try {
      const response = await axiosPrivate.get(EMP_DESIGNATION_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setEmpDesignationList(response?.data?.empDesigantion_List);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteEmpDesignationDetails = async () => {
    try {
      const data = {};
      data.emp_designation_id = empDesignationId;
      const response = await axiosPrivate.post(
        `${EMP_DESIGNATION_URL}/deleteEmpDesignation`,
        data,
        {
          signal: controller.signal,
        }
      );

      response.status === 200 &&
        showToast("Employee Designation deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the Employee Designation is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getEmpDesignationList();
      setShowDelete(false);
    }
  };

  let isEdit =
    editEmpDesignationDetails === undefined ||
    editEmpDesignationDetails === null
      ? false
      : Object?.keys(editEmpDesignationDetails)?.length > 0
      ? true
      : false;

  useEffect(() => {
    let isMounted = true;
    getEmpDesignationList();

    if (empDesignationId !== null && empDesignationId !== undefined) {
      deleteEmpDesignationDetails();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Employee Designation "}
          setShowAddForm={setShowAddForm}
        />

        {showAddForm ? (
          <>
            <GenericModal
              open={showAddForm}
              setOpen={setShowAddForm}
              title={` ${
                isEdit
                  ? "Update Employee Designation"
                  : "Add Employee Designation"
              }`}
              isAdd={true}
            >
              <EmployeeDesignationForm
                setShowAddForm={setShowAddForm}
                editEmpDesignationDetails={editEmpDesignationDetails}
                getEmpDesignationList={getEmpDesignationList}
                setEditEmpDesignationDetails={setEditEmpDesignationDetails}
              />
            </GenericModal>
          </>
        ) : (
          <EmployeeDesignationList
            empDesignationList={empDesignationList}
            setEditEmpDesignationDetails={setEditEmpDesignationDetails}
            setShowAddForm={setShowAddForm}
            setEmpDesignationId={setEmpDesignationId}
            setShowDelete={setShowDelete}
          />
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteEmpDesignationDetails}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
