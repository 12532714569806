import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { ABOUT_US_PUBLIC_URL } from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

const About = () => {
  const controller = new AbortController();

  const [aboutUs, setAboutus] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const getAboutUsDetails = async () => {
    try {
      const response = await axios.get(
        `${ABOUT_US_PUBLIC_URL}/aboutUsContent`,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && setAboutus(response.data.aboutUsDetails);
      response.status === 202 &&
        showToast("No about us summary found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getAboutUsDetails();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
          <div className="text-center">
            <H1>About Us</H1>
            {/* <p className="text-slate-300/70 w-full text-xs md:text-sm lg:text-base">
              {aboutUs.shortsummary}
            </p> */}
          </div>
        </div>
      </HeroBanner>
      {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container mx-auto my-6 ">
          <div
            className="leading-6"
            dangerouslySetInnerHTML={{ __html: aboutUs.aboutuscontent }}
          />
          {/* <p className="text-slate-700 w-full text-xs md:text-sm lg:text-base">
            {aboutUs?.shortsummary}
          </p> */}
        </div>
      )}
    </div>
  );
};

export default About;
