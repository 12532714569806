import React from "react";
import { Outlet } from "react-router-dom";
import PublicFooter from "../../common/publicNavbar/PublicFooter";
import PublicNavbar from "../../common/publicNavbar/PublicNavbar";

export function PublicLayout() {
  return (
    <>
      <PublicNavbar />
      <Outlet />
      <PublicFooter />
    </>
  );
}
