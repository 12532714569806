import React, { Fragment, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import Button from "../../../../reusable-components/buttons/Button";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  button,
  cancel_button,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";

export default function PublicationsList({
  publicationsList,
  setEditPublicationsDetails,
  publicationTypeList,
  setShowAddForm,
}) {
  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const onClickEdit = (publication) => {
    setEditPublicationsDetails(publication);
    setShowAddForm(true);
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${title} Document`);
    setShowPDFViewer(true);
  };

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
            >
              Publication Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
            >
              Publication Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
            >
              Publication Description
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center font-medium text-slate-900 uppercase tracking-wider"
            >
              Publication Document
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center font-medium text-slate-900 uppercase tracking-wider"
            >
              Posted On
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {publicationsList.length > 0 &&
            publicationsList.map((publication, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className="font-medium">
                    {publication.publicationtitle}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className="font-medium">
                    {publication.publicationtype}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className="font-medium text-justify">
                    {publication.publicationdesc}
                  </span>
                </td>

                <td className="px-6 py-4 whitespace-nowrap text-sm flex justify-center items-center">
                  <Fragment>
                    <IconContext.Provider
                      value={{ className: "text-red-700 text-5xl" }}
                    >
                      <FaFilePdf />
                    </IconContext.Provider>
                    <Button
                      className={linkTextPrimary}
                      onClick={() =>
                        onClickView(
                          publication.publicationdoc,
                          publication.publicationtitle
                        )
                      }
                    >
                      View
                    </Button>
                  </Fragment>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <span className="font-medium text-justify">
                    {publication.updatedat !== null
                      ? getFormattedDateDDMMMYYYYDate(publication.updatedat)
                      : getFormattedDateDDMMMYYYYDate(publication.createdat)}
                  </span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(publication)}
                      aria-label="Edit Publication Details"
                      className={linkTextPrimary}
                    >
                      Edit
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </Fragment>
  );
}
