import React from "react";
import Dashboard from "../../common/Dashboard";
import DashboardConfig from "../system-admin/Dashboard/DashboardConfig";

export default function AssistantManagerDashboard() {
  return (
    <Dashboard sidebarType="Assistant Manager">
      <DashboardConfig />
    </Dashboard>
  );
}
