import React from "react";
import { formatINRCurrency } from "../../../../../utilities/currency/currency";
import { getFormattedDateDDMMMYYYYDate } from "../../../../../utilities/dateFunctions/formatdate";
import LabelWithDetailPrint from "./LabelWithDetailPrint";

export default function InterestOnInterestListPrint({
  interestOnInterestList,
  addRepaymentDetails,
  principleDueWithIntData,
  totalDueAmount,
  penalTotalDue,
  ioiTotalDue,
}) {
  return (
    <>
      <section className="border px-3 py-2 text-[10px] my-3 grid grid-cols-3 bg-slate-100 relative">
        <LabelWithDetailPrint
          label={"Name"}
          value={addRepaymentDetails?.applicantname}
        />
        <LabelWithDetailPrint
          label={"Loan ID"}
          value={addRepaymentDetails?.loanee_id}
        />
        <LabelWithDetailPrint
          label={"Loan No"}
          value={addRepaymentDetails?.loan_number}
        />
        <LabelWithDetailPrint
          label={"Category"}
          value={addRepaymentDetails?.categoryname}
        />
        <LabelWithDetailPrint
          label={"Amount"}
          value={formatINRCurrency(addRepaymentDetails?.loanappliedamt)}
        />

        <LabelWithDetailPrint
          label="Int Rate (Scheme)"
          value={`${+principleDueWithIntData?.int_rate}  (${
            addRepaymentDetails?.loan_scheme || "---"
          }) `}
        />

        <LabelWithDetailPrint
          label="Principle Outstanding"
          value={formatINRCurrency(
            principleDueWithIntData?.principle_outstanding_amount
          )}
        />
        <LabelWithDetailPrint
          label="Principle Due"
          value={formatINRCurrency(principleDueWithIntData?.principle_due)}
        />
        <LabelWithDetailPrint
          label="Interest Due"
          value={formatINRCurrency(
            principleDueWithIntData?.interest_due_amount
          )}
        />

        <LabelWithDetailPrint
          label="Total Due Amount"
          value={formatINRCurrency(totalDueAmount)}
        />

        {addRepaymentDetails?.categoryname === "OBC" && (
          <>
            <LabelWithDetailPrint
              label="Penal Due"
              value={formatINRCurrency(penalTotalDue)}
              noRecord={+penalTotalDue === -1 ? true : false}
              isApprox={true}
            />
            <LabelWithDetailPrint
              label="IOI Due"
              value={formatINRCurrency(ioiTotalDue)}
              noRecord={+ioiTotalDue === -1 ? true : false}
              isApprox={true}
            />
          </>
        )}
      </section>
      <table className="min-w-full divide-y divide-slate-200">
        <thead className="bg-slate-200 text-[11px] ">
          <tr>
            <th
              scope="col"
              className="px-3 py-1 text-left font-medium text-slate-900  "
            >
              SI No{" "}
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Balance
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Repay
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Int Due
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Period
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Days
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              <div>Int. Rate</div>
              <div>(Anually)</div>
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Interest
            </th>
            <th
              scope="col"
              className="px-3 text-left font-medium text-slate-900  "
            >
              Total
            </th>
          </tr>
        </thead>

        <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-[10px]">
          {interestOnInterestList?.length > 0 ? (
            interestOnInterestList?.map((ioiObj, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-1 whitespace-nowrap">{+index + 1}</td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(ioiObj?.cal_date)}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_int_balance || 0}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_repay_int_bal || 0}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_int_due_balance || 0}
                </td>

                <td className="px-3 py-1 whitespace-nowrap ">
                  <div>
                    {getFormattedDateDDMMMYYYYDate(ioiObj?.cal_start_date)}, To
                  </div>

                  <div>
                    {getFormattedDateDDMMMYYYYDate(ioiObj?.cal_end_date)}
                  </div>
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_days || 0}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_ioi_rate || 0}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_ioi || 0}
                </td>
                <td className="px-3 py-1 whitespace-nowrap">
                  {ioiObj?.cal_total || 0}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
