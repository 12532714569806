import React from "react";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdPermMedia } from "react-icons/md";
import { BiChevronLeft } from "react-icons/bi";
import { IconContext } from "react-icons";
import Card from "../../../../reusable-components/Card/Card";
import testImage from "../../../../assets/photo.png";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function ViewPhotos({
  setViewPhotos,
  albumId,
  mediaItemList,
  setPhotoObj,
  setShowDelete,
  showDelete,
  deletePhoto,
}) {
  let albumPhotos = [];

  albumPhotos = mediaItemList.filter(
    (album_id) => album_id?.media_album_id === albumId
  );

  const handleDelete = (photo_obj) => {
    try {
      setPhotoObj(photo_obj);
      setShowDelete(true);
    } catch (error) {
      console.error("Error", error);
    }
  };

  return (
    <div>
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <MdPermMedia />
          </IconContext.Provider>
          &nbsp;View Photos
        </H2withIcon>

        <span
          className="flex items-center cursor-pointer hover:underline underline-offset-2 "
          onClick={() => setViewPhotos(false)}
        >
          <BiChevronLeft size={24} /> back
        </span>
      </div>

      <section>
        <div className="grid grid-cols-2">
          {albumPhotos.length > 0 &&
            albumPhotos?.map((albumphotos_Obj) => (
              <Card
                cardTitle="test"
                cardImage={albumphotos_Obj?.media_item_url}
                onClick={() => handleDelete(albumphotos_Obj)}
              />
            ))}
        </div>
      </section>

      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message={"The User data would be deleted permenantly. Are you sure?"}
        onDelete={deletePhoto}
        setShowDelete={setShowDelete}
        showDelete={showDelete}
      />
    </div>
  );
}
