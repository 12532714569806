/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import {
  MdKeyboardArrowLeft,
  MdOutlineFilterAlt,
  MdOutlineSearch,
  MdPrint,
} from "react-icons/md";
import ReactToPrint from "react-to-print";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
  REPORTS_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import { Paging } from "../../../../reusable-components/paging/Paging";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddRepaymentFormConfig from "./AddRepaymentFormConfig";
import { InterestOnInterestToPrint } from "./IntersetOnInterestToPrint";
import { PenalInterestToPrint } from "./PenalInterestToPrint";
import RepaymentList from "./RepaymentList";
import { genderDD } from "../../../../utilities/constants/constants";

const RepaymentConfig = () => {
  const { auth } = useAuth();
  const [repaymentList, setRepaymentList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [addRepaymentDetails, setAddRepaymentDetails] = useState({});

  const [viewApplicantInterestDetail, setViewApplicantInterestDetail] =
    useState(false);
  const [viewPenalInterestDetail, setViewPenalInterestDetail] = useState(false);
  const [viewInterestOnInterestDetail, setViewInterestOnInterestDetail] =
    useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [penalInterestList, setPenalInterestList] = useState([]);
  const [intersetOnInterestList, setInterestOnInterestList] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSize, setPageSize] = useState();
  const [showFilters, setShowFilters] = useState(true);
  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [constituencyList, setConstituencyList] = useState([]);
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();

  const [selectedApplicantGender, setSelectedApplicantGender] = useState();

  const controller = new AbortController();

  const penalRefToPrint = useRef();
  const interestRefToPrint = useRef();

  // get District List
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  // get searched repayment list
  const getSearchedRepaymentList = async (
    page_no,
    page_size,
    search_text,
    category_type,
    districtId,
    constituencyId,
    applicantGender
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getRepaymentSearchListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type,
            decreestatus: false,
            onetimesettlementstatus: false,
            district_id: districtId || 0,
            constituency_id: constituencyId || 0,
            applicant_gender: applicantGender || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setRepaymentList(response?.data?.repaymentList);

        const totalNumberOfApplicant =
          response?.data?.repaymentList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get Penal Interst List
  const getPenalInterestList = async (personaldetails_id) => {
    try {
      const response = await axiosPrivate.get(
        `${REPORTS_CONFIG_URL}/getPenalInterestList`,
        {
          params: {
            personaldetailsid: personaldetails_id || 61,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setPenalInterestList(response?.data?.penalInterestList);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get Interest on Interest list
  const getInterestOnInterestList = async (personaldetails_id) => {
    try {
      const response = await axiosPrivate.get(
        `${REPORTS_CONFIG_URL}/getInterestOnInterestList`,
        {
          params: {
            personaldetailsid: personaldetails_id || 61,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setInterestOnInterestList(response?.data?.interestOnInterestList);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  // get category list
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  // function for page change
  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  // get constituency list
  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchedRepaymentList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label || "",
      selectedDistrictName?.value,
      selectedConstituencyName?.value,
      selectedApplicantGender?.value || ""
    );
  }, [
    pageNo,
    pageSize?.value,
    selectedCategory?.label,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
    selectedApplicantGender?.value || "",
  ]);

  useEffect(() => {
    let isMounted = true;
    // getRepaymentList();
    getCategoryList();
    getDistrictList();
    getConstituencyList();
    setAddRepaymentDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {viewApplicantInterestDetail ? (
        <>
          {viewPenalInterestDetail && (
            <>
              <div className="flex items-center justify-between mb-2">
                <Button
                  className="flex items-center"
                  onClick={() => {
                    setViewPenalInterestDetail(false);
                    setViewApplicantInterestDetail(false);
                    setAddRepaymentDetails({});
                  }}
                >
                  <MdKeyboardArrowLeft size={20} />
                  back
                </Button>
              </div>
              <div className="">
                <PenalInterestToPrint
                  penalInterestList={penalInterestList}
                  ref={penalRefToPrint}
                />
              </div>
            </>
          )}
          {viewInterestOnInterestDetail && (
            <>
              <div className="flex items-center justify-between mb-2">
                <Button
                  className="flex items-center"
                  onClick={() => {
                    setViewInterestOnInterestDetail(false);
                    setViewApplicantInterestDetail(false);
                  }}
                >
                  <MdKeyboardArrowLeft size={20} />
                  back
                </Button>
                <ReactToPrint
                  trigger={() => (
                    <span
                      className={`text-white text-sm flex items-center gap-1 py-2 rounded px-4 bg-primary cursor-pointer`}
                    >
                      <MdPrint size={18} />
                      Interst on Interest
                    </span>
                  )}
                  content={() => interestRefToPrint?.current}
                  pageStyle="A4"
                  documentTitle="Report"
                />
              </div>
              <div className="">
                <InterestOnInterestToPrint
                  intersetOnInterestList={intersetOnInterestList}
                  ref={interestRefToPrint}
                />
              </div>
            </>
          )}
        </>
      ) : (
        <>
          {isLoading ? (
            <>
              <LoadingModal message={"Loading"} />
            </>
          ) : (
            <>
              <div className="flex flex-col md:flex-row md:items-center justify-between gap-2">
                <H2withIcon>
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="h-5 w-5 mr-1"
                    viewBox="0 0 20 20"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm4.707 3.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L8.414 9H10a3 3 0 013 3v1a1 1 0 102 0v-1a5 5 0 00-5-5H8.414l1.293-1.293z"
                      clipRule="evenodd"
                    />
                  </svg>
                  Repayment{" "}
                </H2withIcon>

                {showAddForm ? (
                  <>
                    <Button
                      className="flex items-center"
                      onClick={() => {
                        setShowAddForm(false);
                        setAddRepaymentDetails({});
                      }}
                    >
                      <MdKeyboardArrowLeft size={20} />
                      back
                    </Button>
                  </>
                ) : (
                  <>
                    {/* <section className="flex justify-between items-center gap-2 md:gap-4 md:w-1/2 mb-4 ">
                      <FilterDropdown
                        defaultName="filterCategory"
                        labelname="Scheme"
                        required={true}
                        classes={`text-sm md:w-full w-[50%]`}
                        data={categoryList}
                        defaultValue={null}
                        setSelected={setSelectedCategory}
                        selected={selectedCategory}
                        maxMenuHeight={120}
                      />
                    
                    </section> */}

                    <div className="flex gap-2">
                      <Button
                        className={
                          "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
                        }
                        onClick={() => {
                          setShowFilters(!showFilters);
                        }}
                      >
                        Apply Filters
                        <MdOutlineFilterAlt size={18} />
                      </Button>

                      <SearchField
                        placeholder="Search"
                        defaultValue={searchText}
                        onChange={(e) => {
                          setSearchText(e);
                        }}
                        onClick={() => {
                          setPageNo(1);
                          getSearchedRepaymentList(
                            pageNo,
                            pageSize?.value,
                            searchText || "",
                            selectedCategory?.label || "",
                            selectedDistrictName?.value,
                            selectedConstituencyName?.value,
                            selectedApplicantGender?.value
                          );
                        }}
                        classes={"bg-primary "}
                        onKeyDown={(e) => {
                          if (e?.key === "Enter") {
                            getSearchedRepaymentList(
                              pageNo,
                              pageSize?.value,
                              searchText || "",
                              selectedCategory?.label || "",
                              selectedDistrictName?.value,
                              selectedConstituencyName?.value,
                              selectedApplicantGender?.value
                            );
                          }
                        }}
                      />
                    </div>
                  </>
                )}
              </div>

              {showFilters && !showAddForm && (
                <section className="grid grid-cols-1 md:grid-cols-3 gap-2 bg-slate-100 p-3 mt-3">
                  <FilterDropdown
                    defaultName="filterCategory"
                    required={true}
                    classes={`text-sm w-full`}
                    placeholder={"Select Category..."}
                    data={categoryList}
                    defaultValue={null}
                    setSelected={setSelectedCategory}
                    selected={selectedCategory}
                    maxMenuHeight={256}
                  />

                  <FilterDropdown
                    defaultName="filterGender"
                    required={true}
                    classes={`text-xs w-full`}
                    placeholder={"Select Gender..."}
                    data={genderDD}
                    defaultValue={null}
                    setSelected={setSelectedApplicantGender}
                    selected={selectedApplicantGender}
                    maxMenuHeight={256}
                  />

                  <FilterDropdown
                    defaultName="filterDistrict"
                    required={true}
                    classes={`text-sm w-full`}
                    placeholder={"Select District..."}
                    data={districtList}
                    defaultValue={null}
                    setSelected={setSelectedDistrictName}
                    selected={selectedDistrictName}
                    maxMenuHeight={256}
                  />
                  <FilterDropdown
                    defaultName="filterConstituency"
                    required={true}
                    classes={`text-sm w-full`}
                    placeholder={"Select Constituency..."}
                    data={constituencyList}
                    defaultValue={null}
                    setSelected={setSelectedConstituencyName}
                    selected={selectedConstituencyName}
                    maxMenuHeight={256}
                  />
                </section>
              )}
            </>
          )}

          {showAddForm ? (
            <>
              {" "}
              <AddRepaymentFormConfig
                getRepaymentList={getSearchedRepaymentList} //not used
                addRepaymentDetails={addRepaymentDetails}
                setAddRepaymentDetails={setAddRepaymentDetails} //not used
                setShowAddForm={setShowAddForm} //not used
              />
            </>
          ) : (
            <>
              <div className="grid grid-cols-1 mt-3">
                <RepaymentList
                  repaymentList={repaymentList}
                  setAddRepaymentDetails={setAddRepaymentDetails}
                  setShowAddForm={setShowAddForm}
                  penalRefToPrint={penalRefToPrint}
                  getPenalInterestList={getPenalInterestList}
                  setViewApplicantInterestDetail={
                    setViewApplicantInterestDetail
                  }
                  setViewPenalInterestDetail={setViewPenalInterestDetail}
                  getInterestOnInterestList={getInterestOnInterestList}
                  setViewInterestOnInterestDetail={
                    setViewInterestOnInterestDetail
                  }
                />
              </div>
              <>
                <Paging
                  maxMenuHeight={100}
                  selected={pageSize}
                  setSelected={setPageSize}
                  pageNo={pageNo}
                  onPageChangeHandler={onPageChangeHandler}
                  numberOfPages={numberOfPages}
                />
              </>
            </>
          )}
        </>
      )}
    </Dashboard>
  );
};

export default RepaymentConfig;
