import React, { useState } from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateDDMMYYYY,
  getFormattedDateMMMYYYY,
  getFormattedDateYYYY,
} from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { FiEye } from "react-icons/fi";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { PrintReport } from "../PrintReport/PrintReport";
import Button from "../../../../reusable-components/buttons/Button";
import { cancel_button } from "../../../../theme/lightTheme";
import PrintReceipt from "./PrintReceipt";
import { ReceiptPrintReport } from "../PrintReport/ReceiptPrintReport";
import ViewReceipt from "./ViewReceipt";

// principle_outstanding_amount
export default function ReceiptList({
  receiptRepaymentList,
  addRepaymentDetails,
  totalDueAmount,
  principleDueWithIntData,
  loaneeTotalRepaymentAmt,
  penalTotalDue,
  ioiTotalDue,
}) {
  const [showModal, setShowModal] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [receiptDetails, setReceiptDetails] = useState();

  const handleViewReceipts = (receiptDetailsObj) => {
    setShowModal(true);
    setReceiptDetails(receiptDetailsObj);
  };

  return (
    <>
      <section>
        <TableWrapper>
          <thead className="bg-slate-200 text-sm my-3 sticky top-0">
            <tr>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Receipt No
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Repayment Date
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Repayment Amount
              </th>
              <th
                scope="col"
                className="px-3 py-2 text-left font-medium text-slate-900 "
              >
                Mode
              </th>

              <th
                scope="col"
                className="px-3 py-2 font-medium text-slate-900  w-[10%]"
              >
                Action
              </th>
            </tr>
          </thead>
          <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
            {receiptRepaymentList?.length > 0 ? (
              receiptRepaymentList
                .slice() // Create a copy to avoid mutating original array
                .sort((a, b) => {
                  // Assuming receipt_no is numeric, convert to numbers for comparison
                  const receiptNoA = parseInt(a.receipt_no);
                  const receiptNoB = parseInt(b.receipt_no);
                  return receiptNoB - receiptNoA; // Sort in descending order by receipt number
                })
                .map((receiptDetailsObj, index) => (
                  <tr
                    key={index}
                    className={index % 2 === 1 ? "bg-slate-100" : null}
                  >
                    <td className="px-3 py-2 whitespace-nowrap">
                      {receiptDetailsObj?.receipt_no}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                      {getFormattedDateDDMMMYYYYDate(
                        receiptDetailsObj?.rp_date
                      )}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap ">
                      {formatINRCurrency(receiptDetailsObj?.rp_amount)}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap">
                      {receiptDetailsObj?.rp_mode}
                    </td>
                    <td className="px-3 py-2 whitespace-nowrap ">
                      <div
                        onClick={() => handleViewReceipts(receiptDetailsObj)}
                      >
                        <div className="flex items-center justify-center gap-3 text-black hover:text-primary cursor-pointer ">
                          <FiEye size={16} color="green" />
                          <p className=""></p>
                        </div>
                      </div>
                    </td>
                  </tr>
                ))
            ) : (
              <tr>
                <td colSpan={8} className="p-4 text-center">
                  No Record Found
                </td>
              </tr>
            )}
          </tbody>
        </TableWrapper>
      </section>

      <>
        <GenericModal
          open={showModal}
          setOpen={setShowModal}
          title={"Repayment Receipt"}
          isLarge={true}
          isAdd={false}
        >
          <>
            <>
              <ViewReceipt
                receiptDetails={receiptDetails}
                addRepaymentDetails={addRepaymentDetails}
                totalDueAmount={totalDueAmount}
                principleDueWithIntData={principleDueWithIntData}
                loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                penalTotalDue={penalTotalDue}
                ioiTotalDue={ioiTotalDue}
              />
            </>

            <div className="flex justify-end items-center gap-x-6">
              <ReceiptPrintReport reportTitle={"Receipt"} buttonName={""}>
                <PrintReceipt
                  receiptDetails={receiptDetails}
                  addRepaymentDetails={addRepaymentDetails}
                  totalDueAmount={totalDueAmount}
                  principleDueWithIntData={principleDueWithIntData}
                  loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                  penalTotalDue={penalTotalDue}
                  ioiTotalDue={ioiTotalDue}
                />
              </ReceiptPrintReport>
              <Button
                className={cancel_button}
                onClick={() => {
                  setShowModal(false);
                }}
              >
                Close
              </Button>
            </div>
          </>
        </GenericModal>
      </>
    </>
  );
}
