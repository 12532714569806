import React from "react";
import { IconContext } from "react-icons";

export default function SidebarIcons(props) {
  return (
    <IconContext.Provider
      value={{ className: props.className, size: props.size }}
    >
      {props.children}
    </IconContext.Provider>
  );
}
