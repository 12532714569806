import React, { useEffect, useState } from "react";
import Dashboard from "../../../common/Dashboard";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { NEWS_CONFIG_URL } from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import AddButton from "../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import NewsList from "./NewsList";
import AddNewsForm from "./AddNewsForm";
import { FaNewspaper } from "react-icons/fa";
import useAuth from "../../../authentication/hooks/useAuth";

export default function NewsConfig() {
  const { auth } = useAuth();

  const [newsList, setNewsList] = useState([]);
  const [editNewsDetails, setEditNewsDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editNewsDetails).length > 0 ? true : false;

  const newsTypeList = [
    {
      value: "News",
      label: "News",
    },
    {
      value: "Event",
      label: "Event",
    },
  ];

  const getNewsList = async () => {
    try {
      const response = await axiosPrivate.get(NEWS_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setNewsList(response.data.newsList);
      response.status === 202 &&
        showToast("No news & events list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getNewsList();
    isMounted && setEditNewsDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaNewspaper />
          </IconContext.Provider>
          &nbsp; News &amp; Events
        </H2withIcon>
        {!showAddForm && (
          <AddButton
            label={`Add News / Events`}
            onclick={() => setShowAddForm(true)}
          />
        )}
      </div>
      {!showAddForm && (
        <p className="text-red-700 text-sm mb-2 -mt-2">
          A new blinker will be shown near the news &amp; events for 3 days from
          the start date or from start to end date whichever is earlier
        </p>
      )}
      {showAddForm ? (
        <AddNewsForm
          getNewsList={getNewsList}
          editNewsDetails={editNewsDetails}
          setEditNewsDetails={setEditNewsDetails}
          newsTypeList={newsTypeList}
          setShowAddForm={setShowAddForm}
        />
      ) : (
        <ContainerShadow>
          <div className="grid grid-cols-1">
            {newsList.length > 0 ? (
              <NewsList
                newsList={newsList}
                setEditNewsDetails={setEditNewsDetails}
                setShowAddForm={setShowAddForm}
              />
            ) : (
              <p>News &amp; Events yet to be added</p>
            )}
          </div>
        </ContainerShadow>
      )}
    </Dashboard>
  );
}
