import React from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { CiCalendar } from "react-icons/ci";
import YearlyCompiledCard from "./YearlyCompiledCard";

const YearlyCompiledReportConfig_Print = ({
  selectedFiscalYear,
  activeBorrower,
  totalDisbursed,
  paymentReceived,
  totalDueAmount,
  categoryWiseDetails,
  totalInterestDue,
  totalPrincipleDue,
  totalPrincipleOutstanding,
  ioiDue,
  penalDue,
}) => {
  let fiscalYear = new Date(selectedFiscalYear).getFullYear();

  return (
    <>
      <section className="mt-3 text-[12px]">
        <div className="flex items-start justify-center gap-1 my-1">
          <CiCalendar size={18} />
          <div className="font-semibold text-[14px]">
            <span>
              {selectedFiscalYear
                ? `${+fiscalYear - +1} - ${fiscalYear}`
                : null}
            </span>
          </div>
        </div>

        <section className="mt-3 !text-[11px]">
          <div className="border border-slate-300 p-3">
            <div className="text-[12px] text-primary font-semibold text-center ">
              All Category
            </div>
            <div className=" bg-slate-50 grid grid-cols-2 gap-x-3 md:gap-x-6 pt-3">
              <div className="grid grid-cols-2 items-center">
                Borrowers: <span className="font-bold"> {activeBorrower}</span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Disbursed Amount:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalDisbursed)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Payment Received:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(paymentReceived)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Due Amount:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalDueAmount)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Principle Outstanding:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalPrincipleOutstanding)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Principle Due:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalPrincipleDue)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Interest Due Amount:
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(totalInterestDue)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Penal Interest Due :
                <span className="font-bold">
                  {" "}
                  {formatINRCurrency(penalDue)}
                </span>
              </div>
              <div className="grid grid-cols-2 items-center">
                Interest on Interest Due :
                <span className="font-bold"> {formatINRCurrency(ioiDue)}</span>
              </div>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2  gap-3 md:gap-3 mt-3">
            {categoryWiseDetails?.length > 0
              ? categoryWiseDetails?.map(
                  ({
                    borrower,
                    categoryname,
                    di_amount,
                    due_amount,
                    rp_amount,
                    principle_due,
                    principle_outstanding,
                    int_due_amount,
                    ioi_due,
                    penal_due,
                  }) => (
                    <>
                      {categoryname ? (
                        <>
                          <YearlyCompiledCard
                            borrower={borrower}
                            categoryname={categoryname}
                            di_amount={di_amount}
                            due_amount={due_amount}
                            rp_amount={rp_amount}
                            principle_due={principle_due}
                            principle_outstanding={principle_outstanding}
                            int_due_amount={int_due_amount}
                            to_print={true}
                            ioi_due={ioi_due}
                            penal_due={penal_due}
                          />
                        </>
                      ) : null}
                    </>
                  )
                )
              : null}
          </div>
        </section>
      </section>
    </>
  );
};

export default YearlyCompiledReportConfig_Print;
