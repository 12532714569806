import React, { useEffect, useState } from "react";
import { USER_MANAGEMENT_CONFIG_URL } from "../../../api/api_routing_urls";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../reusable-components/buttons/Button";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { linkTextDanger, linkTextPrimary } from "../../../theme/lightTheme";
import { MdOutlineEditNote } from "react-icons/md";
import { BiTrash } from "react-icons/bi";

export default function UserList({
  userList,
  setEditUserDetails,
  setShowAddForm,
  setUserID,
  setShowDelete,
}) {
  const axiosPrivate = useAxiosPrivate();

  const controller = new AbortController();
  const [roleList, setRoleList] = useState({});

  const getRoleList = async () => {
    try {
      const response = await axiosPrivate.get(
        `${USER_MANAGEMENT_CONFIG_URL}/roleConfig`,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.roleList?.length > 0) {
          setRoleList(response?.data?.roleList);
        } else {
          setRoleList([]);
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const onClickEdit = (user) => {
    setEditUserDetails(user);
    setShowAddForm(true);
  };

  const onClickDelete = (user_id) => {
    setUserID(user_id);
    setShowDelete(true);
  };

  useEffect(() => {
    getRoleList();
  }, []);

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr className="bg-slate-200">
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900  tracking-wider"
          >
            User Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900  tracking-wider"
          >
            User Role
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900  tracking-wider"
          >
            User Status
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-right font-medium text-slate-900  tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {userList?.length > 0 &&
          userList?.map((userObj, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {userObj?.username}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {roleList?.map(
                  (roleObj) =>
                    roleObj?.roleid === parseInt(userObj?.roleid) &&
                    roleObj?.rolename
                )}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {userObj?.isdisabled ? "Disabled" : "Active"}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(userObj)}
                    aria-label="Edit User Details"
                    className={linkTextPrimary}
                  >
                    <MdOutlineEditNote size={24} />
                  </Button>
                  <Button
                    onClick={() => onClickDelete(userObj?.userid)}
                    aria-label="Delete User Details"
                    className={linkTextDanger}
                  >
                    <BiTrash size={20} />
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
