import React, { useEffect, useState } from "react";
import Dashboard from "../../../common/Dashboard";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import {
  FEEDBACK_CONFIG_URL,
  NEWS_CONFIG_URL,
} from "../../../api/api_routing_urls";
import showToast from "../../../utilities/notification/NotificationModal";
import H2withIcon from "../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import AddButton from "../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import FeedbackList from "./FeedbackList";

import { FaFeedbackpaper } from "react-icons/fa";
import useAuth from "../../../authentication/hooks/useAuth";
import { MdOutlineMessage } from "react-icons/md";

export default function FeedbackConfig() {
  const { auth } = useAuth();

  const [feedbackList, setFeedbackList] = useState([]);
  const [editFeedbackDetails, setEditFeedbackDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editFeedbackDetails).length > 0 ? true : false;

  const feedbackTypeList = [
    {
      value: "Feedback",
      label: "Feedback",
    },
    {
      value: "Event",
      label: "Event",
    },
  ];

  const getFeedbackList = async () => {
    try {
      const response = await axiosPrivate.get(FEEDBACK_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setFeedbackList(response.data.feedbackList);
      response.status === 202 &&
        showToast("No feedback & events list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getFeedbackList();
    isMounted && setEditFeedbackDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <MdOutlineMessage />
          </IconContext.Provider>
          &nbsp; Feedback
        </H2withIcon>
      </div>

      <ContainerShadow>
        <div className="grid grid-cols-1">
          {feedbackList?.length > 0 ? (
            <FeedbackList
              feedbackList={feedbackList}
              setEditFeedbackDetails={setEditFeedbackDetails}
              setShowAddForm={setShowAddForm}
            />
          ) : (
            <p>Feedback &amp; Events yet to be added</p>
          )}
        </div>
      </ContainerShadow>
    </Dashboard>
  );
}
