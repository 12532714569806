/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, Fragment, useState } from "react";
import {
  MdAssignmentTurnedIn,
  MdOutlineFilterAlt,
  MdOutlineSearch,
  MdPrint,
} from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DEFAULTER_LOAN_REMINDER,
  DISTRICT_CONFIG_URL,
  GRAMPANCHAYAT_CONFIG_URL,
  MUNICIPAL_CORPORATION_CONFIG_URL,
  NAGAR_PANCHAYAT_CONFIG_URL,
  REPORTS_CONFIG_URL,
  SCHEME_CONFIG_URL,
  SUBDIVISION_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import {
  defaulterDD,
  genderDD,
} from "../../../../utilities/constants/constants";

import showToast from "../../../../utilities/notification/NotificationModal";
import DefaulterDetailList from "./DefaulterDetailList";
import ReactToPrint from "react-to-print";

import { PrintReport } from "../PrintReport/PrintReport";
import DefaulterDetailListPrint from "./DefaulterDetailListPrint";
import { MdOutlineMessage } from "react-icons/md";
import { json } from "react-router-dom";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";

const DefaulterDetailConfig = () => {
  const { auth } = useAuth();
  const [defaulterDetailList, setDefaulterDetailList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showSendSMS, setShowSendSMS] = useState(false);

  const [editRepaymentDetails, setEditRepaymentDetails] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [searchText, setSearchText] = useState("");

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const pageSize = 10;

  const [categoryList, setCategoryList] = useState([]);
  const [schemeList, setSchemeList] = useState([]);
  const [districtList, setDistrictList] = useState([]);
  const [constituencyList, setConstituencyList] = useState([]);
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [gramPanchayatList, setGramPanchayatList] = useState([]);
  const [nagarPanchayatList, setNagarPanchayatList] = useState([]);
  const [municipalCorporationList, setMunicipalCorporationList] = useState([]);

  const [selectedDefaulterType, setSelectedDefaulterType] = useState();

  const [selectedCategory, setSelectedCategory] = useState();
  const [selectedApplicantGender, setSelectedApplicantGender] = useState();
  const [selectedSchemeName, setSelectedSchemeName] = useState();
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [selectedSubDivisionName, setSelectedSubDivisionName] = useState();
  const [selectedGramPanchayatName, setSelectedGramPanchayatName] = useState();
  const [
    selectedMumicipalCorporationName,
    setSelectedMumicipalCorporationName,
  ] = useState();
  const [selectedNagarPanchayatName, setSelectedNagarPanchayatName] =
    useState();

  const [sendLoanReminderList, setSendLoanReminderList] = useState([]);

  const [showFilters, setShowFilters] = useState(false);

  const controller = new AbortController();

  const componentRefToPrint = useRef();

  // get searched repayment list
  const getDefaulterDetailList = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPORTS_CONFIG_URL}/getDefaulterDetailListConfig`,
        {
          params: {
            defaulterlisttype:
              selectedDefaulterType?.label || "Exceeding 2 Years",
            applicantgender: selectedApplicantGender?.value || "",
            categoryname: selectedCategory?.label || "",
            schemename: selectedSchemeName?.label || "",
            districtname: selectedDistrictName?.label || "",
            constituencyname: selectedConstituencyName?.label || "",
            subdivisionname: selectedSubDivisionName?.label || "",
            grampanchayatname: selectedGramPanchayatName?.label || "",
            municipalcorporationname:
              selectedMumicipalCorporationName?.label || "",
            nagarpanchayatname: selectedNagarPanchayatName?.label || "",
            pgno: pageNo || 1,
            pgsize: pageSize || 10,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setDefaulterDetailList(response?.data?.defaulterDetailList || []);
        console.log(
          "defaulterDetailList",
          response?.data?.defaulterDetailList || []
        );

        const totalNumberOfApplicant = 0;

        // getting total Number Of pages
        setNumberOfPages(Math.ceil(totalNumberOfApplicant / pageSize));
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get category list
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  // get scheme list
  const getSchemeList = async () => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.schemeList?.length > 0) {
          let SchemeDD = [];
          response?.data?.schemeList?.map((schemeObj) => {
            let SchemeObj = {
              value: schemeObj.schemeid,
              label: `${schemeObj.schemename}
              ${" "}(${schemeObj.schemeshortname})`,
            };
            SchemeDD.push(SchemeObj);
          });
          setSchemeList(SchemeDD);
        } else {
          setSchemeList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get District List
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get constituency list
  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get sub division
  const getSubDivisionList = async () => {
    try {
      const response = await axiosPrivate.get(SUBDIVISION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.subDivisionList?.length > 0) {
          let subDivisionDD = [];
          response?.data?.subDivisionList?.map((subDivisionObj) => {
            let subDivisionObjDD = {
              value: subDivisionObj?.subdivisionid,
              label: subDivisionObj?.subdivisionname,
            };
            subDivisionDD.push(subDivisionObjDD);
          });
          setSubDivisionList(subDivisionDD);
        } else {
          setSubDivisionList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get gram panchayat list
  const getGramPanchayatList = async () => {
    try {
      const response = await axiosPrivate.get(GRAMPANCHAYAT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.grampanchayatList?.length > 0) {
          let gramPanchayatDD = [];
          response?.data?.grampanchayatList?.map((gramPanchayatObj) => {
            let gramPanchayatObjDD = {
              value: gramPanchayatObj.grampanchayatid,
              label: gramPanchayatObj.grampanchayatname,
            };
            gramPanchayatDD.push(gramPanchayatObjDD);
          });
          setGramPanchayatList(gramPanchayatDD);
        } else {
          setGramPanchayatList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get nagar panchayat
  const getNagarPanchayatList = async () => {
    try {
      const response = await axiosPrivate.get(NAGAR_PANCHAYAT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.nagarPanchayatList?.length > 0) {
          let nagarPanchayatDD = [];
          response?.data?.nagarPanchayatList?.map((nagarpanchayatObj) => {
            let nagarPanchayatObjDD = {
              value: nagarpanchayatObj.nagarpanchayatid,
              label: nagarpanchayatObj.nagarpanchayatname,
            };
            nagarPanchayatDD.push(nagarPanchayatObjDD);
          });
          setNagarPanchayatList(nagarPanchayatDD);
        } else {
          setNagarPanchayatList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // get municipal corporation
  const getMunicipalCorporationList = async () => {
    try {
      const response = await axiosPrivate.get(
        MUNICIPAL_CORPORATION_CONFIG_URL,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (response?.data?.municipalCorporationList?.length > 0) {
          let municipalCorporationDD = [];
          response?.data?.municipalCorporationList?.map(
            (municipalCorporationObj) => {
              let municipalCorporationObjDD = {
                value: municipalCorporationObj?.municipalcorporationid,
                label: municipalCorporationObj?.municipalcorporationname,
              };
              municipalCorporationDD.push(municipalCorporationObjDD);
            }
          );

          setMunicipalCorporationList(municipalCorporationDD);
        } else {
          setMunicipalCorporationList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error.message);
    }
  };

  // function for page change
  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  const getSearchedResult = () => {
    getDefaulterDetailList();
  };

  // POST SEND DEFAULTER LOAN REMINDER SMS
  const sendLoanReminder = async () => {
    try {
      const response = await axiosPrivate.post(
        DEFAULTER_LOAN_REMINDER,
        { loan_reminder_list: JSON.stringify(sendLoanReminderList) },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        console.log(response.data);
        showToast("SMS reminders to defaulters send successfully", "success");
      }
    } catch (error) {
      console.error(error.message);
    } finally {
      setShowSendSMS(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getDefaulterDetailList();
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [
    selectedDefaulterType?.value,
    selectedCategory?.value,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
    selectedApplicantGender?.value || "",
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getCategoryList();
      getSchemeList();
      getConstituencyList();
      getGramPanchayatList();
      getDistrictList();
      getMunicipalCorporationList();
      getSubDivisionList();
      getNagarPanchayatList();
    }

    setEditRepaymentDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <Fragment>
          <LoadingModal message={"Loading"} />
        </Fragment>
      ) : (
        <>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdAssignmentTurnedIn />{" "}
              <span className="pl-1.5">Defaulter Details</span>
            </H2withIcon>
          </div>
        </>
      )}

      <>
        <section className="flex flex-wrap gap-3 justify-between items-end">
          <div>
            <FilterDropdown
              defaultName="filterDefaulterListType"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Defaulter Type"}
              data={defaulterDD}
              defaultValue={null}
              setSelected={setSelectedDefaulterType}
              selected={selectedDefaulterType}
              maxMenuHeight={256}
            />
          </div>
          {sendLoanReminderList?.length > 0 ? (
            <>
              <Button
                className={
                  "bg-primary text-white text-sm px-3 py-2 md:min-w-36 flex items-center justify-center gap-1"
                }
                onClick={() => {
                  setShowSendSMS(true);
                }}
              >
                Send Loan Reminder
                <MdOutlineMessage size={18} />
              </Button>
            </>
          ) : null}

          <ConfirmationModal
            open={showSendSMS}
            setOpen={setShowSendSMS}
            message={`Are you ready to send SMS reminders to ${sendLoanReminderList?.length} defaulters?`}
            btnName={"Confirm"}
            onConfirm={() => sendLoanReminder()}
          />

          <div className="flex gap-2">
            <Button
              className={
                "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => {
                setShowFilters(!showFilters);
              }}
            >
              Apply Filters
              <MdOutlineFilterAlt size={18} />
            </Button>
            <Button
              className={
                "bg-primary text-white text-sm px-3 py-2 md:min-w-36 flex items-center justify-center gap-1"
              }
              onClick={() => getSearchedResult()}
            >
              Search
              <MdOutlineSearch size={18} />
            </Button>
          </div>
        </section>
        {showFilters && (
          <section className="grid grid-cols-1 md:grid-cols-4 gap-2 mt-3">
            <FilterDropdown
              defaultName="filterCategory"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Category..."}
              data={categoryList}
              defaultValue={null}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterGender"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Gender..."}
              data={genderDD}
              defaultValue={null}
              setSelected={setSelectedApplicantGender}
              selected={selectedApplicantGender}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterDistrict"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select District..."}
              data={districtList}
              defaultValue={null}
              setSelected={setSelectedDistrictName}
              selected={selectedDistrictName}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterConstituency"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Constituency..."}
              data={constituencyList}
              defaultValue={null}
              setSelected={setSelectedConstituencyName}
              selected={selectedConstituencyName}
              maxMenuHeight={256}
            />
          </section>
        )}
      </>

      <div className="grid grid-cols-1 mt-6">
        <div className="mb-2 text-slate-800 text-sm">
          {" "}
          Defaulters:
          <span className="font-semibold text-base ml-1">
            {" "}
            {defaulterDetailList?.length || 0}
          </span>
        </div>
        <DefaulterDetailList
          defaulterDetailList={defaulterDetailList}
          sendLoanReminderList={sendLoanReminderList}
          setSendLoanReminderList={setSendLoanReminderList}
        />
        {defaulterDetailList?.length > 0 ? (
          <PrintReport reportTitle={"Defaulter Report"}>
            <DefaulterDetailListPrint
              defaulterDetailList={defaulterDetailList}
              selectedDefaulterType={selectedDefaulterType}
              selectedCategory={selectedCategory}
              selectedDistrictName={selectedDistrictName}
              selectedConstituencyName={selectedConstituencyName}
            />
          </PrintReport>
        ) : null}
      </div>
      {defaulterDetailList[0]?.listcount > 0 && (
        <>
          <section className="mt-4 p-2 bg-slate-200 text-sm">
            <p>
              Total principle due amount:{" "}
              <span className="font-semibold">
                ₹{defaulterDetailList[0]?.principledue}
              </span>
            </p>
            <p>
              Total interest due amount:{" "}
              <span className="font-semibold">
                ₹{defaulterDetailList[0]?.interestdue}
              </span>
            </p>
            <p>
              Total amount sanctioned:{" "}
              <span className="font-semibold">
                ₹{defaulterDetailList[0]?.totalsanctionamount}
              </span>
            </p>
            <p>
              Total number of borrower:{" "}
              <span className="font-semibold">
                {defaulterDetailList[0]?.listcount}
              </span>
            </p>
          </section>
        </>
      )}
      {numberOfPages > 1 && (
        <>
          <div className="flex justify-between mt-6">
            <Button
              className={`${
                pageNo === 1
                  ? "cursor-not-allowed bg-slate-600"
                  : "cursor-pointer"
              } bg-secondary text-sm px-3 py-2 text-white md:min-w-36 flex items-center justify-center gap-1`}
              onClick={() => {
                onPageChangeHandler(pageNo, "Prev");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                  clipRule="evenodd"
                />
              </svg>
              Previous
            </Button>
            {pageNo} / {numberOfPages || 1}
            <Button
              className={`${
                pageNo === numberOfPages
                  ? "cursor-not-allowed bg-slate-600"
                  : "cursor-pointer"
              }             
                    bg-secondary text-sm px-3 py-2 text-white md:min-w-36 flex items-center justify-center gap-1`}
              onClick={() => {
                onPageChangeHandler(pageNo, "Next");
              }}
            >
              Next
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                  clipRule="evenodd"
                />
              </svg>
            </Button>
          </div>
        </>
      )}
    </Dashboard>
  );
};

export default DefaulterDetailConfig;
