import React from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

export default function PhotosList({
  photoGalleryList,
  setEditPhotoDetails,
  setShowAddForm,
}) {
  const onClickEdit = (photo) => {
    setEditPhotoDetails(photo);
    setShowAddForm(true);
  };
  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
          >
            Brochure
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            Caption
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {photoGalleryList.length > 0 &&
          photoGalleryList.map((photo, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center">
                <img src={photo.mediafile} className={"w-40 h-auto"} />
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <span className="font-medium">{photo.mediacaption}</span>
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(photo)}
                    aria-label="Edit Photo Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
