const formatINRCurrency = (amount) => {
  let formatedCurrency = Intl.NumberFormat("en-IN", {
    style: "currency",
    currency: "INR",
  }).format(amount);

  return `${formatedCurrency}`;
};

module.exports = {
  formatINRCurrency,
};
