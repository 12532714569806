import React from "react";
import H2withSettingIcon from "../../reusable-components/headings/H2withSettingIcon";
import AddButton from "../../reusable-components/buttons/AddButton";

const MasterConfigHeaderWithoutDeleteWrapper = ({
  headerTitle,
  setShowAddForm,
}) => {
  return (
    <div className="flex items-center justify-between">
      <H2withSettingIcon>{headerTitle} Configuration</H2withSettingIcon>
      <AddButton
        label={`Add ${headerTitle}`}
        onclick={() => setShowAddForm(true)}
      />
    </div>
  );
};

export default MasterConfigHeaderWithoutDeleteWrapper;
