import React, { useEffect, useRef, useState } from "react";
import { BsBook, BsBookFill } from "react-icons/bs";
import {
  HiOutlineHome,
  HiOutlineInformationCircle,
  HiOutlineChatAlt,
  HiMenu,
  HiHome,
  HiInformationCircle,
  HiChatAlt,
} from "react-icons/hi";

import { IoDocumentTextOutline } from "react-icons/io5";

import { MdOutlinePermMedia, MdPermMedia } from "react-icons/md";
import { AiOutlineBank, AiTwotoneBank } from "react-icons/ai";

import { IconContext } from "react-icons";
import NavLinks from "./NavLinks";
import "../../utilities/css/mobileMenu.css";
import MobileNavLinks from "./MobileNavLinks";
import nav_header_80 from "../../assets/SABCCO_128x128_white.png";
import NavButtons from "./NavHeader/NavButtons";
import { TbCoinRupee } from "react-icons/tb";

import { FiPhoneCall } from "react-icons/fi";

export default function Navbar(showTransparentNav) {
  const [isMobileNav, setIsMobileNav] = useState(false);
  const dropdownRef = useRef(null);
  const wrapperRef = useRef(null);
  const [isActive, setIsActive] = useState(false);

  const closeMenu = () => {
    setIsActive(false);
  };

  useEffect(() => {
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        closeMenu();
      }
    }
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  const [navLinkData, setNavLinkData] = useState([
    {
      navlink: "Home",
      normal_icon: <HiOutlineHome />,
      active_icon: <HiHome />,
      hasSubMenu: false,
      subMenu: [],
      url: "/",
    },
    {
      navlink: "About Us",
      normal_icon: <HiOutlineInformationCircle />,
      active_icon: <HiInformationCircle />,
      hasSubMenu: false,
      subMenu: [],
      url: "/about-us",
    },
    // {
    //   navlink: "Schemes",
    //   normal_icon: <MdOutlineMediation />,
    //   active_icon: <MdMediation />,
    //   hasSubMenu: true,
    //   subMenu: [],
    //   url: "#schemes",
    // },
    // {
    //   navlink: "Award & Appreciation",
    //   normal_icon: <FaAward />,
    //   active_icon: <FaAward />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/award_s",
    // },
    // {
    //   navlink: "Publications",
    //   normal_icon: <HiOutlineDocumentSearch />,
    //   active_icon: <HiDocumentSearch />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/publication_s",
    // },
    // {
    //   navlink: "News & Events",
    //   normal_icon: <FaRegNewspaper />,
    //   active_icon: <FaNewspaper />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/new_s",
    // },
    {
      navlink: "Media Gallery",
      normal_icon: <MdOutlinePermMedia />,
      active_icon: <MdPermMedia />,
      hasSubMenu: true,
      subMenu: [
        {
          title: "Photo Gallery",
          url: "/media/photo-gallery",
        },
        // {
        //   title: "Video Gallery",
        //   url: "/videogallery",
        // },

        // {
        //   title: "Brochures & Pamphlets",
        //   url: "/media/brochures-and-pamphlets",
        // },
      ],
      url: "/media/photo-gallery",
    },
    {
      navlink: "Schemes",
      normal_icon: <AiOutlineBank />,
      active_icon: <AiTwotoneBank />,
      hasSubMenu: false,
      subMenu: [],
      url: "/sabcco-offered-schemes",
    },
    // {
    //   navlink: "Employees",
    //   normal_icon: <IoIosPeople />,
    //   active_icon: <IoIosPeople />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/sabcco-employees",
    // },
    {
      navlink: "Feedback",
      normal_icon: <HiOutlineChatAlt />,
      active_icon: <HiChatAlt />,
      hasSubMenu: false,
      subMenu: [],
      url: "/feed-back",
    },

    {
      navlink: "RTI",
      normal_icon: <BsBook size={16} />,
      active_icon: <BsBookFill size={16} />,
      hasSubMenu: false,
      subMenu: [],
      url: "/rti",
    },
    {
      navlink: "NOC Application",
      normal_icon: <IoDocumentTextOutline size={16} />,
      active_icon: <IoDocumentTextOutline size={16} />,
      hasSubMenu: false,
      subMenu: [],
      url: "/noc-application",
    },
    {
      navlink: "Contact Us",
      normal_icon: <FiPhoneCall size={16} />,
      active_icon: <FiPhoneCall size={16} />,
      hasSubMenu: false,
      subMenu: [],
      url: "/contact-us",
    },
    {
      navlink: "Repayment",
      normal_icon: <TbCoinRupee size={20} />,
      active_icon: <TbCoinRupee size={20} />,
      hasSubMenu: false,
      subMenu: [],
      url: "/online-repayment-login",
    },
    // {
    //   navlink: "Contact Us",
    //   normal_icon: <HiOutlinePhoneIncoming />,
    //   active_icon: <HiPhoneIncoming />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "/contact-us",
    // },
    // {
    //   navlink: "FAQs",
    //   // normal_icon: <HiOutlineQuestionMarkCircle />,
    //   hasSubMenu: false,
    //   subMenu: [],
    //   url: "https://files.sikkimsabcco.com/SABCCO%20NOC%20Application.pdf",
    // },
  ]);

  useEffect(() => {
    window.innerWidth < 1117 ? setIsMobileNav(true) : setIsMobileNav(false);
  }, [window.innerWidth]);

  return (
    <>
      <nav
        className={`px-3  bg-transparent w-full md:px-9  py-2 absolute flex items-center justify-between text-secondary z-30 `}
      >
        <img
          src={nav_header_80}
          alt="SABCCO"
          className={isMobileNav ? "w-12 " : "w-16"}
        />
        {!isMobileNav && (
          <div className="text-white text-xs ml-1 flex flex-col justify-start leading-4">
            <p>Sikkim SC, ST and OBC Development Corporation Ltd.</p>
            <small>(A GOVERNMENT OF SIKKIM UNDERTAKING)</small>
          </div>
        )}
        {/* <>
        {window.innerWidth > 425 ? (
        ) : (
          <img src={nav_header_60} alt="SABCCO" />
        )}
      </> */}
        <div
          ref={wrapperRef}
          className={`container px-2 flex items-center justify-end`}
        >
          {!isMobileNav ? (
            <div
              className={`hidden lg:flex flex-grow justify-end items-center`}
              id="example-navbar-danger"
            >
              <IconContext.Provider
                value={{ className: "text-sm md:text-base lg:text-lg" }}
              >
                <ul className="flex flex-col lg:flex-row items-center list-none">
                  {navLinkData.map((item, index) => (
                    <NavLinks
                      showTransparentNav={showTransparentNav.showTransparentNav}
                      item={item}
                      index={index}
                      key={index}
                    />
                  ))}
                </ul>
              </IconContext.Provider>
            </div>
          ) : (
            <div className="flex flex-1 flex-row justify-between items-center">
              <div>{/* <h2 className="font-bold text-lg">Menu</h2> */}</div>
              <div
                className="group inline-block relative px-1 py-0.5 items-center text-xs md:text-xxs lg:text-xs uppercase font-bold leading-snug text-secondary hover:text-white hover:opacity-75 bg-transparent hover:bg-ternary text-decoration-none
        focus:bg-ternary active:bg-ternary"
                onClick={() => setIsActive(!isActive)}
              >
                <IconContext.Provider
                  value={{ className: "text-2xl text-white font-bold" }}
                >
                  <HiMenu />
                </IconContext.Provider>
              </div>
              <nav
                ref={dropdownRef}
                className={`menu ${isActive ? "active" : "inactive"} text-left`}
              >
                <ul className="bg-white">
                  {navLinkData.map((item, index) => (
                    <MobileNavLinks
                      item={item}
                      index={index}
                      key={index}
                      setIsActive={setIsActive}
                    />
                  ))}
                </ul>
              </nav>
            </div>
          )}
        </div>

        <NavButtons />
      </nav>
    </>
  );
}
