import React, { Fragment, useEffect, useState } from "react";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import Button from "../../../../reusable-components/buttons/Button";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  button,
  cancel_button,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";

export default function SchemesList({
  schemesList,
  setEditSchemeDetails,
  categoryDDList,
  setShowAddForm,
}) {
  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const onClickEdit = (scheme) => {
    setEditSchemeDetails(scheme);
    setShowAddForm(true);
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${title} Document`);
    setShowPDFViewer(true);
  };

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-center"
            >
              Scheme
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              Scheme For
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-center font-medium text-slate-900 uppercase tracking-wider"
            >
              Scheme Document
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {schemesList.length > 0 &&
            schemesList.map((scheme, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex flex-col justify-center items-center">
                    <img src={scheme.schemeicon} className="h-20 w-20" />
                    <span className="font-medium">{scheme.schemetitle}</span>
                  </div>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {categoryDDList.length > 0 &&
                    categoryDDList.filter(
                      (category) =>
                        parseInt(category.value) === parseInt(scheme.schemefor)
                    )[0].label}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm flex justify-center items-center">
                  {scheme.schemedoc ? (
                    <Fragment>
                      <IconContext.Provider
                        value={{ className: "text-red-700 text-5xl" }}
                      >
                        <FaFilePdf />
                      </IconContext.Provider>
                      <Button
                        className={linkTextPrimary}
                        onClick={() =>
                          onClickView(scheme.schemedoc, scheme.schemetitle)
                        }
                      >
                        View
                      </Button>
                    </Fragment>
                  ) : (
                    "NA"
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(scheme)}
                      aria-label="Edit Scheme Details"
                      className={linkTextPrimary}
                    >
                      Edit
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </Fragment>
  );
}
