import React, { useState } from "react";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import { BsPersonWorkspace } from "react-icons/bs";
import { BiChevronLeft } from "react-icons/bi";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../../theme/lightTheme";
import { useForm } from "react-hook-form";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import CapturePhoto from "../../../../reusable-components/inputs/FileUploader/CapturePhoto";
import NewImageUploader from "../../../../reusable-components/inputs/FileUploader/NewImageUploader";
import Passphoto from "../../../../reusable-components/inputs/FileUploader/Passphoto";
import { useRef } from "react";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import showToast from "../../../../utilities/notification/NotificationModal";
import { EMP_DETAILS_URL } from "../../../../api/api_routing_urls";

export default function EmployeeDetailsForm({
  setShowAddForm,
  setSelectedEmpDesignatiom,
  selectedEmpDesignation,
  employeeDesignationDD,
  editEmpDetails,
  getEmpDetailsList,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();

  let isEdit =
    editEmpDetails === undefined || editEmpDetails === null
      ? false
      : Object?.keys(editEmpDetails)?.length > 0
      ? true
      : false;

  const [userPassPhoto, setUserPassPhoto] = useState();

  const defaultValues = {
    emp_photo: !isEdit ? "" : editEmpDetails?.emp_photo,
    emp_name: !isEdit ? "" : editEmpDetails?.emp_name,

    emp_designation: !isEdit
      ? ""
      : {
          label: editEmpDetails?.emp_designation,
          value: editEmpDetails?.emp_id,
        },
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      let response = "";
      let sendDataObj = {};
      sendDataObj.emp_name = data?.emp_name;
      sendDataObj.emp_designation = data?.emp_designation?.label;

      if (userPassPhoto) {
        sendDataObj.emp_photo = await uploadFile(userPassPhoto);
      } else {
        sendDataObj.emp_photo = editEmpDetails?.emp_photo;
      }

      if (!isEdit) {
        sendDataObj.createdby = auth.userid;

        response = await axiosPrivate.post(
          `${EMP_DETAILS_URL}/save`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      } else {
        sendDataObj.updatedby = auth.userid;
        sendDataObj.emp_id = editEmpDetails?.emp_id;

        response = await axiosPrivate.post(
          `${EMP_DETAILS_URL}/update`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          showToast(
            "Employee Details has been updated successfully",
            "success"
          );
        } else {
          showToast("Employee Details has been added successfully", "success");
        }
        getEmpDetailsList();
      } else {
        if (isEdit) {
          if (response.data.saveEmpDetails.includes("unique_details")) {
            showToast(
              "Designation already exists. Cannot insert duplicate designation",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveEmpDetails.includes("unique_details")) {
            showToast(
              "Designation already exists. Cannot insert duplicate designation",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      // reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const passPhotoRef = useRef(null);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  // for uploading document on digital ocean s3
  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  return (
    <>
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <BsPersonWorkspace />
          </IconContext.Provider>
          &nbsp; Employee Details Form
        </H2withIcon>
        <div
          className="flex items-center cursor-pointer hover:underline underline-offset-2 "
          onClick={() => {
            setShowAddForm(false);
          }}
        >
          <BiChevronLeft size={24} /> back
        </div>
      </div>

      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <ContainerShadow>
            <div className=" mb-6 " ref={passPhotoRef}>
              <CapturePhoto
                handleScroll={handleScroll}
                control={control}
                register={register}
                defaultName="emp_photo"
                name="Employee photo"
                required={isEdit ? false : true}
                pattern={null}
                placeholder="Employee Photo"
                type="file"
                errors={errors}
                classes={`rounded px-3 py-2 text-sm w-full `}
                setError={setError}
                clearError={clearErrors}
                // file={passphoto}
                // setPhotoBase64={setPassphoto}
                setValue={setValue}
                oldFileOnEdit={isEdit ? defaultValues.emp_photo : ""}
                setFile={setUserPassPhoto}
              />
            </div>
            <div className="flex justify-between ">
              <Input
                defaultName="emp_name"
                register={register}
                name="Employee Name"
                required={true}
                pattern={null}
                errors={errors}
                placeholder="Enter employee name"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm text-sm w-[90%]`}
                onChangeInput={null}
                defaultValue={defaultValues.emp_name}
                setValue={setValue}
              />

              <Dropdown
                defaultName="emp_designation"
                register={register}
                labelname="Employee Designation"
                required={false}
                pattern={null}
                errors={errors}
                classes={`rounded-sm  text-sm w-[90%] `}
                setError={setError}
                clearError={clearErrors}
                onChangeInput={null}
                control={control}
                data={employeeDesignationDD}
                defaultValue={defaultValues.emp_designation}
                setValue={setValue}
                setSelected={setSelectedEmpDesignatiom}
                selected={selectedEmpDesignation}
                maxMenuHeight={120}
              />
            </div>

            <div className="flex justify-end gap-4 mt-8">
              <SubmitButton
                isSubmitting={isSubmitting}
                label={!isEdit ? "Submit" : "Update"}
                className={button}
              />
              <Button
                onClick={() => {
                  // setEditEmpDesignationDetails({});
                  setValue("emp_name", "");
                  setShowAddForm(false);
                }}
                aria-label="Cancel Employee  Update Button"
                className={cancel_button}
              >
                Cancel
              </Button>
            </div>
          </ContainerShadow>
        </form>
      </section>
    </>
  );
}
