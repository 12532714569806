import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { CATEGORY_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import AddCategoryForm from "./AddCategoryForm";
import CategoryList from "./CategoryList";
import useAuth from "../../../../authentication/hooks/useAuth";

export default function CategoryConfig() {
  const { auth } = useAuth();
  const [categoryList, setCategoryList] = useState([]);
  const [editCategoryDetails, setEditCategoryDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editCategoryDetails).length > 0 ? true : false;
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setCategoryList(response.data.categoryList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getCategoryList();
    setEditCategoryDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Category"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Category" : "Add Category"}`}
        isAdd={true}
      >
        <AddCategoryForm
          getCategoryList={getCategoryList}
          editCategoryDetails={editCategoryDetails}
          setEditCategoryDetails={setEditCategoryDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <CategoryList
          categoryList={categoryList}
          setEditCategoryDetails={setEditCategoryDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
}
