/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";
import PaymentResponseHandler from "./PaymentResponseHandler";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";

const BillDeskPage = ({ orderObj, responseUrl }) => {
  const environment = "PROD";
  const [transactionResponse, setTransactionResponse] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  console.log({ transactionResponse });

  const config = {
    UAT: {
      SCRIPT_SRC:
        "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.esm.js",
      SCRIPT_NOMODULE_SRC:
        "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk.js",
      LINK_SRC:
        "https://uat1.billdesk.com/merchant-uat/sdk/dist/billdesksdk/billdesksdk.css",
    },
    PROD: {
      SCRIPT_SRC:
        "https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.esm.js",
      SCRIPT_NOMODULE_SRC:
        "https://pay.billdesk.com/jssdk/v1/dist/billdesksdk.js",
      LINK_SRC:
        "https://pay.billdesk.com/jssdk/v1/dist/billdesksdk/billdesksdk.css",
    },
  };

  const { SCRIPT_SRC, SCRIPT_NOMODULE_SRC, LINK_SRC } = config[environment];
  const containerRef = useRef(null);

  const setPaymentSDKHandler = () => {
    if (orderObj?.authToken) {
      const sdkHtml = `
        <!DOCTYPE html>
        <html lang="en">
        <head>
          <meta charset="UTF-8">
          <meta name="viewport" content="width=device-width, initial-scale=1.0">
          <meta http-equiv="X-UA-Compatible" content="ie=edge">
          <title>BillDesk SDK</title>
          <script type="module" src="${SCRIPT_SRC}"></script>
          <script nomodule="" src="${SCRIPT_NOMODULE_SRC}"></script>
          <link href="${LINK_SRC}" rel="stylesheet">
          <script>
            var flow_config = {
              merchantId: "${orderObj?.merchantId}",
              bdOrderId: "${orderObj?.bdOrderId}",
              authToken: "${orderObj?.authToken}",
              childWindow: true,
              returnUrl: "${responseUrl}",
              crossButtonHandling: 'Y',
              retryCount: 3
            };

            var responseHandler = function (txn) {
              if (txn.response) {
                // Send the response back to the parent window
                window.parent.postMessage(
                  { type: 'BILLDESK_SDK_MESSAGE', txn: txn },
                  '*'
                );
              }
            };

            var config = {
              flowConfig: flow_config,
              flowType: "payments"
            };

            window.onload = function () {
              window.loadBillDeskSdk(config);
            };

            window.addEventListener('message', function(event) {
              if (event.data && event.data.type === 'BILLDESK_SDK_MESSAGE') {
                window.parent.postMessage(event.data, '*');
              }
            });
          </script>
        </head>
        <body>
        </body>
        </html>`;

      const iframe = document.createElement("iframe");
      iframe.style.width = "380px";
      iframe.style.height = "610px";
      iframe.style.border = "none";
      iframe.srcdoc = sdkHtml;
      iframe.onload = () => {
        console.log("Iframe loaded");
        setIsLoading(false); // Hide the loader once the iframe is loaded
      };

      containerRef.current.innerHTML = ""; // Clear any previous content
      containerRef.current.appendChild(iframe);
    }
  };

  useEffect(() => {
    const handleMessage = (event) => {
      const { url, data } = event;

      if (url === responseUrl) {
        const msgSplit = data.split("<pre")[1].split(">")[1].split("</pre");

        let actData = JSON.parse(msgSplit[0]);
        const paymentResponse = actData.response;

        console.log({ paymentResponse });
        setTransactionResponse(paymentResponse);
      }

      if (event.data && event.data.type === "BILLDESK_SDK_MESSAGE") {
        const { txn } = event.data;

        // Close the iframe
        if (containerRef.current) {
          containerRef.current.innerHTML = "";
        }

        // Handle the transaction response
        setTransactionResponse(txn.response);

        // Optionally redirect or render a response page
        // Example:
        // window.location.href = `/transaction-response?status=${txn.status}`;
      }
    };

    window.addEventListener("message", handleMessage);

    setPaymentSDKHandler();

    return () => {
      window.removeEventListener("message", handleMessage);
      if (containerRef.current) {
        containerRef.current.innerHTML = "";
      }
    };
  }, [orderObj, responseUrl]);

  // useEffect(() => {
  //   const responseData = {
  //     order_id: "805620240722114519",
  //     transaction_validity: true,
  //     auth_status: "Success",
  //     txn_reference_no: "U1230001011874",
  //     transaction_time: "2024-07-22T11:45:47+05:30",
  //     transaction_amt: "100.00",
  //     error_description: "Transaction Successful",
  //   };

  //   setTransactionResponse(responseData);
  // }, []);

  return (
    <div>
      {isLoading && (
        <div className="my-9 text-center">
          <p className="text-center">
            Please do not close or refresh this page...
          </p>
          <SpinnerWithText />
        </div>
      )}
      {!transactionResponse ? (
        <div id="billdesk-sdk-container" ref={containerRef}></div>
      ) : (
        <div>
          {/* <h2>Transaction Response</h2> */}
          {/* <pre>{JSON.stringify(transactionResponse, null, 2)}</pre> */}
          <PaymentResponseHandler paymentResponse={transactionResponse} />
        </div>
      )}
    </div>
  );
};

export default BillDeskPage;
