/* eslint-disable no-whitespace-before-property */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import footerDesign from "../../../../assets/footer_Design.png";
import Section from "../../../../reusable-components/section/Section";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { number_in_words } from "../../../../utilities/generalFunctions/numberToWrod";
import { IoCutOutline } from "react-icons/io5";

export default function PrintReceipt({
  receiptDetails,
  addRepaymentDetails,
  totalDueAmount,
  loaneeTotalRepaymentAmt,
  ioiTotalDue,
  penalTotalDue,
}) {
  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }
  let total_amt_receved = +loaneeTotalRepaymentAmt + +receiptDetails?.rp_amount;
  let total_amt_due = +totalDueAmount + +ioiTotalDue + +penalTotalDue;

  return (
    <>
      <div className="text-[12px]">
        <header className=" bg-white ">
          <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
            <div className="flex justify-center gap-3 items-center">
              <img className="h-24 w-24" src={sabccoLogo} alt="SABCCO Logo" />
            </div>
          </div>
          <div>
            <p className="text-center text-[12px] font-semibold">
              SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
              <br />
              CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
              <br />
              <span className="text-center text-[12px] font-normal">
                SONAM TSHERING MARG, GANGTOK
              </span>
            </p>
          </div>
        </header>
        {(receiptDetails?.rp_mode === "Cash" ||
          receiptDetails?.rp_mode === "Branch") && (
          <Section className="">
            <body className="pt-3 bg-white">
              <div className="pt-1">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CASH RECEIPT
                </p>

                <div className="flex justify-between items-center pt-3  mx-14 ">
                  <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                  <p>
                    Date:{" "}
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>

                <div className="mt-1 flex justify-center items-center">
                  <div className="tracking-widest leading-6  mx-14  text-center">
                    <p className="pt-3 text-justify">
                      Received with thanks from Mr/Ms{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(addRepaymentDetails?.applicantname)}
                      </span>{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelative
                        )}
                        &nbsp;
                      </span>
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelativename
                        )}
                        &nbsp;
                      </span>
                      resident of{" "}
                      <span className="font-semibold capitalize">
                        {addRepaymentDetails?.permanentaddress}&nbsp;
                      </span>
                      Loan ID No{" "}
                      <span className="px-1 font-semibold">
                        {addRepaymentDetails?.loanee_id}
                      </span>
                      a sum of Rs{" "}
                      <span className="px-1 font-semibold">
                        {formatINRCurrency(receiptDetails?.rp_amount)}/-
                      </span>{" "}
                      ( Rupees
                      <span className="font-semibold">
                        {number_in_words(receiptDetails?.rp_amount)}
                      </span>{" "}
                      ) only on account of SABCCO loan by Cash.
                    </p>
                  </div>
                </div>
              </div>
            </body>
          </Section>
        )}

        {receiptDetails?.rp_mode === "Cheque" && (
          <Section className=" ">
            <body className="pt-3 bg-white">
              <div className="pt-1">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CHEQUE RECEIPT
                </p>
                <div className="flex justify-between items-center pt-3 mx-14">
                  <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                  <p>
                    Date:
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>
                <div className="mt-1 flex justify-center items-center">
                  <div className="tracking-widest leading-6 mx-14 text-center">
                    <p className="pt-3 text-justify">
                      Received with thanks from Mr/Ms{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(addRepaymentDetails?.applicantname)}
                      </span>{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelative
                        )}
                        &nbsp;
                      </span>
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelativename
                        )}
                        &nbsp;
                      </span>
                      Resident of{" "}
                      <span className="font-semibold capitalize">
                        {addRepaymentDetails?.permanentaddress}&nbsp;
                      </span>
                      Loan ID No{" "}
                      <span className="px-1 font-semibold">
                        {addRepaymentDetails?.loanee_id}
                      </span>
                      a sum of Rs{" "}
                      <span className="px-1 font-semibold">
                        {formatINRCurrency(receiptDetails?.rp_amount)}
                      </span>
                      /- ( Rupees{" "}
                      <span className="font-bold">
                        {number_in_words(receiptDetails?.rp_amount)}
                      </span>{" "}
                      ) only on account of SABCCO loan by Cheque/D.D. No{" "}
                      <span className="font-bold px-1">
                        {receiptDetails?.tr_cheque_dd_no}
                      </span>{" "}
                      dated &nbsp;
                      <span className="font-semibold">
                        {getFormattedDateDDMMMYYYYDate(
                          receiptDetails?.cheque_date
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </body>
          </Section>
        )}

        <footer className="mt-6 mx-14">
          <div className="flex justify-end items-center pt-6 text-[11px]">
            <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
          </div>
          <div className="pt-1">
            <img
              className="opacity-90 w-full"
              src={footerDesign}
              alt="dash"
              srcset=""
            />
            <div className="text-center text-[11px]">
              <p className="text-center">
                Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                Classes Development Corporation Ltd.
              </p>
              <p className="text-center">
                Contact No: 7908335935 & 9434863433 / Website:
                www.sikkimsabcco.com
              </p>
            </div>
          </div>
        </footer>
      </div>

      <p className="text-center py-3 flex items-center justify-center">
        ---------------------------------------------------------{" "}
        <IoCutOutline />{" "}
        ---------------------------------------------------------
      </p>

      <div className="text-[12px]">
        <header className=" bg-white ">
          <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
            <div className="flex justify-center gap-3 items-center">
              <img className="h-24 w-24" src={sabccoLogo} alt="SABCCO Logo" />
            </div>
          </div>
          <div>
            <p className="text-center text-[12px] font-semibold">
              SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
              <br />
              CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
              <br />
              <span className="text-center text-[12px] font-normal">
                SONAM TSHERING MARG, GANGTOK
              </span>
            </p>
          </div>
        </header>
        {(receiptDetails?.rp_mode === "Cash" ||
          receiptDetails?.rp_mode === "Branch") && (
          <Section className="">
            <body className="pt-3 bg-white">
              <div className="pt-1">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CASH RECEIPT
                </p>

                <div className="flex justify-between items-center pt-3  mx-14 ">
                  <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                  <p>
                    Date:{" "}
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>

                <div className="mt-1 flex justify-center items-center">
                  <div className="tracking-widest leading-6  mx-14  text-center">
                    <p className="pt-3 text-justify">
                      Received with thanks from Mr/Ms{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(addRepaymentDetails?.applicantname)}
                      </span>{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelative
                        )}
                        &nbsp;
                      </span>
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelativename
                        )}
                        &nbsp;
                      </span>
                      resident of{" "}
                      <span className="font-semibold capitalize">
                        {addRepaymentDetails?.permanentaddress}&nbsp;
                      </span>
                      Loan ID No{" "}
                      <span className="px-1 font-semibold">
                        {addRepaymentDetails?.loanee_id}
                      </span>
                      a sum of Rs{" "}
                      <span className="px-1 font-semibold">
                        {formatINRCurrency(receiptDetails?.rp_amount)}/-
                      </span>{" "}
                      ( Rupees
                      <span className="font-semibold">
                        {number_in_words(receiptDetails?.rp_amount)}
                      </span>{" "}
                      ) only on account of SABCCO loan by Cash.
                    </p>
                  </div>
                </div>
              </div>
            </body>
          </Section>
        )}

        {receiptDetails?.rp_mode === "Cheque" && (
          <Section className=" ">
            <body className="pt-3 bg-white">
              <div className="pt-1">
                <p className="text-center font-bold underline underline-offset-4 text-sm">
                  CHEQUE RECEIPT
                </p>
                <div className="flex justify-between items-center pt-3 mx-14">
                  <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                  <p>
                    Date:
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                  </p>
                </div>
                <div className="mt-1 flex justify-center items-center">
                  <div className="tracking-widest leading-6 mx-14 text-center">
                    <p className="pt-3 text-justify">
                      Received with thanks from Mr/Ms{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(addRepaymentDetails?.applicantname)}
                      </span>{" "}
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelative
                        )}
                        &nbsp;
                      </span>
                      <span className="font-semibold capitalize">
                        {capitalizeWords(
                          addRepaymentDetails?.applicantrelativename
                        )}
                        &nbsp;
                      </span>
                      Resident of{" "}
                      <span className="font-semibold capitalize">
                        {addRepaymentDetails?.permanentaddress}&nbsp;
                      </span>
                      Loan ID No{" "}
                      <span className="px-1 font-semibold">
                        {addRepaymentDetails?.loanee_id}
                      </span>
                      a sum of Rs{" "}
                      <span className="px-1 font-semibold">
                        {formatINRCurrency(receiptDetails?.rp_amount)}
                      </span>
                      /- ( Rupees{" "}
                      <span className="font-bold">
                        {number_in_words(receiptDetails?.rp_amount)}
                      </span>{" "}
                      ) only on account of SABCCO loan by Cheque/D.D. No{" "}
                      <span className="font-bold px-1">
                        {receiptDetails?.tr_cheque_dd_no}
                      </span>{" "}
                      dated &nbsp;
                      <span className="font-semibold">
                        {getFormattedDateDDMMMYYYYDate(
                          receiptDetails?.cheque_date
                        )}
                      </span>
                    </p>
                  </div>
                </div>
              </div>
            </body>
          </Section>
        )}

        <footer className="mt-6 mx-14">
          <div className="flex justify-end items-center pt-6 text-[11px]">
            <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
          </div>
          <div className="pt-1">
            <img
              className="opacity-90 w-full"
              src={footerDesign}
              alt="dash"
              srcset=""
            />
            <div className="text-center text-[11px]">
              <p className="text-center">
                Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                Classes Development Corporation Ltd.
              </p>
              <p className="text-center">
                Contact No: 7908335935 & 9434863433 / Website:
                www.sikkimsabcco.com
              </p>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
}
