import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { AWARDS_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import {
  button,
  cancel_button,
  linkTextPrimary,
} from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import PDFImageUploader from "../../../../reusable-components/inputs/FileUploader/PDFImageUploader";
import { base64toBlob } from "../../../../utilities/fileFunctions/pdfFunctions";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import axios from "../../../../api/axios";
import { fileTypeChecker } from "../../../../utilities/validateDocuments/validateDocument";

export default function AddAwardsForm({
  getAwardsList,
  editAwardsDetails,
  setEditAwardsDetails,
  setShowAddForm,
}) {
  const [awardsDoc, setAwardsDoc] = useState("");
  const [docFilename, setDocFilename] = useState("");

  const [awardFile, setAwardFile] = useState();

  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [fileUploadError, setFileUploadError] = useState(false);

  async function uploadImageToCloudinary(fileObj) {
    try {
      let data = new FormData();
      data.append("upload_preset", process.env.REACT_APP_UPLOAD_PRESET);
      data.append("cloud_name", process.env.REACT_APP_CLOUD_NAME);
      data.append("file", fileObj);
      data.append("folder", "sabcco/images");
      data.append("context", fileObj?.name);
      const url = `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUD_NAME}/image/upload`;
      let result = await axios.post(url, data);
      if (result) {
        return result.data.secure_url;
      }
    } catch (error) {
      console.error("unable to upload ", error);
    }
  }

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editAwardsDetails).length > 0;

  const defaultValues = {
    publicationtitle: !isEdit ? "" : editAwardsDetails?.publicationtitle,
    publicationtype: "Awards",
    publicationdoc: !isEdit ? "" : editAwardsDetails?.publicationdoc,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const onSubmit = async (data) => {
    if (!fileUploadError) {
      try {
        let response = "";
        let fileType = "";

        await fileTypeChecker(awardFile)
          .then((file) => {
            fileType = file;
          })
          .catch((err) => console.error(err));

        if (fileType === "unknown") {
          showToast("File you uploaded seems to be invalid", "error");
          return;
        } else {
          if (typeof data.publicationdoc !== "string") {
            data.publicationdoc = awardsDoc;
          }

          if (fileType === "IMG") {
            await uploadImageToCloudinary(awardFile).then((url) => {
              data.publicationdoc = url;
            });
          }
          if (!isEdit) {
            data.createdby = auth.userid;
            data.publicationtype = defaultValues.publicationtype;

            response = await axiosPrivate.post(AWARDS_CONFIG_URL, data, {
              signal: controller.signal,
            });
          } else {
            data.updatedby = auth.userid;
            data.publicationtype = defaultValues.publicationtype;
            data.publicationid = editAwardsDetails?.publicationid;

            response = await axiosPrivate.post(
              `${AWARDS_CONFIG_URL}/put`,
              data,
              {
                signal: controller.signal,
              }
            );
          }
          if (response?.status === 200) {
            if (isEdit) {
              setEditAwardsDetails({});
              showToast(
                "Award & Appreciation Details has been updated successfully",
                "success"
              );
            } else {
              showToast(
                "Award & Appreciation Details has been added successfully",
                "success"
              );
            }
            getAwardsList();
          } else {
            if (isEdit) {
              showToast(
                "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
                "error"
              );
            } else {
              showToast(
                "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
                "error"
              );
            }
            return;
          }
        }
        setShowAddForm(false);
        reset();
      } catch (error) {
        if (!error?.response) {
          showToast("No Server Response", "error");
        } else if (error.response.status === 422) {
          showToast("Some of the required inputs were not provided", "error");
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
      }
    } else {
      showToast(
        "File you uploaded seems to be invalid or has a invalid name",
        "error"
      );
    }
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${editAwardsDetails.publicationtitle} Document`);
    setShowPDFViewer(true);
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="publicationtitle"
          register={register}
          name="Award Title"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter award title"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.publicationtitle}
          setValue={setValue}
        />
        {isEdit && editAwardsDetails.publicationdoc && (
          <span className="flex items-center">
            Uploaded Document / Image : &nbsp;
            {editAwardsDetails.publicationdoc.includes("image") ? (
              <img
                src={editAwardsDetails.publicationdoc}
                className={"ml-3 w-40 h-auto"}
              />
            ) : (
              <Fragment>
                <IconContext.Provider
                  value={{ className: "text-red-700 text-5xl" }}
                >
                  <FaFilePdf />
                </IconContext.Provider>
                <Button
                  className={linkTextPrimary}
                  onClick={() => onClickView(editAwardsDetails.publicationdoc)}
                >
                  {editAwardsDetails.publicationtitle}.pdf
                </Button>
              </Fragment>
            )}
          </span>
        )}
        <PDFImageUploader
          register={register}
          defaultName="publicationdoc"
          name={`${
            isEdit && editAwardsDetails.publicationdoc
              ? "Replace Awards Document / Image"
              : "Awards Document / Image"
          }`}
          required={isEdit ? false : true}
          pattern={null}
          placeholder="Awards Document / Image"
          type="file"
          errors={errors}
          classes={`rounded-lg px-3 py-2 text-sm w-full border border-slate-100`}
          setError={setError}
          clearError={clearErrors}
          file={awardsDoc}
          setBase64={setAwardsDoc}
          setValue={setValue}
          isEdit={isEdit}
          oldFileOnEdit={isEdit ? defaultValues.publicationdoc : ""}
          setFile={setAwardFile}
          fileUploadError={fileUploadError}
          setFileUploadError={setFileUploadError}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditAwardsDetails({});
              setValue("categoryname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Category Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </div>
  );
}
