import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import DesignationList from "./DesignationList";
import AddDesignationForm from "./AddDesignationForm";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { DESIGNATION_CONFIG_URL } from "../../../../api/api_routing_urls";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import showToast from "../../../../utilities/notification/NotificationModal";

export default function DesignationConifig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [designationList, setDesignationList] = useState();
  const [editDesignationDetails, setEditDesignationDetails] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [designationId, setDesignationId] = useState(false);

  let isEdit =
    editDesignationDetails === undefined || editDesignationDetails === null
      ? false
      : Object?.keys(editDesignationDetails)?.length > 0
      ? true
      : false;
  // const isEdit = false;

  const getDesignationList = async () => {
    try {
      const response = await axiosPrivate.get(DESIGNATION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setDesignationList(response?.data?.designationList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteDesignationDetails = async () => {
    try {
      const data = {};
      data.designation_id = designationId;
      const response = await axiosPrivate.post(
        `${DESIGNATION_CONFIG_URL}/deleteDesignationDetails`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Designation deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getDesignationList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    getDesignationList();
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Desigantion "}
          setShowAddForm={setShowAddForm}
        />

        {showAddForm ? (
          <>
            <GenericModal
              open={showAddForm}
              setOpen={setShowAddForm}
              title={` ${isEdit ? "Update Designation" : "Add Designation"}`}
              isAdd={true}
            >
              <AddDesignationForm
                setShowAddForm={setShowAddForm}
                getDesignationList={getDesignationList}
                editDesignationDetails={editDesignationDetails}
                setEditDesignationDetails={setEditDesignationDetails}
              />
            </GenericModal>
          </>
        ) : (
          <DesignationList
            designationList={designationList}
            setEditDesignationDetails={setEditDesignationDetails}
            setShowAddForm={setShowAddForm}
            setDesignationId={setDesignationId}
            setShowDelete={setShowDelete}
          />
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteDesignationDetails}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
