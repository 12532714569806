import React, { useEffect, useState } from "react";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { BiChevronLeft } from "react-icons/bi";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { getFormattedDateInputDate } from "../../../../utilities/dateFunctions/formatdate";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import { NOTICE_URL } from "../../../../api/api_routing_urls";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import OTSNoticeTemplate from "./OTSNoticeTemplate";
import showToast from "../../../../utilities/notification/NotificationModal";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";

import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";

export default function GenerateOTSNotice({
  setSelectedDate,
  selectedDate,
  loaneeDetails,
  isLoading,
  refNumber,
  setRefNumber,
  onChange,
  setShowGenerateOTSNotice,
  getNoticeList,
  setRefresh,
  otsLoaneeDetails,
  currentFiscalYear,
  otsLoaneeDue,
  setSelectedGuarantor,
  selectedGuarantor,

  copyToExist,
  setCopyToExist,
}) {
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const controller = new AbortController();

  const handleGenerate_ReferenceNo = async () => {
    try {
      let response = "";
      response = await axiosPrivate.post(
        `${NOTICE_URL}/saveNoticeDetails`,
        {
          loanee_id: loaneeDetails?.loanee_id,
          category_type: loaneeDetails?.categoryname,
          generated_date: selectedDate,
          createdby: auth.userid,
          notice_type: "OTS Notice",
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        getNoticeList();
        setRefNumber(response?.data?.saveNoticeDetail);
        showToast("Reference Number has been added successfully", "success");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        return;
      }
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getNoticeList();
    }
  };

  // some days ahead to selectedDate or few days ahead from notice generated date
  const [daysAheadToSelectedDate, setDaysAheadToSelectedDate] =
    useState(selectedDate);

  // Function to add days to a date

  const addDaysToDate = (dateString, daysToAdd) => {
    const date = new Date(dateString);
    date.setDate(date.getDate() + daysToAdd);
    return date;
  };

  // Function to handle adding 15 days to the selected date
  const handleAdd15Days = () => {
    const newDate = addDaysToDate(selectedDate, 15);
    setDaysAheadToSelectedDate(newDate.toISOString().split("T")[0]); // Convert Date to string in 'yyyy-mm-dd' format
  };

  useEffect(() => {
    handleAdd15Days();
  });

  return (
    <>
      <div className="flex justify-between">
        <H2withIcon>
          <MdAssignmentTurnedIn />
          <span className="pl-1.5">Generate One Time Settlement Notice</span>
        </H2withIcon>

        <span
          className="flex items-center cursor-pointer hover:underline underline-offset-2  "
          onClick={() => {
            setShowGenerateOTSNotice(false);
            setRefNumber(null);
            setSelectedDate(null);
          }}
        >
          <BiChevronLeft size={24} /> back
        </span>
      </div>

      <>
        <div className="flex flex-col">
          <Input
            defaultName="otsNoticedate"
            register={register}
            name="Date"
            required={false}
            pattern={null}
            errors={errors}
            placeholder="Enter  date"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="date"
            classes={`rounded-sm px-3 py-2 text-sm w-fit`}
            onChangeInput={(e) => {
              setSelectedDate(e);

              onChange(e);
              // setRefreshPage((prev) => !prev);
            }}
            defaultValue={null}
            setValue={setValue}
            max={getFormattedDateInputDate(new Date())}
          />
        </div>
      </>

      <>
        {refNumber ? (
          <>
            <section className="bg-white">
              <PrintReport
                reportTitle={"One Time Settlement Notice"}
                buttonName={"One Time Settlement  Notice"}
              >
                <OTSNoticeTemplate
                  selectedDate={selectedDate}
                  loaneeDetails={loaneeDetails}
                  refNumber={refNumber}
                  otsLoaneeDetails={otsLoaneeDetails}
                  currentFiscalYear={currentFiscalYear}
                  daysAheadToSelectedDate={daysAheadToSelectedDate}
                  otsLoaneeDue={otsLoaneeDue}
                  copyToExist={copyToExist}
                  selectedGuarantor={selectedGuarantor}
                />
              </PrintReport>
            </section>
          </>
        ) : (
          <>
            {selectedDate === undefined ||
            selectedDate === null ||
            selectedDate === "" ? null : (
              <div className=" py-4">
                <AddButton
                  label="Generate Reference Number"
                  onclick={() => handleGenerate_ReferenceNo()}
                />
              </div>
            )}
          </>
        )}

        {selectedDate === undefined ||
        selectedDate === null ||
        selectedDate === "" ? null : (
          <>
            {isLoading ? (
              <>
                <section className="h-screen">
                  <SpinnerWithText />
                </section>
              </>
            ) : (
              <>
                <OTSNoticeTemplate
                  selectedDate={selectedDate}
                  loaneeDetails={loaneeDetails}
                  refNumber={refNumber}
                  otsLoaneeDetails={otsLoaneeDetails}
                  currentFiscalYear={currentFiscalYear}
                  daysAheadToSelectedDate={daysAheadToSelectedDate}
                  otsLoaneeDue={otsLoaneeDue}
                  copyToExist={copyToExist}
                  selectedGuarantor={selectedGuarantor}
                />
              </>
            )}
          </>
        )}
      </>
    </>
  );
}
