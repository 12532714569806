import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";

// import useAuth from "../hooks/useAuth";
import SpinnerWithText from "../../reusable-components/loader/SpinnerWithText";
import { useSelector } from "react-redux";
import PublicNavbar from "../../common/publicNavbar/PublicNavbar";
import PublicFooter from "../../common/publicNavbar/PublicFooter";
import useRepaymentRefreshToken from "../hooks/useRepaymentRefreshToken";

const PersistRepaymentLoaneDetails = () => {
  const { completeLoaneeDetails } = useSelector((state) => state.loaneeDetails);
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRepaymentRefreshToken();
  // const { auth } = useAuth();

  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();
      } catch (err) {
        console.error(err);
      } finally {
        setIsLoading(false);
      }
    };

    // !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    !completeLoaneeDetails?.accessToken
      ? verifyRefreshToken()
      : setIsLoading(false);
  }, [Object.keys(completeLoaneeDetails).length]);

  return (
    <>
      {isLoading ? (
        <>
          <PublicNavbar />
          <SpinnerWithText />
          <PublicFooter />
        </>
      ) : (
        <>
          <PublicNavbar />
          <Outlet />
          <PublicFooter />
        </>
      )}
    </>
  );
};

export default PersistRepaymentLoaneDetails;
