import React, { useState } from "react";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Dashboard from "../../../../common/Dashboard";
import { ToastContainer } from "react-toastify";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import AreaList from "./AreaList";
import AddAreaForm from "./AddAreaForm";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { AREA_CONFIG_URL } from "../../../../api/api_routing_urls";
import { useEffect } from "react";
import showToast from "../../../../utilities/notification/NotificationModal";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function AreaConfig() {
  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [areaList, setAreaList] = useState();
  const [editAreaDetails, setEditAreaDetails] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [areaId, setAreaId] = useState();

  const isEdit = Object.keys(editAreaDetails).length > 0 ? true : false;

  const [showAddForm, setShowAddForm] = useState(false);

  const getAreaList = async () => {
    try {
      const response = await axiosPrivate.get(AREA_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setAreaList(response?.data?.areaList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteAreaDetails = async () => {
    try {
      const data = {};
      data.area_id = areaId;
      const response = await axiosPrivate.post(
        `${AREA_CONFIG_URL}/deleteAreaDetails`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 && showToast("Area deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getAreaList();
      setShowDelete(false);
    }
  };

  useEffect(() => {
    getAreaList();
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Area "}
          setShowAddForm={setShowAddForm}
        />

        {showAddForm ? (
          <>
            <GenericModal
              open={showAddForm}
              setOpen={setShowAddForm}
              title={` ${isEdit ? "Update Area" : "Add Area"}`}
              isAdd={true}
            >
              <AddAreaForm
                setShowAddForm={setShowAddForm}
                getAreaList={getAreaList}
                editAreaDetails={editAreaDetails}
                setEditAreaDetails={setEditAreaDetails}
              />
            </GenericModal>
          </>
        ) : (
          <AreaList
            areaList={areaList}
            setEditAreaDetails={setEditAreaDetails}
            setShowAddForm={setShowAddForm}
            setAreaId={setAreaId}
            setShowDelete={setShowDelete}
          />
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteAreaDetails}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
