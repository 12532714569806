import React, { useEffect, useState } from "react";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { button, cancel_button } from "../../../../theme/lightTheme";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import {
  AREA_CONFIG_URL,
  DISTRICT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import showToast from "../../../../utilities/notification/NotificationModal";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import Button from "../../../../reusable-components/buttons/Button";

export default function AddAreaForm({
  setShowAddForm,
  getAreaList,
  editAreaDetails,
  setEditAreaDetails,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [districtList, setDistrictList] = useState([]);
  const [selected, setSelected] = useState(null);
  const controller = new AbortController();

  const isEdit = Object.keys(editAreaDetails).length > 0 ? true : false;

  const defaultValues = {
    district_name: !isEdit
      ? ""
      : {
          value: editAreaDetails?.districtid,
          label: editAreaDetails?.district_name,
        },
    area_name: !isEdit ? "" : editAreaDetails?.area_name,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          for (let i = 0; i < response?.data?.districtList?.length; i++) {
            let districtObj = {
              value: parseInt(response.data.districtList[i].districtid),
              label: response.data.districtList[i].districtname,
            };
            districtDD.push(districtObj);
          }

          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No District list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    getDistrictList();
  }, []);

  const onSubmit = async (data) => {
    try {
      let response = "";
      let sendDataObj = {};

      sendDataObj.district_id = data.district_name.value;
      sendDataObj.area_name = data.area_name;
      if (!isEdit) {
        sendDataObj.createdby = auth.userid;

        response = await axiosPrivate.post(
          `${AREA_CONFIG_URL}/saveAreaDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      } else {
        sendDataObj.updatedby = auth.userid;
        sendDataObj.area_id = editAreaDetails?.area_id;
        // data.district_id = parseInt(data?.editAreaDetails?.districtid);

        response = await axiosPrivate.post(
          `${AREA_CONFIG_URL}/updateAreaDetails`,
          sendDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditAreaDetails({});
          showToast("Area has been updated successfully", "success");
        } else {
          showToast("Area has been added successfully", "success");
        }
        getAreaList();
      } else {
        if (isEdit) {
          if (response.data.updateConstituencyDetails.includes("unique_area")) {
            showToast(
              "Area already exists. Cannot insert duplicate area",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveConstituencyDetails.includes("unique_area")) {
            showToast(
              "Area already exists. Cannot insert duplicate constituency",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Dropdown
            defaultName="district_name"
            register={register}
            labelname="District"
            required={true}
            pattern={null}
            errors={errors}
            classes={`rounded-lg text-sm w-full`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={districtList}
            defaultValue={defaultValues.district_name}
            setValue={setValue}
            setSelected={setSelected}
            selected={selected}
            maxMenuHeight={120}
          />
          <Input
            defaultName="area_name"
            register={register}
            name="Area Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter area name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.area_name}
            setValue={setValue}
          />

          <div className="flex justify-between">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                setEditAreaDetails({});
                setValue("areaname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Area Update Button"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
