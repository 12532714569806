import React, { useState } from "react";
import { ToastContainer } from "react-toastify";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import Dashboard from "../../../../common/Dashboard";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import AddCashCounter from "./AddCashCounter";
import CashCounterList from "./CashCounterList";
import { MST_CASHCOUNTER_CONFIG_URL } from "../../../../api/api_routing_urls";
import { useEffect } from "react";
import showToast from "../../../../utilities/notification/NotificationModal";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";

export default function CashCounterConfig() {
  const [showAddForm, setShowAddForm] = useState(false);
  const [showDelete, setShowDelete] = useState(false);
  const [cashCounterList, setCashCounterList] = useState([]);

  const [cashCounterId, setCashCounterId] = useState();

  const [editCashCounterDetails, setEditCashCounterDetails] = useState();

  const { auth } = useAuth();
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const getCashCounterList = async () => {
    try {
      const response = await axiosPrivate.get(MST_CASHCOUNTER_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setCashCounterList(response?.data?.cashCounterList);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const deleteCashCounterDetails = async () => {
    try {
      const data = {};
      data.expense_id = cashCounterId;
      const response = await axiosPrivate.post(
        `${MST_CASHCOUNTER_CONFIG_URL}/deleteCashCounterDetails`,
        data,
        {
          signal: controller.signal,
        }
      );
      response.status === 200 &&
        showToast("Cash Counter Expense title  deleted", "success");
      response.status === 202 &&
        showToast(
          "Processing request, if the bank is not deleted please try again later.",
          "warning"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact adminstrator",
          "error"
        );
      }
    } finally {
      getCashCounterList();
      setShowDelete(false);
    }
  };

  let isEdit =
    editCashCounterDetails === undefined || editCashCounterDetails === null
      ? false
      : Object?.keys(editCashCounterDetails)?.length > 0
      ? true
      : false;

  useEffect(() => {
    getCashCounterList();
  }, []);

  return (
    <>
      <Dashboard sidebarType={auth.rolename}>
        <ToastContainer />

        <MasterConfigHeaderWithoutDeleteWrapper
          headerTitle={"Cash Counter "}
          setShowAddForm={setShowAddForm}
        />

        {showAddForm === true ? (
          <>
            <GenericModal
              open={showAddForm}
              setOpen={setShowAddForm}
              title={` ${isEdit ? "Update Cash Counter" : "Add Cash Counter"}`}
              isAdd={true}
            >
              <AddCashCounter
                setShowAddForm={setShowAddForm}
                getCashCounterList={getCashCounterList}
                editCashCounterDetails={editCashCounterDetails}
                setEditCashCounterDetails={setEditCashCounterDetails}
              />
            </GenericModal>
          </>
        ) : (
          <>
            <CashCounterList
              cashCounterList={cashCounterList}
              setEditCashCounterDetails={setEditCashCounterDetails}
              setShowAddForm={setShowAddForm}
              setCashCounterId={setCashCounterId}
              setShowDelete={setShowDelete}
            />
          </>
        )}

        <DeleteModal
          open={showDelete}
          setOpen={setShowDelete}
          message={"The User data would be deleted permenantly. Are you sure?"}
          onDelete={deleteCashCounterDetails}
          setShowDelete={setShowDelete}
        />
      </Dashboard>
    </>
  );
}
