import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

export default function RefundCancellationPolicy() {
  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Refund Cancellation Policy</H1>
          </div>
        </div>
      </HeroBanner>

      <section>
        <div className="container mx-auto p-4">
          <h1 className="text-lg font-bold mb-4">Scope of Application</h1>
          <p className=" text-sm">
            Terms and Conditions of Online Banking Services (as may be varied
            and amended from time to time, hereinafter referred to as these
            “Terms”) shall apply to the Online Banking Services provided by
            Limited (hereinafter referred to as “We” or “Us”) and shall be
            legally binding on customers and us. Both customers and we shall
            comply with the Terms.
          </p>
          <p className="text-sm">
            Before a customer applies to us for activation of Online Banking
            Services or uses Online Banking Services for the first time, the
            customer shall carefully read the Terms and fully understand
            relevant provisions hereof, and the customer shall have the right to
            require us to provide sufficient explanation about the Terms. BY
            APPLYING TO US FOR THE ACTIVATION OF ONLINE BANKING SERVICES OR
            USING ONLINE BANKING SERVICES FOR THE FIRST TIME, CUSTOMERS SHALL BE
            DEEMED TO HAVE CAREFULLY READ, UNDERSTOOD AND ACCEPTED THE TERMS AND
            AGREED TO BE BOUND HEREBY.
          </p>

          <h1 className="text-lg font-bold mb-4">II. Provision of Services</h1>
          <p className="text-sm">
            Customers may apply to us for the activation of Online Banking
            Services, and we may also activate all or part of the functions of
            Online Banking Services for qualified customers. Customers may use
            part of Online Banking Services without applications or requiring
            our additional operations. Customers may apply to us for the
            termination of certain Online Banking Services, and we have the
            right to accept or reject such application of the customers.
          </p>
          <p className="text-sm">
            Customers may use computers, fixed-line phones, mobile phones, ATMs
            and other self-service bank facilities, or other electronic devices
            to use or operate all or part of financial services via the
            Internet, telephone communication networks, wireless networks, other
            open public networks or private networks provided by us (hereinafter
            referred to as “Online Banking Services” or these “Services”).
          </p>

          <h1 className="text-lg font-bold mb-4">III. Content of Services</h1>
          <p>
            <strong>Online Banking Services</strong>
          </p>
          <p>
            (a) Customers may enjoy Online Banking Services via our website at
            com/cn.
          </p>
          <p>
            (b) Online Banking Services include the Mobile Banking Services.
          </p>
          {/* Continue with the rest of the content */}
        </div>
      </section>
    </>
  );
}
