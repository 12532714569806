/* eslint-disable jsx-a11y/alt-text */
import React, { useRef } from "react";
import { IoPrintOutline } from "react-icons/io5";
import ReactToPrint from "react-to-print";
import { REPAYMENT_CONFIG_URL } from "../../../../api/api_routing_urls";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import useGetAxios from "../../../../hook/useGetAxios";
import { button } from "../../../../theme/lightTheme";

export const ReceiptPrintReport = (props) => {
  const componentRef = useRef();

  const getCurrentDateUrl = `${REPAYMENT_CONFIG_URL}/getCurrentDateFromServer`;

  const { data } = useGetAxios(getCurrentDateUrl);

  return (
    <>
      <div className=" bg-white">
        <ReactToPrint
          trigger={() => (
            <button className={button}>
              <div className="flex gap-1 items-center">
                <IoPrintOutline size={20} />{" "}
                <span>Print {props?.buttonName}</span>
              </div>
            </button>
          )}
          content={() => componentRef.current}
          pageStyle="legal"
          documentTitle={props?.reportTitle}
          bodyClass="bg-white"
        />
      </div>
      <div className="hidden">
        {/* <div className=""> */}
        <section ref={componentRef} className="bg-white ">
          {/*  <div className=" h-[100%] ">
            <div className="w-[95%] mx-auto flex justify-between items-center p-1 gap-3">
            <div className="flex gap-3 items-center">
                <img className="h-16 w-16" src={sabccoLogo} />
                <div>
                  <p className="text-center text-[14px] font-semibold">
                    Sikkim SC / ST / OBC Development Corporation.Ltd. (SABCCO)
                  </p>
                  <p className="text-[12px]  -mt-1">
                    Sonam Tshering Marg, Gangtok - 737101
                  </p>
                </div>
              </div> 
            </div>
          </div>*/}
          {props?.children ? (
            <div className=" bg-white text-justify">{props?.children}</div>
          ) : null}
        </section>
      </div>
    </>
  );
};
