import React, { Fragment, useEffect } from "react";

export default function FilterInput({
  name,
  classes,
  onChangeInput,
  showInput,
  ...rest
}) {
  return (
    <Fragment>
      <div>
        <label className="text-sm text-slate-700">{name}</label>
        <input
          defaultValue={rest?.defaultValue}
          placeholder={rest?.placeholder}
          autoComplete={rest?.autoComplete}
          type={rest?.type}
          className={` outline-none border border-slate-300 ${classes}`}
          onChange={async (e) => {
            if (e?.target?.value !== undefined && e?.target?.value !== null) {
              onChangeInput(e?.target?.value);
            }
          }}
        />
      </div>
    </Fragment>
  );
}
