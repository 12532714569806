import { CiCalculator2, CiMoneyCheck1 } from "react-icons/ci";
import { AiOutlineBranches } from "react-icons/ai";
import { IoCashOutline } from "react-icons/io5";
import { formatINRCurrency } from "../../../utilities/currency/currency";

export default function ReportCard({
  principle,
  interest,
  penal_Interest,
  interest_on_interest,
  title,
  ots_repayment,
}) {
  return (
    <>
      <div className="bg-slate-100 p-2 flex border rounded-sm">
        <div className="flex items-center">
          <div className="tracking-tighter text-xs pr-2 ">
            <div className="font-semibold text-sm pb-3">{title}</div>

            <>{title === "Cheque" && <CiMoneyCheck1 size={24} />}</>
            <>{title === "Branch" && <AiOutlineBranches size={22} />}</>
            <>{title === "Cash" && <IoCashOutline size={20} />}</>
            <>{title === "Total" && <CiCalculator2 size={22} />}</>
          </div>
        </div>
        <div className="border-l-slate-300 border-l col-span-3 pl-2 text-xs ">
          <div className="flex items-center">
            <div className="w-14 tracking-tighter">Principle : </div>
            <div className="font-medium 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(+principle || 0)}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-14 ">Inerest : </div>
            <div className="font-medium 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(+interest || 0)}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-14 ">P.I : </div>
            <div className="font-medium 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(+penal_Interest || 0)}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-14 ">I.O.I : </div>
            <div className="font-medium 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(+interest_on_interest || 0)}
            </div>
          </div>
          <div className="flex items-center">
            <div className="w-14 ">OTS : </div>
            <div className="font-medium 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(+ots_repayment || 0)}
            </div>
          </div>
          <div className="flex items-center font-bold border-t mt-1 border-slate-400">
            <div className="w-14 ">Total : </div>
            <div className=" 2xl:text-sm text-xs pt-1">
              {formatINRCurrency(
                (+principle || 0) +
                  (+interest || 0) +
                  (+penal_Interest || 0) +
                  (+interest_on_interest || 0) +
                  (+ots_repayment || 0)
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
