import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { PUBLICATIONS_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import { FaFileUpload } from "react-icons/fa";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import AddPublicationsForm from "./AddPublicationsForm";
import PublicationsList from "./PublicationsList";
import useAuth from "../../../../authentication/hooks/useAuth";

export default function PublicationsConfig() {
  const { auth } = useAuth();

  const [publicationsList, setPublicationsList] = useState([]);
  const [editPublicationsDetails, setEditPublicationsDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editPublicationsDetails).length > 0 ? true : false;

  const publicationTypeList = [
    {
      value: "Order",
      label: "Order",
    },
    {
      value: "Circulars",
      label: "Circulars",
    },
    { value: "Notifications", label: "Notifications" },
    { value: "Reports", label: "Reports" },
  ];

  const getPublicationsList = async () => {
    try {
      const response = await axiosPrivate.get(PUBLICATIONS_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 &&
        setPublicationsList(response.data.publicationsList);
      response.status === 202 &&
        showToast("No publications list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getPublicationsList();
    setEditPublicationsDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaFileUpload />
          </IconContext.Provider>
          &nbsp; Publications
        </H2withIcon>

        {!showAddForm && (
          <AddButton
            label="Add Publication"
            onclick={() => setShowAddForm(true)}
          />
        )}
      </div>
      <p className="text-red-700 text-sm mb-2 -mt-2">
        A new blinker will be shown near the publications for 7 days from the
        posted date
      </p>
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Publication" : "Add Publication"}`}
        isAdd={true}
      >
        <AddPublicationsForm
          getPublicationsList={getPublicationsList}
          editPublicationsDetails={editPublicationsDetails}
          setEditPublicationsDetails={setEditPublicationsDetails}
          publicationTypeList={publicationTypeList}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <ContainerShadow>
        {publicationsList.length === 0 ? (
          <p>Publication yet to be added</p>
        ) : (
          <div className="grid grid-cols-1">
            <PublicationsList
              publicationsList={publicationsList}
              setEditPublicationsDetails={setEditPublicationsDetails}
              publicationTypeList={publicationTypeList}
              setShowAddForm={setShowAddForm}
            />
          </div>
        )}
      </ContainerShadow>
    </Dashboard>
  );
}
