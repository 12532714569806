import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { DISTRICT_CONFIG_URL } from "../../../api/api_routing_urls";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import H2withSettingIcon from "../../../reusable-components/headings/H2withSettingIcon";

import showToast from "../../../utilities/notification/NotificationModal";
import UpdatePasswordForm from "./UpdatePasswordForm";

const PasswordConfig = () => {
  const { auth } = useAuth();
  const [districtList, setDistrictList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editDistrictDetails, setEditDistrictDetails] = useState({});
  const isEdit = Object.keys(editDistrictDetails).length > 0 ? true : false;
  const controller = new AbortController();
  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setDistrictList(response.data.districtList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />

      <H2withSettingIcon>{"Password"}Configuration</H2withSettingIcon>

      <UpdatePasswordForm />
    </Dashboard>
  );
};

export default PasswordConfig;
