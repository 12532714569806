import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function RepaymentList({
  repaymentList,
  setEditRepaymentDetails,
  setShowAddForm,
}) {
  const onClickEdit = (repayment) => {
    setEditRepaymentDetails(repayment);
    setShowAddForm(true);
  };

  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr className="bg-slate-200">
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Applicant Name
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Relative Name
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Repayment Date
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Repayment Amount
          </th>

          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Interest Paid
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Principle Paid
          </th>
          <th
            scope="col"
            className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
          >
            Remarks
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {repaymentList?.length > 0 &&
          repaymentList?.map((repayment, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.applicantname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.applicantrelativename}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {getFormattedDateDDMMMYYYYDate(repayment?.repaymentdate)}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.repaymentamount}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.interestamountpaid}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {repayment?.principleamountpaid}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <small>{repayment?.remarks}</small>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
