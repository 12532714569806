/* eslint-disable no-undef */
/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable jsx-a11y/no-distracting-elements */
import React, { useEffect, useState } from "react";
import axios from "../../../api/axios";
import { ABOUT_US_PUBLIC_URL } from "../../../api/api_routing_urls";
import Button from "../../../reusable-components/buttons/Button";
import { button, cancel_button } from "../../../theme/lightTheme";
import Section from "../../../reusable-components/section/Section";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import AboutSection from "../home-sections/AboutSection";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import SchemesList from "../Pages/OurScheme/SchemesList";
import { MdNewReleases } from "react-icons/md";
import Confetti from "react-confetti";
import useWindowSize from "react-use/lib/useWindowSize";
import { useLocation } from "react-router-dom";

const Home = () => {
  const controller = new AbortController();
  const [aboutUsSummary, setAboutUsSummary] = useState({});
  const [blobURL, setBlobURL] = useState("");
  const [blobDesc, setBlobDesc] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const [isAboutLoading, setIsAboutLoading] = useState(true);
  const [showConfetti, setShowConfetti] = useState(false);

  const getAboutSummary = async () => {
    try {
      const response = await axios.get(ABOUT_US_PUBLIC_URL, {
        signal: controller.signal,
      });

      response.status === 200 &&
        setAboutUsSummary(response.data.aboutUsDetails);
      setIsAboutLoading(false);
    } catch (error) {
      console.error(error);
      setIsAboutLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getAboutSummary();
      setIsAboutLoading(true);
    }

    return () => {
      isMounted = false;
      if (aboutUsSummary) {
        controller.abort();
      }
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!showPDFViewer) {
      setBlobURL("");
    }
  }, [showPDFViewer]);

  const location = useLocation();

  useEffect(() => {
    if (location?.state?.fromLaunchPage) {
      setShowConfetti(true);
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 9000);

      return () => clearTimeout(timer);
    }
  }, [location.state]);

  const { width, height } = useWindowSize();

  return (
    <section>
      {showConfetti && (
        <Confetti
          width={width}
          height={height}
          numberOfPieces={400}
          gravity={0.3}
        />
      )}
      <HeroBanner>
        <div className="pb-9 md:top-[6rem] top-[19rem] relative">
          <a
            href="https://files.sikkimsabcco.com/SABCCO%20NOC%20Application.pdf"
            target="_blank"
            className="cursor-pointer"
          >
            <marquee className="cursor-pointer text-center  bg-white">
              <p className=" text-primary flex items-center justify-normal gap-9 ">
                <div>
                  <span className="text-red-600 animate-ping transform delay-1000 absolute">
                    <MdNewReleases size={21} />
                  </span>
                  <span className="text-red-600 ">
                    <MdNewReleases size={21} />
                  </span>
                </div>
                <div className="pt-0.5">Download SABCCO NOC Application</div>

                <div>
                  <span className="text-red-600 animate-ping transform delay-1000 absolute">
                    <MdNewReleases size={21} />
                  </span>
                  <span className="text-red-600 ">
                    <MdNewReleases size={21} />
                  </span>
                </div>
              </p>
            </marquee>
          </a>
        </div>
        <main className="absolute left-5 md:left-14 lg:left-1/5 2xl:left-[10%] right-10 md:right-14 lg:right-10 top-20 md:top-1/4">
          <div className="text-left">
            <p className="font-medium leading-5 tracking-widest text-lg md:text-3xl lg:text-5xl text-white ">
              Financial <br />
              Assistance/Loans <br />
              at low <span className="text-ternary">interest</span>
            </p>
            <div className="md:border-t-3 border-t border-ternary w-32 md:w-1/4 lg:mt-9 md:mt-6 mt-3" />
            <p className="text-slate-300/70 lg:w-2/5 lg:text-lg md:pt-2 text-xs md:text-lg md:w-1/2">
              for setting-up sustainable income generating activities, to the
              Scheduled Castes, Scheduled Tribes, Backward Classes including the
              Persons with Disability and Minorities living Below the Poverty
              Line.
            </p>
          </div>
        </main>
      </HeroBanner>

      <Section className="container mx-auto lg:my-24 relative p-5">
        <div className="grid grid-cols-3">
          <div className="col-span-4 md:col-span-2 lg:col-span-3 lg:col-start-2 w-full ">
            <AboutSection
              aboutUsSummary={aboutUsSummary}
              isAboutLoading={isAboutLoading}
            />
          </div>

          {/* <div className="col-span-4 md:col-span-2 lg:col-span-1">
            <NoticeBoardSection
              noticeBoardList={noticeBoardList}
              onClickView={onClickView}
              isNoticeLoading={isNoticeLoading}
            />
          </div> */}
        </div>
      </Section>

      <section className="py-9 bg-white">
        <>
          <section className="container mx-auto my-12 px-9">
            <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31] ">
              Objectives
            </div>
            <p className="text-justify leading-7 text-slate-700 text-sm mt-9">
              To promote economic and development activities for the benefit of
              Scheduled Castes, Scheduled Tribes, Other Backward Classes and
              Handicapped Persons. To assist individuals or groups of
              individuals belonging to Scheduled Castes, Scheduled Tribes, Other
              Backward Classes and Handicapped Persons by way of loans and
              advances for income and employment generating activities. To
              extend loans to Scheduled Castes, Scheduled Tribes, Other Backward
              Classes and Handicapped Persons pursuing
              general/professional/technical education or training. To help in
              furthering Government policies and programmes for the development
              of Scheduled Castes, Scheduled Tribes, Other Backward Classes and
              Handicapped Persons. To enter into any agreement with Central
              Government/State Government of any State or local authorities or
              otherwise for the purpose of carrying out objects of the Company
              and to obtain from any such Government, State authorities or
              persons, any charters, subsidies, loans, grants etc.
            </p>
            <div className="mx-4 pt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9"></div>
          </section>
        </>
      </section>
      <section className="py-9 bg-slate-50">
        <>
          <section className="container mx-auto my-12 px-9">
            <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31] ">
              <p>Target Group</p>
            </div>
            <p className="text-lg font-semibold text-slate-700 text-center">
              Promoting economic development activities
            </p>
            <div className="mt-16 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
              <Cards title={"Scheduled Castes"} />
              <Cards title={"Scheduled Tribes"} />
              <Cards title={"Other Backward Classes"} />
              <Cards title={"Persons with Disabilities"} />
              <Cards title={"Minorities belonging to low-income group"} />
              <Cards title={"Educated unemployed and women"} />
            </div>
          </section>
        </>
      </section>

      <section className="py-9 bg-white">
        <SchemesList isHomePage={true} />
      </section>
      {/* <SchemeSection
        schemesList={schemesList}
        isSchemesLoading={isSchemesLoading}
      /> */}

      {/* <Section className="p-9 mx-auto "> */}
      {/* <div className="container mx-auto"> */}
      {/* <AwardsSection
            awardsList={awardsList}
            onClickAwardsView={onClickAwardsView}
            isAwardsLoading={isAwardsLoading}
          /> */}

      {/* <div className="col-span-4 md:col-span-2">
            <NewsSection
              newsList={newsList}
              onClickView={onClickView}
              isNewsLoading={isNewsLoading}
            />
          </div> */}
      {/* </div> */}
      {/* </Section> */}
      {/* <PhotoGallerySection
        photoGalleryList={photoGalleryList}
        isPhotoLoading={isPhotoLoading}
      /> */}
      {/* <FAQSection faqList={faqList} isFAQLoading={isFAQLoading} /> */}
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        {blobDesc !== "" && blobDesc !== null && (
          <div
            className="leading-8 text-justify py-2"
            dangerouslySetInnerHTML={{ __html: blobDesc }}
          />
        )}
        {blobURL !== "" && (
          <div className="mt-2 flex items-center space-x-2">
            <p>{modalTitle} Document</p>
            <a href={blobURL} download={modalTitle}>
              <Button className={button}>Download</Button>
            </a>
          </div>
        )}
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("");
              setBlobDesc("");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </section>
  );
};

export default Home;

const Cards = ({ title }) => {
  return (
    <>
      <div className="bg-white shadow-md rounded-sm p-6">
        <p className="text-lg font-semibold text-slate-800">{title}</p>
      </div>
    </>
  );
};
