import React, { useEffect, useState, Fragment } from "react";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  CATEGORY_CONFIG_URL,
  LOANEE_DETAILS_CONFIG,
  NOTIFICATION_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { IconContext } from "react-icons";
import Dashboard from "../../../../common/Dashboard";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { FaUsers } from "react-icons/fa";
import { MdPersonAdd, MdOutlineArrowBackIos } from "react-icons/md";
import AddButton from "../../../../reusable-components/buttons/AddButton";

import ViewRejectedApplicantDetails from "./ViewRejectedApplicantDetails";
import useAuth from "../../../../authentication/hooks/useAuth";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";

import SearchField from "../../../../reusable-components/search/SearchField";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import { Paging } from "../../../../reusable-components/paging/Paging";
import useGetAxios from "../../../../hook/useGetAxios";
import useNotificationHook from "../../../../authentication/hooks/useNotificationHook";
import RejectedApplicantList from "./RejectedApplicantList";

export default function RejectedApplicantDetailsConfig() {
  const { notificationDetail, setNotificationDetail } = useNotificationHook();

  const [applicantSearchedList, setApplicantSearchList] = useState([]);
  const [editRejectedApplicantDetails, setEditRejectedApplicantDetails] =
    useState({});
  const [editGuarantorDetails, setEditGuarantorDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [showAddDocumentsForm, setShowAddDocumentsForm] = useState(false);
  const [showAddGuarantorForm, setShowAddGuarantorForm] = useState(false);
  const controller = new AbortController();
  const isEdit =
    Object.keys(editRejectedApplicantDetails).length > 0 ? true : false;
  const isEditGuarantor =
    Object.keys(editGuarantorDetails).length > 0 ? true : false;

  const [refresh, setRefresh] = useState(false);

  const [loaneeId, setLoaneeId] = useState();
  const [loaneeName, setLoaneeName] = useState();
  const [viewRejectedApplicantDetail, setViewRejectedApplicantDetail] =
    useState(false);
  const [rejectedApplicantDetail, setRejectedApplicantDetail] = useState({});
  const [roleName, setRoleName] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [guarantorList, setGuarantorList] = useState([]);

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");

  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSize, setPageSize] = useState();

  const { auth } = useAuth();

  const getSearchedApplicantList = async (
    page_no,
    page_size,
    search_text,
    category_type
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${LOANEE_DETAILS_CONFIG}/getLoaneeListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || pageNo || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type || selectedCategory?.label || "",
            rejected_loanee: true,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setApplicantSearchList(
          response?.data?.applicantSearchedList?.loanee_list
        );

        const totalNumberOfApplicant =
          +response?.data?.applicantSearchedList?.list_count;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }

      response.status === 202 &&
        showToast("No state list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  {
    //#endregion
  }

  const getApplicantGuarantorDetailList = async () => {
    try {
      const response = await axiosPrivate.get(
        `${APPLICANT_PERSONAL_DETAILS_CONFIG_URL}/guarantorConfig`,

        {
          signal: controller.signal,
        }
      );

      response.status === 200 &&
        setGuarantorList(response?.data?.guarantorList);

      response.status === 202 &&
        showToast("No Guarantor list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });
      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };
  // getting applicant list on load

  // function for page change
  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  const { data: notificationData, loading: notificationLoading } = useGetAxios(
    NOTIFICATION_CONFIG_URL
  );

  const notificationDataHandler = () => {
    try {
      setNotificationDetail((prev) => ({ ...prev, ...notificationData }));
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getSearchedApplicantList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label
    );
  }, [pageNo, pageSize, selectedCategory?.label, refresh]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !notificationLoading) {
      notificationDataHandler();
    }

    return () => {
      isMounted = false;
    };
  }, [notificationLoading]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && applicantSearchedList) {
      getCategoryList();
      setEditRejectedApplicantDetails({});
      setRoleName(auth?.rolename);
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [applicantSearchedList]);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {!viewRejectedApplicantDetail ? (
        <>
          {isLoading ? (
            <>
              <LoadingModal message={"Loading"} />
            </>
          ) : (
            <>
              <div className="flex flex-col md:flex-row md:items-center justify-between w-full ">
                <H2withIcon>
                  <IconContext.Provider value={{ className: "text-secondary" }}>
                    <FaUsers />
                  </IconContext.Provider>
                  <span>&nbsp; Rejected Applicant List</span>
                </H2withIcon>

                <section className="flex justify-between items-center gap-3 md:w-[60%] mb-4  text-xs  md:text-sm">
                  <FilterDropdown
                    defaultName="filterCategory"
                    labelname="Scheme"
                    required={true}
                    classes={`md:w-[80%] w-1/2`}
                    data={categoryList}
                    defaultValue={null}
                    setSelected={setSelectedCategory}
                    selected={selectedCategory}
                    maxMenuHeight={200}
                  />
                  <SearchField
                    placeholder="Search"
                    defaultValue={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onClick={() => {
                      setPageNo(1);
                      getSearchedApplicantList(
                        1,
                        pageSize?.value,
                        searchText || "",
                        selectedCategory?.label || ""
                      );
                    }}
                    classes={"bg-primary"}
                    onKeyDown={(e) => {
                      if (e?.key === "Enter") {
                        getSearchedApplicantList(
                          1,
                          pageSize?.value,
                          searchText || "",
                          selectedCategory?.label || ""
                        );
                      }
                    }}
                  />
                </section>
              </div>

              <div className="grid grid-cols-1">
                <RejectedApplicantList
                  setLoaneeId={setLoaneeId}
                  setEditGuarantorDetails={setEditGuarantorDetails}
                  setShowAddGuarantorForm={setShowAddGuarantorForm}
                  applicantSearchedList={applicantSearchedList}
                  setEditRejectedApplicantDetails={
                    setEditRejectedApplicantDetails
                  }
                  setShowAddForm={setShowAddForm}
                  setShowAddDocumentsForm={setShowAddDocumentsForm}
                  setViewRejectedApplicantDetail={
                    setViewRejectedApplicantDetail
                  }
                  setRejectedApplicantDetail={setRejectedApplicantDetail}
                  getSearchedApplicantList={getSearchedApplicantList}
                  setLoaneeName={setLoaneeName}
                />
              </div>
              <Paging
                maxMenuHeight={100}
                selected={pageSize}
                setSelected={setPageSize}
                pageNo={pageNo}
                onPageChangeHandler={onPageChangeHandler}
                numberOfPages={numberOfPages}
              />
            </>
          )}
        </>
      ) : (
        <>
          <ViewRejectedApplicantDetails
            setViewRejectedApplicantDetail={setViewRejectedApplicantDetail}
            rejectedApplicantDetail={rejectedApplicantDetail}
            setRejectedApplicantDetail={setRejectedApplicantDetail}
            roleName={roleName}
            setRefresh={setRefresh}
          />
        </>
      )}
    </Dashboard>
  );
}
