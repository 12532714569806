import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";

import { CSVLink } from "react-csv";

export default function DisbursmentDetailList({ disbursementDetailList, ref }) {
  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200 text-sm sticky top-0 leading-5"
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Gender
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Scheme Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              District
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Constituency
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600 text-xs">
          {disbursementDetailList?.length > 0 ? (
            disbursementDetailList?.map((disbursementList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.tr_loan_number}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.applicantname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.categoryname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.applicantgender}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.schemename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.districtname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.constituencyname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap ">
                  {disbursementList?.tr_amount}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={disbursementDetailList}
          target="_blank"
          filename={`SABCCO-DisbursementDetailList-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}
