import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { SCHEME_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddSchemeForm from "./AddSchemeForm";
import SchemeList from "./SchemeList";

const SchemeConfig = () => {
  const { auth } = useAuth();

  const [schemeList, setSchemeList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editSchemeDetails, setEditSchemeDetails] = useState({});
  const isEdit = Object.keys(editSchemeDetails).length > 0 ? true : false;
  const controller = new AbortController();
  const getSchemeList = async () => {
    try {
      const response = await axiosPrivate.get(SCHEME_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setSchemeList(response.data.schemeList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  useEffect(() => {
    let isMounted = true;
    getSchemeList();
    setEditSchemeDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"Scheme"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update Scheme" : "Add Scheme"}`}
        isAdd={true}
        isLarge={true}
      >
        <AddSchemeForm
          getSchemeList={getSchemeList}
          editSchemeDetails={editSchemeDetails}
          setEditSchemeDetails={setEditSchemeDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <SchemeList
          schemeList={schemeList}
          setEditSchemeDetails={setEditSchemeDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default SchemeConfig;
