import React from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { CSVLink } from "react-csv";

export default function DailyReportList({ dailyReportList, ref }) {
  return (
    <>
      <div>
        <TableWrapper>
          <thead
            className="bg-slate-200 text-sm leading-5 sticky top-0"
            ref={ref}
          >
            <tr>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                SI No
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Loan Id
              </th>
              {/* <th
      scope="col"
      className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
    >
      Loan No
    </th> */}
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Applicant Name
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Relative Name
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Payment Mode
              </th>

              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Principle Paid
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                Interest Paid
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                P.I Paid
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                I.O.I Paid
              </th>
              <th
                scope="col"
                className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
              >
                OTS Paid
              </th>
            </tr>
          </thead>

          {/* {dailyReportList?.daily_report?.map((dailyObj, key) => (
    <p>{dailyObj?.applicantname}</p>
  ))} */}

          <tbody className="bg-white divide-y text-xs divide-slate-100 text-slate-600">
            {dailyReportList?.daily_report?.length > 0 ? (
              dailyReportList?.daily_report?.map((dailyObj, index) => (
                <tr
                  key={index}
                  className={index % 2 === 1 ? "bg-slate-100" : null}
                >
                  <td className="px-6 py-2 whitespace-nowrap">{index + 1}</td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj.loanee_id}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.applicantname}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.applicantrelativename}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.pay_mode}
                  </td>

                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.tr_amount}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.int_credit}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.int_picredit}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.interest_on_interest_pay}
                  </td>
                  <td className="px-6 py-2 whitespace-nowrap">
                    {dailyObj?.ots_tr_amount}
                  </td>
                </tr>
              ))
            ) : (
              <td colSpan={8} className="p-4 text-center">
                {" "}
                No Record Found
              </td>
            )}
          </tbody>
        </TableWrapper>
      </div>
    </>
  );
}
