import React from "react";

const Badge = (props) => {
  return (
    <div className="rounded-full bg-red-700 w-9 h-6 absolute right-4 top-0 flex items-center justify-center text-xs z-auto">
      {props.children}
    </div>
  );
};

export default Badge;
