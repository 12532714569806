import React from "react";
import { useForm } from "react-hook-form";
import { NAGAR_PANCHAYAT_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";

const AddNagarPanchayatForm = ({
  getNagarPanchayatList,
  editNagarPanchayatDetails,
  setEditNagarPanchayatDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editNagarPanchayatDetails)?.length > 0;

  const defaultValues = {
    nagarpanchayatname: !isEdit
      ? ""
      : editNagarPanchayatDetails?.nagarpanchayatname,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (!isEdit) {
        data.createdby = auth.userid;
        response = await axiosPrivate.post(NAGAR_PANCHAYAT_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.nagarpanchayatid = editNagarPanchayatDetails?.nagarpanchayatid;
        response = await axiosPrivate.put(NAGAR_PANCHAYAT_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditNagarPanchayatDetails({});
          showToast("Nagar Panchayat has been updated successfully", "success");
        } else {
          showToast("Nagar Panchayat has been added successfully", "success");
        }
        getNagarPanchayatList();
      } else {
        if (isEdit) {
          if (
            response.data.updateNagarPanchayatDetails.includes(
              "unique_nagarpanchayat"
            )
          ) {
            showToast(
              "Nagar Panchayat already exists. Cannot insert duplicate nagarpanchayat",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveNagarPanchayatDetails.includes(
              "unique_nagarpanchayat"
            )
          ) {
            showToast(
              "Nagar Panchayat already exists. Cannot insert duplicate Nagar Panchayat",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <section>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="nagarpanchayatname"
          register={register}
          name="Nagar Panchayat Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter nagar panchayat name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.nagarpanchayatname}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditNagarPanchayatDetails({});
              setValue("nagarpanchayatname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Nagar Panchayat Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddNagarPanchayatForm;
