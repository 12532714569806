import React from "react";
import { RiRefreshLine } from "react-icons/ri";
const LabelWithDetail = ({ label, value, isApprox, noRecord, ...rest }) => {
  if (noRecord) {
    value = 0;
  }
  return (
    <>
      <div className="flex text-sm items-center">
        <label htmlFor="lable_with_detil" className="p-1 font">
          {label} :
        </label>
        <p
          id="lable_with_detil"
          className={` text-slate-900 pt-[1px] font-medium
          ${
            rest.pill
              ? "px-3  rounded-full bg-ternary border border-ternary bg-opacity-70"
              : " px-2"
          }`}
        >
          {value || noRecord === true ? (
            <>{isApprox ? `${value} (approx.)` : value}</>
          ) : (
            <>
              <div className="flex items-center gap-1  text-primary bg-ternary bg-opacity-80 rounded p-0.5 px-1.5">
                <RiRefreshLine size={14} className={"animate-spin delay-300"} />
                <div className="text-primary font-medium text-xs flex ">
                  Loading<div className="animate-pulse font-bold">..</div>
                </div>
              </div>
            </>
          )}
        </p>
      </div>
    </>
  );
};
export default LabelWithDetail;
