import React, { Fragment } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { CATEGORY_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";

export default function AddCategoryForm({
  getCategoryList,
  editCategoryDetails,
  setEditCategoryDetails,
  setShowAddForm,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editCategoryDetails).length > 0;

  const defaultValues = {
    categoryname: !isEdit ? "" : editCategoryDetails?.categoryname,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (!isEdit) {
        data.createdby = auth.userid;
        response = await axiosPrivate.post(CATEGORY_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.categoryid = editCategoryDetails?.categoryid;
        response = await axiosPrivate.put(CATEGORY_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditCategoryDetails({});
          showToast("Category has been updated successfully", "success");
        } else {
          showToast("Category has been added successfully", "success");
        }
        getCategoryList();
      } else {
        if (isEdit) {
          if (response.data.updateCategoryDetails.includes("unique_category")) {
            showToast(
              "Category already exists. Cannot insert duplicate category",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (response.data.saveCategoryDetails.includes("unique_category")) {
            showToast(
              "Category already exists. Cannot insert duplicate category",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <Fragment>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="categoryname"
          register={register}
          name="Category Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter category name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.categoryname}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditCategoryDetails({});
              setValue("categoryname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel Category Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Fragment>
  );
}
