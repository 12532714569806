import React, { useEffect, useRef, useState } from "react";
import HeroBanner from "../../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../../reusable-components/headings/H1";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { full_width_button } from "../../../../theme/lightTheme";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";

import axios from "../../../../api/axios";
import { REPAYMENT_lOGIN_URL } from "../../../../api/api_routing_urls";

import { useSelector, useDispatch } from "react-redux";
import { setCompleteLoaneeDetails } from "../../../../features/loaneeDetails/LoaneeDetails";
import { reContact } from "../../../../utilities/constants/regularexpressions";

export default function RepaymentLogin() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const errRef = useRef();
  const [errMsg, setErrMsg] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      const response = await axios.post(REPAYMENT_lOGIN_URL, {
        loan_number: data?.loan_number,
      });

      let loaneeDetailsForOtp = response?.data?.loaneeDetailsForRepayment;

      const isContactValid = reContact.test(loaneeDetailsForOtp?.contactno);
      console.log(isContactValid);

      if (isContactValid) {
        dispatch(
          setCompleteLoaneeDetails({
            loanee_id: loaneeDetailsForOtp?.loanee_id,
            loan_number: loaneeDetailsForOtp?.loan_number,
            contact_number: loaneeDetailsForOtp?.contactno,
            applicantname: loaneeDetailsForOtp?.applicantname,
            accessToken: null,
          })
        );

        if (loaneeDetailsForOtp?.loanee_id) {
          // console.log("loanee_id", loaneeDetailsForOtp?.loanee_id);
          navigate("/verify-login-otp");
        } else {
          setErrMsg("Invalid Loan Number");
        }
      } else {
        setErrMsg(
          "Contact No. invalid, kindly visit SABCCO Ltd. to update your contact details."
        );
      }
    } catch (err) {
      if (!err?.response) {
        setErrMsg("No Server Response");
      } else if (err.response?.status === 422) {
        setErrMsg("Invalid Loan Number");
      } else if (err.response?.status === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Login Failed");
      }
      errRef.current.focus();
    }
  };

  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>Repayment</H1>
          </div>
        </div>
      </HeroBanner>
      <section className="my-10 min-h-screen">
        <div className=" flex flex-col justify-start items-center  ">
          <div className="w-11/12 md:w-4/6 lg:w-2/4 xl:w-2/6 2xl:w-2/6 bg-white border p-4 ">
            <div className="text-center pb-4">
              {/* <p className="text-xl font-medium text-primary ">
                  Lonee Repayment Details
                </p> */}
            </div>

            <form onSubmit={handleSubmit(onSubmit)} id="repaymentLoginForm">
              <p
                ref={errRef}
                className={
                  errMsg ? "px-2 py-1 bg-red-100 text-red-800" : "hidden"
                }
                aria-live="assertive"
              >
                {errMsg}
              </p>

              <Input
                defaultName="loan_number"
                register={register}
                name="Loan Number"
                required={false}
                pattern={null}
                errors={errors}
                placeholder="e.g. VBN0000003"
                setError={setError}
                clearError={clearErrors}
                autoComplete="off"
                type="text"
                classes={`rounded-sm px-3 py-2 text-sm w-[100%] `}
                onChangeInput={null}
                defaultValue={null}
                setValue={setValue}
              />

              <div className="pt-6">
                <SubmitButton
                  isSubmitting={isSubmitting}
                  label="Check Loan Details"
                  className={`${full_width_button} `}
                />
              </div>
            </form>
          </div>
        </div>
      </section>
    </>
  );
}
