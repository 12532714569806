import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { FaLeaf } from "react-icons/fa";
import {
  SUBDIVISION_CONFIG_URL,
  GRAMPANCHAYAT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import { pinCode } from "../../../../utilities/constants/regularexpressions";
import showToast from "../../../../utilities/notification/NotificationModal";

const AddGramPahchayatForm = ({
  getGramPanchayatList,
  editGramPanchayatDetails,
  setEditGramPanchayatDetails,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const [subDivisionList, setSubDivisionList] = useState([]);
  const [selected, setSelected] = useState(null);

  const isEdit = Object.keys(editGramPanchayatDetails)?.length > 0;

  const defaultValues = {
    grampanchayatname: !isEdit
      ? ""
      : editGramPanchayatDetails?.grampanchayatname,
    policestation: !isEdit ? "" : editGramPanchayatDetails?.policestation,
    postoffice: !isEdit ? "" : editGramPanchayatDetails?.postoffice,
    pincode: !isEdit ? "" : editGramPanchayatDetails?.pincode,
    subdivisionid: !isEdit ? "" : selected,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const getSubDivisionList = async () => {
    try {
      const response = await axiosPrivate.get(SUBDIVISION_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.subDivisionList?.length > 0) {
          let subDivisionDD = [];
          for (let i = 0; i < response?.data?.subDivisionList?.length; i++) {
            let subDivisionObj = {
              value: response.data.subDivisionList[i].subdivisionid,
              label: `${
                response.data.subDivisionList[i].subdivisionname
              } ${" "} (${response.data.subDivisionList[i].districtname})`,
            };
            subDivisionDD.push(subDivisionObj);
          }
          setSubDivisionList(subDivisionDD);
        } else {
          setSubDivisionList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const controller = new AbortController();

  const onSubmit = async (data) => {
    data.subdivisionid = data?.subdivisionid?.value;

    try {
      let response = "";
      if (!isEdit) {
        data.createdby = auth.userid;
        response = await axiosPrivate.post(GRAMPANCHAYAT_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.grampanchayatid = editGramPanchayatDetails?.grampanchayatid;

        response = await axiosPrivate.put(GRAMPANCHAYAT_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditGramPanchayatDetails({});
          showToast("GramPanchayat has been updated successfully", "success");
        } else {
          showToast("GramPanchayat has been added successfully", "success");
        }
        getGramPanchayatList();
      } else {
        if (isEdit) {
          if (
            response.data.updateGramPanchayatDetails.includes(
              "unique_grampanchayat"
            )
          ) {
            showToast(
              "Sub Division already exists. Cannot insert duplicate Sub Division",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveGramPanchayatDetails.includes(
              "unique_grampanchayat"
            )
          ) {
            showToast(
              "Sub Division already exists. Cannot insert duplicate Sub Division",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    if (isEdit) {
      setSelected({
        value: editGramPanchayatDetails?.subdivisionid,
        label: `${editGramPanchayatDetails?.subdivisionname} ${" "} (${
          editGramPanchayatDetails?.districtname
        })`,
      });
    }
  }, [isEdit]);

  useEffect(() => {
    getSubDivisionList();
  }, []);

  return (
    <section>
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Dropdown
          defaultName="subdivisionid"
          register={register}
          labelname="Sub Division"
          required={true}
          pattern={null}
          errors={errors}
          classes={`rounded-lg text-sm w-full`}
          setError={setError}
          clearError={clearErrors}
          onChangeInput={null}
          control={control}
          data={subDivisionList}
          defaultValue={defaultValues.subdivisionid}
          setValue={setValue}
          setSelected={setSelected}
          selected={selected}
          maxMenuHeight={120}
        />
        <Input
          defaultName="grampanchayatname"
          register={register}
          name="Gram Panchayat Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter Gram Panchayat name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.grampanchayatname}
          setValue={setValue}
        />
        <Input
          defaultName="policestation"
          register={register}
          name="Police Startion Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter Police Station name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.policestation}
          setValue={setValue}
        />
        <Input
          defaultName="postoffice"
          register={register}
          name="Post Office Name"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter Post Office name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.postoffice}
          setValue={setValue}
        />
        <Input
          defaultName="pincode"
          register={register}
          name="Pin Code"
          required={true}
          pattern={pinCode}
          errors={errors}
          placeholder="Enter Pin Code"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.pincode}
          setValue={setValue}
        />

        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditGramPanchayatDetails({});
              setValue("subdivisionname", "");
              setShowAddForm(false);
            }}
            aria-label="Cancel SubDivision Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </section>
  );
};

export default AddGramPahchayatForm;
