import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useForm } from "react-hook-form";
import { IoEllipseSharp } from "react-icons/io5";
import { Link } from "react-router-dom";
import { CHANGE_PASSWORD, RAS_KEY_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import useAuth from "../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";
import rsa from "../../../utilities/rsaFunction/rsaEncryption";

const { rsaEncrypt } = rsa;

const UpdatePasswordForm = ({}) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [rsaPublicKey, setRsaPublicKey] = useState();
  const [newPassword, setNewPassword] = useState();
  const [btnDisable, setBtnDisable] = useState(true);

  const confirmNewPassword = (e) => {
    if (newPassword === e) {
      setBtnDisable(false);
    } else {
      setBtnDisable(true);
    }
  };

  let goToDashboard = window.location.pathname.replace(
    "change-password",
    "dashboard"
  );

  const getPublicKeyRsa = async () => {
    try {
      const response = await axios.get(RAS_KEY_URL);
      if (response?.data?.status === 201) {
        console.log("RAS_KEY_URL", response?.data?.rsa_detail);

        return response?.data?.rsa_detail;
      } else {
        return;
      }
    } catch (err) {
      console.error(err);
    }
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  const onSubmit = async (data) => {
    const { publicKey, session_uuid } = await getPublicKeyRsa();

    const encryptedOldPassword = rsaEncrypt(data?.oldpassword, publicKey);
    const encryptedNewPassword = rsaEncrypt(data?.newpassword, publicKey);
    const encryptedConfirmPassword = rsaEncrypt(
      data?.confirmpassword,
      publicKey
    );

    try {
      let response = "";

      response = await axiosPrivate.post(
        CHANGE_PASSWORD,
        {
          oldpassword: encryptedOldPassword,
          newpassword: encryptedNewPassword,
          confirmpassword: encryptedConfirmPassword,
          username: auth?.username,
          session_uuid: session_uuid,
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        showToast("Password changed successfully", "success");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
        return;
      }

      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <section className="mt-9 md:w-1/2">
      {" "}
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="oldpassword"
          register={register}
          name="Old Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter district name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full `}
          onChangeInput={null}
          setValue={setValue}
        />
        <Input
          defaultName="newpassword"
          register={register}
          name="New Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter district name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full `}
          onChangeInput={(e) => setNewPassword(e)}
          setValue={setValue}
        />
        <Input
          defaultName="confirmpassword"
          register={register}
          name="Confirm Password"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter district name"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="password"
          classes={`rounded-sm px-3 py-2 text-sm w-full `}
          onChangeInput={(e) => confirmNewPassword(e)}
          setValue={setValue}
        />
        <div className="flex justify-between mt-9">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={"Change Password"}
            className={button}
            isDisabled={btnDisable}
          />
          <Link
            to={goToDashboard}
            aria-label="Cancel"
            className={cancel_button}
          >
            Cancel
          </Link>
        </div>
      </form>
    </section>
  );
};

export default UpdatePasswordForm;
