import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../reusable-components/buttons/Button";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import H2 from "../../../reusable-components/headings/H2";
import H3 from "../../../reusable-components/headings/H3";
import H5 from "../../../reusable-components/headings/H5";
import TextArea from "../../../reusable-components/inputs/InputTextAreas/TextArea";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import {
  button,
  cancel_button,
  disabled_button,
  linkTextPrimary,
  outlined_btn_primary,
} from "../../../theme/lightTheme";
import {
  contactnoPattern,
  emailPattern,
} from "../../../utilities/generalFunctions/regexPatterns";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import { IconContext } from "react-icons";
import { FaCheck, FaCheckCircle, FaCross } from "react-icons/fa";
import showToast from "../../../utilities/notification/NotificationModal";
import axios from "../../../api/axios";
import {
  FEEDBACK_URL,
  PUBLIC_NOC_APPLICATION,
} from "../../../api/api_routing_urls";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import Captcha from "../../../utilities/captcah/Captcha";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateDDMMYYYY,
} from "../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import sabccoLogo from "../../../assets/SABCCO_128x128.png";
import { BiError } from "react-icons/bi";
import { NavLink, useNavigate } from "react-router-dom";
import { MdContentCopy } from "react-icons/md";

const NOCApplication = () => {
  const controller = new AbortController();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [successSubmission, setSuccessSubmission] = useState(false);

  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");
  const [showPreview, setShowPreview] = useState(false);

  const [processing, setProcessing] = useState(false);
  const [fullName, setFullName] = useState("");
  const [relativeName, setRelativeName] = useState("");
  const [residentAddress, setResidentAddress] = useState("");
  const [departmentName, setDepartmentName] = useState("");
  const [serialNumber, setSerialNumber] = useState("");

  const [copied, setCopied] = useState(false);

  const navigate = useNavigate();

  const defaultValues = {
    fullname: "",
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isValidating },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onShowPreview = async (data) => {
    if (data) {
      setShowPreview(true);
      window.scroll({ top: 100 });
    }
  };
  const onSubmitApplication = async () => {
    try {
      let response = await axios.post(
        `${PUBLIC_NOC_APPLICATION}/save`,
        {
          noc_name: fullName,
          noc_relative_name: relativeName,
          noc_resident: residentAddress,
          noc_department_name: departmentName,
          noc_plot_no: null,
          noc_area: null,
          noc_situated: null,
          noc_availing_loan: null,
          noc_extra: null,
        },
        {
          signal: controller.signal,
        }
      );

      console.log("PUBLIC_NOC_APPLICATION", response.data.serialNo);

      if (response.status === 200) {
        setSerialNumber(response.data.serialNo);
        setSuccessSubmission(true);
        setIsCaptchaValid(false);
        reset();
        window.scroll({ top: 100 });
      }
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const handleCopy = (serial_no) => {
    navigator.clipboard
      .writeText(serial_no)
      .then(() => {
        setCopied(true);
        setTimeout(() => {
          setCopied(false);
        }, 3000);
      })
      .catch(() => setCopied(false));
  };

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>No Objection Certificate Application</H1>
          </div>
        </div>
      </HeroBanner>

      <div className="container mx-auto max-w-7xl mb-4 px-3">
        <div>
          {successSubmission ? (
            <>
              <ResponseStatus
                serialNumber={serialNumber}
                handleCopy={handleCopy}
                copied={copied}
                navigate={navigate}
              />

              <NavLink end to="/noc-application">
                <p className={` text-center ${linkTextPrimary} mb-9`}>
                  Return to NOC Application
                </p>
              </NavLink>
            </>
          ) : (
            <>
              <p className="bg-yellow-100 text-sm font-semibold p-3 my-9 border border-yellow-800 rounded-sm text-yellow-900">
                Submission of this application implies accuracy of provided
                information; any inaccuracies may lead to rejection.
                Responsibility rests with the submitter to ensure data validity.
                Submissions are subject to verification, and discrepancies may
                prompt rejection. Your cooperation in providing accurate details
                is appreciated.
              </p>
              <p class=" font-semibold mb-3">
                Input fields with (<span class="text-red-800">✱</span>) are
                required fields
              </p>
              <form
                onSubmit={handleSubmit(onShowPreview)}
                className="mt-6 pb-[6rem]"
              >
                {!showPreview ? (
                  <>
                    <div className="grid grid-cols-4 gap-3">
                      <div className="col-span-4 md:col-span-2">
                        <Input
                          defaultName="noc_name"
                          register={register}
                          name="Full Name"
                          required={true}
                          pattern={null}
                          errors={errors}
                          placeholder="Enter Full Name"
                          setError={setError}
                          clearError={clearErrors}
                          autoComplete="off"
                          type="text"
                          classes={`rounded-sm px-3 py-2 text-sm w-full`}
                          onChangeInput={(e) => setFullName(e)}
                          defaultValue={fullName}
                          setValue={setValue}
                        />
                      </div>
                      <div className="col-span-4 md:col-span-2">
                        <Input
                          defaultName="noc_relative_name"
                          register={register}
                          name="Relative Name (S/o, D/o, W/o)"
                          required={true}
                          pattern={null}
                          errors={errors}
                          placeholder="Enter Relative Name"
                          setError={setError}
                          clearError={clearErrors}
                          autoComplete="off"
                          type="text"
                          classes={`rounded-sm px-3 py-2 text-sm w-full`}
                          onChangeInput={(e) => setRelativeName(e)}
                          defaultValue={relativeName}
                          setValue={setValue}
                        />
                      </div>

                      <div className="col-span-4 ">
                        <TextArea
                          defaultName="noc_resident"
                          register={register}
                          name="Resident of"
                          required={true}
                          pattern={null}
                          errors={errors}
                          placeholder="Resident of"
                          setError={setError}
                          clearError={clearErrors}
                          autoComplete="off"
                          type="text"
                          classes={`rounded-sm px-3 py-2 text-sm w-full`}
                          onChangeInput={(e) => setResidentAddress(e)}
                          defaultValue={residentAddress}
                          setValue={setValue}
                        />
                      </div>
                      <div className="col-span-4 md:col-span-2">
                        <Input
                          defaultName="noc_department_name"
                          register={register}
                          name="Department Name"
                          required={true}
                          pattern={null}
                          errors={errors}
                          placeholder="Enter Department Name"
                          setError={setError}
                          clearError={clearErrors}
                          autoComplete="off"
                          type="text"
                          classes={`rounded-sm px-3 py-2 text-sm w-full`}
                          onChangeInput={(e) => setDepartmentName(e)}
                          defaultValue={departmentName}
                          setValue={setValue}
                        />
                      </div>
                      {/*
                <div className="col-span-4 bg-yellow-50 border border-yellow-200 rounded-sm p-3">
                  <p className="font-bold py-3 ">
                    This Corporation does not have any objection for:
                  </p>
                  <p className="border-t pt-3">
                    Transfer/Sale of his/her/their land
                  </p>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-3 md:col-span-1">
                      <Input
                        defaultName="noc_plot_no"
                        register={register}
                        name="Plot No"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter Plot No"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={defaultValues.noc_plot_no}
                        setValue={setValue}
                      />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                      <Input
                        defaultName="noc_area"
                        register={register}
                        name="Area"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter Area"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={defaultValues.noc_area}
                        setValue={setValue}
                      />
                    </div>
                    <div className="col-span-3 md:col-span-1">
                      <Input
                        defaultName="noc_situated"
                        register={register}
                        name="Situated at"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter Situated at"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={defaultValues.noc_situated}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                  <p className="border-t pt-3 mt-3">Availing loan from</p>
                  <div className="grid grid-cols-3 gap-3">
                    <div className="col-span-3 md:col-span-1">
                      <Input
                        defaultName="noc_availing_loan"
                        register={register}
                        name="Availing Loan"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter Availing Loan"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={defaultValues.noc_availing_loan}
                        setValue={setValue}
                      />
                    </div>
                  </div>
                </div>
                  */}
                    </div>

                    <div className="flex justify-between w-full mt-[3rem]">
                      <div>
                        <NavLink end to="/noc-application">
                          <p className={` text-center ${linkTextPrimary}`}>
                            Return to NOC Application
                          </p>
                        </NavLink>
                      </div>

                      <SubmitButton
                        className={button}
                        isSubmitting={isSubmitting}
                        label={"Preview Application"}
                      />
                    </div>
                  </>
                ) : (
                  <>
                    <NOCApplicationForm
                      nocSerialNumber={null}
                      nocName={fullName}
                      nocRelativeName={relativeName}
                      nocResident={residentAddress}
                      nocDepartment={departmentName}
                    />
                    <p className="bg-yellow-100 text-sm font-semibold p-3 my-9 border border-yellow-800 rounded-sm text-yellow-900">
                      Upon successful submission, you will be provided with a
                      serial number. Please make sure to record it for checking
                      its verification status and downloading it later.
                    </p>
                    <div className="flex justify-between items-center w-full mt-3">
                      <div>
                        <Button
                          className={outlined_btn_primary}
                          onClick={() => setShowPreview(false)}
                        >
                          back to Form
                        </Button>
                      </div>
                      <Captcha
                        setCaptchaSuccess={setCaptchaSuccess}
                        captchaReset={captchaReset}
                        setCaptchaInput={setCaptchaInput}
                      />
                    </div>

                    <div className="flex justify-end w-full mt-3">
                      {!captchaSuccess ? (
                        <Button className={disabled_button} disabled={true}>
                          Submit Application
                        </Button>
                      ) : (
                        // <SubmitButton
                        //   className={button}
                        //   isSubmitting={isSubmitting}
                        //   label={"Submit Application"}
                        // />

                        <>
                          <Button
                            className={button}
                            onClick={() => {
                              setProcessing(true);
                              onSubmitApplication();
                            }}
                          >
                            {processing ? (
                              <>
                                <svg
                                  className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  ></circle>
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  ></path>
                                </svg>
                                Processing
                              </>
                            ) : (
                              <>Submit Application</>
                            )}
                          </Button>
                        </>
                      )}
                    </div>
                  </>
                )}
              </form>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default NOCApplication;

export const NOCApplicationForm = ({
  nocSerialNumber,
  nocName,
  nocRelativeName,
  nocResident,
  nocDepartment,
}) => {
  return (
    <>
      <section className=" text-[14px] p-9 text-justify bg-yellow-50 border">
        <div className="w-full">
          <div className="flex gap-3 items-center my-3 w-fit mx-auto">
            <img className="h-16 w-16" src={sabccoLogo} />
            <div>
              <p className="text-center text-[16px] font-semibold">
                Sikkim SC / ST / OBC Development Corporation.Ltd. (SABCCO)
              </p>
              <p className="text-[14px]  -mt-1">
                Sonam Tshering Marg, Gangtok - 737101
              </p>
            </div>
          </div>
        </div>

        {nocSerialNumber ? (
          <>
            <p className="font-semibold">Serial No.: {nocSerialNumber}</p>
          </>
        ) : null}

        <div className="my-9">
          <p className="font-semibold text-center mb-6">
            TO WHOM IT MAY CONCERN
          </p>
          <p>
            This is to certify that Shri/Smt/Kumari
            <span className="font-semibold"> {nocName} </span>
            S/o, D/o, W/o
            <span className="font-semibold"> {nocRelativeName} </span>
            resident of
            <span className="font-semibold"> {nocResident} </span>
            does not have any loan till date or outstanding as reflected in the
            books. Hence this Corporation does not have any objection for grant
            of Job/Regularisation in
            <span className="font-semibold"> {nocDepartment} </span>
            Department in respect of the applicant.
          </p>

          <p className="pt-3">
            This certificate is issued without prejudice to the Corporation's
            right to recover the loan amount if it is found at any later point
            of time that he/she owes any amount to this Corporation.
          </p>

          <p className="pt-3">
            This certificate is valid for a period of 6 (Six) months from the
            date of issue.
          </p>
        </div>

        <div className="flex flex-row justify-between pt-6">
          <p className="font-semibold">{formatINRCurrency(150)}/-</p>
          <div>
            <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
          </div>
        </div>
      </section>
    </>
  );
};

export const ResponseStatus = ({
  serialNumber,
  handleCopy,
  copied,
  navigate,
}) => {
  return (
    <>
      {serialNumber ? (
        <section className="py-9">
          <p className="text-center ">
            <div className="border border-green-200 py-6 my-3 text-slate-700 bg-green-100">
              <IconContext.Provider
                value={{
                  className: "text-green-900 text-5xl text-center mx-auto",
                }}
              >
                <FaCheckCircle />
              </IconContext.Provider>
              <p className="text-3xl text-center flex items-center justify-center gap-3">
                Serial No:
                <span className="font-bold text-green-900">
                  {" "}
                  {serialNumber || "NA"}
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => handleCopy(serialNumber)}
                >
                  {copied ? <FaCheck /> : <MdContentCopy />}
                </span>
              </p>
            </div>
            <div className="max-w-md mx-auto">
              <H5>
                Your NOC Application is submitted successfully. We'll look into
                your application as soon as possible.
                <br />
                <br /> You will be able to check you application status with the
                above serial no.
              </H5>
              <br></br>
              <Button
                className={button}
                onClick={() =>
                  navigate(`/view-noc-application/${serialNumber}`)
                }
              >
                <div>Proceed with Online Payment</div>
              </Button>
            </div>
          </p>
        </section>
      ) : (
        <section className="py-9">
          <p className="text-center ">
            <div className="border border-red-200 py-6 my-3 text-red-900 bg-red-100">
              <IconContext.Provider
                value={{
                  className: "text-red-900 text-5xl text-center mx-auto",
                }}
              >
                <BiError />
              </IconContext.Provider>
              <p className="text-3xl text-center">
                Your NOC application submission failed.
              </p>
            </div>
            <div className="max-w-md mx-auto">
              <H5>
                Sorry, we encountered an issue while submitting your NOC
                application. Please try again.
                <br />
              </H5>
            </div>
          </p>
        </section>
      )}
    </>
  );
};
