import React, { useState, Fragment } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import { IoMdEye } from "react-icons/io";
import { MdDone } from "react-icons/md";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";
import { formatINRCurrency } from "../../../../utilities/currency/currency";

export default function OneTimeSettlementRepaymentList({
  repaymentList,
  setLoaneeData,
  setShowAddForm,
}) {
  const onViewClickHandler = (data) => {
    setLoaneeData(data);
    setShowAddForm(true);
  };

  return (
    <>
      <div className="flex justify-between items-center border p-2 my-4 bg-slate-100 text-sm font-semibold">
        <p>Total: {repaymentList?.[0]?.listcount}</p>
        <p>Active: {repaymentList?.[0]?.active_count}</p>
        <p>Inactive: {repaymentList?.[0]?.inactive_count}</p>
      </div>

      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr className="bg-slate-200 ">
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan amount
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan status
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {repaymentList?.length > 0 ? (
            repaymentList?.map((repayment, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.loanee_id}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.applicantname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.categoryname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {formatINRCurrency(repayment?.loanappliedamt)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.loan_status === "Active" ? (
                    <div className="flex items-start gap-1 text-green-700">
                      <AiFillUnlock size={18} />
                      <span className="text-green-700">
                        {repayment?.loan_status}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-start gap-1 text-slate-600">
                      <AiFillLock size={18} />{" "}
                      <span className="text-primary">
                        {repayment?.loan_status}
                      </span>
                    </div>
                  )}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm w-24">
                  <div className="flex justify-between gap-2">
                    <div
                      className="flex items-center text-secondary hover:text-primary cursor-pointer"
                      onClick={() => onViewClickHandler(repayment)}
                    >
                      <IoMdEye size={20} />
                    </div>
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr className="m-auto text-center">
                <td colSpan={9} className="py-3">
                  No List Found
                </td>
              </tr>
            </>
          )}
        </tbody>
      </TableWrapper>
    </>
  );
}
