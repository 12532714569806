import React, { Fragment, useState } from "react";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import Button from "../../../reusable-components/buttons/Button";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import { cancel_button, linkTextPrimary } from "../../../theme/lightTheme";
import { getFormattedDateDDMMMYYYYDate } from "../../../utilities/dateFunctions/formatdate";
import { base64toBlob } from "../../../utilities/fileFunctions/pdfFunctions";

export default function NewsList({
  newsList,
  setEditNewsDetails,
  setShowAddForm,
}) {
  const [blobURL, setBlobURL] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);

  const onClickEdit = (news) => {
    setEditNewsDetails(news);
    setShowAddForm(true);
  };

  const onClickView = (base64, title) => {
    const url = URL.createObjectURL(base64toBlob(base64));
    setBlobURL(url);
    setModalTitle(`${title} Document`);
    setShowPDFViewer(true);
  };

  return (
    <Fragment>
      <TableWrapper>
        <thead className="bg-white text-sm">
          <tr>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              News / Event Title
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              News / Event Type
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              News / Event Document
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              News / Event Start Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
            >
              News / Event End Date
            </th>
            <th
              scope="col"
              className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {newsList.length > 0 &&
            newsList.map((news, index) => (
              <tr key={index}>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {news.newstitle}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {news.newstype}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm flex items-center">
                  {news.newsdoc ? (
                    <Fragment>
                      <IconContext.Provider
                        value={{ className: "text-red-700 text-5xl" }}
                      >
                        <FaFilePdf />
                      </IconContext.Provider>
                      <Button
                        className={linkTextPrimary}
                        onClick={() =>
                          onClickView(news.newsdoc, news.newstitle)
                        }
                      >
                        View
                      </Button>
                    </Fragment>
                  ) : (
                    "NA"
                  )}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {getFormattedDateDDMMMYYYYDate(news.newsstartdate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  {getFormattedDateDDMMMYYYYDate(news.newsenddate)}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm">
                  <div className="flex justify-end">
                    <Button
                      onClick={() => onClickEdit(news)}
                      aria-label="Edit News Details"
                      className={linkTextPrimary}
                    >
                      Edit
                    </Button>
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>
      <GenericModal
        open={showPDFViewer}
        setOpen={setShowPDFViewer}
        title={modalTitle}
        isLarge={true}
        isAdd={false}
      >
        <iframe
          onLoad={console.table("Loading...")}
          target="_blank"
          className="w-full h-75vh mb-2"
          // style={{ width: "100%", height: "800px" }}
          src={blobURL}
          type="application/pdf"
          title="title"
        />
        <div className="flex justify-end">
          <Button
            className={cancel_button}
            onClick={() => {
              setShowPDFViewer(false);
              setBlobURL("url");
              setModalTitle(``);
            }}
          >
            Close
          </Button>
        </div>
      </GenericModal>
    </Fragment>
  );
}
