import React, { useEffect } from "react";
import { APPLICANT_REMARK_CONFIG_URL } from "../../../../api/api_routing_urls";
import useGetWithParameters from "../../../../hook/getApi/useGetWithParameters";
import H3 from "../../../../reusable-components/headings/H3";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import PrintApplicantDetailsRemarks from "../applicantDetails/PrintApplicantDetailsRemarks";
import useAuth from "../../../../authentication/hooks/useAuth";
import { LiaEdit } from "react-icons/lia";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import { useState } from "react";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { button } from "../../../../theme/lightTheme";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import { useForm } from "react-hook-form";
import { axiosPrivate } from "../../../../api/axios";
import showToast from "../../../../utilities/notification/NotificationModal";

const Remarks = ({
  loanee_id,
  applicantDetail,
  schemeName,
  districtName,
  constituencyName,
  subDivisionName,
  gramPanchayatName,
  selected_Loanee,
}) => {
  const [remarkList, setRemarkList] = useState(null);
  const [showEdit, setShowEdit] = useState(false);
  const [remark, setRemark] = useState("");
  const [remarkId, setRemarkId] = useState();
  const [remarkError, setRemarkError] = useState(true);
  const [loading, setIsLoading] = useState(true);
  const [refresh, setRefresh] = useState(true);

  // let { response: remark_data, loading } = useGetWithParameters(
  //   APPLICANT_REMARK_CONFIG_URL,
  //   "loanee_id",
  //   loanee_id
  // );

  const { auth } = useAuth();
  const controller = new AbortController();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: "",
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onEditHandler = (remark_id, remark_data) => {
    setShowEdit(true);
    setRemark(remark_data);
    setRemarkId(remark_id);
  };

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (remarkId) {
        response = await axiosPrivate.post(
          `${APPLICANT_REMARK_CONFIG_URL}/update`,
          {
            remark_id: remarkId,
            remark: remark,
            updatedby: auth.username,
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status === 200) {
          showToast("Remark Detail has been updated successfully", "success");
          setShowEdit(false);
          setRefresh((prev) => !prev);
          reset();
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const getRemarkDetail = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        APPLICANT_REMARK_CONFIG_URL,
        { params: { loanee_id } },
        {
          signal: controller.signal,
        }
      );
      if (response?.status === 200) {
        setRemarkList(response.data);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    let isMounted = true;

    if (isMounted) {
      getRemarkDetail();
    }

    return () => {
      isMounted = false;
    };
  }, [refresh]);
  return (
    <>
      <section className="mt-9">
        <H3>Remarks</H3>
        {!loading ? (
          <>
            <div className="my-3 max-h-96 overflow-auto border-t border-b">
              {remarkList?.remarkList?.length > 0 ? (
                remarkList?.remarkList?.map((responseObj, index) => (
                  <>
                    <div key={index} className="mt-6 ">
                      <div className="text-slate-800 flex justify-between">
                        <div className="text-xs font-medium">
                          {" "}
                          {responseObj?.remarkby}{" "}
                          {responseObj?.fullname
                            ? ` (${responseObj?.fullname})`
                            : null}
                        </div>
                        <div className="text-xs pr-3 flex items-center">
                          {getFormattedDateDDMMMYYYYDate(
                            responseObj.remarkdate
                          )}
                        </div>
                      </div>
                      <div className="bg-slate-100 text-sm rounded-sm text-slate-800 p-3 mt-0.5 flex items-center w-full">
                        <div className=" w-full">{responseObj?.remark}</div>

                        <div
                          onClick={() =>
                            onEditHandler(
                              responseObj?.applicantremarkid,
                              responseObj?.remark
                            )
                          }
                          className="px-2 w-14 text-yellow-800 cursor-pointer"
                        >
                          {" "}
                          {auth?.username === responseObj?.username ? (
                            <>
                              <LiaEdit size={22} />
                            </>
                          ) : null}
                        </div>
                      </div>
                    </div>
                    {responseObj?.updatedby && (
                      <div className="text-yellow-800 text-xs mt-0.5 text-right px-3">
                        Edited by:
                        {responseObj?.fullname
                          ? ` ${responseObj?.fullname}`
                          : null}
                        <span className="px-2">on</span>
                        {getFormattedDateDDMMMYYYYDate(responseObj?.updatedat)}
                      </div>
                    )}
                  </>
                ))
              ) : (
                <p className="text-slate-600">No remarks found</p>
              )}
            </div>

            <GenericModal
              open={showEdit}
              setOpen={setShowEdit}
              isLarge={false}
              title={"Edit Remark Details"}
              isAdd={true}
              clearData={null}
            >
              <>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="max-w-2xl mb-3">
                    <TextArea
                      defaultName="remarks"
                      register={register}
                      name="Remarks"
                      required={true}
                      pattern={null}
                      errors={errors}
                      placeholder="Enter remarks"
                      setError={setError}
                      clearError={clearErrors}
                      autoComplete="off"
                      type="text"
                      classes={`rounded-sm px-3 py-2 text-sm w-full`}
                      onChangeInput={(e) => {
                        if (e !== "") {
                          setRemark(e);
                          setRemarkError(false);
                        } else {
                          setRemarkError(true);
                        }
                      }}
                      defaultValue={remark}
                      setValue={setValue}
                    />
                  </div>

                  <div className="flex justify-between">
                    <SubmitButton
                      isSubmitting={isSubmitting}
                      label={"Update Remark"}
                      className={button}
                      onClick={null}
                    />
                  </div>
                </form>
              </>
            </GenericModal>
          </>
        ) : (
          <SpinnerWithText />
        )}
        {/* Print Remarks Section  */}
        <>
          <PrintReport reportTitle={"Remarks"} buttonName={""}>
            <PrintApplicantDetailsRemarks
              applicantDetail={applicantDetail}
              schemeName={schemeName}
              districtName={districtName}
              constituencyName={constituencyName}
              subDivisionName={subDivisionName}
              gramPanchayatName={gramPanchayatName}
              selected_Loanee={selected_Loanee}
            />
            <H3>Remarks</H3>
            <div className="my-3 ">
              {remarkList?.remarkList?.length > 0 ? (
                remarkList?.remarkList
                  ?.slice(0)
                  .reverse()
                  ?.map((responseObj) => (
                    <>
                      <div className="mt-6  border-t border-black m-2">
                        <div className="text-black flex justify-between ">
                          <div className="text-xs font-medium p-2">
                            {" "}
                            {responseObj?.remarkby}{" "}
                            {responseObj?.fullname
                              ? ` (${responseObj?.fullname})`
                              : null}
                          </div>
                          <div className="text-xs pr-3 ">
                            {getFormattedDateDDMMMYYYYDate(
                              responseObj.remarkdate
                            )}
                          </div>
                        </div>
                        <div className="bg-slate-100 text-sm rounded-sm text-slate-800   p-3 mt-0.5">
                          {responseObj?.remark}
                        </div>
                      </div>
                    </>
                  ))
              ) : (
                <p className="text-slate-600">No remarks found</p>
              )}
            </div>
          </PrintReport>
        </>
      </section>
    </>
  );
};

export default Remarks;
