import React from "react";
import Dashboard from "../../common/Dashboard";
import DashboardConfig from "../system-admin/Dashboard/DashboardConfig";

export default function Sr_OA_II_Dashboard() {
  return (
    <Dashboard sidebarType="Sr.O.A-II">
      <DashboardConfig />
    </Dashboard>
  );
}
