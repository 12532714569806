import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  completeLoaneeDetails: {},
};
export const loaneeDetailsSlice = createSlice({
  name: "loaneeDetails",
  initialState,
  reducers: {
    setCompleteLoaneeDetails: (state, action) => {
      state.completeLoaneeDetails = action.payload;
    },

    resetAllVariables: (state) => {
      state.completeLoaneeDetails = {};
    },
  },
});
export const { setCompleteLoaneeDetails, resetAllVariables } =
  loaneeDetailsSlice.actions;

export default loaneeDetailsSlice.reducer;
