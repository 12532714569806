/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react/jsx-pascal-case */
import React, { useEffect, useState } from "react";
import { CiMoneyCheck1 } from "react-icons/ci";
import { TiGroupOutline } from "react-icons/ti";
import { GiReceiveMoney } from "react-icons/gi";
import { BsVectorPen } from "react-icons/bs";
import {
  GET_DASHBOARD_DETAILS_URL,
  GET_DASHBOARD_REPORT_URL,
  NOTIFICATION_CONFIG_URL,
} from "../../../api/api_routing_urls";
import useGetAxios from "../../../hook/useGetAxios";
import ReportCard from "./ReportCard";
import PieChartReport from "./PieChartReport";
import { colorsList } from "../../../utilities/constants/colors";
import H4 from "../../../reusable-components/headings/H4";
import LineChartReport from "./LineChartReport";
import BarGraphReport from "./BarGraphReport";
import { getFormattedDateMMMYYYY } from "../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import useAxiosPrivate from "../../../authentication/hooks/useAxiosPrivate";
import CategoryWise_DetailsCard from "./CategoryWise_DetailsCard";
import useNotificationHook from "../../../authentication/hooks/useNotificationHook";
import { IoIosNotificationsOutline } from "react-icons/io";

const DashboardConfig = () => {
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const currentYear = new Date().getFullYear();

  const currentFiscalYear = `${currentYear - 1}-${currentYear
    .toString()
    .slice(-2)}`;

  const { notificationDetail, setNotificationDetail } = useNotificationHook();

  const [selectedTab, setSelectedTab] = useState("pending");
  const [selectedOverviewTab, setSelectedOverviewTab] = useState("monthly");

  const [totalBorrower, setTotalBorrower] = useState(0);
  const [totalCurrentYearDiAmt, setTotalCurrentYearDiAmt] = useState(0);
  const [totalCurrentYearRpAmt, setTotalCurrentYearRpAmt] = useState(0);
  const [totalCurrentYearDueAmt, setTotalCurrentYearDueAmt] = useState(0);
  const [activeBorrower, setActiveBorrower] = useState(0);
  const [inactiveBorrower, setInactiveBorrower] = useState(0);
  const [totalDisbursed, setTotalDisbursed] = useState(0);
  const [paymentReceived, setPaymentReceived] = useState(0);
  const [totalDueAmount, setTotalDueAmount] = useState(0);

  const [districtWiseCount, setDistrictWiseCount] = useState();
  const [constituencyWiseCount, setConstituencyWiseCount] = useState();

  const [monthlyCollectionDataObj, setMonthlyCollectionDataObj] = useState();
  const [yearlyCollectionDataObj, setYearylCollectionDataObj] = useState();

  const [categoryWiseDetails, setCategoryWiseDetails] = useState([]);

  const { data, loading } = useGetAxios(GET_DASHBOARD_REPORT_URL);

  const { data: districtWiseData, loading: districtWiseLoading } = useGetAxios(
    `${GET_DASHBOARD_REPORT_URL}/districtWise`
  );
  const { data: constituencyWiseData, loading: constituencyWiseLoading } =
    useGetAxios(`${GET_DASHBOARD_REPORT_URL}/constituencyWise`);

  const { data: monthlyCollectionData, loading: monthlyCollectionLoading } =
    useGetAxios(`${GET_DASHBOARD_REPORT_URL}/monthlyCollection`);

  const { data: yearlyCollectionData, loading: yearlyhCollectionLoading } =
    useGetAxios(`${GET_DASHBOARD_REPORT_URL}/yearlyhCollection`);

  // Category Wise Dashboard Report

  const { data: notificationData, loading: notificationLoading } = useGetAxios(
    NOTIFICATION_CONFIG_URL
  );

  const notificationDataHandler = () => {
    try {
      setNotificationDetail((prev) => ({ ...prev, ...notificationData }));
    } catch (error) {
      console.error(error);
    }
  };

  const getDashboardDetails = async () => {
    try {
      const response = await axiosPrivate.get(GET_DASHBOARD_DETAILS_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        setCategoryWiseDetails(response?.data?.dashboardDetails);
        getDashboardReport(response?.data?.dashboardDetails);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const approvedDataObj = [
    {
      name: "Approved",
      value: +data?.dashboardReport?.no_approved,
      color: "#0284c7",
    },
    {
      name: "Disbursed",
      value: +data?.dashboardReport?.no_disbursed,
      color: "#10b981",
    },
  ];

  const pendingDataObj = [
    // { name: "Approved", value: +data?.no_approved },
    // { name: "Disbursed", value: +data?.no_disbursed },
    {
      name: "Pending",
      value: +data?.dashboardReport?.no_pending,
      color: "#eab308",
    },
    {
      name: "Reverted",
      value: +data?.dashboardReport?.no_revert,
      color: "#ef4444",
    },
  ];

  const districtObjHandler = () => {
    let districtObj = [];

    districtWiseData?.countDistrictWise?.map((obj, index) => {
      districtObj.push({
        name: [obj?.districtname],
        value: +obj?.num,
        color: colorsList[index],
      });
    });

    setDistrictWiseCount(districtObj);
  };

  const constituencyObjHandler = () => {
    let constituencyObj = [];

    constituencyWiseData?.countConstituencyWise?.map((obj, index) => {
      constituencyObj.push({
        name: [obj?.constituencyname],
        number: +obj?.num,
      });
    });

    setConstituencyWiseCount(constituencyObj);
  };

  const monthlyCollectionObjHandler = () => {
    let monthlyCollectionObj = [];

    monthlyCollectionData?.monthlyCollection?.map((obj, index) => {
      monthlyCollectionObj.push({
        name: [getFormattedDateMMMYYYY(obj?.col_date)],
        actual: +obj?.col_actual_received,
        expect: +obj?.col_expected_received,
      });
    });

    setMonthlyCollectionDataObj(monthlyCollectionObj);
  };

  const yearlyhCollectionObjHandler = () => {
    let yearlyCollectionObj = [];

    yearlyCollectionData?.yearlyCollection?.map((obj, index) => {
      yearlyCollectionObj.push({
        name: [new Date(obj?.col_date).getFullYear()],
        av: +obj?.col_actual_received,
        ev: +obj?.col_expected_received,
      });
    });

    setYearylCollectionDataObj(yearlyCollectionObj);
  };

  const getDashboardReport = (details) => {
    let total_Borrower = 0;
    let total_Disbursed = 0;
    let total_Repay_Amount = 0;
    let total_Due = 0;
    let active_borrower = 0;
    let inactive_borrower = 0;
    let total_current_di_amt = 0;
    let total_current_rp_amt = 0;
    let total_current_due_amt = 0;

    details.map((obj) => {
      total_Borrower += +obj.total_borrowers;
      total_Disbursed += +obj.di_amount;
      total_Repay_Amount += +obj.rp_amount;
      total_Due += +obj.due_amount;
      active_borrower += +obj.active_borrowers;
      inactive_borrower += +obj.inactive_borrowers;
      total_current_di_amt += +obj.total_current_di_amt;
      total_current_rp_amt += +obj.total_current_rp_amt;
      total_current_due_amt += +obj.total_due_amount_current_year;
    });

    setTotalBorrower(total_Borrower);
    setTotalDisbursed(total_Disbursed);
    setPaymentReceived(total_Repay_Amount);
    setTotalDueAmount(total_Due);
    setActiveBorrower(active_borrower);
    setInactiveBorrower(inactive_borrower);
    setTotalCurrentYearDiAmt(total_current_di_amt);
    setTotalCurrentYearRpAmt(total_current_rp_amt);
    setTotalCurrentYearDueAmt(total_current_due_amt);
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !districtWiseLoading) {
      districtObjHandler();
    }
    if (isMounted && !constituencyWiseLoading) {
      constituencyObjHandler();
    }
    if (isMounted && !yearlyhCollectionLoading) {
      yearlyhCollectionObjHandler();
    }
    if (isMounted && !yearlyhCollectionLoading) {
      monthlyCollectionObjHandler();
    }

    return () => {
      isMounted = false;
    };
  }, [
    districtWiseLoading,
    constituencyWiseLoading,
    monthlyCollectionLoading,
    yearlyhCollectionLoading,
  ]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted && !notificationLoading) {
      notificationDataHandler();
    }

    return () => {
      isMounted = false;
    };
  }, [notificationLoading]);

  useEffect(() => {
    getDashboardDetails();
  }, []);

  const total_count = notificationDetail?.notificationDetails?.total_count || 0;

  return (
    <>
      <section className=" text-slate-700">
        {total_count > 0 ? (
          <div className="flex items-center gap-3 border rounded-sm bg-slate-50 mb-6 p-3">
            <div className="flex items-center justify-center bg-yellow-400 rounded-full p-1 w-9 h-9 text-white">
              <IoIosNotificationsOutline size={24} />
            </div>

            <div className="pl-3 border-l text-sm md:text-base">
              You have{" "}
              <span className="text-slate-800 font-semibold">
                {total_count}
              </span>{" "}
              pending request.
            </div>
          </div>
        ) : null}

        <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3">
          <ReportCard
            lable={"Total Borrower"}
            value={totalBorrower || 0}
            loading={loading}
            color={"#0C3E97"}
          >
            <TiGroupOutline size={28} />
          </ReportCard>

          <ReportCard
            lable={"Total Disbursed"}
            value={totalDisbursed || 0}
            loading={loading}
            color={"#782363"}
            isMoney={true}
          >
            <CiMoneyCheck1 size={30} />
          </ReportCard>

          <ReportCard
            lable={"Payment Received"}
            value={paymentReceived || 0}
            loading={loading}
            color={"#237838"}
            isMoney={true}
          >
            <GiReceiveMoney size={28} />
          </ReportCard>

          <ReportCard
            lable={"Due Amount"}
            value={totalDueAmount || 0}
            loading={loading}
            color={"#97650C"}
            isMoney={true}
          >
            <BsVectorPen size={26} />
          </ReportCard>
        </div>

        {/* <div className="grid grid-cols-4  my-10 gap-4 text-sm">
          {categoryWiseDetails &&
            categoryWiseDetails?.map((dashboardDetailsObj) => (
              <>
                <CategoryWise_DetailsCard
                  categoryname={dashboardDetailsObj?.categoryname}
                  activeborrower={dashboardDetailsObj?.borrower}
                  disbursement_amount={dashboardDetailsObj?.di_amount}
                  repayment_amount={dashboardDetailsObj?.rp_amount}
                  due_amount={dashboardDetailsObj?.due_amount}
                />
              </>
            ))}
        </div> */}

        <section className="mt-3 md:mt-0">
          <div className="grid grid-cols-1 md:grid-cols-2 xl:grid-cols-4 gap-3 text-sm">
            <div>
              <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#E2E7F0] text-slate-900 ">
                {/* <p className="text-center text-[#0C3E97]  font-semibold ">
                  Borrowers
                </p> */}

                <p className="text-[#0C3E97] font-semibold text-sm">
                  Active Borrowers:&nbsp;
                  <span className="font-medium text-sm">{activeBorrower}</span>
                </p>
                <div className="text-[#0C3E97] font-semibold text-sm ">
                  Inactive Borrowers:&nbsp;
                  <span className="font-medium text-sm">
                    {inactiveBorrower}
                  </span>
                </div>
              </div>
            </div>

            <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#EDE5EB] text-slate-900 ">
              {/* <p className="text-center text-[#782363]  font-semibold">
                Total Disbursed({currentYear})
              </p> */}

              <div className="text-[#782363] font-semibold text-sm">
                Disbursed Amount for {currentFiscalYear}
                :&nbsp;
                <p className="font-medium text-sm">
                  <p>{formatINRCurrency(totalCurrentYearDiAmt)}</p>
                </p>
              </div>
            </div>

            <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#E5EDE7] text-slate-900 ">
              {/* <p className="text-center text-[#237838]  font-semibold">
                Payment Received({currentYear})
              </p> */}

              <div className="text-[#237838] font-semibold text-sm">
                Payment Received for {currentFiscalYear}:&nbsp;
                <p className="font-medium text-sm">
                  {formatINRCurrency(totalCurrentYearRpAmt)}
                </p>
              </div>
            </div>

            <div className="grid col-span-1 border rounded-sm px-6 py-2 bg-[#F0EBE2] text-slate-900  ">
              {/* <p className="text-center text-[#97650C]  font-semibold">
                Due Amount({currentYear})
              </p> */}

              <div className="text-[#97650C] font-semibold text-sm">
                Due Amount for {currentFiscalYear}
                :&nbsp;
                <p className="font-medium text-sm">
                  <p className="font-medium text-sm ">
                    {formatINRCurrency(totalCurrentYearDueAmt)}
                  </p>
                </p>
              </div>
            </div>
          </div>
        </section>

        <CategoryWise_DetailsCard
          total_borrowers={categoryWiseDetails?.map((obj) => ({
            borrower: obj.total_borrowers,
            categoryname: obj.categoryname,
          }))}
          total_disbursed={categoryWiseDetails?.map((obj) => ({
            di_amount: obj.di_amount,
            categoryname: obj.categoryname,
          }))}
          payement_received={categoryWiseDetails?.map((obj) => ({
            rp_amount: obj.rp_amount,
            categoryname: obj.categoryname,
          }))}
          due_amount={categoryWiseDetails?.map((obj) => ({
            due_amount: obj.due_amount,
            categoryname: obj.categoryname,
          }))}
        />

        <div className="grid grid-cols-1 gap-6 xl:grid-cols-3 ">
          <div className="col-span-1">
            <div className="mt-9 xl:mt-6">
              <div className="flex gap-1 text-primary text-xs my-1">
                <p
                  className={`${
                    selectedTab === "pending"
                      ? " bg-slate-600 text-white"
                      : "text-slate-700 "
                  } rounded-sm cursor-pointer px-3 py-1`}
                  onClick={() => setSelectedTab(() => "pending")}
                >
                  Pending
                </p>
                <p
                  className={`${
                    selectedTab === "approved"
                      ? " bg-slate-600 text-white"
                      : "text-slate-700 "
                  } rounded-sm cursor-pointer px-3 py-1`}
                  onClick={() => setSelectedTab(() => "approved")}
                >
                  Approved
                </p>
              </div>

              {selectedTab === "pending" ? (
                <PieChartReport
                  title={" Pending Status"}
                  dataObj={pendingDataObj}
                />
              ) : (
                <PieChartReport
                  title={" Approved Status"}
                  dataObj={approvedDataObj}
                />
              )}
            </div>

            <div className="mt-6 bg-slate-50 border p-3">
              <div className="text-center">
                <H4>Collection Statistics</H4>
              </div>
              <div className="grid grid-cols-3 gap-2 text-xs text-center">
                <div>
                  <p className="text-base font-medium text-center text-slate-800">
                    {formatINRCurrency(
                      data?.dashboardReport?.collection_daily
                    ) || 0}
                  </p>
                  <span>Today</span>
                </div>
                <div>
                  <p className="text-base font-medium text-center text-slate-800">
                    {formatINRCurrency(
                      data?.dashboardReport?.collection_weekly
                    ) || 0}
                  </p>
                  <span>Last Week</span>
                </div>
                <div>
                  <p className="text-base font-medium text-center text-slate-800">
                    {formatINRCurrency(
                      data?.dashboardReport?.collection_monthly
                    ) || 0}
                  </p>
                  <span>This Month</span>
                </div>
              </div>
            </div>

            {districtWiseCount ? (
              <div className="mt-6">
                <PieChartReport
                  title={" District Wise Borrowers"}
                  dataObj={districtWiseCount}
                />
              </div>
            ) : null}
          </div>

          <div className="col-span-1 xl:col-span-2">
            <div className="mt-9 xl:mt-6">
              <div className="flex gap-1 text-primary text-xs  my-1">
                <p
                  className={`${
                    selectedOverviewTab === "monthly"
                      ? " bg-slate-600 text-white"
                      : "text-slate-700 "
                  } rounded-sm cursor-pointer px-3 py-1`}
                  onClick={() => setSelectedOverviewTab(() => "monthly")}
                >
                  Monthly
                </p>
                <p
                  className={`${
                    selectedOverviewTab === "yearly"
                      ? " bg-slate-600 text-white"
                      : "text-slate-700 "
                  } rounded-sm cursor-pointer px-3 py-1`}
                  onClick={() => setSelectedOverviewTab(() => "yearly")}
                >
                  Yearly
                </p>
              </div>
              <div className=" bg-slate-50 p-3 border">
                <div className="text-center">
                  <H4>
                    {selectedOverviewTab === "monthly"
                      ? "Monthly Collection Overview"
                      : "Yearly Collection Overview"}
                  </H4>
                </div>
                <section className="w-full h-[32rem] text-xs pt-3">
                  {selectedOverviewTab === "monthly" ? (
                    <LineChartReport dataObj={monthlyCollectionDataObj} />
                  ) : (
                    <LineChartReport dataObj={yearlyCollectionDataObj} />
                  )}
                </section>
              </div>
            </div>
          </div>
        </div>
        <div className="mt-6 bg-slate-50  p-3 border">
          <div className="text-center">
            <H4>Constituency Wise Borrower</H4>
          </div>
          <section className="w-full h-[28rem] text-xs mt-9">
            <BarGraphReport dataObj={constituencyWiseCount} />
          </section>
        </div>
      </section>
    </>
  );
};

export default DashboardConfig;
