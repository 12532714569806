import { RiRefreshLine } from "react-icons/ri";

export default function ReportCard({ lable, value, ...rest }) {
  let color = `${rest.color}`;

  return (
    <>
      <section
        style={{
          color: rest.color,
        }}
        className={`relative p-2 rounded-sm flex items-center`}
      >
        <div
          style={{
            color: rest.color,
            backgroundColor: color,
            opacity: 0.1,
          }}
          className="w-full h-full rounded-sm absolute z-0 left-0"
        />
        <div
          style={{
            color: rest.color,
            border: `1px solid`,
            opacity: 0.2,
          }}
          className="w-full h-full absolute rounded-sm z-0 left-0"
        />
        <div className="pr-2 pl-0.5">{rest?.children}</div>
        <div className="pl-3 pt-1 border-slate-300 border-l flex justify-center flex-col w-full gap-1 ">
          <div
            style={{ backgroundColor: rest.color }}
            className=" text-white font-normal rounded-full px-3 w-full pt-1.5 pb-1 "
          >
            {rest?.loading ? (
              <>
                <div className="pb-0.5 text-center">
                  <RiRefreshLine
                    size={20}
                    className={"animate-spin delay-300"}
                  />
                </div>
              </>
            ) : rest?.isMoney ? (
              new Intl.NumberFormat("en-IN", {
                style: "currency",
                currency: "INR",
              }).format(value)
            ) : (
              value
            )}
          </div>
          <div className="text-sm font-medium xl:text-sm px-1">{lable}</div>
        </div>
      </section>
    </>
  );
}
