/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useMemo } from "react";
import { MdAdd } from "react-icons/md";

import { TbReceipt } from "react-icons/tb";

import { RiRefreshLine } from "react-icons/ri";
import {
  GET_CHEQUE_RECEIPT_LIST_DETAILS,
  GET_INTEREST_LEDGER_DETAILS,
  GET_INTEREST_ON_INTEREST_DETAILS,
  GET_LEDGER_DETAILS,
  GET_PENAL_INTEREST_DETAILS,
  GET_RECEIPT_REPAYMENT_DETAILS,
  REPAYMENT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";

import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import { add_btn, outlined_btn_primary } from "../../../../theme/lightTheme";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import showToast from "../../../../utilities/notification/NotificationModal";
import InterestLedgerList from "../../ReportsList/Ledger/InterestLedgerList";
import InterestOnInterestList from "../../ReportsList/Ledger/InterestOnInterestList";
import LedgerList from "../../ReportsList/Ledger/LedgerList";
import InterestLedgerListPrint from "../../ReportsList/Ledger/ListToPrint/InterestLedgerListPrint";
import InterestOnInterestListPrint from "../../ReportsList/Ledger/ListToPrint/InterestOnInterestListPrint";
import LedgerListPrint from "../../ReportsList/Ledger/ListToPrint/LedgerListPrint";
import PenalInterestListPrint from "../../ReportsList/Ledger/ListToPrint/PenalInterestListPrint";
import PenalInterestList from "../../ReportsList/Ledger/PenalInterestList";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import AddIOIForm from "./Repayment-Components/AddIOIForm";
import AddPenalForm from "./Repayment-Components/AddPenalForm";
import AddRepaymentForm from "./Repayment-Components/AddRepaymentForm";

import LabelWithDetail from "./Repayment-Components/LabelWithDetail";
import ReceiptList from "../../ReportsList/Ledger/ReceiptList";
import GenerateChequeForm from "./Repayment-Components/GenerateChequeForm";
import ChequeReceiptList from "../../ReportsList/Ledger/ChequeReceiptList";
import ExcessAmtForm from "./Repayment-Components/ExcessAmtForm";

const AddRepaymentFormConfig = ({ addRepaymentDetails }) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [principleDueWithIntData, setPrincipleDueWithIntData] = useState();
  const [totalDueAmount, setTotalDueAmount] = useState(0);
  const [principlePaidAmount, setPrinciplePaidAmount] = useState(0);
  const [interestPaidAmount, setInterestPaidAmount] = useState(0);
  const [currentDateFromServer, setCurrentDateFromServer] = useState();
  const [repaymentDate, setRepaymentDate] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [selectedTab, setSelectedTab] = useState("ledger");
  const [repaymentAmount, setRepaymentAmount] = useState();
  const [specficDate, setSpecficDate] = useState();
  const [maximumPayAmount, setMaximumPayAmount] = useState();
  const [addRepayment, setAddRepayment] = useState(false);
  const [addIOI, setAddIOI] = useState(false);
  const [addPenalInterest, setAddPenalInterest] = useState(false);
  const [generateChequeReceipt, setGenerateChequeReceipt] = useState(false);

  const [ledgerList, setLedgerList] = useState({});
  const [interestLedgerList, setInterestLedgerList] = useState({});
  const [penalInterestList, setPenalInterestList] = useState({});
  const [interestOnInterestList, setInterestOnInterestList] = useState({});

  const [penalTotalDue, setPenalTotalDue] = useState(0);
  const [ioiTotalDue, setIoiTotalDue] = useState(0);
  const [refreshPage, setRefreshPage] = useState(false);
  const [refreshSpin, setRefreshSpin] = useState(false);

  const [receiptRepaymentList, setReceiptRepaymentList] = useState([]);
  const [loaneeTotalRepaymentAmt, setLoaneeTotalRepaymentAmt] = useState([]);
  const [receiptChequeList, setReceiptChequeList] = useState([]);

  const [showExcessAmtForm, setShowExcessAmtForm] = useState(false);
  const [excessAmt, setExcessAmt] = useState();

  const controller = new AbortController();

  const getInitialLoadCalculation = async (repayment_date) => {
    try {
      setIsLoading(true);

      if (repayment_date) {
        getPrincipleDueWithInterestUntilDate(repayment_date);
      } else if (currentDateFromServer) {
        getPrincipleDueWithInterestUntilDate(currentDateFromServer);
      } else {
        const response = await axiosPrivate.get(
          `${REPAYMENT_CONFIG_URL}/getCurrentDateFromServer`,
          {
            signal: controller.signal,
          }
        );
        if (response.status === 200) {
          setCurrentDateFromServer(
            getFormattedDateInputDate(response?.data?.currentDateFromServer)
          );

          getPrincipleDueWithInterestUntilDate(
            response?.data?.currentDateFromServer
          );
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPrincipleDueWithInterestUntilDate = async (specfic_date) => {
    try {
      if (specfic_date) {
        const response = await axiosPrivate.get(
          `${REPAYMENT_CONFIG_URL}/principleDueWithInterestUntilDate`,
          {
            params: {
              loan_number: addRepaymentDetails?.loan_number,
              specficdate: specfic_date,
            },
          },
          {
            signal: controller.signal,
          }
        );

        if (response.status === 200) {
          let last_repayment_date = getFormattedDateInputDate(
            response?.data?.principleDueWithInterestData?.last_repaymentdate
          );

          if (last_repayment_date <= getFormattedDateInputDate(specfic_date)) {
            setPrincipleDueWithIntData(
              response?.data?.principleDueWithInterestData
            );
            setTotalDueAmount(
              +response?.data?.principleDueWithInterestData
                ?.interest_due_amount +
                +response?.data?.principleDueWithInterestData?.principle_due
            );
            setMaximumPayAmount(
              +response?.data?.principleDueWithInterestData
                ?.interest_due_amount +
                +response?.data?.principleDueWithInterestData
                  ?.principle_outstanding_amount
            );
          } else {
            getPrincipleDueWithInterestUntilDate(last_repayment_date);
            repaymentDateChangeHandler(last_repayment_date);
          }

          response.status === 202 &&
            showToast("No state list found in the system", "error");
        }
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setIsLoading(false);
    }
  };

  const repaymentDateChangeHandler = (e) => {
    localStorage.setItem("repayment_date", e);

    setRepaymentDate(e);
  };

  const repaymentAmountChangeHandler = () => {
    if (
      repaymentAmount !== null &&
      repaymentAmount !== undefined &&
      repaymentAmount !== ""
    ) {
      let repayAmount = +repaymentAmount;
      let interestDueAmount = +principleDueWithIntData?.interest_due_amount;

      if (repayAmount > interestDueAmount) {
        setInterestPaidAmount(() => interestDueAmount);
        setPrinciplePaidAmount(() => repayAmount - interestDueAmount);
      }
      if (repayAmount <= interestDueAmount) {
        setInterestPaidAmount(() => repayAmount);
        setPrinciplePaidAmount(0);
      }
    } else {
      setPrinciplePaidAmount(0);
      setInterestPaidAmount(0);
    }
  };

  const getLedgerDetail = async () => {
    setIsLoading(true);

    try {
      let responseLedger = await axiosPrivate.get(
        GET_LEDGER_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (responseLedger.data) {
        setIsLoading(false);
        setLedgerList(responseLedger?.data?.ledgerDetails);
      }
      let responseInterestLedger = await axiosPrivate.get(
        GET_INTEREST_LEDGER_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (responseInterestLedger.data) {
        setIsLoading(false);
        setInterestLedgerList(responseInterestLedger?.data?.ledgerDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getPenalInterestDetail = async () => {
    setIsLoading(true);
    let penlaValue = undefined;

    try {
      let response = await axiosPrivate.get(
        GET_PENAL_INTEREST_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setPenalInterestList(response?.data?.ledgerDetails?.pi_ledger_list);

        penlaValue = +response?.data?.ledgerDetails?.penal_due_amount;
        setPenalTotalDue(penlaValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getInterestOnInterestDetail = async () => {
    setIsLoading(true);
    let ioiValue = undefined;

    try {
      let response = await axiosPrivate.get(
        GET_INTEREST_ON_INTEREST_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
            // NRM0000027
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.data) {
        setIsLoading(false);
        setInterestOnInterestList(
          response?.data?.ledgerDetails?.ioi_ledger_list
        );
        ioiValue = +response?.data?.ledgerDetails?.ioi_due_amount;

        setIoiTotalDue(ioiValue);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getReceiptRepaymentDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_RECEIPT_REPAYMENT_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      // console.log("response---", response?.data?.ledgerDetails);

      if (response.status === 200) {
        setIsLoading(false);
        setReceiptRepaymentList(response?.data?.ledgerDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getChequeReceiptDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_CHEQUE_RECEIPT_LIST_DETAILS,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        setReceiptChequeList(response?.data?.receiptList);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLoaneeTotalRepaymentAmount = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getLoaneeTotalRepaymentAmount`,
        {
          params: {
            loan_number: addRepaymentDetails?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      setLoaneeTotalRepaymentAmt(
        response?.data?.loaneeTotalRepaymentAmount?.get_loanee_repayment_amount
          ?.total_amt_repay || 0
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExcessAmtWithLoaneeId = async (LoaneeId) => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getExcessAmtWithLoaneeId`,
        {
          params: {
            loanee_id: +LoaneeId,
          },
        },
        {
          signal: controller.signal,
        }
      );

      console.log(
        "respose for excess amount",
        response?.data?.getExcessAmtWithLoaneeId
          ?.get_excess_amt_with_loanee_id[0]?.excess_amt
      );

      if (response.status === 200) {
        setIsLoading(false);
        setExcessAmt(
          response?.data?.getExcessAmtWithLoaneeId
            ?.get_excess_amt_with_loanee_id[0]?.excess_amt
        );
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getExcessAmtWithLoaneeId(addRepaymentDetails?.loanee_id);
  }, []);

  useMemo(
    () => getPrincipleDueWithInterestUntilDate(specficDate),
    [specficDate]
  );

  useMemo(() => {
    repaymentAmountChangeHandler();
  }, [repaymentAmount, specficDate]);

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getLedgerDetail();
      getLoaneeTotalRepaymentAmount();

      if (
        addRepaymentDetails?.loan_number !== null &&
        addRepaymentDetails?.loan_number !== undefined
      ) {
        getReceiptRepaymentDetail();
        getChequeReceiptDetail();
      }

      if (addRepaymentDetails?.categoryname === "OBC") {
        getPenalInterestDetail();
        getInterestOnInterestDetail();
      }

      let repayment_date = localStorage.getItem("repayment_date");

      if (repayment_date && auth.rolename === "Assistant Manager") {
        setRepaymentDate(repayment_date);
        setSpecficDate(repayment_date);
        getInitialLoadCalculation(repayment_date);
      } else {
        getInitialLoadCalculation();
      }
    }

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refreshPage, addRepaymentDetails?.loan_number]);

  // console.log("excessAmt", excessAmt);

  return (
    <>
      {" "}
      {isLoading ? (
        <>
          <LoadingModal message={"Loading"} />
        </>
      ) : (
        <>
          <fieldset className="border p-3 text-sm my-6 grid grid-cols-1 md:grid-cols-3 bg-slate-100 relative">
            <div
              className={`absolute -top-14 right-0 flex items-center gap-1 text-primary cursor-pointer 
              hover:underline underline-offset-2 font-medium`}
              onMouseOver={() => setRefreshSpin(true)}
              onMouseLeave={() => setRefreshSpin(false)}
              onClick={() => {
                setRefreshPage((prev) => !prev);
                setPenalTotalDue(null);
                setIoiTotalDue(null);
                setRefreshSpin(false);
              }}
            >
              <RiRefreshLine
                size={18}
                className={refreshSpin ? "animate-spin delay-700 " : null}
              />
              <span className="text-base">Refresh</span>
            </div>
            <legend className="ml-3 text-slate-700">
              <div className="flex flex-col bg-slate-50 border px-3 py-1 rounded-sm">
                <span>
                  Loan ID:
                  <span className="font-semibold ml-3  text-slate-900">
                    {addRepaymentDetails?.loanee_id}
                  </span>
                </span>
                <span>
                  Loan No:
                  <span className="font-semibold ml-2  text-slate-900">
                    {addRepaymentDetails?.loan_number}
                  </span>
                </span>
              </div>
            </legend>

            <LabelWithDetail
              label={"Name"}
              value={addRepaymentDetails?.applicantname}
            />
            <LabelWithDetail
              label={"Category"}
              value={addRepaymentDetails?.categoryname}
            />
            <LabelWithDetail
              label={"Amount"}
              value={formatINRCurrency(addRepaymentDetails?.loanappliedamt)}
            />

            <LabelWithDetail
              label="Int Rate (Scheme)"
              value={`${+principleDueWithIntData?.int_rate}  (${
                addRepaymentDetails?.loan_scheme || "---"
              }) `}
            />
            <LabelWithDetail
              label="Calculation Start Date"
              value={
                getFormattedDateDDMMMYYYYDate(
                  principleDueWithIntData?.last_repaymentdate
                ) || "---"
              }
            />
            <LabelWithDetail
              label="No of Days"
              value={principleDueWithIntData?.calculationfordays}
            />

            <LabelWithDetail
              label="Principle Outstanding"
              value={formatINRCurrency(
                principleDueWithIntData?.principle_outstanding_amount
              )}
            />
            <LabelWithDetail
              label="Principle Due"
              value={formatINRCurrency(principleDueWithIntData?.principle_due)}
            />
            <LabelWithDetail
              label="Interest Due"
              value={formatINRCurrency(
                principleDueWithIntData?.interest_due_amount
              )}
            />

            <LabelWithDetail
              label="Total Due Amount"
              value={formatINRCurrency(totalDueAmount)}
            />

            {addRepaymentDetails?.categoryname === "OBC" && (
              <>
                {+penalTotalDue > 0 ? (
                  <LabelWithDetail
                    label="Penal Due"
                    value={formatINRCurrency(penalTotalDue)}
                    noRecord={+penalTotalDue === -1 ? true : false}
                    isApprox={true}
                  />
                ) : (
                  <p className="p-1 text-sm text-slate-600 text-left w-10/12">
                    The calculation for Penal Interest will be conducted after a
                    repayment is entered.
                  </p>
                )}
                {+ioiTotalDue > 0 ? (
                  <LabelWithDetail
                    label="IOI Due"
                    value={formatINRCurrency(ioiTotalDue)}
                    noRecord={+ioiTotalDue === -1 ? true : false}
                    isApprox={true}
                  />
                ) : (
                  <p className="p-1 text-sm text-slate-600 text-left w-10/12">
                    The calculation for Interest on Interest will be conducted
                    after a repayment is entered.
                  </p>
                )}
              </>
            )}

            <LabelWithDetail
              label="Excess Amount"
              value={formatINRCurrency(excessAmt)}
            />
          </fieldset>
          {addRepayment ? (
            <AddRepaymentForm
              principleDueWithIntData={principleDueWithIntData}
              repaymentDateChangeHandler={repaymentDateChangeHandler}
              setSpecficDate={setSpecficDate}
              repaymentDate={repaymentDate}
              currentDateFromServer={currentDateFromServer}
              setAddRepayment={setAddRepayment}
              maximumPayAmount={maximumPayAmount}
              addRepaymentDetails={addRepaymentDetails}
              specficDate={specficDate}
              principlePaidAmount={principlePaidAmount}
              interestPaidAmount={interestPaidAmount}
              getInitialLoadCalculation={getInitialLoadCalculation}
              setRepaymentAmount={setRepaymentAmount}
              setRefreshPage={setRefreshPage}
              totalDueAmount={totalDueAmount}
              penalTotalDue={penalTotalDue}
              ioiTotalDue={ioiTotalDue}
              loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
            />
          ) : addPenalInterest ? (
            <>
              <AddPenalForm
                addRepaymentDetails={addRepaymentDetails}
                principleDueWithIntData={principleDueWithIntData}
                repaymentDate={repaymentDate}
                currentDateFromServer={currentDateFromServer}
                setAddPenalInterest={setAddPenalInterest}
                getInitialLoadCalculation={getInitialLoadCalculation}
                setRefreshPage={setRefreshPage}
                totalDueAmount={totalDueAmount}
                penalTotalDue={penalTotalDue}
                ioiTotalDue={ioiTotalDue}
                loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
              />
            </>
          ) : addIOI ? (
            <>
              <AddIOIForm
                addRepaymentDetails={addRepaymentDetails}
                principleDueWithIntData={principleDueWithIntData}
                repaymentDate={repaymentDate}
                currentDateFromServer={currentDateFromServer}
                setAddIOI={setAddIOI}
                getInitialLoadCalculation={getInitialLoadCalculation}
                setRefreshPage={setRefreshPage}
                totalDueAmount={totalDueAmount}
                penalTotalDue={penalTotalDue}
                ioiTotalDue={ioiTotalDue}
                loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
              />
            </>
          ) : generateChequeReceipt ? (
            <>
              <GenerateChequeForm
                addRepaymentDetails={addRepaymentDetails}
                principleDueWithIntData={principleDueWithIntData}
                repaymentDate={repaymentDate}
                currentDateFromServer={currentDateFromServer}
                setGenerateChequeReceipt={setGenerateChequeReceipt}
                getInitialLoadCalculation={getInitialLoadCalculation}
                setRefreshPage={setRefreshPage}
                totalDueAmount={totalDueAmount}
                penalTotalDue={penalTotalDue}
                ioiTotalDue={ioiTotalDue}
                loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
              />
            </>
          ) : showExcessAmtForm ? (
            <>
              <ExcessAmtForm
                setShowExcessAmtForm={setShowExcessAmtForm}
                addRepaymentDetails={addRepaymentDetails}
                getExcessAmtWithLoaneeId={getExcessAmtWithLoaneeId}
                excessAmt={excessAmt}
              />
            </>
          ) : (
            <section className="my-6">
              <div className="flex flex-col md:flex-row justify-between md:items-center md:h-14">
                <div className="mb-3">
                  <div className="flex flex-wrap gap-1 text-primary text-sm">
                    <p
                      className={`${
                        selectedTab === "ledger"
                          ? " bg-slate-700 text-white"
                          : "text-slate-700 "
                      } rounded-sm cursor-pointer px-3 py-1`}
                      onClick={() => setSelectedTab("ledger")}
                    >
                      Ledger
                    </p>
                    <p
                      className={`${
                        selectedTab === "int_ledger"
                          ? " bg-slate-700 text-white"
                          : "text-slate-700 "
                      } rounded-sm cursor-pointer px-3 py-1`}
                      onClick={() => setSelectedTab("int_ledger")}
                    >
                      Interest Ledger
                    </p>

                    {addRepaymentDetails?.categoryname === "OBC" && (
                      <>
                        <p
                          className={`${
                            selectedTab === "int_penal"
                              ? " bg-slate-700 text-white"
                              : "text-slate-700 "
                          } rounded-sm cursor-pointer px-3 py-1`}
                          onClick={() => setSelectedTab("int_penal")}
                        >
                          Penal Interest
                        </p>
                        <p
                          className={`${
                            selectedTab === "int_on_interest"
                              ? " bg-slate-700 text-white"
                              : "text-slate-700 "
                          } rounded-sm cursor-pointer px-3 py-1`}
                          onClick={() => setSelectedTab("int_on_interest")}
                        >
                          Interest On Interest
                        </p>
                      </>
                    )}

                    <p
                      className={`${
                        selectedTab === "repayment-receipts"
                          ? " bg-slate-700 text-white"
                          : "text-slate-700 "
                      } rounded-sm cursor-pointer px-3 py-1`}
                      onClick={() => setSelectedTab("repayment-receipts")}
                    >
                      Repayment Receipts
                    </p>
                    <p
                      className={`${
                        selectedTab === "cheque-receipts"
                          ? " bg-slate-700 text-white"
                          : "text-slate-700 "
                      } rounded-sm cursor-pointer px-3 py-1`}
                      onClick={() => setSelectedTab("cheque-receipts")}
                    >
                      Cheque Receipts
                    </p>
                  </div>
                </div>

                {(auth.rolename === "Assistant Manager" ||
                  auth.rolename === "Managing Director" ||
                  auth.rolename === "Cashier") &&
                addRepaymentDetails?.loan_status === "Inactive" ? (
                  <>
                    <Button
                      className={outlined_btn_primary}
                      onClick={() => setShowExcessAmtForm(true)}
                    >
                      <TbReceipt size={18} />
                      <span className="pl-1">Excess Amounsst</span>
                    </Button>
                  </>
                ) : null}

                {(auth.rolename === "Assistant Manager" ||
                  auth.rolename === "Cashier") &&
                  addRepaymentDetails?.loan_status === "Active" && (
                    <>
                      <div className="flex gap-3">
                        <Button
                          className={outlined_btn_primary}
                          onClick={() => setGenerateChequeReceipt(true)}
                        >
                          <TbReceipt size={18} />
                          <span className="pl-1">Generate Cheque Receipt</span>
                        </Button>
                        {totalDueAmount > 0 ||
                        principleDueWithIntData?.principle_outstanding_amount >
                          0 ? (
                          <Button
                            className={add_btn}
                            onClick={() => setAddRepayment(true)}
                          >
                            <MdAdd size={18} /> Add Repayment
                          </Button>
                        ) : (
                          <>
                            {penalTotalDue > 0 ? (
                              <Button
                                className={outlined_btn_primary}
                                onClick={() => setAddPenalInterest(true)}
                              >
                                <MdAdd size={18} /> Add Penal
                              </Button>
                            ) : null}

                            {ioiTotalDue > 0 ? (
                              <Button
                                className={add_btn}
                                onClick={() => setAddIOI(true)}
                              >
                                <MdAdd size={18} /> Add Int on int
                              </Button>
                            ) : null}
                          </>
                        )}
                      </div>
                    </>
                  )}
              </div>
              {selectedTab === "ledger" && (
                <>
                  <LedgerList
                    ledgerList={ledgerList}
                    setLedgerList={setLedgerList}
                    setRefreshPage={setRefreshPage}
                  />
                  {ledgerList?.length > 0 ? (
                    <PrintReport reportTitle={"Ledger Report"}>
                      <LedgerListPrint
                        ledgerList={ledgerList}
                        addRepaymentDetails={addRepaymentDetails}
                        principleDueWithIntData={principleDueWithIntData}
                        totalDueAmount={totalDueAmount}
                        penalTotalDue={penalTotalDue}
                        ioiTotalDue={ioiTotalDue}
                      />
                    </PrintReport>
                  ) : null}
                </>
              )}
              {selectedTab === "int_ledger" && (
                <>
                  <InterestLedgerList
                    ledgerList={interestLedgerList}
                    setInterestLedgerList={setInterestLedgerList}
                    setRefreshPage={setRefreshPage}
                  />

                  {interestLedgerList?.length > 0 ? (
                    <PrintReport reportTitle={"InterestLedger Report"}>
                      <InterestLedgerListPrint
                        interestLedgerList={interestLedgerList}
                        addRepaymentDetails={addRepaymentDetails}
                        principleDueWithIntData={principleDueWithIntData}
                        totalDueAmount={totalDueAmount}
                        penalTotalDue={penalTotalDue}
                        ioiTotalDue={ioiTotalDue}
                      />
                    </PrintReport>
                  ) : null}
                </>
              )}
              {selectedTab === "int_penal" && (
                <>
                  <PenalInterestList penalList={penalInterestList} />
                  {penalInterestList?.length > 0 ? (
                    <PrintReport reportTitle={"Penal Interest Report"}>
                      <PenalInterestListPrint
                        penalInterestList={penalInterestList}
                        addRepaymentDetails={addRepaymentDetails}
                        principleDueWithIntData={principleDueWithIntData}
                        totalDueAmount={totalDueAmount}
                        penalTotalDue={penalTotalDue}
                        ioiTotalDue={ioiTotalDue}
                      />
                    </PrintReport>
                  ) : null}
                </>
              )}
              {selectedTab === "int_on_interest" && (
                <>
                  <InterestOnInterestList ioiList={interestOnInterestList} />
                  {interestOnInterestList?.length > 0 ? (
                    <PrintReport reportTitle={"Interest on Interest Report"}>
                      <InterestOnInterestListPrint
                        interestOnInterestList={interestOnInterestList}
                        addRepaymentDetails={addRepaymentDetails}
                        principleDueWithIntData={principleDueWithIntData}
                        totalDueAmount={totalDueAmount}
                        penalTotalDue={penalTotalDue}
                        ioiTotalDue={ioiTotalDue}
                      />
                    </PrintReport>
                  ) : null}
                </>
              )}
              {selectedTab === "repayment-receipts" && (
                <ReceiptList
                  receiptRepaymentList={receiptRepaymentList}
                  addRepaymentDetails={addRepaymentDetails}
                  principleDueWithIntData={principleDueWithIntData}
                  totalDueAmount={totalDueAmount}
                  penalTotalDue={penalTotalDue}
                  ioiTotalDue={ioiTotalDue}
                  loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                />
              )}
              {selectedTab === "cheque-receipts" && (
                <ChequeReceiptList
                  receiptChequeList={receiptChequeList}
                  addRepaymentDetails={addRepaymentDetails}
                  principleDueWithIntData={principleDueWithIntData}
                  totalDueAmount={totalDueAmount}
                  penalTotalDue={penalTotalDue}
                  ioiTotalDue={ioiTotalDue}
                  loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
                />
              )}
            </section>
          )}
        </>
      )}
    </>
  );
};

export default AddRepaymentFormConfig;
