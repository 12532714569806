import React, { useEffect } from "react";
import { useState } from "react";
import { IconContext } from "react-icons";
import { FaUsers } from "react-icons/fa";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  LOANEE_DEATH_CASE,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { Paging } from "../../../../reusable-components/paging/Paging";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import showToast from "../../../../utilities/notification/NotificationModal";
import DeathCaseList from "./DeathCaseList";
import ViewDeathCase from "./ViewDeathCase";

export default function DeathCaseConfig() {
  const [applicantPersonalDetailName, setApplicantPersonalDetailName] =
    useState();
  const [showAddDocumentsForm, setShowAddDocumentsForm] = useState(false);
  const [editApplicantDetails, setEditApplicantDetails] = useState({});
  const [showAddGuarantorForm, setShowAddGuarantorForm] = useState(false);
  const [roleName, setRoleName] = useState();
  const [viewDeathCaseDetails, setViewDeathCaseDetail] = useState(false);
  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [searchText, setSearchText] = useState("");
  const [pageNo, setPageNo] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [pageSize, setPageSize] = useState();
  const [deathCaseSearchedList, setDeathCaseSearchList] = useState([]);
  const [loaneeDeathCaseList, setLoaneeDeathCaseList] = useState([]);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [loaneeId, setLoaneeId] = useState();

  const [deathCaseDetail, setDeathCaseDetail] = useState({});

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();
  const controller = new AbortController();

  const getDeathCaseList = async (
    page_no,
    page_size,
    search_text,
    category_type
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${LOANEE_DEATH_CASE}`,
        {
          params: {
            rolename: auth?.rolename,
            pgno: page_no || pageNo || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type || selectedCategory?.label || "",
            isloaneedeathcase: true,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setLoaneeDeathCaseList(response?.data?.deathCaseList);

        const totalNumberOfDeathList =
          +response?.data?.deathCaseList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfDeathList / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No Death List  found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });
      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setRoleName(auth?.rolename);
      getCategoryList();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [Object.keys(deathCaseSearchedList).length > 0]);

  useEffect(() => {
    getDeathCaseList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label
    );
  }, [pageNo, pageSize, selectedCategory?.label]);

  return (
    <>
      <Dashboard sidebarType={auth?.rolename}>
        <ToastContainer />

        {!viewDeathCaseDetails ? (
          <>
            <div className="flex flex-col md:flex-row md:items-center justify-between w-full ">
              <H2withIcon>
                <IconContext.Provider value={{ className: "text-secondary" }}>
                  <FaUsers />
                </IconContext.Provider>
                <span>&nbsp; Death Case List</span>
              </H2withIcon>

              <>
                <section className="flex justify-between items-center gap-3 md:w-[60%] mb-4 z-10 text-xs  md:text-sm">
                  <FilterDropdown
                    defaultName="filterCategory"
                    labelname="Scheme"
                    required={true}
                    classes={`md:w-[80%] w-1/2`}
                    data={categoryList}
                    defaultValue={null}
                    setSelected={setSelectedCategory}
                    selected={selectedCategory}
                    maxMenuHeight={200}
                  />
                  <SearchField
                    placeholder="Search"
                    defaultValue={searchText}
                    onChange={(e) => {
                      setSearchText(e);
                    }}
                    onClick={() => {
                      setPageNo(1);
                      getDeathCaseList(
                        1,
                        pageSize?.value || 10,
                        searchText || "",
                        selectedCategory?.label || ""
                      );
                    }}
                    classes={"bg-primary"}
                    onKeyDown={(e) => {
                      if (e?.key === "Enter") {
                        getDeathCaseList(
                          1,
                          pageSize?.value,
                          searchText || "",
                          selectedCategory?.label || ""
                        );
                      }
                    }}
                  />
                </section>
              </>
            </div>

            <div className="grid grid-cols-1">
              <DeathCaseList
                loaneeDeathCaseList={loaneeDeathCaseList}
                setViewDeathCaseDetail={setViewDeathCaseDetail}
                setDeathCaseDetail={setDeathCaseDetail}
                setLoaneeId={setLoaneeId}
                setApplicantPersonalDetailName={setApplicantPersonalDetailName}
                setShowAddGuarantorForm={setShowAddGuarantorForm}
                setEditApplicantDetails={setEditApplicantDetails}
                setShowAddDocumentsForm={setShowAddDocumentsForm}
                getDeathCaseList={getDeathCaseList}
              />
              <Paging
                maxMenuHeight={100}
                selected={pageSize}
                setSelected={setPageSize}
                pageNo={pageNo}
                onPageChangeHandler={onPageChangeHandler}
                numberOfPages={numberOfPages}
              />
            </div>
          </>
        ) : (
          <ViewDeathCase
            setViewDeathCaseDetail={setViewDeathCaseDetail}
            deathCaseDetail={deathCaseDetail}
            roleName={roleName}
            setDeathCaseDetail={setDeathCaseDetail}
          />
        )}
      </Dashboard>
    </>
  );
}
