/* eslint-disable no-mixed-operators */
import React, { useState, Fragment } from "react";
import { useDetectClickOutside } from "react-detect-click-outside";
import { IoMdEye } from "react-icons/io";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";
import { AiFillLock, AiFillUnlock } from "react-icons/ai";
import useAuth from "../../../../authentication/hooks/useAuth";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { BsPhone } from "react-icons/bs";
import { CSVLink, CSVDownload } from "react-csv";

export default function RepaymentList({
  repaymentList,
  setAddRepaymentDetails,
  setShowAddForm,
  getPenalInterestList,
  setViewApplicantInterestDetail,
  setViewPenalInterestDetail,
  getInterestOnInterestList,
  setViewInterestOnInterestDetail,
}) {
  const onClickView = (repayment) => {
    setAddRepaymentDetails(repayment);

    setShowAddForm(true);
  };

  const { auth } = useAuth();

  const date = new Date();

  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();

  let currentDate = `${day}-${month}-${year}`;

  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-sm sticky top-0 z-0">
          <tr className="bg-slate-200 ">
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Applicant Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Relative Name
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Category
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan amount
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Loan status
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-center font-medium text-slate-900  tracking-wider"
            >
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {repaymentList?.length > 0 &&
            repaymentList?.map((repayment, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-6 py-2 whitespace-nowrap text-sm  text-slate-800 font-medium ">
                  <div className=" px-3 py-1 rounded-sm">
                    {repayment?.loanee_id}
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm text-slate-800 font-medium">
                  {repayment?.applicantname}
                  <div className="text-slate-600 font-normal text-xs flex items-center gap-1">
                    <BsPhone size={12} /> {repayment?.contactno || "---"}
                  </div>
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.applicantrelativename}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.categoryname}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {formatINRCurrency(repayment?.loanappliedamt)}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm">
                  {repayment?.loan_status === "Active" ? (
                    <div className="flex items-start gap-1 text-green-700">
                      <AiFillUnlock size={18} />
                      <span className="text-green-700">
                        {repayment?.loan_status}
                      </span>
                    </div>
                  ) : (
                    <div className="flex items-start gap-1 text-slate-600">
                      <AiFillLock size={18} />{" "}
                      <span className="text-primary">
                        {repayment?.loan_status}
                      </span>
                    </div>
                  )}
                </td>
                <td className="px-6 py-2 whitespace-nowrap text-sm w-24">
                  <div className="flex justify-between gap-2">
                    {repayment?.loan_status === "Active" ? (
                      <div
                        className="flex items-center text-secondary hover:text-primary cursor-pointer"
                        onClick={() => onClickView(repayment)}
                      >
                        <IoMdEye size={20} />
                      </div>
                    ) : (
                      <>
                        {/* Paid off */}
                        {/* <div className="flex gap-2 items-center text-xs">
                        <MdDone size={16} color={"slate"} />
                      </div> */}
                        <div
                          className="flex items-center text-secondary hover:text-primary cursor-pointer"
                          onClick={() => onClickView(repayment)}
                        >
                          <IoMdEye size={20} />
                        </div>
                      </>
                    )}
                    {false &&
                      repayment?.loan_status === "Active" &&
                      repayment?.categoryname === "OBC" &&
                      (auth.rolename === "Assistant Manager" ||
                        auth.rolename === "Cashier") && (
                        <SubMenu
                          getPenalInterestList={getPenalInterestList}
                          setViewApplicantInterestDetail={
                            setViewApplicantInterestDetail
                          }
                          setViewPenalInterestDetail={
                            setViewPenalInterestDetail
                          }
                          getInterestOnInterestList={getInterestOnInterestList}
                          setViewInterestOnInterestDetail={
                            setViewInterestOnInterestDetail
                          }
                        />
                      )}
                  </div>
                </td>
              </tr>
            ))}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={repaymentList}
          target="_blank"
          filename={`SABCCO-Repayment-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}

const SubMenu = ({
  getPenalInterestList,
  setViewApplicantInterestDetail,
  setViewPenalInterestDetail,
  getInterestOnInterestList,
  setViewInterestOnInterestDetail,
}) => {
  const [showSubMenu, setShowSubMenu] = useState(false);

  const ref = useDetectClickOutside({
    onTriggered: () => setShowSubMenu(false),
  });

  return (
    <>
      <div className="relative inline-block text-left" ref={ref}>
        <div className="flex">
          <button type="button" onClick={() => setShowSubMenu(!showSubMenu)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 text-primary hover:text-slate-700 "
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path d="M10 6a2 2 0 110-4 2 2 0 010 4zM10 12a2 2 0 110-4 2 2 0 010 4zM10 18a2 2 0 110-4 2 2 0 010 4z" />
            </svg>
          </button>
        </div>
        {showSubMenu && (
          <div
            className="z-60  absolute -right-3 bottom-4 w-56 rounded-sm shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none p-2"
            role="menu"
          >
            <div className="flex items-start justify-start flex-col">
              <Button
                className={`${linkTextPrimary} text-xs flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                onClick={() => {
                  getPenalInterestList();
                  setViewPenalInterestDetail(true);
                  setViewApplicantInterestDetail(true);
                }}
              >
                Convert to OTS
              </Button>

              <Button
                className={`${linkTextPrimary} text-xs flex items-center gap-1 py-1 hover:bg-slate-100 w-full`}
                onClick={() => {
                  getInterestOnInterestList();
                  setViewInterestOnInterestDetail(true);
                  setViewApplicantInterestDetail(true);
                }}
              >
                Convert to decree case
              </Button>
            </div>
          </div>
        )}
      </div>
    </>
  );
};
