import { useEffect, useState } from "react";
import useAxiosPrivate from "../authentication/hooks/useAxiosPrivate";

const useGetAxios = (url) => {
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  const getAxious = async () => {
    setLoading(true);
    try {
      const response = await axiosPrivate.get(url, {
        signal: controller.signal,
      });

      if (response?.status === 200 || response?.data) {
        setData(response?.data);
      } else {
        setData([]);
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        setError("No Server Response");
      } else if (error.response.status === 422) {
        setError("Some of the required inputs were not provided", "error");
      } else {
        setError(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;

    if (isMounted && url) {
      getAxious();
    }

    return () => {
      isMounted = false;
    };
  }, []);

  return { data, error, loading };
};

export default useGetAxios;
