import React, { useEffect, useState, Fragment } from "react";
import { NEWs_EVENTs_LIST_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import Button from "../../../reusable-components/buttons/Button";
import H1 from "../../../reusable-components/headings/H1";
import H2 from "../../../reusable-components/headings/H2";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import GenericModal from "../../../reusable-components/modals/GenericModal";
import HeadlessUITab from "../../../reusable-components/tabs/HeadlessUITab";
import HeadlessUITabWithTable from "../../../reusable-components/tabs/HeadlessUITabWithTable";
import { button, cancel_button } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const News_Events = () => {
  const controller = new AbortController();

  const [newsList, setNewsList] = useState({});
  const [blobURL, setBlobURL] = useState("");
  const [blobDesc, setBlobDesc] = useState("");
  const [modalTitle, setModalTitle] = useState("");
  const [showPDFViewer, setShowPDFViewer] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const getNewsList = async () => {
    try {
      const response = await axios.get(`${NEWs_EVENTs_LIST_URL}`, {
        signal: controller.signal,
      });
      let newsObj = {};
      let newsType = ["News", "Event"];
      let newsList = response.data.newsList;
      if (newsList.length > 0) {
        for (let j = 0; j < newsType.length; j++) {
          let objList = [];
          for (let i = 0; i < newsList.length; i++) {
            if (newsType[j] === newsList[i].newstype) {
              let newsListObj = {
                id: newsList[i].newsid,
                title: newsList[i].newstitle,
                doc: newsList[i].newsdoc,
                desc: newsList[i].newsdesc,
                type: newsList[i].newstype,
                postedon: newsList[i].newsstartdate,
                endson: newsList[i].newsenddate,
              };
              objList.push(newsListObj);
            }
          }
          newsObj = {
            ...newsObj,
            [`${newsType[j]}`]: objList,
          };
        }
      }
      response.status === 200 && setNewsList(newsObj);
      response.status === 202 &&
        showToast("No about us summary found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getNewsList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  const onClickView = (base64, title, desc) => {
    let url = "";
    if (desc !== null && desc !== "") {
      if (base64 !== null && base64 !== "") {
        url = base64;
      }
      setBlobURL(url);
      setBlobDesc(desc);
      setModalTitle(`${title}`);
      setShowPDFViewer(true);
    } else {
      var a = document.createElement("a"); //Create <a>
      a.href = base64; //Image Base64 Goes here
      a.download = title; //File name Here
      a.click(); //Downloaded file
    }
  };

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>News &amp; Events</H1>
          </div>
        </div>
      </HeroBanner>
      {isLoading ? (
        <SpinnerWithText />
      ) : (
        <Fragment>
          <div className="container mx-auto max-w-7xl mb-4">
            <HeadlessUITabWithTable
              tabCategory={newsList}
              onClickView={onClickView}
            />
          </div>
          <GenericModal
            open={showPDFViewer}
            setOpen={setShowPDFViewer}
            title={modalTitle}
            isLarge={true}
            isAdd={false}
          >
            {blobDesc !== "" && blobDesc !== null && (
              <div
                className="leading-8 text-justify py-2"
                dangerouslySetInnerHTML={{ __html: blobDesc }}
              />
            )}
            {blobURL !== "" && (
              <div className="mt-2 flex items-center space-x-2">
                <p>{modalTitle} Document</p>
                <a href={blobURL} download={modalTitle}>
                  <Button className={button}>Download</Button>
                </a>
              </div>
            )}
            <div className="flex justify-end">
              <Button
                className={cancel_button}
                onClick={() => {
                  setShowPDFViewer(false);
                  setBlobURL("");
                  setBlobDesc("");
                  setModalTitle(``);
                }}
              >
                Close
              </Button>
            </div>
          </GenericModal>
        </Fragment>
      )}
    </div>
  );
};

export default News_Events;
