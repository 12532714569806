import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import { FaBuildingColumns } from "react-icons/fa6";
import { TiGroup } from "react-icons/ti";
import { FaFileCircleCheck } from "react-icons/fa6";
import { RiLightbulbFlashFill } from "react-icons/ri";
import { MdManageAccounts } from "react-icons/md";
import { FaPeopleRoof } from "react-icons/fa6";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { PiReceiptFill } from "react-icons/pi";
import { MdPermDeviceInformation } from "react-icons/md";
import { FaUserShield } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { BsFillClipboardDataFill } from "react-icons/bs";

export default function Rti_v2() {
  const navigate = useNavigate();

  let cardDetails = [
    {
      cardId: 1,
      cardIcon: (
        <>
          <FaBuildingColumns className="text-blue-500" size={36} />
        </>
      ),
      cardName: " Organization, Functions and Duties",
    },
    {
      cardId: 2,
      cardIcon: (
        <>
          <TiGroup className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Name and duties of officers and Employees",
    },
    {
      cardId: 3,
      cardIcon: (
        <>
          <FaFileCircleCheck className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Memorandum of Articles and Association",
    },
    {
      cardId: 4,
      cardIcon: (
        <>
          <RiLightbulbFlashFill className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Procedures followed in Decision-making process",
    },
    {
      cardId: 5,
      cardIcon: (
        <>
          <MdManageAccounts className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Management for Consultation ... ",
    },
    {
      cardId: 6,
      cardIcon: (
        <>
          <FaPeopleRoof className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Board Of Directors Of SABCCO",
    },
    {
      cardId: 7,
      cardIcon: (
        <>
          <FaMoneyBillTransfer className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Monthly Remuneration received by officers and Employees ...",
    },

    {
      cardId: 8,
      cardIcon: (
        <>
          <PiReceiptFill className="text-blue-500" size={36} />
        </>
      ),
      cardName:
        "Particulars of Receipt of the concession, Permits or Authorization ...",
    },
    {
      cardId: 9,
      cardIcon: (
        <>
          <MdPermDeviceInformation className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Information Available in Electronic Form",
    },
    {
      cardId: 10,
      cardIcon: (
        <>
          <FaUserShield className="text-blue-500" size={36} />
        </>
      ),
      cardName:
        "Names, Designations and other particulars of Public Information Officers",
    },
    {
      cardId: 11,
      cardIcon: (
        <>
          <BsFillClipboardDataFill className="text-blue-500" size={36} />
        </>
      ),
      cardName: "Annual Report",
    },
  ];

  const handleShowCardDetails = (cardId) => {
    // console.log("handleShowCardDetails ", cardId);

    if (cardId === 1) {
      navigate("/rti/organization-and-function");
    }
    if (cardId === 2) {
      navigate("/rti/names-and-duties");
    }
    if (cardId === 4) {
      navigate("/rti/procedure-decision-making");
    }
    if (cardId === 7) {
      navigate("/rti/montly-remuneration");
    }
    if (cardId === 6) {
      navigate("/rti/board-of-directors");
    }
    if (cardId === 10) {
      navigate("/rti/details-of-public-info-officers");
    }
    if (cardId === 3) {
      navigate("/rti/memorandum-of-articles");
    }
    if (cardId === 5) {
      navigate("/rti/management-for-consultation");
    }
    if (cardId === 11) {
      navigate("/rti/annual-report");
    }
  };

  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-24 md:left-[35%] lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
            <div className="text-center">
              <p className="font-medium  text-xl md:text-3xl xl:4xl text-white ">
                Right To Information (RTI)
              </p>
            </div>
          </div>
        </HeroBanner>
      </header>
      <section className="my-20 px-6">
        <div className="grid  md:grid-cols-2 lg:grid-cols-4 gap-10 ">
          {cardDetails?.map((cardObj) => (
            <div
              className="cursor-pointer border p-4 rounded-md shadow-md hover:shadow-lg transition duration-300 ease-in-out transform hover:scale-105 relative "
              onClick={() => handleShowCardDetails(cardObj?.cardId)}
            >
              <div className="flex items-center justify-center mb-4 ">
                <div className="rounded-full border-2 border-blue-500 p-3">
                  {cardObj?.cardIcon}
                </div>
              </div>
              <p className="text-sm font-semibold text-gray-800 text-center leading-tight mb-4">
                {cardObj?.cardName}
              </p>
            </div>
          ))}
        </div>
      </section>
    </>
  );
}
