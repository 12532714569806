import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { DISTRICT_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import MasterConfigHeaderWithoutDeleteWrapper from "../../../../common/master-config/MasterConfigHeaderWithoutDeleteWrapper";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddDistrictForm from "./AddDistrictForm";
import DistrictList from "./DistrictList";

const DistrictConfig = () => {
  const { auth } = useAuth();
  const [districtList, setDistrictList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editDistrictDetails, setEditDistrictDetails] = useState({});
  const isEdit = Object.keys(editDistrictDetails).length > 0 ? true : false;
  const controller = new AbortController();

  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });
      response.status === 200 && setDistrictList(response.data.districtList);
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  useEffect(() => {
    let isMounted = true;
    getDistrictList();
    setEditDistrictDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <MasterConfigHeaderWithoutDeleteWrapper
        headerTitle={"District"}
        setShowAddForm={setShowAddForm}
      />
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={` ${isEdit ? "Update District" : "Add District"}`}
        isAdd={true}
      >
        <AddDistrictForm
          getDistrictList={getDistrictList}
          editDistrictDetails={editDistrictDetails}
          setEditDistrictDetails={setEditDistrictDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <div className="grid grid-cols-1">
        <DistrictList
          districtList={districtList}
          setEditDistrictDetails={setEditDistrictDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
    </Dashboard>
  );
};

export default DistrictConfig;
