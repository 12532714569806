import React, { useEffect, useState } from "react";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { CSVLink } from "react-csv";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { MdDoNotDisturbAlt } from "react-icons/md";
import { reContact } from "../../../../utilities/constants/regularexpressions";

export default function DefaulterDetailList({
  defaulterDetailList,
  ref,
  sendLoanReminderList,
  setSendLoanReminderList,
}) {
  const date = new Date();
  let day = date.getDate();
  let month = date.getMonth() + 1;
  let year = date.getFullYear();
  let currentDate = `${day}-${month}-${year}`;
  const [selectAll, setSelectAll] = useState(false);

  console.log({ sendLoanReminderList });
  console.log({ selectAll });

  const onCheckHandler = ({ loanee_id, contactno, total_due, checked }) => {
    try {
      let list = [...sendLoanReminderList];

      if (checked === true && reContact.test(contactno)) {
        const new_obj = {
          loanee_id: loanee_id,
          amount: total_due,
          phonenumber: contactno,
        };

        list.push(new_obj);
      }
      if (checked === false) {
        list = list.filter((rm_obj) => rm_obj.loanee_id !== loanee_id);
      }

      setSendLoanReminderList(list);

      console.log("onCheckHandler", loanee_id, contactno, total_due, checked);
    } catch (error) {
      console.error(error);
    }
  };

  const onCheckSelectAll = ({ checked }) => {
    try {
      let reminderList = [];

      if (checked === true) {
        setSelectAll(true);

        console.log("checked setSelectAll");

        defaulterDetailList?.map((list) => {
          if (reContact.test(list.contactno)) {
            const new_obj = {
              loanee_id: list.loanee_id,
              amount: list.total_due,
              phonenumber: list.contactno,
            };

            console.log("defaulterDetailList", list);

            reminderList.push(new_obj);
          }
        });
      }

      if (checked === false) {
        setSelectAll(false);
        reminderList = [];
      }

      setSendLoanReminderList(reminderList);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    setSelectAll(false);
    onCheckSelectAll({
      checked: false,
    });
  }, [defaulterDetailList?.length]);

  return (
    <>
      <TableWrapper>
        <thead
          className="bg-slate-200  sticky top-0 font-bold text-sm leading-5"
          ref={ref}
        >
          <tr>
            <th
              scope="col"
              className="pl-6 py-2 text-left font-bold text-slate-900 whitespace-nowrap "
            >
              <>
                <input
                  type="checkbox"
                  id="select_all"
                  name="select_all"
                  checked={selectAll}
                  onClick={(e) => {
                    onCheckSelectAll({
                      checked: e.target.checked,
                    });
                  }}
                />
                <label for="select_all" className="font-normal text-xs">
                  {" "}
                  Select All
                </label>
              </>
            </th>
            {/* <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 whitespace-nowrap "
            >
              SI No.
            </th> */}
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 whitespace-nowrap "
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              <div>
                Name <span>(Category)</span>
              </div>
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 whitespace-nowrap"
            >
              <p>(S/o, W/o, D/o) </p>
              <p>Relative Name</p>
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Contact No.
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Loan Amount
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Address
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              District
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Constituency
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Scheme Name
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Date of Sanction
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Last Repayment Made
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Principle Repay
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Interest Repaid
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Total Paid
            </th>

            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Principle Due
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Interest Due
            </th>
            <th
              scope="col"
              className="px-6 py-2 text-left font-bold text-slate-900 "
            >
              Total Due
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-xs divide-y divide-slate-100 text-slate-600">
          {defaulterDetailList?.length > 0 ? (
            defaulterDetailList?.map((defaulterList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="pl-6 py-2  ">
                  {defaulterList?.contactno &&
                  reContact.test(defaulterList?.contactno) ? (
                    <>
                      <input
                        type="checkbox"
                        name={`select_${defaulterList?.loanee_id}`}
                        checked={
                          sendLoanReminderList.filter(
                            (obj) => obj.loanee_id === defaulterList?.loanee_id
                          ).length > 0
                        }
                        onClick={(e) => {
                          onCheckHandler({
                            loanee_id: defaulterList?.loanee_id,
                            contactno: defaulterList?.contactno,
                            total_due: defaulterList?.total_due,
                            checked: e.target.checked,
                          });
                        }}
                      />
                    </>
                  ) : (
                    <div>
                      <p className="text-sm text-red-600">
                        <MdDoNotDisturbAlt size={20} />
                      </p>
                    </div>
                  )}
                </td>
                {/* <td className="px-6 py-2  ">{index + 1}</td> */}
                <td className="px-6 py-2  ">{defaulterList?.loanee_id}</td>
                <td className="px-6 py-2   flex flex-col">
                  {defaulterList?.applicantname}
                  <span className="font-semibold">
                    ({defaulterList?.categoryname})
                  </span>
                </td>

                <td className="px-6 py-2  ">
                  <p className="font-semibold">
                    ({defaulterList?.applicantrelative}){" "}
                  </p>
                  <p>{defaulterList?.applicantrelativename}</p>
                </td>

                <td className="px-6 py-2  ">
                  {defaulterList?.contactno || "NA"}
                </td>
                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.loan_amount)}
                </td>
                <td className="px-6 py-2  ">
                  {defaulterList?.permanentaddress}
                </td>
                <td className="px-6 py-2  ">
                  {defaulterList?.districtname || "NA"}
                </td>
                <td className="px-6 py-2  ">
                  {defaulterList?.constituencyname || "NA"}
                </td>
                <td className="px-6 py-2  ">
                  {defaulterList?.schemename || "NA"}
                </td>

                <td className="px-6 py-2  whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(
                    defaulterList?.loan_first_disbursment_date
                  )}
                </td>

                <td className="px-6 py-2  whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(
                    defaulterList?.last_repaymentdate
                  )}
                </td>

                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.principal_repay || 0)}
                </td>
                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.int_pay_amt || 0)}
                </td>
                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.totalpaid || 0)}
                </td>

                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.principle_due || 0)}
                </td>
                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.interest_due_amount || 0)}
                </td>
                <td className="px-6 py-2  ">
                  {formatINRCurrency(defaulterList?.total_due || 0)}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <div className="mt-10">
        <CSVLink
          className="bg-[#001727] hover:bg-[#001D31] text-white px-6 py-2"
          data={defaulterDetailList}
          target="_blank"
          filename={`SABCCO-DefaulterReportList-Reports/${currentDate}.csv`}
        >
          Export CSV
        </CSVLink>
      </div>
    </>
  );
}
