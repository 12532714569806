// developed by Jesson

import React from "react";
import { useState } from "react";

import { formatINRCurrency } from "../../../../utilities/currency/currency";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import { SCHEMES_LIST_PUBLIC_URL } from "../../../../api/api_routing_urls";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const SchemesList = ({ isHomePage }) => {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const controller = new AbortController();
  const [isLoading, setIsLoading] = useState(true);
  const [schemesDetails, setSchemesDetails] = useState([]);

  const getSchemesDetails = async () => {
    try {
      const response = await axiosPrivate.get(
        `${SCHEMES_LIST_PUBLIC_URL}/schemesDetails`,
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        if (isHomePage) {
          let schemeList = [];
          response?.data?.schemesDetails?.map((schemeObj, index) => {
            if (index < 9) {
              schemeList.push(schemeObj);
            }
          });
          setSchemesDetails(schemeList);
        } else {
          setSchemesDetails(response.data.schemesDetails);
        }
      }
      response.status === 202 &&
        showToast(
          "No Scheme Details Found list in the System found in the system",
          "error"
        );
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      getSchemesDetails();
    }
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [isHomePage]);

  return (
    <>
      <div className="bg-white">
        {/* <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
            <div className="text-center">
              <H1>Our Schemes</H1>
            </div>
          </div>
        </HeroBanner> */}
        {/* {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container mx-auto my-6">
          <div className="leading-6" />
          Schemes Offered By Us
        </div>
      )} */}
        <section className="container mx-auto my-12">
          <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31]">
            Schemes Offered By Us
          </div>
          <div className="mx-4 pt-20 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-9">
            {schemesDetails?.length > 0 ? (
              <>
                {schemesDetails?.map((schemeObj) => (
                  <SchemeCard schemeData={schemeObj} />
                ))}
              </>
            ) : null}
          </div>
          {isHomePage ? (
            <>
              <NavLink end to="/sabcco-offered-schemes">
                <p className="text-center py-9 text-primary text-sm cursor-pointer underline">
                  View all schemes
                </p>
              </NavLink>
            </>
          ) : null}
        </section>
      </div>
    </>
  );
};

export default SchemesList;

const SchemeCard = ({ schemeData }) => {
  return (
    <div className="hover:bg-slate-50 rounded-md p-4 border cursor-default">
      <div className="flex flex-row gap-2">
        {/* <div className="hidden lg:block">
          <MdOutlinePolicy size={34} color="#59E98D" />
        </div>
        <div className="block lg:hidden">
          <MdOutlinePolicy size={24} color="#59E98D" />
        </div> */}
        <>
          <div className="pb-3 flex-col">
            <div className="flex">
              <div className="font-bold text-black text-sm">Scheme Name:</div>
              <div className="pl-1 font-medium text-black text-sm ">
                {schemeData?.schemename}
              </div>
            </div>
            <div className="flex">
              <div className="font-bold text-black text-sm">
                Sanctioned Amount:
              </div>
              <div className="pl-1 font-medium text-black text-sm">
                {formatINRCurrency(schemeData.sanctionamount)}
              </div>
            </div>
            <div className="flex pt-2">
              <div className="font-semibold text-gray-600 text-sm">
                Category:
              </div>
              <div className="pl-1 font-normal text-gray-600 text-sm">
                {schemeData?.scheme_category?.map((item, index) => (
                  <>
                    {item?.value === null ? null : (
                      <>
                        {index > 0 ? <span> | </span> : null}
                        {item?.key}
                      </>
                    )}
                  </>
                ))}
              </div>
            </div>
          </div>
        </>
      </div>
      <hr></hr>
      <div>
        <div className="py-3 flex justify-center font-bold text-sm">
          Rate Of Interest
        </div>

        <div className="flex flex-wrap gap-x-3 justify-center">
          {schemeData?.scheme_category?.map((item, index) => (
            <>
              {item?.value === null ? null : (
                <>
                  <p
                    key={index}
                    className="text-slate-600 text-xs border px-2 py-1 rounded-md bg-white uppercase"
                  >
                    {item?.key} :{" "}
                    <span className="text-slate-700 font-semibold">
                      {item?.value}%
                    </span>
                  </p>
                </>
              )}
            </>
          ))}
        </div>
      </div>
    </div>
  );
};
