import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { LOANEE_DETAILS_CONFIG } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import PDFImageUploaderAWS from "../../../../reusable-components/inputs/FileUploader/PDFUploaderAWS";
import InputCheckbox from "../../../../reusable-components/inputs/InputCheckBox/InputCheckbox";
import H4 from "../../../../reusable-components/headings/H4";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import uploadFileController from "../../../../utilities/spacesFile/uploadFileController";

export default function AddApplicantDocumentsForm({
  // getApplicantDetailList,
  editApplicantDetails,
  setEditApplicantDetails,
  setShowAddForm,
  getSearchedApplicantList,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editApplicantDetails).length > 0;

  const [sikkimSubjectDoc, setSikkimSubjectDoc] = useState("");
  const [castDoc, setCastDoc] = useState("");
  const [salaryDoc, setSalaryDoc] = useState("");
  const [suretyParchaDoc, setSuretyParchaDoc] = useState("");
  const [tradeLicenseDoc, setTradeLicenseDoc] = useState("");
  const [incomeCertDoc, setIncomeCertDoc] = useState("");
  const [adharCardDoc, setAdharCardDoc] = useState("");
  const [voterCardDoc, setVoterCardDoc] = useState("");
  const [bankPassbookDoc, setBankPassbookDoc] = useState("");
  const [nocParchaDoc, setNocParchaDoc] = useState("");
  const [disabilityCertDoc, setDisabilityCertDoc] = useState("");

  const [parchaMortageDoc, setParchaMortageDoc] = useState("");
  const [salaryDeductionDoc, setSalaryDeductionDoc] = useState("");
  const [bankNocDoc, setBankNocDoc] = useState("");
  const [loanProcessingFeeDoc, setLoanProcessingFeeDoc] = useState("");
  // const [salaryDeductionFailDoc, setSalaryDeductionFailDoc] = useState("");
  const [departmenatalUndertakingDoc, setDepartmentalUndertakingDoc] =
    useState("");
  const [affidavitDoc, setAffidavitDoc] = useState("");

  const [sikkimSubjectChecked, setSikkimSubjectChecked] = useState(false);
  const [castCertificateChecked, setCastCertificateChecked] = useState(false);
  const [suretyParchaChecked, setSuretyParchaChecked] = useState(false);
  const [salaryDocChecked, setSalaryDocChecked] = useState(false);
  const [tradeLicenceChecked, setTradeLicenceChecked] = useState(false);
  const [incomeCertificateChecked, setIncomeCertificateChecked] =
    useState(false);
  const [adharCardChecked, setAdharCardChecked] = useState(false);
  const [voterCardChecked, setVoterCardChecked] = useState(false);
  const [bankPassbookChecked, setBankPassbookChecked] = useState(false);
  const [nocParchaDocChecked, setNocParchaDocChecked] = useState(false);
  const [disabilityCertificateChecked, setDisabilityCertificateChecked] =
    useState(false);

  const [affidavitChecked, setAffidavitChecked] = useState(false);
  const [parchaMortageChecked, setParchaMortageChecked] = useState(false);
  const [salaryDeductionChecked, setSalaryDeductionChecked] = useState(false);
  // const [salaryDeductionFailChecked, setSalaryDeductionFailChecked] =useState(false);
  const [departmentalUndertakingChecked, setDepartmentalUndertakingChecked] =
    useState(false);

  const [bankNOCChecked, setbankNOCChecked] = useState(false);
  const [loanProcessingFeeChecked, setLoanProcessingFeeChecked] =
    useState(false);

  const defaultValues = {
    applicantname: !isEdit ? "" : editApplicantDetails?.applicantname,
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const checkListHandler = () => {
    //for pre sanction documents
    editApplicantDetails?.filechecklist?.map((fileObj) => {
      if (fileObj.file === "Sikkim Subject") {
        setSikkimSubjectChecked(fileObj?.check);
      }
      if (fileObj.file === "Surety's Parcha") {
        setSuretyParchaChecked(fileObj?.check);
      }
      if (fileObj.file === "Salary Certificate") {
        setSalaryDocChecked(fileObj?.check);
      }
      if (fileObj.file === "Income Certificate") {
        setIncomeCertificateChecked(fileObj?.check);
      }
      if (fileObj.file === "Adhar Card") {
        setAdharCardChecked(fileObj?.check);
      }
      if (fileObj.file === "Voter Card") {
        setVoterCardChecked(fileObj?.check);
      }
      if (fileObj.file === "NOC Parcha") {
        setNocParchaDocChecked(fileObj?.check);
      }
      if (fileObj.file === "Cast Certificate") {
        setCastCertificateChecked(fileObj?.check);
      }
      if (fileObj.file === "Trade Licence") {
        setTradeLicenceChecked(fileObj?.check);
      }
      if (fileObj.file === "Bank Passbook") {
        setBankPassbookChecked(fileObj?.check);
      }
      if (fileObj.file === "Disability Certificate") {
        setDisabilityCertificateChecked(fileObj?.check);
      }
      if (fileObj.file === "Affidavit") {
        setAffidavitChecked(fileObj?.check);
      }
      if (fileObj.file === "Parcha Mortage") {
        setParchaMortageChecked(fileObj?.check);
      }
      if (fileObj.file === "Salary Deduction") {
        setSalaryDeductionChecked(fileObj?.check);
      }
      if (fileObj.file === "Departmental Undertaking") {
        setDepartmentalUndertakingChecked(fileObj?.check);
      }
      if (fileObj.file === "Bank NOC") {
        setbankNOCChecked(fileObj?.check);
      }
      if (fileObj.file === "Loan Processing Fee") {
        setLoanProcessingFeeChecked(fileObj?.check);
      }
    });
  };

  function createFileChecklistObj(file, check) {
    let fileObj = {
      file: file,
      check: check,
    };
    return fileObj;
  }

  async function uploadFile(file) {
    let fileUrl = "";

    try {
      await uploadFileController(file)
        .then((file_url) => {
          fileUrl = file_url;
        })
        .catch((err) => {
          fileUrl = "Error";
          console.error(err);
        });

      return fileUrl;
    } catch (error) {
      console.error(error);
      return "Error";
    }
  }

  async function applicantDocChecklistHandler() {
    let fileschecklist = [];

    fileschecklist.push(
      createFileChecklistObj("Sikkim Subject", sikkimSubjectChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Cast Certificate", castCertificateChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Surety's Parcha", suretyParchaChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Salary Certificate", salaryDocChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Trade Licence", tradeLicenceChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Income Certificate", incomeCertificateChecked)
    );
    fileschecklist.push(createFileChecklistObj("Adhar Card", adharCardChecked));
    fileschecklist.push(createFileChecklistObj("Voter Card", voterCardChecked));
    fileschecklist.push(
      createFileChecklistObj("Bank Passbook", bankPassbookChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("NOC Parcha", nocParchaDocChecked)
    );
    fileschecklist.push(
      createFileChecklistObj(
        "Disability Certificate",
        disabilityCertificateChecked
      )
    );
    fileschecklist.push(createFileChecklistObj("Affidavit", affidavitChecked));
    fileschecklist.push(
      createFileChecklistObj("Parcha Mortage", parchaMortageChecked)
    );
    fileschecklist.push(
      createFileChecklistObj("Salary Deduction", salaryDeductionChecked)
    );
    // fileschecklist.push(
    //   createFileChecklistObj(
    //     "Salary Deduction Falure",
    //     departmentalUndertakingChecked
    //   )
    // );
    fileschecklist.push(
      createFileChecklistObj(
        "Departmental Undertaking",
        departmentalUndertakingChecked
      )
    );
    fileschecklist.push(createFileChecklistObj("Bank NOC", bankNOCChecked));
    fileschecklist.push(
      createFileChecklistObj("Loan Processing Fee", loanProcessingFeeChecked)
    );

    return fileschecklist;
  }

  function createFileObj(file_name, file_type, file_url) {
    let fileObj = {
      filename: file_name,
      filetype: file_type,
      fileurl: file_url,
    };
    return fileObj;
  }

  async function updateApplicantDocument() {
    let file_url = "";
    let file_list = [];

    if (sikkimSubjectDoc) {
      file_url = await uploadFile(sikkimSubjectDoc);
      file_list.push(
        createFileObj(sikkimSubjectDoc?.name, "Sikkim Subject", file_url)
      );
    }
    if (castDoc) {
      file_url = await uploadFile(castDoc);
      file_list.push(
        createFileObj(castDoc?.name, "Cast Certificate", file_url)
      );
    }
    if (suretyParchaDoc) {
      file_url = await uploadFile(suretyParchaDoc);
      file_list.push(
        createFileObj(suretyParchaDoc?.name, "Surety's Parcha", file_url)
      );
    }
    if (salaryDoc) {
      file_url = await uploadFile(salaryDoc);
      file_list.push(
        createFileObj(salaryDoc?.name, "Salary Certificate", file_url)
      );
    }
    if (tradeLicenseDoc) {
      file_url = await uploadFile(tradeLicenseDoc);
      file_list.push(
        createFileObj(tradeLicenseDoc?.name, "Trade Licence", file_url)
      );
    }
    if (incomeCertDoc) {
      file_url = await uploadFile(incomeCertDoc);
      file_list.push(
        createFileObj(incomeCertDoc?.name, "Income Certificate", file_url)
      );
    }
    if (adharCardDoc) {
      file_url = await uploadFile(adharCardDoc);
      file_list.push(createFileObj(adharCardDoc?.name, "Adhar Card", file_url));
    }
    if (voterCardDoc) {
      file_url = await uploadFile(voterCardDoc);
      file_list.push(createFileObj(voterCardDoc?.name, "Voter Card", file_url));
    }
    if (bankPassbookDoc) {
      file_url = await uploadFile(bankPassbookDoc);
      file_list.push(
        createFileObj(bankPassbookDoc?.name, "Bank Passbook", file_url)
      );
    }
    if (nocParchaDoc) {
      file_url = await uploadFile(nocParchaDoc);
      file_list.push(createFileObj(nocParchaDoc?.name, "NOC Parcha", file_url));
    }
    if (disabilityCertDoc) {
      file_url = await uploadFile(disabilityCertDoc);
      file_list.push(
        createFileObj(
          disabilityCertDoc?.name,
          "Disability Certificate",
          file_url
        )
      );
    }

    if (parchaMortageDoc) {
      file_url = await uploadFile(parchaMortageDoc);
      file_list.push(
        createFileObj(parchaMortageDoc?.name, "Parcha Mortage", file_url)
      );
    }

    if (salaryDeductionDoc) {
      file_url = await uploadFile(salaryDeductionDoc);
      file_list.push(
        createFileObj(salaryDeductionDoc?.name, "Salary Deduction", file_url)
      );
    }

    if (bankNocDoc) {
      file_url = await uploadFile(bankNocDoc);
      file_list.push(createFileObj(bankNocDoc?.name, "Bank NOC", file_url));
    }

    if (loanProcessingFeeDoc) {
      file_url = await uploadFile(loanProcessingFeeDoc);
      file_list.push(
        createFileObj(
          loanProcessingFeeDoc?.name,
          "Loan Processing Fee",
          file_url
        )
      );
    }

    if (departmenatalUndertakingDoc) {
      file_url = await uploadFile(departmenatalUndertakingDoc);
      file_list.push(
        createFileObj(
          departmenatalUndertakingDoc?.name,
          "Departmental Undertaking",
          file_url
        )
      );
    }

    if (affidavitDoc) {
      file_url = await uploadFile(affidavitDoc);
      file_list.push(createFileObj(affidavitDoc?.name, "Affidavit", file_url));
    }

    return file_list;
  }

  const onSubmit = async (data) => {
    try {
      let fileList = await updateApplicantDocument();
      let file_checklist = await applicantDocChecklistHandler();

      data.sanctionfiles = JSON.stringify(fileList);
      data.filechecklist = JSON.stringify(file_checklist);

      let response = "";

      if (isEdit) {
        let formDataObj = {};

        formDataObj.sanctionfiles = data?.sanctionfiles;
        formDataObj.filechecklist = data?.filechecklist;
        formDataObj.loanee_id = editApplicantDetails?.loanee_id;
        formDataObj.updatedby = auth.userid;

        response = await axiosPrivate.post(
          `${LOANEE_DETAILS_CONFIG}/updatePostSanctionDocuments`,
          formDataObj,
          {
            signal: controller.signal,
          }
        );
      }

      if (response.status === 200) {
        if (isEdit) {
          setEditApplicantDetails({});
          showToast(
            "Applicant Detail has been updated successfully",
            "success"
          );
        } else {
          showToast("Applicant Detail has been added successfully", "success");
        }
        getSearchedApplicantList();
        // getApplicantDetailList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowAddForm(false);
      setEditApplicantDetails({});

      reset();
    } catch (error) {
      console.error(error);

      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    checkListHandler();
  }, []);

  return (
    <Fragment>
      <H4>
        <span className="text-slate-500 mr-2">Applicant Name:</span>
        {editApplicantDetails?.applicantname}
      </H4>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="pt-4 border-t">
          <div className="grid grid-cols-2 gap-x-3">
            <PDFImageUploaderAWS
              register={register}
              defaultName="sikkimsubject"
              name={`${
                isEdit && editApplicantDetails?.sikkimsubject
                  ? "Replace Copy of Sikkim Subject Document / Image"
                  : "Attested Copy of Sikkim Subject Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={sikkimSubjectDoc}
              setFile={setSikkimSubjectDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="castcertificate"
              name={`${
                isEdit && editApplicantDetails?.castcertificate
                  ? "Replace Copy of ST/SC/OBC Document / Image"
                  : "Attested Copy of ST/SC/OBC Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={castDoc}
              setFile={setCastDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.castcertificate : ""}
            />
            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="salarycertificate"
              name={`${
                isEdit && editApplicantDetails?.salarycertificate
                  ? "Replace Surety's Parcha/ Salary Document / Image"
                  : "Surety's Parcha/ Salary Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDoc}
              setFile={setSalaryDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.salarycertificate : ""}
            /> */}
            <PDFImageUploaderAWS
              register={register}
              defaultName="suretyparcha"
              name={`${
                isEdit && editApplicantDetails?.suretyparcha
                  ? "Replace Surety's Parcha  Document / Image"
                  : "Surety's Parcha  Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder=" Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={suretyParchaDoc}
              setFile={setSuretyParchaDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.suretyparcha : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="salarycertificate"
              name={`${
                isEdit && editApplicantDetails?.salarycertificate
                  ? "Replace  Salary Document / Image"
                  : "Salary Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder=" Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDoc}
              setFile={setSalaryDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.salarycertificate : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="tradelicense"
              name={`${
                isEdit && editApplicantDetails?.tradelicense
                  ? "Replace Trade Licence/ Firm Registration Document / Image"
                  : "Trade Licence/ Firm Registration Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Awards Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 py-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={tradeLicenseDoc}
              setFile={setTradeLicenseDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.tradelicense : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="incomecertificate"
              name={`${
                isEdit && editApplicantDetails?.incomecertificate
                  ? "Replace Income Certificate Document / Image"
                  : "Income Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={incomeCertDoc}
              setFile={setIncomeCertDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="adharcard"
              name={`${
                isEdit && editApplicantDetails?.adharcard
                  ? "Replace Adhar Card Document / Image"
                  : "Adhar Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={adharCardDoc}
              setFile={setAdharCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="votercard"
              name={`${
                isEdit && editApplicantDetails?.votercard
                  ? "Replace Voter Card Document / Image"
                  : "Voter Card Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={voterCardDoc}
              setFile={setVoterCardDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="bankpassbook"
              name={`${
                isEdit && editApplicantDetails?.votercard
                  ? "Replace Bank Passbook Document / Image"
                  : "Bank Passbook Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={bankPassbookDoc}
              setFile={setBankPassbookDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="parchanoc"
              name={`${
                isEdit && editApplicantDetails?.parchanoc
                  ? "Replace NOC of Parcha Holder Document / Image"
                  : "NOC of Parcha Holder Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={nocParchaDoc}
              setFile={setNocParchaDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="diasbilitycertificate"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Disability Certificate Document / Image"
                  : "Disability Certificate Document / Image"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={disabilityCertDoc}
              setFile={setDisabilityCertDoc}
              setValue={setValue}
              isEdit={isEdit}
              oldFileOnEdit={isEdit ? defaultValues.sikkimsubject : ""}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="parchamortage"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Parcha Mortage Document"
                  : "Parcha Mortage Document"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={parchaMortageDoc}
              setFile={setParchaMortageDoc}
              setValue={setValue}
              isEdit={isEdit}
            />

            <PDFImageUploaderAWS
              register={register}
              defaultName="salarydeduction"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Salary Deduction Document"
                  : "Salary Deduction Document"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDeductionDoc}
              setFile={setSalaryDeductionDoc}
              setValue={setValue}
              isEdit={isEdit}
            />

            <PDFImageUploaderAWS
              register={register}
              defaultName="banknoc"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace NOC from Bank"
                  : "NOC from Bank"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={bankNocDoc}
              setFile={setBankNocDoc}
              setValue={setValue}
              isEdit={isEdit}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="loanprocessingfee"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Loan processing fee receipt"
                  : "Loan processing fee receipt"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={loanProcessingFeeDoc}
              setFile={setLoanProcessingFeeDoc}
              setValue={setValue}
              isEdit={isEdit}
            />
            {/* <PDFImageUploaderAWS
              register={register}
              defaultName="salarydeductionfail"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Salary Deduction [For falure to repay 3 consecutive installment] Document"
                  : "Salary Deduction [For falure to repay 3 consecutive installment] Document"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={salaryDeductionFailDoc}
              setFile={setSalaryDeductionFailDoc}
              setValue={setValue}
              isEdit={isEdit}
            /> */}
            <PDFImageUploaderAWS
              register={register}
              defaultName="departmentalUndertaking"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Departmental Undertaking Document"
                  : "Departmental Undertaking Document"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={departmenatalUndertakingDoc}
              setFile={setDepartmentalUndertakingDoc}
              setValue={setValue}
              isEdit={isEdit}
            />
            <PDFImageUploaderAWS
              register={register}
              defaultName="affidavit"
              name={`${
                isEdit && editApplicantDetails?.diasbilitycertificate
                  ? "Replace Affidavit by both Applicant & Guarantor (signed by DC/ADC/SDM)"
                  : "Affidavit by both Applicant & Guarantor (signed by DC/ADC/SDM)"
              }`}
              required={false}
              pattern={null}
              placeholder="Document / Image"
              type="file"
              errors={errors}
              classes={`rounded-lg px-1 text-sm w-full border-none outline-none`}
              setError={setError}
              clearError={clearErrors}
              file={affidavitDoc}
              setFile={setAffidavitDoc}
              setValue={setValue}
              isEdit={isEdit}
            />
          </div>
        </div>
        <div className="mt-4 pt-4 border-t ">
          <div className="grid grid-cols-2 gap-x-3">
            <InputCheckbox
              defaultName="sikkimsubjectchecked"
              register={register}
              name="Attested Copy of Sikkim Subject"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSikkimSubjectChecked}
              defaultValue={sikkimSubjectChecked}
            />
            <InputCheckbox
              defaultName="castcertificatechecked"
              register={register}
              name="Attested Copy of ST/SC/OBC"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setCastCertificateChecked}
              defaultValue={castCertificateChecked}
            />
            <InputCheckbox
              defaultName="suretyparchachecked"
              register={register}
              name="Surety's Parcha Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSuretyParchaChecked}
              defaultValue={suretyParchaChecked}
            />
            <InputCheckbox
              defaultName="salarycertificatechecked"
              register={register}
              name="Salary Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSalaryDocChecked}
              defaultValue={salaryDocChecked}
            />
            <InputCheckbox
              defaultName="tradelicencechecked"
              register={register}
              name="Trade Licence/ Firm Registration Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setTradeLicenceChecked}
              defaultValue={tradeLicenceChecked}
            />
            <InputCheckbox
              defaultName="incomecertificatechecked"
              register={register}
              name="Income Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setIncomeCertificateChecked}
              defaultValue={incomeCertificateChecked}
            />
            <InputCheckbox
              defaultName="adharchecked"
              register={register}
              name="Adhar Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setAdharCardChecked}
              defaultValue={adharCardChecked}
            />
            <InputCheckbox
              defaultName="votercardchecked"
              register={register}
              name="Voter Card Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setVoterCardChecked}
              defaultValue={voterCardChecked}
            />
            <InputCheckbox
              defaultName="bankpasebookchecked"
              register={register}
              name="Bank Passbook Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setBankPassbookChecked}
              defaultValue={bankPassbookChecked}
            />
            <InputCheckbox
              defaultName="nocparchachecked"
              register={register}
              name="NOC of Parcha Holder Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setNocParchaDocChecked}
              defaultValue={nocParchaDocChecked}
            />
            <InputCheckbox
              defaultName="disabilitycertificatechecked"
              register={register}
              name="Disability Certificate Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setDisabilityCertificateChecked}
              defaultValue={disabilityCertificateChecked}
            />
            {/* after post sanction */}
            <InputCheckbox
              defaultName="parchamortagechecked"
              register={register}
              name="Parcha Mortage Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setParchaMortageChecked}
              defaultValue={parchaMortageChecked}
            />
            <InputCheckbox
              defaultName="salarydeductionchecked"
              register={register}
              name="Salary Deduction Document"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setSalaryDeductionChecked}
              defaultValue={salaryDeductionChecked}
            />

            <InputCheckbox
              defaultName="banknocchecked"
              register={register}
              name="NOC from Bank "
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setbankNOCChecked}
              defaultValue={bankNOCChecked}
            />
            <InputCheckbox
              defaultName="loanprocessingfeechecked"
              register={register}
              name="Loan processing fee receipt"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setLoanProcessingFeeChecked}
              defaultValue={loanProcessingFeeChecked}
            />

            <InputCheckbox
              defaultName="departmentalundertakingchecked"
              register={register}
              name="Departmental Undertaking Document "
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setDepartmentalUndertakingChecked}
              defaultValue={departmentalUndertakingChecked}
            />

            <InputCheckbox
              defaultName="affidavitchecked"
              register={register}
              name="Affidavit by both Applicant &amp; Guarantor (signed by DC/ADC/SDM)"
              required={false}
              pattern={null}
              errors={errors}
              placeholder=""
              setError={setError}
              clearError={clearErrors}
              autoComplete="off"
              type="checkbox"
              classes={`rounded shadow-lg text-sm mr-2`}
              onChangeInput={setAffidavitChecked}
              defaultValue={affidavitChecked}
            />
          </div>
        </div>
        <div className="flex justify-between mt-6">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditApplicantDetails({});
              setShowAddForm(false);
              reset();
            }}
            aria-label="Cancel FAQ Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </Fragment>
  );
}
