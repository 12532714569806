import React from "react";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { linkTextPrimary } from "../../../../theme/lightTheme";

export default function DistrictList({
  districtList,
  setEditDistrictDetails,
  setShowAddForm,
}) {
  const onClickEdit = (district) => {
    setEditDistrictDetails(district);
    setShowAddForm(true);
  };
  return (
    <TableWrapper>
      <thead className="bg-white text-sm">
        <tr>
          <th
            scope="col"
            className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
          >
            District Name
          </th>
          <th
            scope="col"
            className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
          >
            Actions
          </th>
        </tr>
      </thead>
      <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
        {districtList.length > 0 &&
          districtList.map((district, index) => (
            <tr key={index}>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                {district.districtname}
              </td>
              <td className="px-6 py-4 whitespace-nowrap text-sm">
                <div className="flex justify-end">
                  <Button
                    onClick={() => onClickEdit(district)}
                    aria-label="Edit District Details"
                    className={linkTextPrimary}
                  >
                    Edit
                  </Button>
                </div>
              </td>
            </tr>
          ))}
      </tbody>
    </TableWrapper>
  );
}
