import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import TableWrapper from "../../../reusable-components/table/TableWrapper";
import H1 from "../../../reusable-components/headings/H1";

export default function RTIMonthlyRemunerationPage() {
  const navigate = useNavigate();

  let employeeDetails = [
    {
      name: "Shri, Janga Bdr. Subba",
      designation: "Chairman",
      levelPayMatrix: "",
    },
    {
      name: "Shri, Ongda Tshering Bhutia, IRS",
      designation: "Managing Director",
      levelPayMatrix: "13 in the Pay Matrix(Government of India)",
    },
    {
      name: "Shri, Kishore Shrestha ",
      designation: "General Manager",
      levelPayMatrix: "20 (Govt. of Sikkim)",
    },
    {
      name: "Mrs, Indira Pillai  ",
      designation: "Additional L.O.",
      levelPayMatrix: `20 (Govt. of Sikkim) `,
    },
    {
      name: "Shri, Arun Gurung  ",
      designation: "DY. General Manager-I",
      levelPayMatrix: `19 (Govt. of Sikkim)`,
    },
    {
      name: "Shri, Durga Bdr. Manger ",
      designation: " DY. General Manager II",
      levelPayMatrix: `19 (Govt. of Sikkim)`,
    },
    {
      name: "Shri, Binod Ghatani",
      designation: " DY. General Manager II",
      levelPayMatrix: `19 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Purnima Ghimirey",
      designation: " P.S to MD",
      levelPayMatrix: `15 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs, Rinzing Ongmu Bhutia",
      designation: "Assistant Manager -I",
      levelPayMatrix: `15 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Meera Dural",
      designation: "Assistant Manager -II",
      levelPayMatrix: `15 (Govt. of Sikkim)`,
    },
    {
      name: "Ms.Phup Lhamu Bhutia",
      designation: "Jr. Manager-I",
      levelPayMatrix: `14 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Shanti Maya Subba",
      designation: "Jr. Manager-II",
      levelPayMatrix: `14 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Pempa Lhamu Tamang",
      designation: "Jr. Manager-III",
      levelPayMatrix: `14 (Govt. of Sikkim)`,
    },
    {
      name: "Mr.Tenzing Dorjee Lachenpa",
      designation: "Jr. Manager-IV",
      levelPayMatrix: `14 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Kabita Sherpa",
      designation: "Sr. O.A-I",
      levelPayMatrix: `11 (Govt. of Sikkim)
      `,
    },
    {
      name: "Mrs. Sanju Rai",
      designation: "Sr. O.A-II",
      levelPayMatrix: `11 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Chum Paden ",
      designation: "Sr. O.A-III",
      levelPayMatrix: `11 (Govt. of Sikkim)`,
    },
    {
      name: " Mrs. Tashi Ongmu Bhutia ",
      designation: "Sr. O.A-IV",
      levelPayMatrix: `11 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Tirtha Maya Tamang",
      designation: "O.A-I",
      levelPayMatrix: `9 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Yapching Bhutia",
      designation: "O.A-II",
      levelPayMatrix: `9 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Hem Bdr. Lamichaney",
      designation: "Driver",
      levelPayMatrix: `10 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Prem Kr. Pradhan",
      designation: "Jr. Driver",
      levelPayMatrix: `2 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Ney Tsh. Lepcha",
      designation: "Jr. Driver",
      levelPayMatrix: `2 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Naresh Citury",
      designation: "Peon ",
      levelPayMatrix: `4 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Samphey Bhutia",
      designation: "Peon ",
      levelPayMatrix: `4 (Govt. of Sikkim)`,
    },
    {
      name: "Smt. Rekha Devi Ram",
      designation: "Safaikarmachari/ peon",
      levelPayMatrix: `4 (Govt. of Sikkim)`,
    },
    {
      name: "Mrs. Krishna Manger",
      designation: "Jr. Safaikarmachari/ Peon",
      levelPayMatrix: `1 (Govt. of Sikkim)`,
    },
    {
      name: "Mr. Mani Kr. Chettri",
      designation: "Jr.Driver",
      levelPayMatrix: `Muster Roll`,
    },
    {
      name: "Mr. Dawa Lepcha",
      designation: "Jr.Driver",
      levelPayMatrix: `Muster Roll`,
    },
  ];
  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1> Monthly Remuneration Details</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className="lg:mx-20 lg:my-10">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 my-4 lg:mx-0 lg:my-0">
          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2">
            Monthly remuneration of Officers and staff of Sikkim SC ST & OBC
            Development Corporation Ltd. (SABCCO )
          </p>
          <section className="my-10">
            <div className=" border border-slate-200 overflow-x-auto">
              <table className=" divide-y divide-slate-200">
                <thead className="bg-white text-sm ">
                  <tr>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      S.No.
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Name of Officers and Staff
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Designation
                    </th>
                    <th
                      scope="col"
                      className="px-6 py-3 text-left font-semibold text-slate-900 "
                    >
                      Level as per Pay Matrix
                    </th>
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-slate-200 text-slate-800 overflow-hidden  ">
                  {employeeDetails.length > 0 &&
                    employeeDetails.map((employeeDetailsObj, index) => (
                      <tr key={index}>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {index + 1}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {employeeDetailsObj.name}
                        </td>
                        <td className="px-6 py-4 whitespace-nowrap text-sm">
                          {employeeDetailsObj.designation}
                        </td>
                        <td className=" px-6 py-4 whitespace-nowrap lg:whitespace-normal text-sm">
                          {employeeDetailsObj.levelPayMatrix}
                        </td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </section>
        </div>
      </section>
    </>
  );
}
