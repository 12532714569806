import React, { useEffect, useState } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { SCHEMES_LIST_PUBLIC_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import Button from "../../../reusable-components/buttons/Button";
import ContainerOutline from "../../../reusable-components/containers/ContainerOutline";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import H1 from "../../../reusable-components/headings/H1";
import H2 from "../../../reusable-components/headings/H2";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { button, linkTextSecondary } from "../../../theme/lightTheme";
import showToast from "../../../utilities/notification/NotificationModal";

const SchemeDetails = () => {
  const controller = new AbortController();
  const { schemeid } = useParams();

  const [schemeDetails, setSchemeDetails] = useState({});

  const getSchemeDetailsBySchemeID = async () => {
    try {
      const response = await axios.get(
        `${SCHEMES_LIST_PUBLIC_URL}/schemeDetailsBySchemeID`,
        { params: { schemeid: schemeid } },
        {
          signal: controller.signal,
        }
      );
      if (Object.keys(response.data.schemeDetails).length === 0) {
        window.location.href = "/404";
        return;
      }
      response.status === 200 && setSchemeDetails(response.data.schemeDetails);
      response.status === 202 &&
        showToast("No about us summary found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  //   const { scheme } = location.state;

  useEffect(() => {
    let isMounted = true;

    isMounted && getSchemeDetailsBySchemeID();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>{schemeDetails.schemetitle} Scheme</H1>
          </div>
        </div>
      </HeroBanner>
      <ContainerShadow>
        <div className="flex flex-col space-x-3 justify-end items-end">
          <Link to={`/schemes/${schemeDetails.schemefor}`}>
            <Button className={linkTextSecondary}>
              <div className="flex justify-between items-center space-x-3">
                <FaArrowLeft />

                <span className="ml-3">Back to List</span>
              </div>
            </Button>
          </Link>
        </div>
        <div className="container mx-auto max-w-7xl">
          <div className="flex flex-col space-x-3 justify-center items-center">
            <img src={schemeDetails.schemeicon} className="h-20 md:h-40" />
          </div>
          <ContainerShadow>
            <div
              dangerouslySetInnerHTML={{ __html: schemeDetails.schemedesc }}
            />
            {schemeDetails.schemedoc !== "" &&
              schemeDetails.schemedoc !== null && (
                <div className="mt-3 flex items-center">
                  <p>{schemeDetails.schemetitle} Document &nbsp;</p>
                  <a
                    download={schemeDetails.schemetitle}
                    href={schemeDetails.schemedoc}
                  >
                    <Button className={button}>Download</Button>
                  </a>
                </div>
              )}
          </ContainerShadow>
        </div>
      </ContainerShadow>
    </div>
  );
};

export default SchemeDetails;
