/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable array-callback-return */
import React from "react";
import { useState, useEffect } from "react";
import H5 from "../../../../reusable-components/headings/H5";
import { BiTrash } from "react-icons/bi";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import useAuth from "../../../../authentication/hooks/useAuth";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import { LEDGER_CONFIG_URL } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import showToast from "../../../../utilities/notification/NotificationModal";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { CodeStarNotifications } from "aws-sdk";

export default function LedgerList({
  ledgerList,
  setLedgerList,
  setRefreshPage,
  ref,
  totalLedgerRepaymentMade,
  setTotalLedgerRepaymentMade,
}) {
  const controller = new AbortController();
  const axiosPrivate = useAxiosPrivate();

  const [list, setList] = useState(ledgerList);

  const { auth } = useAuth();
  const [transectionAmount, setTransectionAmount] = useState({});
  const [showDelete, setShowDelete] = useState(false);
  const [ledgerDetailToDelete, setLedgerDetailToDelete] = useState();

  const getTotalTransectionAmount = () => {
    let totalDisbursmentAmount = 0;
    let totalRepaymentAmount = 0;
    list?.length > 0 &&
      list?.map((ledger) => {
        ledger?.tr_type === "DI" &&
          (totalDisbursmentAmount += +ledger?.tr_amount);
        ledger?.tr_type !== "DI" &&
          (totalRepaymentAmount += +ledger?.tr_amount);
      });
    setTransectionAmount({
      DI: totalDisbursmentAmount,
      RI: totalRepaymentAmount,
    });
  };

  const deleteLedgerHandler = async () => {
    try {
      const dataObj = {};
      const delUrl = LEDGER_CONFIG_URL + "/deleteLedger";
      dataObj.ledgerid = ledgerDetailToDelete?.ledgerid;
      dataObj.deletedby = auth?.userid;

      const response = await axiosPrivate.post(delUrl, dataObj, {
        signal: controller.signal,
      });

      if (response.status === 200 || response?.data?.ledgerDel === true) {
        let tempList = undefined;
        tempList = list?.filter(
          (obj) => obj.ledgerid !== ledgerDetailToDelete?.ledgerid
        );
        setList(tempList);
        setLedgerList(tempList);
        setRefreshPage((prev) => !prev);

        showToast("Ledger detail has been deleted successfully", "success");
      }
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      setShowDelete(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    if (isMounted) {
      setList(ledgerList);
      getTotalTransectionAmount();
    }

    return () => {
      isMounted = false;
    };
  }, [ledgerList]);

  return (
    <>
      <TableWrapper>
        <thead className="bg-slate-200 text-sm my-3 sticky top-0" ref={ref}>
          <tr>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              TR
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Date
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Disbursment
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Repayment
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider"
            >
              Particulars
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium text-slate-900  tracking-wider w-[10%]"
            >
              Mode
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-700 text-xs">
          {list?.length > 0 ? (
            list?.map((ledger, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-2 whitespace-nowrap">
                  {ledger?.ledgerid}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {getFormattedDateDDMMMYYYYDate(ledger?.tr_date)}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {ledger?.tr_type == "DI"
                    ? formatINRCurrency(ledger?.tr_amount)
                    : 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {ledger?.tr_type != "DI"
                    ? formatINRCurrency(ledger?.tr_amount)
                    : 0}
                </td>
                <td className="px-3 py-2 whitespace-nowrap">
                  {ledger?.tr_particulars || "---"}
                </td>
                <td className="px-3 py-2 whitespace-nowrap flex justify-between">
                  {ledger?.tr_paymode}

                  {auth?.rolename === "Assistant Manager" &&
                  +list?.length === +index + 1 &&
                  ledger?.tr_type === "RP" ? (
                    <div
                      className="cursor-pointer text-red-500 hover:text-red-600"
                      onClick={() => {
                        setLedgerDetailToDelete(ledger);
                        setShowDelete(true);
                      }}
                    >
                      <BiTrash size={16} />
                    </div>
                  ) : null}
                </td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </TableWrapper>

      <DeleteModal
        open={showDelete}
        setOpen={setShowDelete}
        message={`The ledger with following detail
         would be deleted. Are you sure?`}
        onDelete={deleteLedgerHandler}
      >
        <div className="text-xs text-slate-700 flex flex-wrap gap-x-6 bg-slate-50 p-2 border mt-3">
          <div>
            Date:{" "}
            <span className="font-medium text-sm">
              {getFormattedDateDDMMMYYYYDate(ledgerDetailToDelete?.tr_date)}
            </span>
          </div>
          <div>
            Mode:{" "}
            <span className="font-medium text-sm">
              {ledgerDetailToDelete?.tr_paymode}
            </span>
          </div>
          <div>
            Repayment:{" "}
            <span className="font-medium text-sm">
              ₹ {ledgerDetailToDelete?.tr_amount}
            </span>
          </div>
        </div>
      </DeleteModal>

      <section className="p-3 bg-ternary border rounded-sm bg-opacity-30 text-sm mt-6">
        <H5>Ledger Details</H5>
        <p>
          Total Disbursment Amount:
          <span className="font-semibold ml-2">
            {formatINRCurrency(transectionAmount?.DI)}
          </span>
        </p>
        <p>
          Total Repayment Amount:
          <span className="font-semibold ml-2">
            {formatINRCurrency(transectionAmount?.RI)}
          </span>
        </p>
      </section>
    </>
  );
}
