import React, { Fragment, useEffect, useState } from "react";
import user_image from "../../../assets/userimage.png";
import logo_image from "../../../assets/logoplaceholder.jpeg";
import {
  dataURLtoFile,
  getBase64,
} from "../../../utilities/fileFunctions/imageFunctions";
import Error from "../../outputs/Error";
import Webcam from "react-webcam";
import { MdOutlineImage, MdUploadFile } from "react-icons/md";
import "./CustomCSS/fileUpload.css";
import { useRef } from "react";
import { fileTypeChecker } from "../../../utilities/validateDocuments/validateDocument";
import { fileNameExtension } from "../../../utilities/constants/regularexpressions";
// import { TbCapture } from "react-icons/tb";
// import FilterDropdown from "../../search/FilterDropdown";
// import { imageUploadTypeDD } from "../../../utilities/constants/constants";

export default function CapturePhoto({
  register,
  defaultName,
  name,
  required,
  pattern,
  errors,
  classes,
  setError,
  clearError,
  setFilename,
  setValue,
  oldFileOnEdit,
  setFile,
  ...rest
}) {
  const passPhotoRef = useRef(null);
  const [passphoto, setPassphoto] = useState("");
  const [fileUploadError, setFileUploadError] = useState(false);

  const { onChange, ...props } = register(defaultName, {
    required: {
      value: passphoto ? false : required,
      message: `${name} is required, please provide the input.`,
    },
    pattern: pattern,
  });

  const [captureFromCamera, setCaptureFromCamera] = useState(false);
  const [isCapturePhoto, setIsCapturePhoto] = useState(null);

  const handleScroll = (ref) => {
    ref.current.scrollIntoView({ behavior: "smooth", block: "end" });
  };

  const validateFileType = async (file) => {
    try {
      const fileType = await fileTypeChecker(file);
      setFileUploadError(false);
      return fileType;
    } catch (err) {
      setFileUploadError(true);
      console.error("Invalid File:", err);
      return "unknown";
    }
  };

  const isImageFileNameValid = (fileName) => {
    return fileNameExtension.test(fileName);
  };

  const handleValidImageFile = (file) => {
    setFile(file);
    setValue(defaultName, file);
    getBase64(file, (result) => {
      setPassphoto(result);
    });
    setFilename(file.name);
  };

  const onChangePassphoto = async (e) => {
    try {
      const selectedFile = e.target.files[0];

      if (!selectedFile) {
        return;
      }

      const fileType = await validateFileType(selectedFile);

      if (fileType === "IMG" && isImageFileNameValid(selectedFile.name)) {
        handleValidImageFile(selectedFile);
      } else {
        setFileUploadError(true);
      }
    } catch (error) {
      console.error("Some error", error);
    }
  };

  const onCapturePassphoto = (imageSrc) => {
    try {
      setPassphoto(imageSrc);
      setFile(imageSrc);
      setValue(defaultName, imageSrc);
      setCaptureFromCamera(false);
    } catch (error) {
      console.error({ error });
    }
  };

  const videoConstraints = {
    width: 200,
    height: 200,
    facingMode: "user",
  };

  errors[defaultName] && handleScroll(passPhotoRef);

  useEffect(() => {
    if (oldFileOnEdit !== "") {
      setPassphoto(oldFileOnEdit);
    }
  }, [oldFileOnEdit]);

  return (
    <Fragment>
      <p className="font-medium text-center" ref={passPhotoRef}>
        {name} {required && <span className="text-red-700">*</span>}
      </p>
      <div className="flex flex-col max-full mb-2 justify-center items-center">
        {!captureFromCamera && (
          <img
            src={
              passphoto !== ""
                ? passphoto
                : name === "Passphoto"
                ? user_image
                : logo_image
            }
            className="h-36 w-36 border"
            alt="User"
          />
        )}

        <>
          {captureFromCamera ? (
            <>
              <Webcam
                audio={false}
                height={154}
                screenshotFormat="image/jpeg"
                width={154}
                mirrored={false}
                videoConstraints={videoConstraints}
              >
                {({ getScreenshot }) => (
                  <button
                    type="button"
                    className={`px-3 py-2 shadow-lg rounded-lg mt-2 text-sm ${
                      errors[defaultName] && passphoto === ""
                        ? "bg-red-700 text-white"
                        : "bg-primary text-white"
                    }`}
                    onClick={() => {
                      const imageSrc = getScreenshot();
                      if (imageSrc) {
                        onCapturePassphoto(imageSrc);
                        setCaptureFromCamera(false);
                        clearError(defaultName);
                      }
                    }}
                  >
                    Capture {name}
                  </button>
                )}
              </Webcam>
            </>
          ) : (
            <>
              <input
                id={defaultName}
                disabled={isCapturePhoto}
                onChange={(e) => {
                  const selectedFile = e.target.files[0]; // Get the selected file

                  const isEmptyValue = e.target.value === "";
                  const isUndefinedPassphoto =
                    passphoto === undefined || passphoto === "";

                  if (isEmptyValue && isUndefinedPassphoto) {
                    setError(defaultName, {
                      type: "required",
                      message: `${name} is required`,
                    });
                  } else {
                    clearError(defaultName);
                    onChangePassphoto(e);
                  }

                  clearError(defaultName);
                  onChange(e);
                }}
                // required={isCapturePhoto ? false : required}
                {...props}
                {...rest}
                className={`${classes} invisible h-0 border ${
                  isCapturePhoto ? "hidden" : ""
                } ${
                  errors[defaultName] && passphoto === ""
                    ? "border-red-700"
                    : "border-slate-300"
                }`}
              />
            </>
          )}
        </>
        {!captureFromCamera && (
          <>
            <button
              onClick={() => {
                setIsCapturePhoto(false);

                // setPhotoBase64("");
                setValue(defaultName, "");
                document.getElementById(defaultName).click();
              }}
              type="button"
              className={`px-3 py-2 shadow-lg rounded-lg text-sm ${
                errors[defaultName] && passphoto === ""
                  ? "bg-red-700 text-white"
                  : "bg-primary text-white"
              }`}
            >
              Upload {name}
            </button>
          </>
        )}
        <OptionBtwCaptureAndUpload
          captureFromCamera={captureFromCamera}
          setCaptureFromCamera={setCaptureFromCamera}
        />
        {errors[defaultName] && (
          <Error
            classes="flex flex-row gap-1 justify-center items-center max-w-sm w-full mt-2"
            message={`${name} is required`}
          />
        )}
      </div>
    </Fragment>
  );
}

const OptionBtwCaptureAndUpload = ({
  captureFromCamera,
  setCaptureFromCamera,
}) => {
  return (
    <Fragment>
      <div className=" flex items-center justify-between cursor-pointer text-primary my-2">
        <div
          className={`tooltip px-5 py-1.5 border border-slate-400 rounded-l-full hover:bg-white ${
            !captureFromCamera ? `bg-slate-400` : `bg-white`
          }`}
          onClick={() => {
            setCaptureFromCamera(true);
          }}
        >
          <MdOutlineImage size={20} />
          {/* capture */}
          <span class="tooltiptext text-xs px-2">
            Capture Passphoto with webcam
          </span>
        </div>
        <div
          className={`tooltip px-5 py-1.5 border  border-slate-400 rounded-r-full hover:bg-white ${
            captureFromCamera ? `bg-slate-400` : `bg-white`
          }`}
          onClick={() => {
            setCaptureFromCamera(false);
          }}
        >
          <MdUploadFile size={20} />
          {/* upload */}
          <span class="tooltiptext text-xs px-2">Upload from file</span>
        </div>
      </div>
    </Fragment>
  );
};
