import React from "react";
import { ABOUT_US_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import useAuth from "../../../../authentication/hooks/useAuth";
import { useForm } from "react-hook-form";
import { button, cancel_button } from "../../../../theme/lightTheme";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Button from "../../../../reusable-components/buttons/Button";
import RichTextArea from "../../../../reusable-components/richtexteditor/RichTextArea";

const AddAboutUs = ({
  getAboutUsDetails,
  aboutUs,
  setAboutUs,
  setShowAddForm,
}) => {
  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const { auth } = useAuth();

  const isEdit =
    aboutUs !== undefined ? Object.keys(aboutUs).length > 0 : false;

  const defaultValues = {
    shortsummary: isEdit ? aboutUs.shortsummary : "",
    aboutuscontent: isEdit ? aboutUs.aboutuscontent : "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    defaultValues: defaultValues,
  });

  const emptyObj = {};
  const setDefaultValueEmpty = () => {
    setShowAddForm(false);
  };

  const onSubmit = async (data) => {
    // var safeHTMLString = html`${data.aboutuscontent}`;

    try {
      let response = {};
      if (isEdit) {
        data.aboutusid = aboutUs.aboutusid;
        data.updatedby = auth.userid;
        // data.aboutuscontent = safeHTMLString.__html;
        response = await axiosPrivate.post(
          `${ABOUT_US_CONFIG_URL}/update`,
          data,
          {
            signal: controller.signal,
          }
        );
      } else {
        data.createdby = auth.userid;
        // data.aboutuscontent = safeHTMLString.__html;
        response = await axiosPrivate.post(ABOUT_US_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          showToast("About Us Details Successfully Updated", "success");
        } else {
          showToast("About Us Details Successfully Added", "success");
        }
        getAboutUsDetails();
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setDefaultValueEmpty();
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <ContainerShadow>
      <form onSubmit={handleSubmit(onSubmit)}>
        <TextArea
          defaultName="shortsummary"
          register={register}
          name="Short Summary About The Corporation"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Write Short Summary"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full resize-none h-32`}
          onChangeInput={null}
          defaultValue={defaultValues.shortsummary}
          setValue={setValue}
        />
        <RichTextArea
          defaultName={"aboutuscontent"}
          register={register}
          name={"About Us Description"}
          classes={"w-full"}
          clearError={clearErrors}
          setError={setError}
          setValue={setValue}
          data={defaultValues.aboutuscontent}
          errors={errors}
          pattern={null}
          required={true}
        />
        <div className="flex items-center justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={`${isEdit ? "Update" : "Submit"}`}
            className={button}
          />
          <Button className={cancel_button} onClick={setDefaultValueEmpty}>
            Cancel
          </Button>
        </div>
      </form>
    </ContainerShadow>
  );
};

export default AddAboutUs;
