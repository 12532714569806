// developed by Jesson

import React from "react";

import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

import samplebrochure from "../../../assets/Test_Pdf.pdf";

const MediaBrochures = () => {
  const brochureObj = [
    {
      doc_id: 5,
      doc_pdf: { samplebrochure },
      doc_title: "Sample Brochure Fifth",
      publisher: "SABCCO",
      dateOfUpload: "April 24, 2023",
    },
    {
      doc_id: 4,
      doc_pdf: { samplebrochure },
      doc_title: "Sample Brochure Fourth",
      publisher: "SABCCO",
      dateOfUpload: "April 23, 2023",
    },
    {
      doc_id: 3,
      doc_pdf: { samplebrochure },
      doc_title: "Sample Brochure Third",
      publisher: "SABCCO",
      dateOfUpload: "April 22, 2023",
    },
    {
      doc_id: 2,
      doc_pdf: { samplebrochure },
      doc_title: "Sample Brochure Second",
      publisher: "SABCCO",
      dateOfUpload: "April 21, 2023",
    },
    {
      doc_id: 1,
      doc_pdf: { samplebrochure },
      doc_title: "Sample Brochure First",
      publisher: "SABCCO",
      dateOfUpload: "April 19, 2023",
    },
  ];

  return (
    <>
      <div className="bg-white">
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/4 md:top-1/3">
            <div className="text-center">
              <H1>Media Gallery</H1>
            </div>
          </div>
        </HeroBanner>
        {/* {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container mx-auto my-6">
          <div className="leading-6" />
          Brochures and Pamphlets
        </div>
      )} */}
        <div className="container mx-auto my-20">
          <div className="font-bold text-2xl lg:text-4xl flex justify-center text-[#001D31]">
            Brochures and Pamphlets
          </div>
          <div className="py-10 text-center text-base lg:text-2xl font-medium text-gray-500 w-[95%] md:w-[60%] lg:w-[70%] mx-auto">
            Browse through our e-Brochures / e-Pamphlets archive to explore our
            services and find the ones that best suits your needs.
          </div>
          {brochureObj?.length > 0 &&
            brochureObj.map((item) => (
              <div className="py-3 hover:bg-slate-50 hover:rounded-sm pl-3">
                <a href={item.doc_pdf.samplebrochure} target="_blank">
                  <div className="text-sm lg:text-lg font-bold cursor-pointer text-[#59E98D] hover:text-[#001D31] focus:text-[#001D31] hover:underline">
                    {item.doc_title}
                  </div>
                </a>
                <div className="text-sm lg:text-lg text-gray-500">
                  {item.publisher} . {item.dateOfUpload}
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default MediaBrochures;
