import React, { useEffect, Fragment, useState } from "react";
import { MdHistory } from "react-icons/md";
import { ToastContainer } from "react-toastify";
import {
  CATEGORY_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import showToast from "../../../../utilities/notification/NotificationModal";
import RepaymentHistoryList from "./RepaymentHistoryList";

const RepaymentHistoryConfig = () => {
  const { auth } = useAuth();
  const [repaymentList, setRepaymentList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [editRepaymentDetails, setEditRepaymentDetails] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [constituencyList, setConstituencyList] = useState([]);
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();

  const pageSize = 20;

  const controller = new AbortController();

  const getSearchedRepaymentList = async (
    page_no,
    page_size,
    search_text,
    category_type
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getRepaymentSearchHistoryListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type,
            decreestatus: false,
            onetimesettlementstatus: false,
            district_id: 0,
            constituency_id: 0,
            applicant_gender: "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setRepaymentList(response?.data?.repaymentList);

        const totalNumberOfApplicant =
          response?.data?.repaymentList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(Math.ceil(totalNumberOfApplicant / pageSize) || 1);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get category list

  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // function for page change
  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  useEffect(() => {
    getSearchedRepaymentList(
      pageNo,
      pageSize,
      searchText,
      selectedCategory?.label || ""
    );
  }, [pageNo]);

  useEffect(() => {
    let isMounted = true;
    // getRepaymentList();
    getCategoryList();

    setEditRepaymentDetails({});
    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);
  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />
      {isLoading ? (
        <Fragment>
          <LoadingModal message={"Loading"} />
        </Fragment>
      ) : (
        <Fragment>
          <div className="flex items-center justify-between gap-2">
            <H2withIcon>
              <MdHistory /> <span className="ml-1">Repayment History </span>
            </H2withIcon>
            <Fragment>
              <section className="flex justify-between items-center gap-4 w-1/2 mb-4">
                <FilterDropdown
                  defaultName="filterCategory"
                  labelname="Scheme"
                  required={true}
                  classes={`text-sm w-full`}
                  // onChangeInput={(e) => {
                  //   if (e?.label !== undefined && e?.label !== null) {
                  //     getSearchedRepaymentList(
                  //       pageNo,
                  //       pageSize,
                  //       searchText,
                  //       e?.label
                  //     );
                  //   } else {
                  //     getSearchedRepaymentList(
                  //       pageNo,
                  //       pageSize,
                  //       searchText,
                  //       ""
                  //     );
                  //   }
                  // }}
                  data={categoryList}
                  defaultValue={null}
                  setSelected={setSelectedCategory}
                  selected={selectedCategory}
                  maxMenuHeight={120}
                />
                <SearchField
                  placeholder="Search"
                  defaultValue={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                  onClick={() => {
                    setPageNo(1);
                    getSearchedRepaymentList(
                      pageNo,
                      pageSize,
                      searchText || "",
                      selectedCategory?.label || ""
                    );
                  }}
                  classes={"bg-primary"}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") {
                      getSearchedRepaymentList(
                        pageNo,
                        pageSize,
                        searchText || "",
                        selectedCategory?.label || ""
                      );
                    }
                  }}
                />
              </section>
            </Fragment>
          </div>
        </Fragment>
      )}

      <div className="grid grid-cols-1">
        <RepaymentHistoryList
          repaymentList={repaymentList}
          setEditRepaymentDetails={setEditRepaymentDetails}
          setShowAddForm={setShowAddForm}
        />
      </div>
      <Fragment>
        <div className="flex justify-between mt-6">
          <Button
            className={`${
              pageNo === 1
                ? "cursor-not-allowed bg-slate-600"
                : "cursor-pointer"
            } bg-secondary text-sm px-3 py-2 text-white md:min-w-36 flex items-center justify-center gap-1`}
            onClick={() => {
              onPageChangeHandler(pageNo, "Prev");
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M12.707 5.293a1 1 0 010 1.414L9.414 10l3.293 3.293a1 1 0 01-1.414 1.414l-4-4a1 1 0 010-1.414l4-4a1 1 0 011.414 0z"
                clipRule="evenodd"
              />
            </svg>
            Previous
          </Button>
          {pageNo} / {numberOfPages}
          <Button
            className={`${
              pageNo === numberOfPages
                ? "cursor-not-allowed bg-slate-600"
                : "cursor-pointer"
            }             
                    bg-secondary text-sm px-3 py-2 text-white md:min-w-36 flex items-center justify-center gap-1`}
            onClick={() => {
              onPageChangeHandler(pageNo, "Next");
            }}
          >
            Next
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                clipRule="evenodd"
              />
            </svg>
          </Button>
        </div>
      </Fragment>
    </Dashboard>
  );
};

export default RepaymentHistoryConfig;
