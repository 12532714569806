import React, { useState } from "react";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { MdAssignmentTurnedIn } from "react-icons/md";
import { BiChevronLeft } from "react-icons/bi";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { useForm } from "react-hook-form";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { ReferenceArea } from "recharts";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import DirectDeptNoticeTemplate from "./DirectDeptNoticeTemplate";
import { NOTICE_URL } from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import showToast from "../../../../utilities/notification/NotificationModal";
import { useEffect } from "react";

export default function GenerateDirectDeptNotice({
  onChange,
  isLoading,
  setShowGenerateDirectDeptNoticePage,
  loaneeDetails,
  selectedDate,
  setSelectedDate,
  refNumber,
  setRefNumber,
  getNoticeList,
  guarantorListForNotice,
  setRefresh,
}) {
  const [toString, setToString] = useState();

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: null,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  // const handleGenerate_ReferenceNo = async () => {
  //   try {
  //     let response = "";
  //     response = await axiosPrivate.post(
  //       `${NOTICE_URL}/saveNoticeDetails`,
  //       {
  //         loanee_id: loaneeDetails?.loanee_id,
  //         category_type: loaneeDetails?.categoryname,
  //         generated_date: selectedDate,
  //         createdby: auth.userid,
  //         notice_type: "Direct Departmental Notice",
  //       },
  //       {
  //         signal: controller.signal,
  //       }
  //     );

  //     if (response.status === 200) {
  //       getNoticeList();
  //       setRefNumber(response?.data?.saveNoticeDetail);
  //       showToast("Reference Number has been added successfully", "success");
  //     } else {
  //       showToast(
  //         "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
  //         "error"
  //       );

  //       return;
  //     }
  //     reset();
  //   } catch (error) {
  //     console.error(error);
  //     if (!error?.response) {
  //       showToast("No Server Response", "error");
  //     } else if (error.response.status === 422) {
  //       showToast("Some of the required inputs were not provided", "error");
  //     } else {
  //       showToast(
  //         "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
  //         "error"
  //       );
  //     }
  //   } finally {
  //     getNoticeList();
  //   }
  // };

  const handleGenerate_ReferenceNo = async () => {
    try {
      let response = "";
      response = await axiosPrivate.post(
        `${NOTICE_URL}/saveNoticeDetails`,
        {
          loanee_id: loaneeDetails?.loanee_id,
          category_type: loaneeDetails?.categoryname,
          generated_date: selectedDate,
          createdby: auth.userid,
          notice_type: "Direct Departmental Notice",
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        getNoticeList();
        setRefNumber(response?.data?.saveNoticeDetail?.ref_num);
        showToast("Reference Number has been added successfully", "success");
      } else if (response.data.saveNoticeDetail?.reason === "Already Exist") {
        showToast(`${response.data.saveNoticeDetail?.message}`, "warning");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );

        return;
      }
      reset();
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getNoticeList();
    }
  };

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const controller = new AbortController();

  const to_string = `${loaneeDetails?.applicantname}
${loaneeDetails?.applicantrelative}: ${loaneeDetails?.applicantrelativename}
${loaneeDetails?.permanentaddress}`;

  useEffect(() => {
    setToString(to_string);
  }, [to_string]);

  return (
    <>
      <div className="flex justify-between">
        <H2withIcon>
          <MdAssignmentTurnedIn />
          <span className="pl-1.5">Generate Direct Departmental Notice</span>
        </H2withIcon>

        <span
          className="flex items-center cursor-pointer hover:underline underline-offset-2  "
          onClick={() => {
            setShowGenerateDirectDeptNoticePage(false);
            setRefNumber(null);
            setRefresh((prev) => !prev);
          }}
        >
          <BiChevronLeft size={24} /> back
        </span>
      </div>
      <div className="flex">
        <Input
          defaultName="demandNoticedate"
          register={register}
          name="Date"
          required={false}
          pattern={null}
          errors={errors}
          placeholder="Enter repayment date"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="date"
          classes={`rounded-sm px-3 py-2 text-sm w-fit`}
          onChangeInput={(e) => {
            setSelectedDate(e);

            onChange(e);
            // setRefreshPage((prev) => !prev);
          }}
          defaultValue={null}
          setValue={setValue}
          max={getFormattedDateInputDate(new Date())}
        />

        {refNumber ? (
          <>
            <section className="bg-white">
              <PrintReport
                reportTitle={"Direct Departmental  Notice"}
                buttonName={"Direct Departmental  Notice"}
              >
                <DirectDeptNoticeTemplate
                  refNumber={refNumber}
                  selectedDate={selectedDate}
                  loaneeDetails={loaneeDetails}
                  guarantorListForNotice={guarantorListForNotice}
                  toString={toString}
                  setToString={setToString}
                  to_Print={true}
                />
              </PrintReport>
            </section>
          </>
        ) : (
          <>
            {selectedDate === undefined ||
            selectedDate === null ||
            selectedDate === "" ? null : (
              <div className="flex items-center gap-4 ">
                <AddButton
                  label="Generate Reference Number"
                  onclick={() => handleGenerate_ReferenceNo()}
                />
              </div>
            )}
          </>
        )}
      </div>

      {selectedDate === undefined ||
      selectedDate === null ||
      selectedDate === "" ? null : (
        <>
          {isLoading ? (
            <>
              <section className="h-screen">
                <SpinnerWithText />
              </section>
            </>
          ) : (
            <DirectDeptNoticeTemplate
              selectedDate={selectedDate}
              refNumber={refNumber}
              loaneeDetails={loaneeDetails}
              guarantorListForNotice={guarantorListForNotice}
              toString={toString}
              setToString={setToString}
              to_Print={false}
            />
          )}
        </>
      )}
    </>
  );
}
