import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function ManagementForConsultationPage() {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Management of Consultation</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <p className="font-semibold  text-center pb-4 text-base md:text-xl underline underline-offset-2">
          Management of Consultation
        </p>

        <p>
          SABCCO is a State Channelising agency for the 5 Apex Corporations
          based in Delhi. Most of the policy decisions regarding beneficiaries
          are taken by these Apex Corporations and based on which notional
          allocation of funds are placed at our disposal. SABCCO however sends
          an Annual Action Plan based on the sectors areas where funds are found
          to be required based on the data provided by various agencies of the
          State working towards self-employment and entrepreneurship programs
          and schemes.
        </p>
      </section>
    </>
  );
}
