import React, { Fragment } from "react";
import { MdOutlineChevronLeft, MdOutlineChevronRight } from "react-icons/md";
import Select from "react-select";
import { pageSizeDD } from "../../utilities/constants/constants";
import Button from "../buttons/Button";

export const Paging = ({
  maxMenuHeight,
  selected,
  setSelected,
  pageNo,
  onPageChangeHandler,
  numberOfPages,
  ...rest
}) => {
  return (
    <>
      <div className="flex justify-between items-center mt-6 ">
        <div className="text-sm">
          <Select
            maxMenuHeight={maxMenuHeight}
            options={pageSizeDD}
            value={selected || pageSizeDD[1]}
            isMulti={rest.isMulti}
            closeMenuOnSelect={rest.closeMenuOnSelect}
            onChange={(e) => setSelected(e)}
            {...rest}
          />
        </div>
        <div className="flex items-center text-primary">
          <Button
            className={pageNo === 1 && `cursor-not-allowed`}
            onClick={() => {
              onPageChangeHandler(pageNo, "Prev");
            }}
          >
            <MdOutlineChevronLeft size={28} />
          </Button>
          <div className="border p-2 text-sm">
            {pageNo || 1} / {numberOfPages || 1}
          </div>
          <Button
            className={pageNo === numberOfPages && `cursor-not-allowed`}
            onClick={() => {
              onPageChangeHandler(pageNo, "Next");
            }}
          >
            <MdOutlineChevronRight size={28} />
          </Button>
        </div>
      </div>
    </>
  );
};
