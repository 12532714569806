import React from "react";
import {
  BarChart,
  Bar,
  Brush,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { colorsList } from "../../../utilities/constants/colors";

export default function BarGraphReport({ dataObj, title, ...rest }) {
  const data = dataObj;

  return (
    <>
      <ResponsiveContainer width="100%" height="100%">
        <BarChart
          width={500}
          height={300}
          data={data}
          margin={{
            top: 60,
            right: 6,
            left: -9,
            bottom: 90,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis tickMargin={20} dataKey="name" tick={<CustomizedAxisTick />} />
          <YAxis tick={<CustomizedYAxisTick />} />
          <Tooltip />

          <Brush y={0} dataKey="name" height={28} stroke={colorsList[0]} />
          <Bar
            dataKey="number"
            label={{ fill: "white", fontSize: 12 }}
            fill={colorsList[0]}
          />
        </BarChart>
      </ResponsiveContainer>
    </>
  );
}

const CustomizedAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text
        x={0}
        y={0}
        dy={0}
        textAnchor="end"
        fill="#666"
        transform="rotate(-50)"
      >
        {payload.value}
      </text>
    </g>
  );
};
const CustomizedYAxisTick = (props) => {
  const { x, y, stroke, payload } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-6} y={0} textAnchor="end" fill="#666">
        {payload.value}
      </text>
    </g>
  );
};
