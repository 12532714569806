import React, { useState } from "react";
import { useForm } from "react-hook-form";
import Button from "../../../../reusable-components/buttons/Button";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import { button, cancel_button } from "../../../../theme/lightTheme";
import Dropdown from "../../../../reusable-components/inputs/Dropdowns/Dropdown";
import showToast from "../../../../utilities/notification/NotificationModal";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import { BANKBRANCH_CONFIG_URL } from "../../../../api/api_routing_urls";

export default function AddBankBranchForm({
  setShowAddForm,
  bankNamesDD,
  getBankBranchList,
  editBankBranchDetails,
  setEditBankBranchDetails,
}) {
  let filteredList = bankNamesDD?.filter(
    (bankNames) => bankNames?.value === editBankBranchDetails?.bankid
  );

  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const [selectedBankName, setSelectedBankName] = useState("");
  const isEdit = Object.keys(editBankBranchDetails).length > 0 ? true : false;

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (!isEdit) {
        data.created_by = auth.userid;
        data.bankid = data?.bankname?.value;
        data.bankname = data?.bankname?.label;

        response = await axiosPrivate.post(BANKBRANCH_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.bankid = editBankBranchDetails?.bankid;
        data.bankname = data?.bankname?.label;
        data.updated_by = auth.userid;
        data.bankbranchid = editBankBranchDetails?.bankbranchid;

        response = await axiosPrivate.put(BANKBRANCH_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditBankBranchDetails({});
          showToast("Bank Branch has been updated successfully", "success");
        } else {
          getBankBranchList();
          showToast("Bank Branch has been added successfully", "success");
        }
        getBankBranchList();
      } else {
        if (isEdit) {
          if (
            response.data.updateBankBranchDetails.includes("unique_bankbranch")
          ) {
            showToast(
              "Bank Branch already exists. Cannot insert duplicate bank",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        } else {
          if (
            response.data.saveBankBranchDetails.includes("unique_bankbranch")
          ) {
            showToast(
              "Bank Branch already exists. Cannot insert duplicate district",
              "error"
            );
          } else {
            showToast(
              "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
              "error"
            );
          }
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops123!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  const defaultValues = {
    bankbranch: !isEdit ? "" : editBankBranchDetails?.bankbranch,
    bankifsccode: !isEdit ? "" : editBankBranchDetails?.bankifsccode,
    bankname: !isEdit ? "" : filteredList[0],
  };

  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const controller = new AbortController();

  return (
    <>
      <section>
        <form onSubmit={handleSubmit(onSubmit)}>
          <Dropdown
            defaultName="bankname"
            register={register}
            labelname="Bank Name"
            required={true}
            errors={errors}
            classes={`rounded-lg text-sm w-full z-40`}
            setError={setError}
            clearError={clearErrors}
            onChangeInput={null}
            control={control}
            data={bankNamesDD}
            defaultValue={defaultValues.bankname}
            setValue={setValue}
            setSelected={setSelectedBankName}
            selected={selectedBankName}
            maxMenuHeight={120}
            // isDisabled={isEdit ? true : false}
          />

          <Input
            defaultName="bankbranch"
            register={register}
            name="Branch Name"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter branch name"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.bankbranch}
            setValue={setValue}
          />
          <Input
            defaultName="bankifsccode"
            register={register}
            name="IFSC Code"
            required={true}
            pattern={null}
            errors={errors}
            placeholder="Enter IFSC code"
            setError={setError}
            clearError={clearErrors}
            autoComplete="off"
            type="text"
            classes={`rounded-lg px-3 py-2 text-sm w-full`}
            onChangeInput={null}
            defaultValue={defaultValues.bankifsccode}
            setValue={setValue}
          />
          <div className="flex justify-between">
            <SubmitButton
              isSubmitting={isSubmitting}
              label={!isEdit ? "Submit" : "Update"}
              className={button}
            />
            <Button
              onClick={() => {
                //   setEditDistrictDetails({});
                //   setValue("districtname", "");
                setShowAddForm(false);
              }}
              aria-label="Cancel Bank Update Bank"
              className={cancel_button}
            >
              Cancel
            </Button>
          </div>
        </form>
      </section>
    </>
  );
}
