import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { SCHEMES_LIST_PUBLIC_URL } from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import showToast from "../../../utilities/notification/NotificationModal";
import H2 from "../../../reusable-components/headings/H2";
import ContainerOutline from "../../../reusable-components/containers/ContainerOutline";
import ContainerShadow from "../../../reusable-components/containers/ContainerShadow";
import H4 from "../../../reusable-components/headings/H4";
import SpinnerWithText from "../../../reusable-components/loader/SpinnerWithText";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";

const SchemeList = () => {
  const controller = new AbortController();
  const { categoryid } = useParams();
  const navigation = useNavigate();
  const [schemesList, setSchemesList] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const getSchemesListByCategoryID = async () => {
    try {
      const response = await axios.get(
        `${SCHEMES_LIST_PUBLIC_URL}/schemeListByCategoryID`,
        { params: { categoryid: categoryid } },
        {
          signal: controller.signal,
        }
      );

      if (response.data.schemesList.length === 0) {
        window.location.href = "/404";
        return;
      }
      response.status === 200 && setSchemesList(response.data.schemesList);
      response.status === 202 &&
        showToast("No about us summary found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
      setIsLoading(false);
    }
  };

  useEffect(() => {
    let isMounted = true;
    isMounted && getSchemesListByCategoryID();
    setIsLoading(true);

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [categoryid]);

  return (
    <div className="bg-white">
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>
              Schemes for{" "}
              {schemesList.length > 0 && schemesList[0].categoryname}
            </H1>
          </div>
        </div>
      </HeroBanner>

      {isLoading ? (
        <SpinnerWithText />
      ) : (
        <div className="container max-w-7xl mx-auto mb-4">
          <ContainerShadow>
            <div className="flex flex-wrap space-x-4">
              {schemesList.length > 0 ? (
                schemesList.map((scheme, index) => {
                  return (
                    <Link
                      to={{
                        pathname: `/schemes/schemedetails/${scheme.schemeid}`,
                      }}
                      className="max-w-md shadow-lg p-2 px-3 border border-slate-200 rounded-lg"
                    >
                      <>
                        <div className="flex flex-col justify-center items-center">
                          <img
                            src={scheme.schemeicon}
                            className="h-20 md:h-40"
                          />
                          <H4>{scheme.schemetitle}</H4>
                        </div>
                      </>
                    </Link>
                  );
                })
              ) : (
                <p>No schemes found</p>
              )}
            </div>
          </ContainerShadow>
        </div>
      )}
    </div>
  );
};

export default SchemeList;
