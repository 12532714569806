import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import Input from "../../../../reusable-components/inputs/InputTextBox/Input";
import SubmitButton from "../../../../reusable-components/buttons/SubmitButton";
import { FAQ_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import { button, cancel_button } from "../../../../theme/lightTheme";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import useAuth from "../../../../authentication/hooks/useAuth";
import Button from "../../../../reusable-components/buttons/Button";
import ImageUploader from "../../../../reusable-components/inputs/FileUploader/ImageUploader";
import TextArea from "../../../../reusable-components/inputs/InputTextAreas/TextArea";

export default function AddFAQForm({
  getFAQList,
  editFAQDetails,
  setEditFAQDetails,
  setShowAddForm,
}) {
  const axiosPrivate = useAxiosPrivate();
  const { auth } = useAuth();

  const isEdit = Object.keys(editFAQDetails).length > 0;

  const defaultValues = {
    faqquestion: !isEdit ? "" : editFAQDetails?.faqquestion,
    faqanswer: !isEdit ? "" : editFAQDetails?.faqanswer,
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
    clearErrors,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });
  const controller = new AbortController();

  const onSubmit = async (data) => {
    try {
      let response = "";
      if (!isEdit) {
        data.createdby = auth.userid;
        response = await axiosPrivate.post(FAQ_CONFIG_URL, data, {
          signal: controller.signal,
        });
      } else {
        data.updatedby = auth.userid;
        data.faqid = editFAQDetails?.faqid;
        response = await axiosPrivate.put(FAQ_CONFIG_URL, data, {
          signal: controller.signal,
        });
      }
      if (response.status === 200) {
        if (isEdit) {
          setEditFAQDetails({});
          showToast("FAQ has been updated successfully", "success");
        } else {
          showToast("FAQ has been added successfully", "success");
        }
        getFAQList();
      } else {
        if (isEdit) {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        } else {
          showToast(
            "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
            "error"
          );
        }
        return;
      }
      setShowAddForm(false);
      reset();
    } catch (error) {
      if (!error?.response) {
        showToast("No Server Response", "error");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Input
          defaultName="faqquestion"
          register={register}
          name="Question"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Enter question"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-lg px-3 py-2 text-sm w-full`}
          onChangeInput={null}
          defaultValue={defaultValues.faqquestion}
          setValue={setValue}
        />
        <TextArea
          defaultName="faqanswer"
          register={register}
          name="Answer"
          required={true}
          pattern={null}
          errors={errors}
          placeholder="Write Answer"
          setError={setError}
          clearError={clearErrors}
          autoComplete="off"
          type="text"
          classes={`rounded-sm px-3 py-2 text-sm w-full resize-none h-32`}
          onChangeInput={null}
          defaultValue={defaultValues.faqanswer}
          setValue={setValue}
        />
        <div className="flex justify-between">
          <SubmitButton
            isSubmitting={isSubmitting}
            label={!isEdit ? "Submit" : "Update"}
            className={button}
          />
          <Button
            onClick={() => {
              setEditFAQDetails({});
              setShowAddForm(false);
              reset();
            }}
            aria-label="Cancel FAQ Update Button"
            className={cancel_button}
          >
            Cancel
          </Button>
        </div>
      </form>
    </div>
  );
}
