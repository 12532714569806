import React, { StrictMode } from "react";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context/AuthProvider";
import { disableReactDevTools } from "@fvilers/disable-react-devtools";

import { createRoot } from "react-dom/client";
import { NotificationProvider } from "./context/NotificationProvider";
import { store } from "./app/store";
import { Provider } from "react-redux";
const container = document.getElementById("root");
const root = createRoot(container);

if (process.env.NODE_ENV === "production") {
  disableReactDevTools();
}

root.render(
  // <StrictMode>
  <Router>
    <AuthProvider>
      <NotificationProvider>
        <Provider store={store}>
          <App />
        </Provider>
      </NotificationProvider>
    </AuthProvider>
  </Router>
  // </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
