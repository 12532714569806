/* eslint-disable jsx-a11y/alt-text */
import React, { useState } from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import H1 from "../../../reusable-components/headings/H1";
import { useForm } from "react-hook-form";
import Input from "../../../reusable-components/inputs/InputTextBox/Input";
import Captcha from "../../../utilities/captcah/Captcha";
import Button from "../../../reusable-components/buttons/Button";
import SubmitButton from "../../../reusable-components/buttons/SubmitButton";
import showToast from "../../../utilities/notification/NotificationModal";
import {
  FEEDBACK_URL,
  PUBLIC_NOC_APPLICATION,
} from "../../../api/api_routing_urls";
import axios from "../../../api/axios";
import {
  button,
  disabled_button,
  linkTextPrimary,
} from "../../../theme/lightTheme";
import H3 from "../../../reusable-components/headings/H3";
import { NavLink } from "react-router-dom";
import { formatINRCurrency } from "../../../utilities/currency/currency";
import sabccoLogo from "../../../assets/SABCCO_128x128.png";
import { getFormattedDateDDMMYYYY } from "../../../utilities/dateFunctions/formatdate";
import { PrintNOCApplication } from "../../system-admin/ReportsList/PrintReport/PrintNOCApplication";
import { useNavigate } from "react-router-dom";

const NOCApplicationConfig = () => {
  const controller = new AbortController();
  const [isCaptchaValid, setIsCaptchaValid] = useState(false);
  const [viewApplicationDetail, setViewApplicationDetail] = useState(false);

  const [captchaSuccess, setCaptchaSuccess] = useState(false);
  const [captchaReset, setCaptchaReset] = useState(false);
  const [captchaInput, setCaptchaInput] = useState("");

  const [errMessage, setErrMessage] = useState("");

  const [nocApplicationDetail, setnocApplicationDetail] = useState();

  const navigate = useNavigate();

  const defaultValues = {
    fullname: "",
  };
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting, isValid, isValidating },
    setError,
    clearErrors,
    reset,
    setValue,
    control,
  } = useForm({
    mode: "onChange",
    defaultValues: defaultValues,
    criteriaMode: "all",
    shouldFocusError: true,
    shouldUnregister: true,
  });

  const onSubmit = async (data) => {
    try {
      navigate(`/view-noc-application/${data?.noc_serial_no}`);
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <HeroBanner>
        <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-1/2">
          <div className="text-center">
            <H1>No Objection Certificate Application</H1>
          </div>
        </div>
      </HeroBanner>
      {viewApplicationDetail ? (
        <>
          <section className="container mx-auto max-w-7xl my-9 px-3">
            <p
              className={`text-center text-sm font-semibold p-3 my-6 border rounded-sm
            ${
              nocApplicationDetail.isverified
                ? " border-green-800  text-green-900 bg-green-100"
                : " border-yellow-800  text-yellow-900  bg-yellow-100"
            }
            `}
            >
              This application has
              {nocApplicationDetail.isverified
                ? " been verified. Your may print/download your application."
                : " not been verified yet."}
            </p>{" "}
            <div className="bg-white py-9 border">
              <NOCApplicationForm nocApplicationDetail={nocApplicationDetail} />
            </div>
            <div className=" md:flex justify-between items-center mt-9">
              <Button onClick={() => setViewApplicationDetail(false)}>
                <p className={` text-center ${linkTextPrimary} mb-9`}>
                  Return to NOC Application
                </p>
              </Button>
              {nocApplicationDetail.isverified ? (
                <>
                  <PrintNOCApplication>
                    <NOCApplicationForm
                      nocApplicationDetail={nocApplicationDetail}
                    />
                  </PrintNOCApplication>
                </>
              ) : null}
            </div>
          </section>{" "}
        </>
      ) : (
        <section>
          <div className="container mx-auto max-w-7xl my-9">
            <div className="grid grid-cols-1 md:grid-cols-2">
              <div className="border text-center bg-yellow-100 p-9">
                <H3>Submit Application Form</H3>
                <p className="bg-white text-sm font-semibold p-3 my-6 border border-yellow-800 rounded-sm text-yellow-900">
                  After successfully submitting the NOC form, you will be issued
                  a unique serial number. It is crucial to note down this serial
                  number as it enables you to check the status of your
                  application and download the form when needed at a later time.
                </p>{" "}
                <div className=" text-white mt-[3rem] cursor-pointer">
                  <NavLink end to="/noc-application-form">
                    <Button className={button}>
                      <span className="">Open Application Form</span>
                    </Button>
                  </NavLink>
                </div>
              </div>

              <div className="border text-center bg-green-50 p-9">
                <H3>Check Application Status</H3>
                <div>
                  <p className="bg-yellow-100 text-sm font-semibold p-3 my-6 border border-yellow-800 rounded-sm text-yellow-900">
                    Upon successful submission of the NOC application, you will
                    receive a serial number.
                  </p>{" "}
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="">
                      <Input
                        defaultName="noc_serial_no"
                        register={register}
                        name="Serial No"
                        required={true}
                        pattern={null}
                        errors={errors}
                        placeholder="Enter Serial No"
                        setError={setError}
                        clearError={clearErrors}
                        autoComplete="off"
                        type="text"
                        classes={`rounded-sm px-3 py-2 text-sm w-full`}
                        onChangeInput={null}
                        defaultValue={defaultValues.noc_serial_no}
                        setValue={setValue}
                      />
                    </div>

                    <p className="text-red-700">{errMessage}</p>

                    <div className="flex justify-center w-full mt-3">
                      <Captcha
                        setCaptchaSuccess={setCaptchaSuccess}
                        captchaReset={captchaReset}
                        setCaptchaInput={setCaptchaInput}
                      />
                    </div>
                    <div className="flex justify-center w-full mt-3">
                      {!captchaSuccess ? (
                        <Button className={disabled_button} disabled={true}>
                          Check Application Status
                        </Button>
                      ) : (
                        <SubmitButton
                          className={button}
                          isSubmitting={isSubmitting}
                          label={"Check Application Status"}
                        />
                      )}
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default NOCApplicationConfig;

export const NOCApplicationForm = ({ nocApplicationDetail }) => {
  return (
    <>
      <div className=" text-[14px] px-9 text-justify">
        <div className="w-full">
          <div className="flex gap-3 items-center my-6 w-fit mx-auto">
            <img className="h-16 w-16" src={sabccoLogo} />
            <div>
              <p className="text-center text-[16px] font-semibold">
                Sikkim SC / ST / OBC Development Corporation.Ltd. (SABCCO)
              </p>
              <p className="text-[14px]  -mt-1">
                Sonam Tshering Marg, Gangtok - 737101
              </p>
            </div>
          </div>
        </div>
        <div className="flex items-center justify-between">
          {nocApplicationDetail.noc_serial_no ? (
            <>
              <p className="font-semibold">
                Serial No.: {nocApplicationDetail.noc_serial_no}
              </p>
            </>
          ) : null}

          {nocApplicationDetail.createdat ? (
            <>
              <p className="font-semibold">
                Date: {getFormattedDateDDMMYYYY(nocApplicationDetail.createdat)}
              </p>
            </>
          ) : null}
        </div>

        <div className="mb-9">
          <p className="font-semibold text-center mb-6">
            TO WHOM IT MAY CONCERN
          </p>
          <p>
            This is to certify that Shri/Smt/Kumari
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_name}{" "}
            </span>
            S/o, D/o, W/o
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_relative_name}{" "}
            </span>
            resident of
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_resident}{" "}
            </span>
            does not have any loan till date or outstanding as reflected in the
            books. Hence this Corporation does not have any objection for grant
            of Job/Regularisation in
            <span className="font-semibold">
              {" "}
              {nocApplicationDetail.noc_department_name}{" "}
            </span>
            Department in respect of the applicant.
          </p>

          <p className="pt-3">
            This certificate is issued without prejudice to the Corporation's
            right to recover the loan amount if it is found at any later point
            of time that he/she owes any amount to this Corporation.
          </p>

          <p className="pt-3">
            This certificate is valid for a period of 6 (Six) months from the
            date of issue.
          </p>
        </div>

        <div className="flex flex-row justify-between pt-6">
          <p className="font-semibold">{formatINRCurrency(150)}/-</p>
          <div>
            <p className="mt-16">Asstt. Manager/Manager/Dy.G.M</p>
          </div>
        </div>
      </div>
    </>
  );
};
