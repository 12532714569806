const colorsList = [
  "#003f5c",
  "#444e86",
  "#955196",
  "#dd5182",
  "#ff6e54",
  "#ffa600",
  "#B64992",
  "#49B66D",
];

module.exports = {
  colorsList,
};
