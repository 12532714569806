import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

export default function BoardOfDirectorsPage() {
  const navigate = useNavigate();
  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Board of Directors </H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        {/* <div className="border  inline-flex justify-end items-center gap-4">
          <span>
            <IoReturnDownBack />
          </span>
          <span>back</span>
        </div> */}

        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <div className="mx-6 lg:mx-0 py-10 lg:py-0 text-xs md:text-sm">
          <p className="font-semibold  text-center pb-3 text-base md:text-xl underline underline-offset-2">
            BOARD OF DIRECTORS AS ON 31.03.2023
          </p>
          <p className="font-semibold mt-6">CHAIRMAN</p>
          <p>SHRI JANGA BAHADUR LIMBOO</p>
          <p className="font-semibold py-2">DIRECTORS</p>

          <div className="pl-4">
            <p>
              1. <span className="font-semibold">SECRETARY</span> SOCIAL WELFARE
              DEPARTMENT GOVERNMENT OF SIKKIM
            </p>
            <p>
              2. <span className="font-semibold">DIRECTOR</span> FINANCE
              DEPARTMENT GOVERNMENT OF SIKKIM
            </p>
            <p>
              3. <span className="font-semibold">DIRECTOR</span> ANIMAL
              HUSBANDRY & VETERINARY SERVICES, GOVERNMENT OF SIKKIM{" "}
            </p>
            <p>
              4. <span className="font-semibold">DIRECTOR</span>&nbsp;
              AGRICULTURE & FOOD SECURITY DEPARTMENT, GOVERNMENT OF SIKKIM
            </p>
            <p>
              5. Representative of NSFDC/NSTFDC/NSTFDC, New Delhi on rotation
              basis.
            </p>
            <p className="font-semibold">6. MANAGING DIRECTOR </p>
            <p> SHRI TSHERING ONGDA, IRS</p>
          </div>
        </div>
      </section>
    </>
  );
}
