import React from "react";
import { useLocation } from "react-router-dom";
import NavBgImage from "../../assets/NavBGelements.png";

const HeroBanner = (props) => {
  const location = useLocation();
  return (
    <section>
      {location.pathname === "/" && (
        <>
          {/* circle */}
          <section className="hidden opacity-5 md:block absolute z-10 md:-top-9 md:-right-3 md:h-[34rem] md:overflow-clip  md:scale-90 lg:h-screen">
            <svg
              width="383"
              height="364"
              viewBox="0 0 383 364"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_449_45)">
                <path
                  d="M345.773 -58.6993C386.323 -58.6993 419.18 -25.9356 419.18 14.4631C419.18 54.8618 386.323 87.6255 345.773 87.6255C305.224 87.6255 272.367 54.8619 272.367 14.4631C272.367 -25.9356 305.224 -58.6993 345.773 -58.6993Z"
                  stroke="#96E6B3"
                  strokeOpacity="0.4"
                  strokeWidth="5.12"
                />
                <path
                  d="M346.119 -105.094C412.173 -105.094 465.72 -51.567 465.72 14.4613C465.72 80.4896 412.173 134.017 346.119 134.017C280.064 134.017 226.517 80.4896 226.517 14.4614C226.517 -51.5669 280.064 -105.094 346.119 -105.094Z"
                  stroke="#96E6B3"
                  strokeOpacity="0.4"
                  strokeWidth="3.2"
                />
                <path
                  d="M345.774 -178.661C452.4 -178.661 538.834 -92.3485 538.834 14.1199C538.834 120.588 452.4 206.9 345.774 206.9C239.148 206.9 152.713 120.588 152.713 14.1199C152.713 -92.3485 239.148 -178.661 345.774 -178.661Z"
                  stroke="#96E6B3"
                  strokeOpacity="0.25"
                  strokeWidth="2"
                />
                <path
                  d="M345.773 -295C516.86 -295 655.547 -156.754 655.547 13.7734C655.547 184.301 516.86 322.547 345.773 322.547C174.687 322.547 36 184.301 36 13.7734C36 -156.754 174.687 -295 345.773 -295Z"
                  stroke="#96E6B3"
                  strokeOpacity="0.15625"
                  strokeWidth="2"
                />
              </g>
              <defs>
                <clipPath id="clip0_449_45">
                  <rect width="383" height="364" fill="white" />
                </clipPath>
              </defs>
            </svg>
          </section>

          {/* boxs */}
          <section
            className=" hidden md:block absolute z-10 md:top-20 md:right-0 md:h-[34rem] 
          md:overflow-clip md:scale-90 lg:h-screen 2xl:right-64
          lg:scale-110 lg:right-40 lg:top-1/3
          xl:scale-125 "
          >
            <svg
              width="435"
              height="364"
              viewBox="0 0 435 364"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                x="390.074"
                y="284.463"
                width="84.097"
                height="69.9538"
                transform="rotate(-180 390.074 284.463)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <rect
                x="198.363"
                y="115.953"
                width="82.3033"
                height="69.9538"
                transform="rotate(-180 198.363 115.953)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <rect
                x="304.026"
                y="188.469"
                width="82.3033"
                height="69.9538"
                transform="rotate(-180 304.026 188.469)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <rect
                x="261.875"
                y="286.736"
                width="84.097"
                height="69.9538"
                transform="rotate(-180 261.875 286.736)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <rect
                x="355.822"
                y="144.959"
                width="82.3033"
                height="69.9538"
                transform="rotate(-180 355.822 144.959)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <path
                d="M196.118 252.146L112.021 252.146L112.021 182.193L196.118 182.193L196.118 252.146Z"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <rect
                x="150.021"
                y="214.711"
                width="82.3033"
                height="69.9538"
                transform="rotate(-180 150.021 214.711)"
                fill="#96E6B3"
                fillOpacity="0.4"
              />
              <ellipse
                cx="48.1076"
                cy="229.982"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 48.1076 229.982)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="63.301"
                cy="229.982"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 63.301 229.982)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="78.4943"
                cy="229.982"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 78.4943 229.982)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="93.6877"
                cy="229.982"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 93.6877 229.982)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="229.982"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 229.982)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="48.1076"
                cy="244.172"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 48.1076 244.172)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="78.4943"
                cy="244.172"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 78.4943 244.172)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="93.6877"
                cy="244.172"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 93.6877 244.172)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="244.172"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 244.172)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="48.1076"
                cy="258.367"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 48.1076 258.367)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="78.4943"
                cy="258.367"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 78.4943 258.367)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="108.881"
                cy="258.367"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 108.881 258.367)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="258.367"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 258.367)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="63.301"
                cy="272.557"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 63.301 272.557)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="78.4943"
                cy="272.557"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 78.4943 272.557)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="108.881"
                cy="272.557"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 108.881 272.557)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="272.557"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 272.557)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="48.1076"
                cy="286.748"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 48.1076 286.748)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="63.301"
                cy="286.748"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 63.301 286.748)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="93.6877"
                cy="286.748"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 93.6877 286.748)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="286.748"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 286.748)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="48.1076"
                cy="300.941"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 48.1076 300.941)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="63.301"
                cy="300.941"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 63.301 300.941)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="78.4943"
                cy="300.941"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 78.4943 300.941)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="108.881"
                cy="300.941"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 108.881 300.941)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="300.941"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 300.941)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="93.6877"
                cy="315.133"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 93.6877 315.133)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="108.881"
                cy="315.133"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 108.881 315.133)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
              <ellipse
                cx="124.074"
                cy="315.133"
                rx="3.04107"
                ry="3.10773"
                transform="rotate(90 124.074 315.133)"
                fill="#D9D9D9"
                fillOpacity="0.4"
              />
            </svg>
          </section>
        </>
      )}

      <div
        className={`${
          location.pathname === "/"
            ? "h-[20rem] md:h-[30rem] lg:h-screen"
            : "h-64 md:h-72"
        } bg-primary relative pb-4
        `}
        style={{
          backgroundImage: `url(${NavBgImage})`,
          backgroundRepeat: "no-repeat",
          backgroundPosition: "bottom",
          backgroundPositionX: "bottom",
          backgroundBlendMode: "difference",
        }}
      >
        <div>{props.children}</div>
      </div>
    </section>
  );
};

export default HeroBanner;
