/* eslint-disable no-whitespace-before-property */
/* eslint-disable jsx-a11y/alt-text */
import React from "react";
import sabccoLogo from "../../../../assets/SABCCO_128x128.png";
import footerDesign from "../../../../assets/footer_Design.png";
import Section from "../../../../reusable-components/section/Section";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { number_in_words } from "../../../../utilities/generalFunctions/numberToWrod";

export default function ViewReceipt({
  receiptDetails,
  addRepaymentDetails,
  totalDueAmount,
  loaneeTotalRepaymentAmt,
  penalTotalDue,
  ioiTotalDue,
}) {
  let total_amt_receved = +loaneeTotalRepaymentAmt + +receiptDetails?.rp_amount;
  let total_amt_due = +totalDueAmount + ioiTotalDue + penalTotalDue;

  function capitalizeWords(str) {
    return str
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  }

  console.log("receiptDetails", receiptDetails);

  return (
    <>
      {(receiptDetails?.rp_mode === "Cash" ||
        receiptDetails?.rp_mode === "Branch") && (
        <Section className="border border-slate-200 py-4">
          <header className="bg-white pt-3">
            <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
              <div className="flex justify-center gap-3 items-center">
                <img className="h-28 w-28" src={sabccoLogo} alt="SABCCO Logo" />
              </div>
            </div>
            <div>
              <p className="text-center text-[14px] font-semibold">
                SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
                <br />
                CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
                <br />
                <span className="text-center text-[12px] font-semibold">
                  SONAM TSHERING MARG, GANGTOK
                </span>
              </p>
            </div>
          </header>
          <body className="pt-5 bg-white mx-4">
            <div className="pt-2">
              <p className="text-center font-bold underline underline-offset-4 text-sm">
                CASH RECEIPT
              </p>

              <div className="flex justify-between items-center pt-7 mx-8">
                <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                <p>
                  Date: {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                </p>
              </div>

              <div className="mt-14 flex justify-center items-center tracking-widest leading-10">
                <p className="pt-4 text-justify mx-8">
                  Received with thanks from Mr/Ms{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.applicantname)}
                  </span>{" "}
                  <span className="font-semibold">
                    {capitalizeWords(addRepaymentDetails?.applicantrelative)}
                    &nbsp;
                  </span>
                  <span className="font-semibold capitalize">
                    {capitalizeWords(
                      addRepaymentDetails?.applicantrelativename
                    )}
                    &nbsp;
                  </span>
                  resident of{" "}
                  <span className="font-semibold capitalize">
                    {addRepaymentDetails?.permanentaddress}&nbsp;
                  </span>
                  Loan ID No{" "}
                  <span className="px-1 font-semibold">
                    {addRepaymentDetails?.loanee_id}
                  </span>
                  a sum of Rs{" "}
                  <span className="px-1 font-semibold">
                    {formatINRCurrency(receiptDetails?.rp_amount)}
                  </span>
                  /- ( Rupees
                  <span className="font-semibold">
                    {number_in_words(receiptDetails?.rp_amount)}
                  </span>
                  ) only on account of SABCCO loan by cash.
                </p>
              </div>
            </div>
          </body>
          <footer className="mt-20 mx-3">
            <div className="flex justify-end">
              <table className="text-sm font-semibold border-collapse border border-black">
                <tbody>
                  <tr>
                    <td className="border border-black px-6">
                      Total outstanding due
                    </td>

                    <td className="border border-black px-20">
                      {formatINRCurrency(total_amt_due)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6">
                      Total repayment received till date
                    </td>
                    <td className="border border-black px-20">
                      {formatINRCurrency(total_amt_receved)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-center pt-20">
              <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
            </div>

            <div className="pt-24">
              <img src={footerDesign} alt="" srcset="" />
              <div className="text-center">
                <p className="text-center">
                  Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                  Classes Development Corporation Ltd.
                </p>
                <p className="text-center">
                  Contact No: 7908335935 & 9434863433 / Website:
                  www.sikkimsabcco.com
                </p>
              </div>
            </div>
          </footer>
        </Section>
      )}

      {receiptDetails?.rp_mode === "Cheque" && (
        <Section className="border border-slate-200 py-4 ">
          <header className="bg-white pt-3">
            <div className="h-full w-95 mx-auto flex justify-center items-center px-3 py-2 gap-3">
              <div className="flex justify-center gap-3 items-center">
                <img className="h-28 w-28" src={sabccoLogo} alt="SABCCO Logo" />
              </div>
            </div>
            <div>
              <p className="text-center text-[14px] font-semibold">
                SIKKIM SCHEDULED CASTES, SCHEDULED TRIBES & OTHER BACKWARD
                <br />
                CLASSES DEVELOPMENT CORPORATION LIMITED (SABCCO)
                <br />
                <span className="text-center text-[12px] font-semibold">
                  SONAM TSHERING MARG, GANGTOK
                </span>
              </p>
            </div>
          </header>
          <body className="pt-5 bg-white mx-3">
            <div className="pt-2">
              <p className="text-center font-bold underline underline-offset-4 text-sm">
                CHEQUE RECEIPT
              </p>
              <div className="flex justify-between items-center pt-2 mx-8">
                <p>Sl. No: {receiptDetails?.receipt_reference_number}</p>
                <p>
                  Date: {getFormattedDateDDMMMYYYYDate(receiptDetails?.rp_date)}
                </p>
              </div>

              <div className="mt-14 flex justify-center items-center tracking-widest leading-10 mx-4">
                <p className="pt-4 text-justify mx-4">
                  Received with thanks from Mr/Ms{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.applicantname)}
                  </span>{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.applicantrelative)}
                    &nbsp;
                  </span>
                  <span className="font-semibold capitalize">
                    {capitalizeWords(
                      addRepaymentDetails?.applicantrelativename
                    )}
                    &nbsp;
                  </span>
                  resident of{" "}
                  <span className="font-semibold capitalize">
                    {capitalizeWords(addRepaymentDetails?.permanentaddress)}
                    &nbsp;
                  </span>
                  Loan ID No{" "}
                  <span className="px-1 font-semibold">
                    {addRepaymentDetails?.loanee_id}
                  </span>
                  a sum of Rs
                  <span className="px-1 font-semibold">
                    {formatINRCurrency(receiptDetails?.rp_amount)}
                  </span>
                  (Rupees{" "}
                  <span className="font-semibold">
                    {number_in_words(receiptDetails?.rp_amount)}
                  </span>{" "}
                  ) only on account of SABCCO loan by Cheque/D.D. No{" "}
                  <span className="font-bold px-1">
                    {receiptDetails?.tr_cheque_dd_no}
                  </span>{" "}
                  dated{" "}
                  <span className="font-semibold">
                    {getFormattedDateDDMMMYYYYDate(receiptDetails?.cheque_date)}
                  </span>
                </p>
              </div>
            </div>
          </body>
          <footer className="mt-20 mx-3">
            <div className="flex justify-end">
              <table className="text-sm font-semibold border-collapse border border-black">
                <tbody>
                  <tr>
                    <td className="border border-black px-6">
                      Total outstanding due
                    </td>
                    <td className="border border-black px-20">
                      {formatINRCurrency(total_amt_due)}
                    </td>
                  </tr>
                  <tr>
                    <td className="border border-black px-6">
                      Total repayment received till date
                    </td>
                    <td className="border border-black px-20">
                      {formatINRCurrency(total_amt_receved)}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div className="flex justify-end items-center pt-24">
              <p>For SIKKIM SC, ST & OBC DEV. CORPN LTD (SABCCO)</p>
            </div>
            <div className="flex justify-start items-center ">
              <p>*Subject to realization of cheque</p>
            </div>
            <div className="pt-20">
              <img src={footerDesign} alt="" srcSet="" />
              <div className="text-center">
                <p className="text-center">
                  Sikkim Scheduled Castes, Scheduled Tribes and Other Backward
                  Classes Development Corporation Ltd.
                </p>
                <p className="text-center">
                  Contact No: 7908335935 & 9434863433 / Website:
                  www.sikkimsabcco.com
                </p>
              </div>
            </div>
          </footer>
        </Section>
      )}
    </>
  );
}
