/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, Fragment, useState } from "react";
import { useMemo } from "react";
import { FaArrowLeft } from "react-icons/fa";
import { ToastContainer } from "react-toastify";

import {
  CATEGORY_CONFIG_URL,
  CONSTITUENCY_CONFIG_URL,
  DISTRICT_CONFIG_URL,
  GET_RECEIPT_REPAYMENT_DETAILS,
  OTS_CONFIG_URL,
  REPAYMENT_CONFIG_URL,
} from "../../../../api/api_routing_urls";
import useAuth from "../../../../authentication/hooks/useAuth";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Dashboard from "../../../../common/Dashboard";
import Button from "../../../../reusable-components/buttons/Button";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import LoadingModal from "../../../../reusable-components/modals/LoadingModal";
import { Paging } from "../../../../reusable-components/paging/Paging";
import FilterDropdown from "../../../../reusable-components/search/FilterDropdown";
import SearchField from "../../../../reusable-components/search/SearchField";
import { back_btn, linkTextSecondary } from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";
import AddOneTimeSettlementRepaymentForm from "./AddOneTimeSettlementRepaymentForm";
import OneTimeSettlementRepaymentList from "./OneTimeSettlementRepaymentList";
import { MdOutlineFilterAlt } from "react-icons/md";

const OneTimeSettlementRepaymentConfig = () => {
  const { auth } = useAuth();
  const [repaymentList, setRepaymentList] = useState([]);
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const [loaneeData, setLoaneeData] = useState();
  const [otsLoaneeDetails, setOtsLoaneeDetails] = useState({});
  const [otsLoaneeDue, setOtsLoaneeDue] = useState({});

  const [isLoading, setIsLoading] = useState(true);
  const [searchText, setSearchText] = useState("");

  const [categoryList, setCategoryList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();

  const [pageNo, setPageNo] = useState(1);
  const [numberOfPages, setNumberOfPages] = useState(1);
  const [pageSize, setPageSize] = useState();

  const [refreshPage, setRefreshPage] = useState(false);

  const [districtList, setDistrictList] = useState([]);
  const [selectedDistrictName, setSelectedDistrictName] = useState();
  const [constituencyList, setConstituencyList] = useState([]);
  const [selectedConstituencyName, setSelectedConstituencyName] = useState();
  const [showFilters, setShowFilters] = useState(false);
  const [activeTab, setActiveTab] = useState("makeRepayment");
  const [receiptRepaymentList, setReceiptRepaymentList] = useState([]);

  const [loaneeTotalRepaymentAmt, setLoaneeTotalRepaymentAmt] = useState([]);

  const controller = new AbortController();

  const getSearchedRepaymentList = async (
    page_no,
    page_size,
    search_text,
    category_type,
    districtId,
    constituencyId
  ) => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getRepaymentSearchListConfig`,
        {
          params: {
            rolename: auth?.rolename,
            pageno: page_no || 1,
            pagesize: page_size || pageSize?.value || 10,
            searchtext: search_text || "",
            categorytype: category_type,
            decreestatus: false,
            onetimesettlementstatus: true,
            district_id: districtId || 0,
            constituency_id: constituencyId || 0,
            applicant_gender: "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setRepaymentList(response?.data?.repaymentList);

        const totalNumberOfApplicant =
          response?.data?.repaymentList[0]?.listcount;

        // getting total Number Of pages
        setNumberOfPages(
          Math.ceil(totalNumberOfApplicant / (pageSize?.value || 10))
        );
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
      setIsLoading(false);
    } catch (error) {
      console.error(error);
      setIsLoading(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  // get category list
  const getCategoryList = async () => {
    try {
      const response = await axiosPrivate.get(CATEGORY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.categoryList?.length > 0) {
          let categoryDD = [];

          response?.data?.categoryList?.map((categoryOBJ) => {
            let categoryObj = {
              value: categoryOBJ?.categoryid,
              label: categoryOBJ?.categoryname,
            };

            categoryDD.push(categoryObj);
          });
          setCategoryList(categoryDD);
        } else {
          setCategoryList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };
  // function for page change
  const onPageChangeHandler = (page_no, action_type) => {
    if (action_type === "Next") {
      parseInt(page_no) + 1 <= numberOfPages &&
        setPageNo(() => parseInt(page_no) + 1);
    }
    if (action_type === "Prev") {
      parseInt(page_no) - 1 >= 1 && setPageNo(() => parseInt(page_no) - 1);
    }
  };

  // get category list
  const getOTSDetail = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${OTS_CONFIG_URL}/getOtsDetail`,
        {
          params: {
            loan_nmber: loaneeData?.loan_number,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setOtsLoaneeDetails(response?.data?.otsDetails);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  // get category list
  const getOTSDue = async () => {
    try {
      setIsLoading(true);
      const response = await axiosPrivate.get(
        `${OTS_CONFIG_URL}/otsDueAmount`,
        {
          params: {
            loan_nmber: loaneeData?.loan_number,
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        setOtsLoaneeDue(response?.data?.otsDue);
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getDistrictList = async () => {
    try {
      const response = await axiosPrivate.get(DISTRICT_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.districtList?.length > 0) {
          let districtDD = [];
          response?.data?.districtList?.map((districtObj) => {
            let districtObjDD = {
              value: districtObj.districtid,
              label: districtObj.districtname,
            };
            districtDD.push(districtObjDD);
          });
          setDistrictList(districtDD);
        } else {
          setDistrictList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getConstituencyList = async () => {
    try {
      const response = await axiosPrivate.get(CONSTITUENCY_CONFIG_URL, {
        signal: controller.signal,
      });

      if (response?.status === 200) {
        if (response?.data?.constituencyList?.length > 0) {
          let constituencyDD = [];
          response?.data?.constituencyList?.map((constituencyObj) => {
            let constituencyObjDD = {
              value: constituencyObj.constituencyid,
              label: constituencyObj.constituencyname,
            };
            constituencyDD.push(constituencyObjDD);
          });
          setConstituencyList(constituencyDD);
        } else {
          setConstituencyList([]);
        }
      }
      response.status === 202 &&
        showToast("No state list found in the system", "error");
    } catch (error) {
      console.error(error);
    }
  };

  const getReceiptRepaymentDetail = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        GET_RECEIPT_REPAYMENT_DETAILS,
        {
          params: {
            loan_number: loaneeData?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      if (response.status === 200) {
        setIsLoading(false);
        setReceiptRepaymentList(response?.data?.ledgerDetails);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getLoaneeTotalRepaymentAmount = async () => {
    setIsLoading(true);

    try {
      let response = await axiosPrivate.get(
        `${REPAYMENT_CONFIG_URL}/getLoaneeTotalRepaymentAmount`,
        {
          params: {
            loan_number: loaneeData?.loan_number || "",
          },
        },
        {
          signal: controller.signal,
        }
      );

      setLoaneeTotalRepaymentAmt(
        response?.data?.loaneeTotalRepaymentAmount?.get_loanee_repayment_amount
          ?.total_amt_repay || 0
      );
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  };

  useMemo(() => {
    if (loaneeData?.loan_number) {
      getOTSDetail();
      getOTSDue();
      getReceiptRepaymentDetail();
      getLoaneeTotalRepaymentAmount();
    }
  }, [loaneeData]);

  useEffect(() => {
    getSearchedRepaymentList(
      pageNo,
      pageSize?.value,
      searchText,
      selectedCategory?.label || "",
      selectedDistrictName?.value,
      selectedConstituencyName?.value
    );
  }, [
    pageNo,
    pageSize?.value,
    selectedCategory?.label,
    selectedDistrictName?.value,
    selectedConstituencyName?.value,
  ]);

  useEffect(() => {
    let isMounted = true;

    isMounted && getCategoryList();

    getDistrictList();

    getConstituencyList();

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, [refreshPage]);

  return (
    <Dashboard sidebarType={auth?.rolename}>
      <ToastContainer />

      <>
        <div className="flex items-center justify-between gap-2 mb-6">
          <H2withIcon>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-5 w-5 mr-1"
              viewBox="0 0 20 20"
              fill="currentColor"
            >
              <path
                fillRule="evenodd"
                d="M5 2a2 2 0 00-2 2v14l3.5-2 3.5 2 3.5-2 3.5 2V4a2 2 0 00-2-2H5zm4.707 3.707a1 1 0 00-1.414-1.414l-3 3a1 1 0 000 1.414l3 3a1 1 0 001.414-1.414L8.414 9H10a3 3 0 013 3v1a1 1 0 102 0v-1a5 5 0 00-5-5H8.414l1.293-1.293z"
                clipRule="evenodd"
              />
            </svg>
            OTS Repayment{" "}
          </H2withIcon>

          {showAddForm ? (
            <>
              <Button
                className={linkTextSecondary}
                onClick={() => setShowAddForm(false)}
              >
                <div className="flex justify-between items-center space-x-3">
                  <FaArrowLeft />

                  <span className="ml-3">Back to List</span>
                </div>
              </Button>
            </>
          ) : (
            <>
              <div className="flex gap-2">
                <Button
                  className={
                    "border border-primary text-primary text-sm px-2 py-1 md:min-w-36 flex items-center justify-center gap-1"
                  }
                  onClick={() => {
                    setShowFilters(!showFilters);
                  }}
                >
                  Apply Filters
                  <MdOutlineFilterAlt size={18} />
                </Button>

                <SearchField
                  placeholder="Search"
                  defaultValue={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                  onClick={() => {
                    setPageNo(1);
                    getSearchedRepaymentList(
                      pageNo,
                      pageSize?.value,
                      searchText || "",
                      selectedCategory?.label || "",
                      selectedDistrictName?.value,
                      selectedConstituencyName?.value
                    );
                  }}
                  classes={"bg-primary "}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") {
                      getSearchedRepaymentList(
                        pageNo,
                        pageSize?.value,
                        searchText || "",
                        selectedCategory?.label || "",
                        selectedDistrictName?.value,
                        selectedConstituencyName?.value
                      );
                    }
                  }}
                />
              </div>
              {/* <section className="flex justify-between items-center gap-4 w-1/2 mb-4">
                <FilterDropdown
                  defaultName="filterCategory"
                  labelname="Scheme"
                  required={true}
                  classes={`text-sm w-full`}
                  data={categoryList}
                  defaultValue={null}
                  setSelected={setSelectedCategory}
                  selected={selectedCategory}
                  maxMenuHeight={256}
                />
                <SearchField
                  placeholder="Search"
                  defaultValue={searchText}
                  onChange={(e) => {
                    setSearchText(e);
                  }}
                  onClick={() => {
                    setPageNo(1);
                    getSearchedRepaymentList(
                      pageNo,
                      pageSize,
                      searchText || "",
                      selectedCategory?.label || ""
                    );
                  }}
                  classes={"bg-primary"}
                  onKeyDown={(e) => {
                    if (e?.key === "Enter") {
                      getSearchedRepaymentList(
                        pageNo,
                        pageSize,
                        searchText || "",
                        selectedCategory?.label || ""
                      );
                    }
                  }}
                />
              </section> */}
            </>
          )}
        </div>
      </>

      <>
        {showFilters && (
          <section className="grid grid-cols-1 md:grid-cols-3 gap-2 bg-slate-100 p-3 mt-3">
            <FilterDropdown
              defaultName="filterCategory"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Category..."}
              data={categoryList}
              defaultValue={null}
              setSelected={setSelectedCategory}
              selected={selectedCategory}
              maxMenuHeight={256}
            />

            <FilterDropdown
              defaultName="filterDistrict"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select District..."}
              data={districtList}
              defaultValue={null}
              setSelected={setSelectedDistrictName}
              selected={selectedDistrictName}
              maxMenuHeight={256}
            />
            <FilterDropdown
              defaultName="filterConstituency"
              required={true}
              classes={`text-sm w-full`}
              placeholder={"Select Constituency..."}
              data={constituencyList}
              defaultValue={null}
              setSelected={setSelectedConstituencyName}
              selected={selectedConstituencyName}
              maxMenuHeight={256}
            />
          </section>
        )}
      </>

      <>
        {isLoading ? (
          <>
            <LoadingModal message={"Loading"} />
          </>
        ) : null}

        {showAddForm ? (
          <AddOneTimeSettlementRepaymentForm
            setShowAddForm={setShowAddForm}
            loaneeData={loaneeData}
            setRefreshPage={setRefreshPage}
            otsLoaneeDetails={otsLoaneeDetails}
            otsLoaneeDue={otsLoaneeDue}
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            receiptRepaymentList={receiptRepaymentList}
            getReceiptRepaymentDetail={getReceiptRepaymentDetail}
            getOTSDetail={getOTSDetail}
            getOTSDue={getOTSDue}
            loaneeTotalRepaymentAmt={loaneeTotalRepaymentAmt}
          />
        ) : (
          <>
            <div className="grid grid-cols-1">
              <OneTimeSettlementRepaymentList
                repaymentList={repaymentList}
                setLoaneeData={setLoaneeData}
                setShowAddForm={setShowAddForm}
              />
            </div>
            <Paging
              maxMenuHeight={100}
              selected={pageSize}
              setSelected={setPageSize}
              pageNo={pageNo}
              onPageChangeHandler={onPageChangeHandler}
              numberOfPages={numberOfPages}
            />
          </>
        )}
      </>
    </Dashboard>
  );
};

export default OneTimeSettlementRepaymentConfig;
