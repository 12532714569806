import React, { useEffect, useState } from "react";
import Dashboard from "../../../../common/Dashboard";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import { ToastContainer } from "react-toastify";
import { FAQ_CONFIG_URL } from "../../../../api/api_routing_urls";
import showToast from "../../../../utilities/notification/NotificationModal";
import GenericModal from "../../../../reusable-components/modals/GenericModal";
import H2withIcon from "../../../../reusable-components/headings/H2withIcon";
import { IconContext } from "react-icons";
import AddButton from "../../../../reusable-components/buttons/AddButton";
import ContainerShadow from "../../../../reusable-components/containers/ContainerShadow";
import AddFAQForm from "./AddFAQForm";
import FAQList from "./FAQList";
import { FaQuestionCircle } from "react-icons/fa";
import useAuth from "../../../../authentication/hooks/useAuth";

export default function FAQConfig() {
  const { auth } = useAuth();

  const [faqList, setFAQList] = useState([]);
  const [editFAQDetails, setEditFAQDetails] = useState({});
  const axiosPrivate = useAxiosPrivate();
  const [showAddForm, setShowAddForm] = useState(false);
  const controller = new AbortController();
  const isEdit = Object.keys(editFAQDetails).length > 0 ? true : false;
  const getFAQList = async () => {
    try {
      const response = await axiosPrivate.get(FAQ_CONFIG_URL, {
        signal: controller.signal,
      });

      response.status === 200 && setFAQList(response.data.faqList);
      response.status === 202 &&
        showToast("No faq list found in the system", "error");
    } catch (error) {
      console.error(error);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    }
  };

  useEffect(() => {
    let isMounted = true;

    getFAQList();
    isMounted && setEditFAQDetails({});

    return () => {
      isMounted = false;
      controller.abort();
    };
  }, []);

  return (
    <Dashboard sidebarType={auth.rolename}>
      <ToastContainer />
      <div className="flex items-center justify-between">
        <H2withIcon>
          <IconContext.Provider value={{ className: "text-secondary" }}>
            <FaQuestionCircle />
          </IconContext.Provider>
          &nbsp; FAQ
        </H2withIcon>
        {!showAddForm && (
          <AddButton label="Add FAQ" onclick={() => setShowAddForm(true)} />
        )}
      </div>
      <GenericModal
        open={showAddForm}
        setOpen={setShowAddForm}
        title={`${isEdit ? "Update FAQ" : "Add FAQ"}`}
        isAdd={true}
      >
        <AddFAQForm
          getFAQList={getFAQList}
          editFAQDetails={editFAQDetails}
          setEditFAQDetails={setEditFAQDetails}
          setShowAddForm={setShowAddForm}
        />
      </GenericModal>
      <ContainerShadow>
        {faqList.length === 0 ? (
          <p>FAQ yet to be added</p>
        ) : (
          <div className="grid grid-cols-1">
            <FAQList
              faqList={faqList}
              setEditFAQDetails={setEditFAQDetails}
              setShowAddForm={setShowAddForm}
            />
          </div>
        )}
      </ContainerShadow>
    </Dashboard>
  );
}
