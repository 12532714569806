import React, { useEffect, useState } from "react";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { number_in_words } from "../../../../utilities/generalFunctions/numberToWrod";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateDDMMYYYY,
} from "../../../../utilities/dateFunctions/formatdate";

export default function DirectDeptNoticeTemplate({
  selectedDate,
  refNumber,
  loaneeDetails,
  guarantorListForNotice,
  selectedGuarantor,
  to_Print,
  toString,
  setToString,
}) {
  // Removing those guarantor who dont have departmentname, from the list
  let GuarantorDetailsWithDepartment = [];
  GuarantorDetailsWithDepartment =
    guarantorListForNotice?.get_guarantor_list_for_notice?.filter(
      (obj) => obj.departmantname !== null
    );

  return (
    <section className="text-[14px]">
      {to_Print === true ? null : (
        <>
          <p className="text-base">To</p>
          <textarea
            className="text-[14px] w-[35%]"
            value={toString} // Use 'value' instead of 'defaultValue'
            rows={6}
            onChange={(e) => setToString(e.target.value)}
          ></textarea>
        </>
      )}
      <div className="px-6 ">
        <div className="flex justify-between items-center my-6">
          <p>Ref. No: {refNumber}</p>
          <p>Gangtok, Dated: {getFormattedDateDDMMMYYYYDate(selectedDate)}</p>
        </div>

        <div className="py-3 text-[14px]">
          <p>To, </p>

          {toString ? (
            <div
              dangerouslySetInnerHTML={{
                __html: toString?.replace(/\n/g, "<br>"),
              }}
            />
          ) : null}
        </div>

        <div>
          <p className="pt-3 pb-6 font-bold underline underline-offset-2">
            Subject: Request for salary deduction{" "}
          </p>
          <p>Sir/ Madam,</p>
          <div className="pt-3">
            I would like to draw your kind attention to the fact that Mr/
            Ms&nbsp;
            <span className="font-semibold">
              {loaneeDetails?.applicantname}
            </span>
            &nbsp;
            <span className="font-semibold uppercase">
              {loaneeDetails?.applicantrelative}
            </span>
            &nbsp;Shri &nbsp;
            <span className="font-semibold uppercase">
              {loaneeDetails?.applicantrelativename}
            </span>{" "}
            resident of&nbsp;
            <span className="font-semibold capitalize">
              {loaneeDetails?.permanentaddress}
            </span>{" "}
            had availed&nbsp;{" "}
            <span className="font-semibold">{loaneeDetails?.schemename}</span>
            &nbsp;loan of{" "}
            <span className="font-semibold">
              {formatINRCurrency(loaneeDetails?.loanappliedamt)}/- &nbsp;
            </span>
            only from this Corporation on{" "}
            <span className="font-semibold">
              {getFormattedDateDDMMYYYY(
                loaneeDetails?.loan_first_disbursment_date
              )}
            </span>{" "}
            and Mr/Ms. &nbsp;
            <span className="font-semibold">
              {GuarantorDetailsWithDepartment[0]?.guarantorname}&nbsp;
            </span>{" "}
            working as{" "}
            {GuarantorDetailsWithDepartment[0]?.guarantor_designation ? (
              <span className="font-semibold uppercase">
                {GuarantorDetailsWithDepartment[0]?.guarantor_designation}
              </span>
            ) : (
              <span className="opacity-0"> _________________________</span>
            )}
            {/* <span className="font-semibold">
            {GuarantorDetailsWithDepartment[0]?.departmantname}
          </span> */}
            &nbsp;under your esteemed department has stood surety/ guarantor for
            the loan. He/ She has executed an undertaking for deduction of
            &nbsp;
            <span className="font-semibold">
              {formatINRCurrency(
                GuarantorDetailsWithDepartment[0]?.salarydeductionamount
              )}
              /-
            </span>{" "}
            &nbsp;per month from his/ her monthly salary w.e.f{" "}
            <span className="font-semibold">
              {GuarantorDetailsWithDepartment[0]?.deductiondate ? (
                getFormattedDateDDMMYYYY(
                  GuarantorDetailsWithDepartment[0]?.deductiondate
                )
              ) : (
                <span className="opacity-0"> _________________________</span>
              )}
            </span>
            . A copy of the same is enclosed for your ready reference.
          </div>

          <p className="pt-3">
            I, therefore, request you to kindly arrange to deduct a sum of&nbsp;
            <span className="font-semibold">
              {formatINRCurrency(
                GuarantorDetailsWithDepartment[0]?.salarydeductionamount
              )}
              /-
            </span>
            &nbsp;(Rupees
            <span className="font-semibold">
              {number_in_words(
                GuarantorDetailsWithDepartment[0]?.salarydeductionamount
              )}
            </span>
            )&nbsp; only per month from the salary of Mr/ Ms.
            <span className="font-semibold">
              {GuarantorDetailsWithDepartment[0]?.guarantorname}
            </span>
            ,{" "}
            {GuarantorDetailsWithDepartment[0]?.guarantor_designation ? (
              <span className="font-semibold uppercase">
                {GuarantorDetailsWithDepartment[0]?.guarantor_designation}
              </span>
            ) : (
              <span className="opacity-0"> _________________________</span>
            )}
            &nbsp;of your department with immediate effect and arrange to remit
            the amount in favor of ‘M.D. SABCCO, Gangtok’ until the entire loan
            along with interest is fully liquidated.
          </p>
        </div>
        <p className="py-3">
          Your cooperation in this regard is highly solicited.
        </p>

        <div className=" flex justify-between pt-6">
          <div className="">
            <p>Thanking You,</p>

            {selectedGuarantor ? (
              <div>
                <p className="py-6">Copy To </p>
                <p>
                  Guarantor Name:
                  <span className="font-semibold">
                    {selectedGuarantor?.label}
                  </span>
                </p>
                <p>
                  Guarantor Address:
                  <span className="font-semibold">
                    {selectedGuarantor?.address}
                  </span>
                </p>
              </div>
            ) : null}
          </div>

          <div>
            <br />
            <br />
            <p className="text-right pb-9">Yours faithfully,</p>

            <p className="py-9 text-right">
              Asstt. Manager/Manager/Dy.G.M
              {/* Manager (Fin. & Accounts)/ Asst. Manager */}
            </p>
          </div>
        </div>
        <p>Enclosed: </p>
      </div>
    </section>
  );
}
