import React from "react";
import HeroBanner from "../../../reusable-components/hero_banners/HeroBanner";
import { IoReturnDownBack } from "react-icons/io5";
import { useNavigate } from "react-router-dom";
import H1 from "../../../reusable-components/headings/H1";

import annualReport_1 from "../../../assets/SABCCO_Annul_Report1.PNG";
import annualReport_2 from "../../../assets/SABCCO_Annual_Report2.PNG";
import annualReport_3 from "../../../assets/SABCCO_Annul_Report3.PNG";

export default function AnnualReportPage() {
  const navigate = useNavigate();

  return (
    <>
      <header>
        <HeroBanner>
          <div className="absolute left-10 md:left-14 lg:left-1/5 right-10 md:right-14 lg:right-10 top-[40%]">
            <div className="text-center">
              <H1>Annual Report</H1>
            </div>
          </div>
        </HeroBanner>
      </header>

      <section className=" lg:mx-20  lg:my-10 ">
        <div className="flex justify-start" onClick={() => navigate("/rti")}>
          <div className="   flex justify-end items-center gap-2  p-1 md:px-2.5 md:py-1.5  cursor-pointer underline underline-offset-2 hover:text-secondary ">
            <IoReturnDownBack />
            <span>back to RTI page</span>
          </div>
        </div>

        <p className="font-semibold  text-center py-4 text-base md:text-xl underline underline-offset-2 ">
          Annual reports(Consolidated statement of RTI )of SABCCO for the last
          FY 2020-21, 2021-22 & 2022-23 are shown below
        </p>

        <div className="pt-8">
          <img
            src={annualReport_1}
            alt="Annual Report 1"
            className="h-screen w-auto object-cover"
          />
        </div>
        <div className="pt-8">
          <img
            src={annualReport_2}
            alt="Annual Report 2"
            className="h-screen w-auto object-cover"
          />
        </div>

        <div className="py-6">
          <img
            src={annualReport_3}
            alt="Annual Report 3"
            className="h-screen w-auto object-cover"
          />
        </div>
      </section>
    </>
  );
}
