import React from "react";
import { getFormattedDateDDMMMYYYYDate } from "../../../../utilities/dateFunctions/formatdate";

export default function DefaulterDetailListPrint({
  defaulterDetailList,
  selectedDefaulterType,
  selectedCategory,
  selectedDistrictName,
  selectedConstituencyName,
}) {
  return (
    <>
      <section
        className={`text-[11px] bg-slate-50 border
                      grid grid-cols-4 gap-2 p-2 mb-3`}
      >
        <div className="col-span-4 text-slate-800 flex justify-between items-center">
          <div>
            Defaulters Type:{" "}
            <span className="font-medium ml-1">
              {selectedDefaulterType ? selectedDefaulterType?.label : "N/A"}
            </span>
          </div>

          <div className="flex items-center gap-6">
            <div>
              Category:{" "}
              <span className="font-medium ml-1">
                {selectedCategory ? selectedCategory.label : "N/A"}
              </span>
            </div>
            <div>
              District:{" "}
              <span className="font-medium ml-1">
                {selectedDistrictName ? selectedDistrictName?.label : "N/A"}
              </span>
            </div>
            <div>
              Constituency:{" "}
              <span className="font-medium ml-1">
                {selectedConstituencyName
                  ? selectedConstituencyName?.label
                  : "N/A"}
              </span>
            </div>
          </div>

          <div className="justify end">
            No. of defaulters:{" "}
            <span className="font-medium ml-1">
              {defaulterDetailList.length || 0}
            </span>
          </div>
        </div>
      </section>

      <table className="min-w-full divide-y divide-slate-200">
        <thead className="bg-slate-200 text-[11px] ">
          <tr>
            <th
              scope="col"
              className="px-2 py-1 text-left font-medium text-slate-900 "
            >
              SI No.
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              Loan Id
            </th>
            <th
              scope="col"
              className="px-2 text-left font-medium text-slate-900 "
            >
              <div>Applicant Name</div>
              <div>(Category)</div>
            </th>
            {/* <th
            scope="col"
            className="px-2  text-left font-medium text-slate-900 "
          >
            Relative Name
          </th> */}

            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              Last Repayment
            </th>

            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              Scheme Name
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              District
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              Constituency
            </th>
            <th
              scope="col"
              className="px-2  text-left font-medium text-slate-900 "
            >
              Amount
            </th>
          </tr>
        </thead>
        <tbody className="bg-white text-[10px] divide-y divide-slate-100 text-slate-800">
          {defaulterDetailList?.length > 0 ? (
            defaulterDetailList?.map((defaulterList, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-2 py-1 ">{index + 1}</td>
                <td className="px-2 py-1 ">{defaulterList?.loanee_id}</td>
                <td className="px-2 py-1  flex flex-col  ">
                  {defaulterList?.applicantname}
                  <span className="font-medium">
                    ({defaulterList?.categoryname})
                  </span>
                </td>
                {/* <td className="px-2 py-1 ">
                {defaulterList?.applicantrelativename}
              </td> */}

                <td className="px-2 py-1 ">
                  {getFormattedDateDDMMMYYYYDate(
                    defaulterList?.last_repayment_date
                  )}
                </td>
                <td className="px-2 py-1 ">{defaulterList?.loan_scheme}</td>
                <td className="px-2 py-1 ">{defaulterList?.districtname}</td>

                <td className="px-2 py-1 ">
                  {defaulterList?.constituencyname || "---"}
                </td>
                <td className="px-2 py-1 ">{defaulterList?.loan_amount}</td>
              </tr>
            ))
          ) : (
            <td colSpan={8} className="p-4 text-center">
              {" "}
              No Record Found
            </td>
          )}
        </tbody>
      </table>
    </>
  );
}
