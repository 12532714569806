import { Tab } from "@headlessui/react";
import React, { Fragment } from "react";
import {
  getDifferenceInDays,
  getFormattedDateDDMMMYYYYDate,
} from "../../utilities/dateFunctions/formatdate";
import newImg from "../../assets/new.gif";
import { IconContext } from "react-icons";
import { FaFilePdf } from "react-icons/fa";
import TableWrapper from "../table/TableWrapper";

const HeadlessUITabWithTable = ({ tabCategory, onClickView }) => {
  function classNames(...classes) {
    return classes.filter(Boolean).join(" ");
  }

  return (
    <Tab.Group>
      <Tab.List className="flex p-1 space-x-1 bg-ternary rounded-xl">
        {Object.keys(tabCategory).map((category) => (
          <Tab
            key={category}
            className={({ selected }) =>
              classNames(
                "w-full py-2.5 text-sm leading-5 font-medium rounded-lg",
                "focus:outline-none focus:ring-0 focus:ring-violet-300",
                selected
                  ? "bg-white text-secondary shadow"
                  : "text-slate-600 hover:bg-white/[0.12] hover:text-white"
              )
            }
          >
            {category}
          </Tab>
        ))}
      </Tab.List>
      <Tab.Panels className={"mt-1"}>
        {Object.values(tabCategory).map((posts, idx) => (
          <Tab.Panel
            key={idx}
            className={classNames(
              "p-1",
              "focus:outline-none focus:ring-0 focus:ring-violet-300"
            )}
          >
            <TableWrapper>
              <thead className="bg-slate-300 text-sm">
                <th
                  scope="col"
                  className="px-6 py-3 font-medium text-slate-900 uppercase tracking-wider text-left"
                >
                  Sl. No.
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-left font-medium text-slate-900 uppercase tracking-wider"
                >
                  Title
                </th>
                <th
                  scope="col"
                  className="px-6 py-3 text-right font-medium text-slate-900 uppercase tracking-wider"
                >
                  View / Download
                </th>
              </thead>
              <tbody>
                {posts.length > 0 &&
                  posts.map((post, index) => (
                    <tr
                      key={post.id}
                      className="relative p-3 rounded-md bg-white mb-2 border-b shadow cursor-pointer"
                      onClick={() =>
                        onClickView(post.doc, post.title, post.desc)
                      }
                    >
                      <td className="px-6 py-4 whitespace-nowrap text-sm">
                        {parseInt(index + 1)}
                      </td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm">
                        <p>{post.title}</p>
                        <ul className="flex mt-1 space-x-1 text-xs font-normal leading-4 text-coolGray-500">
                          <li className="font-medium text-xs">
                            <div
                              className={`flex flex-row items-center${
                                post.type !== "News" && post.type !== "Event"
                                  ? new Date().setDate(
                                      new Date().getDate() - 7
                                    ) < new Date(post.postedon)
                                    ? ""
                                    : "hidden"
                                  : getDifferenceInDays(
                                      new Date(post.endson),
                                      new Date(post.postedon)
                                    ) > 3
                                  ? new Date().setDate(
                                      new Date().getDate() - 3
                                    ) < new Date(post.postedon)
                                    ? ""
                                    : "hidden"
                                  : "hidden"
                              }  -left-1 -top-1 text-xs`}
                            >
                              {getFormattedDateDDMMMYYYYDate(post.postedon)}
                              <img src={newImg} className={"h-3 ml-3"} />
                            </div>
                          </li>
                        </ul>
                      </td>
                      <td className="px-6 py-4 text-left whitespace-nowrap text-sm">
                        <div className="flex justify-end">
                          {post.desc !== null && post.desc !== "" ? (
                            post.doc !== null &&
                            post.doc !== "" && (
                              <div className="flex flex-col">
                                <Fragment>
                                  <IconContext.Provider
                                    value={{
                                      className:
                                        "text-red-700 text-5xl cursor-pointer",
                                    }}
                                  >
                                    <FaFilePdf />
                                  </IconContext.Provider>
                                </Fragment>
                              </div>
                            )
                          ) : (
                            <a download={post.title} href={post.doc}>
                              <div className="flex flex-col">
                                <Fragment>
                                  <IconContext.Provider
                                    value={{
                                      className:
                                        "text-red-700 text-5xl cursor-pointer",
                                    }}
                                  >
                                    <FaFilePdf />
                                  </IconContext.Provider>
                                </Fragment>
                              </div>
                            </a>
                          )}
                        </div>
                      </td>
                    </tr>
                  ))}
              </tbody>
            </TableWrapper>
            {posts.length === 0 && (
              <div
                key={1}
                className="relative p-3 rounded-md bg-white mb-2 border-b shadow"
              >
                <h3 className="text-sm font-medium leading-5 text-center">
                  News to be uploaded soon.
                </h3>
              </div>
            )}
          </Tab.Panel>
        ))}
      </Tab.Panels>
    </Tab.Group>
  );
};

export default HeadlessUITabWithTable;
