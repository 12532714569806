import React from "react";
import Dashboard from "../../common/Dashboard";
import DashboardConfig from "../system-admin/Dashboard/DashboardConfig";

export default function RecordKeeperDashboard() {
  return (
    // <Dashboard sidebarType="Record Keeper">

    <Dashboard sidebarType="Record Keeper">
      <DashboardConfig />
    </Dashboard>
  );
}
