import React, { useEffect, useState, Fragment, useMemo } from "react";
import {
  APPLICANT_PERSONAL_DETAILS_CONFIG_URL,
  OTS_CONFIG_URL,
  SCHEME_CONFIG_URL,
  LOANEE_DEATH_CASE,
  LOANEE_DETAILS_CONFIG,
} from "../../../../api/api_routing_urls";
import useAxiosPrivate from "../../../../authentication/hooks/useAxiosPrivate";
import Button from "../../../../reusable-components/buttons/Button";
import TableWrapper from "../../../../reusable-components/table/TableWrapper";
import {
  button,
  linkTextDanger,
  linkTextPrimary,
  linkTextSecondary,
} from "../../../../theme/lightTheme";
import showToast from "../../../../utilities/notification/NotificationModal";
import { useDetectClickOutside } from "react-detect-click-outside";
import useAuth from "../../../../authentication/hooks/useAuth";
import DeleteModal from "../../../../reusable-components/modals/DeleteModal";
import ConfirmationModal from "../../../../reusable-components/modals/ConfirmationModal";
import {
  MdCheck,
  MdOutlineApproval,
  MdOutlinePendingActions,
  MdPhoneIphone,
  MdRemoveRedEye,
} from "react-icons/md";
import { TbArrowBackUp, TbArrowForwardUp } from "react-icons/tb";
import { FaEdit, FaUser } from "react-icons/fa";
import {
  getFormattedDateDDMMMYYYYDate,
  getFormattedDateInputDate,
} from "../../../../utilities/dateFunctions/formatdate";
import { formatINRCurrency } from "../../../../utilities/currency/currency";
import { OtsCalculation } from "../applicant-components/OtsCalculation";
import { SubMenu } from "../applicant-components/SubMenu";
import SpinnerWithText from "../../../../reusable-components/loader/SpinnerWithText";
import { PrintReport } from "../../ReportsList/PrintReport/PrintReport";
import useNotificationHook from "../../../../authentication/hooks/useNotificationHook";

export default function ApplicantList({
  setLoaneeId,
  setEditGuarantorDetails,
  setShowAddGuarantorForm,
  applicantDetailList,
  setEditApplicantDetails,
  setShowAddForm,
  setViewApplicantDetail,
  setApplicantDetail,
  setShowAddDocumentsForm,
  getSearchedApplicantList,
  setLoaneeName,
}) {
  const { auth } = useAuth();

  const { notificationDetail } = useNotificationHook();
  const notificationData = notificationDetail?.notificationDetails?.list || {};

  const axiosPrivate = useAxiosPrivate();
  const controller = new AbortController();

  const [showReject, setShowReject] = useState(false);
  const [showDecree, setShowDecree] = useState(false);

  const [showOneTimeSettlement, setShowOneTimeSettlement] = useState(false);
  const [showloaneeDeathCase, setShowLoaneeDeathCase] = useState(false);
  const [applicantLoaneeId, setApplicantLoaneeId] = useState();
  const [decreeStatus, setDecreeStatus] = useState(false);
  const [deathCaseStatus, setDeathCaseStatus] = useState(false);
  const [oneTimeSettlementStatus, setOneTimeSettlementStatus] = useState(false);

  const [otsLoading, setOtsLoading] = useState(false);
  const [showOTSCalcualtion, setShowOTSCalcualtion] = useState(false);
  const [otsCalculation, setOtsCalcualtion] = useState();

  const [selectedCategory, setSelectedCategory] = useState(false);
  const [otsDate, setOtsDate] = useState(getFormattedDateInputDate(new Date()));
  const [loaneeCategory, setLoaneeCategory] = useState();
  const [showPrint, setShowPrint] = useState(false);

  const [showChangeWaiverPercentage, setShowChangeWaiverPercentage] =
    useState(false);
  const [waiverPercentage, setWaiverPercentage] = useState(20);

  const viewGuarantorDetail = (guarantorDetail, applicantName) => {
    try {
      setEditGuarantorDetails(guarantorDetail);
      setLoaneeName(applicantName);
      setShowAddGuarantorForm(true);
    } catch (error) {
      console.error("viewGuarantorDetail", error);
    }
  };

  const onClickAddGuarantor = (loanee_ID, applicantName) => {
    try {
      setLoaneeId(loanee_ID);
      setLoaneeName(applicantName);

      setShowAddGuarantorForm(true);
    } catch (error) {
      console.error("onClickAddGuarantor", error);
    }
  };

  const onClickEdit = (applicantDetail) => {
    setEditApplicantDetails(applicantDetail);
    setShowAddForm(true);
  };

  const onClickDocumentEdit = (applicantDetail) => {
    setEditApplicantDetails(applicantDetail);
    setShowAddDocumentsForm(true);
  };
  const onClickReject = (loanee_id) => {
    setApplicantLoaneeId(loanee_id);
    setShowReject(true);
  };
  {
    //#region get Gurantor list with personal details id
  }

  //ON Select as Decree case
  const onClickDecreeCase = (personaldetails_id, status, categary_type) => {
    setApplicantLoaneeId(personaldetails_id);
    setDecreeStatus(status);
    setSelectedCategory(categary_type);
    setShowDecree(true);
  };

  //On Select Death Case
  const onClickDeathCase = (loanee_id, status) => {
    setApplicantLoaneeId(loanee_id);
    setDeathCaseStatus(status);
    setShowLoaneeDeathCase(true);
  };

  //for one time settlement
  const onClickOneTimeSettlement = (loan_num, ots_status, loanee_category) => {
    setApplicantLoaneeId(loan_num);
    setOneTimeSettlementStatus(ots_status);
    setLoaneeCategory(loanee_category);
    setShowOneTimeSettlement(true);
  };

  const rejectApplicantDetail = async () => {
    try {
      const response = await axiosPrivate.post(
        `${LOANEE_DETAILS_CONFIG}/rejectLoanee`,
        {
          loanee_id: applicantLoaneeId,
          is_rejected: true,
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        showToast("Applicant Rejected successfully", "success");
        applicantDetailList = applicantDetailList?.filter?.(
          (filterDelete) => filterDelete.loanee_id !== applicantLoaneeId
        );
      }
      if (response.status === 202) {
        showToast(
          "There might be an issue. Please contact administrator.",
          "error"
        );
      }
      setShowReject(false);
    } catch (error) {
      console.error(error);
      setShowReject(false);
      if (!error?.response) {
        showToast("No Server Response");
      } else if (error.response.status === 422) {
        showToast("Some of the required inputs were not provided", "error");
      } else {
        showToast(
          "Whoops!!!! This doesn't feel right. There might be an issue. Please contact administrator",
          "error"
        );
      }
    } finally {
      getSearchedApplicantList();
    }
  };
  {
    //#endregion
  }

  // On click decree case
  const convertToDecree = async () => {
    try {
      const response = await axiosPrivate.put(
        `${APPLICANT_PERSONAL_DETAILS_CONFIG_URL}/convertToDecreeConfig`,
        {
          loanee_id: applicantLoaneeId,
          decreeStatus: decreeStatus,
          createdby: auth.userid,
          categarytype: selectedCategory,
        },
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (decreeStatus === true)
          showToast("Applicant converted to DECREE CASE", "success");
        if (decreeStatus === false)
          showToast("Applicant reverted from DECREE CASE", "success");
        applicantDetailList = applicantDetailList?.filter?.(
          (filterDelete) => filterDelete.loanee_id !== applicantLoaneeId
        );

        getSearchedApplicantList();
      }
      if (response.status === 202) {
        showToast("No state list found in the system", "error");
      }
      setShowDecree(false);
    } catch (error) {
      console.error(error.message);
      setShowDecree(false);
    }
  };

  // to convert to one time settlement
  const convertToOneTimeSettlemant = async () => {
    if (showOTSCalcualtion && otsCalculation) {
      try {
        setOtsLoading(true);
        let submitData = {
          loan_number: otsCalculation?.loan_number,
          otsstatus: oneTimeSettlementStatus,
          createdby: auth.userid,
          waiverpercentage: otsCalculation?.waiver_rate || 20,
          otsdate: otsCalculation?.ots_date,
          category: loaneeCategory,
          repaymentdueamount: otsCalculation?.repayment_due_amount || 0,
          waiveramount: otsCalculation?.waiver_amount || 0,
          outstandingamt: otsCalculation?.outstanding_amt || 0,
          penalintdue: otsCalculation?.penal_int_due || 0,
          ioiintdue: otsCalculation?.ioi_int_due || 0,
          interestdueamount: otsCalculation?.interest_due_amount || 0,
        };
        const response = await axiosPrivate.post(
          `${OTS_CONFIG_URL}/convertToOneTimeSettlemant`,
          submitData,
          {
            signal: controller.signal,
          }
        );

        if (response?.status === 200) {
          setOtsLoading(false);
          if (oneTimeSettlementStatus === true) {
            showToast("Applicant converted to One Time Settelment", "success");
          }
          if (oneTimeSettlementStatus === false) {
            showToast("Applicant reverted from One Time Settelment", "success");
          }

          getSearchedApplicantList();
        }
      } catch (error) {
        console.error(error);
      } finally {
        setOtsLoading(false);
      }
    } else {
      try {
        setOtsLoading(true);
        let submitData = {
          loan_number: applicantLoaneeId,
          otsstatus: oneTimeSettlementStatus,
          createdby: auth.userid,
          waiverpercentage: waiverPercentage || 20,
          otsdate: otsDate || getFormattedDateInputDate(new Date()),
          category: loaneeCategory,
        };
        const response = await axiosPrivate.post(
          `${OTS_CONFIG_URL}/calculateOneTimeSettlemant`,
          submitData,
          {
            signal: controller.signal,
          }
        );

        if (response?.status === 200) {
          if (oneTimeSettlementStatus === true) {
            setShowOTSCalcualtion(true);
            setOtsCalcualtion(response?.data?.otsCalculation);

            setOtsLoading(false);
            // showToast("Applicant converted to One Time Settelment", "success");
          }

          // getSearchedApplicantList();
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        setOtsLoading(false);
      }
    }
  };

  useMemo(() => {
    if (showOTSCalcualtion === false) {
      setOtsCalcualtion({});
    }
  }, [showOTSCalcualtion]);

  // onClick to convert to death case

  const convertToDeathCase = async () => {
    try {
      let SubmitObj = {
        loanee_id: applicantLoaneeId,
        isloaneedeathcase: deathCaseStatus,
        updatedby: auth.userid,
      };

      const response = await axiosPrivate.post(
        `${LOANEE_DEATH_CASE}/convertToDeathcase`,
        SubmitObj,
        {
          signal: controller.signal,
        }
      );

      if (response?.status === 200) {
        if (deathCaseStatus === true)
          showToast("Applicant converted to DEATH CASE", "success");
        if (deathCaseStatus === false)
          showToast("Applicant reverted from DEATH CASE", "success");

        getSearchedApplicantList();
      }
      if (response.status === 202) {
        showToast("No death Case list found in the system", "error");
      }
    } catch (error) {
      console.error(error.message);
      setShowLoaneeDeathCase(false);
    }
  };

  return (
    <>
      <TableWrapper>
        <thead className="bg-white text-xs  md:text-sm ">
          <tr className="bg-slate-200 sticky top-0 z-0">
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loanee ID
            </th>
            <th
              scope="col"
              className="px-3 py-2 text-left font-medium flex flex-col min-w-44"
            >
              <span>Applicant Name</span>{" "}
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Contact
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Scheme
            </th>
            <th scope="col" className="px-3 py-2 text-left font-medium">
              Loan Amount
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Sanction Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Approval Status
            </th>

            <th scope="col" className="px-3 py-2 text-left font-medium">
              Applicant Guarantors
            </th>
            <th scope="col" className="px-3 py-2 text-center font-medium">
              Actions
            </th>
          </tr>
        </thead>
        <tbody className="bg-white divide-y divide-slate-100 text-slate-600">
          {applicantDetailList?.length > 0 ? (
            applicantDetailList?.map((applicantDetail, index) => (
              <tr
                key={index}
                className={index % 2 === 1 ? "bg-slate-100" : null}
              >
                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm font-semibold">
                  <div className="flex items-center justify-start gap-2 z-0">
                    <div className="relative w-3 h-3">
                      {notificationData?.length > 0 &&
                        notificationData?.map((obj, index) =>
                          +obj.loanee_id === +applicantDetail?.loanee_id ? (
                            <span
                              key={index}
                              className="w-3 h-3 rounded-full bg-red-500 absolute"
                            ></span>
                          ) : null
                        )}
                    </div>
                    <p>{applicantDetail?.loanee_id}</p>
                  </div>
                  <div className="bg-blue-600 text-center rounded">
                    <p className="text-center font-medium text-white">
                      {applicantDetail?.is_draft ? "Draft" : null}
                    </p>
                  </div>
                </td>
                <td className="px-3 py-1.5 text-xs 2xl:text-sm ">
                  <p className="flex flex-col text-left ">
                    <span>{applicantDetail?.applicantname}</span>

                    <span className="font-semibold text-xs 2xl:text-sm">
                      ({applicantDetail?.categoryname})
                    </span>
                  </p>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm ">
                  <span className="flex gap-0.5">
                    <span className="mt-0.5 text-primary">
                      <MdPhoneIphone size={15} />
                    </span>

                    {applicantDetail?.contactno > 0
                      ? applicantDetail?.contactno
                      : "---"}
                  </span>
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {/* {`${applicantDetail?.schemename} (${applicantDetail?.schemeshortname})`} */}
                  {`${
                    applicantDetail?.schemename ||
                    applicantDetail?.schemeshortname ||
                    "---"
                  }`}
                </td>
                <td className="px-3 py-1.5 whitespace-nowrap text-xs md:text-sm">
                  {applicantDetail?.loanappliedamt >=
                  applicantDetail?.loan_amount
                    ? applicantDetail?.loanappliedamt
                    : applicantDetail?.loan_amount}
                </td>

                <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                  <div className="flex w-12 justify-between py-0.5">
                    Pre:
                    {applicantDetail?.is_presanction_revert === true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Reverted</span> */}
                        <TbArrowBackUp size={18} />
                      </span>
                    ) : applicantDetail?.is_presanction_approved === true ||
                      applicantDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        {/* <span className="text-slate-700">Pending</span> */}
                        <MdOutlinePendingActions size={18} />
                      </span>
                    )}
                  </div>
                  <div className="flex w-12 justify-between py-0.5">
                    Post:
                    {applicantDetail?.is_postsanction_revert === true ? (
                      <span className="text-red-600 flex gap-1 items-center">
                        <TbArrowBackUp size={18} />
                        {/* <span className="text-slate-700">Reverted</span> */}
                      </span>
                    ) : applicantDetail?.is_postsanction_approved === true ||
                      applicantDetail?.loanee_id <= 7761 ? (
                      <span className="text-green-600 flex gap-1 items-center">
                        <MdCheck size={18} />
                        {/* <span className="text-slate-700">Approved</span> */}
                      </span>
                    ) : (
                      <span className="text-yellow-600 flex gap-1 items-center">
                        <MdOutlinePendingActions size={18} />
                        {/* <span className="text-slate-700">Pending</span> */}
                      </span>
                    )}
                  </div>
                </td>
                {auth.rolename !== "Content Admin" && (
                  <td className="px-3 py-1.5 whitespace-nowrap text-xs 2xl:text-sm">
                    {applicantDetail?.is_presanction_approved !== true &&
                    applicantDetail?.loanee_id > 7761 ? (
                      <>
                        {auth.rolename !== applicantDetail?.pre_forwarded_to &&
                        applicantDetail?.pre_forwarded_to !== null ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    ) : (
                      <>
                        {applicantDetail?.is_postsanction_approved === true ||
                        applicantDetail?.loanee_id <= 7761 ? (
                          <>
                            <span className="text-green-600 flex gap-1 items-center">
                              <span className="text-slate-700">Disbursed</span>
                              <MdCheck size={18} />
                            </span>
                          </>
                        ) : (auth.rolename !==
                            applicantDetail?.post_forwarded_to &&
                            applicantDetail?.post_forwarded_to !== null) ||
                          auth.rolename === "Managing Director" ? (
                          <span className="text-green-600 flex gap-1 items-center">
                            <span className="text-slate-700">Forwarded</span>
                            <TbArrowForwardUp size={18} />
                          </span>
                        ) : (
                          <span className="text-yellow-600 flex gap-1 items-center">
                            <span className="text-slate-700">Pending</span>
                            <MdOutlinePendingActions size={18} />
                          </span>
                        )}
                      </>
                    )}
                  </td>
                )}
                <td className="px-3 py-1.5 whitespace-nowrap text-xs  md:text-sm ">
                  {applicantDetail?.guarantordetails !== null ? (
                    <>
                      <ul>
                        {applicantDetail?.guarantordetails?.map(
                          (guarantorDetail, index) => (
                            <li
                              key={index}
                              className={`py-0.5 text-xs  flex 
                              whitespace-nowrap ${
                                (auth.rolename === "Data Entry Operator" ||
                                  auth.rolename === "Super Admin") &&
                                !applicantDetail?.is_presanction_approved &&
                                ` cursor-pointer `
                              }`}
                              onClick={() => {
                                (auth.rolename === "Data Entry Operator" ||
                                  auth.rolename === "Super Admin") &&
                                  !applicantDetail?.is_presanction_approved &&
                                  viewGuarantorDetail(
                                    guarantorDetail,
                                    applicantDetail?.applicantname
                                  );
                              }}
                            >
                              <span className="mt-0.5">
                                <FaUser size={12} />
                              </span>
                              <div
                                className={` flex text-xs justify-between w-full  ${
                                  (auth.rolename === "Data Entry Operator" ||
                                    auth.rolename === "Super Admin") &&
                                  !applicantDetail?.is_presanction_approved
                                    ? `${linkTextSecondary}  `
                                    : `text-slate-600  px-2`
                                }`}
                              >
                                <div>
                                  {guarantorDetail?.guarantorname.substring(
                                    0,
                                    19
                                  )}{" "}
                                  ...
                                </div>
                                {!applicantDetail?.is_presanction_approved && (
                                  <>
                                    {(auth.rolename === "Data Entry Operator" ||
                                      auth.rolename === "Super Admin") &&
                                      !applicantDetail?.is_presanction_approved && (
                                        <FaEdit size={14} />
                                      )}
                                  </>
                                )}
                              </div>
                            </li>
                          )
                        )}
                      </ul>{" "}
                    </>
                  ) : (
                    "---"
                  )}
                </td>

                <td className="px-3 py-1.5 whitespace-nowrap text-sm relative z-auto">
                  <div className="flex justify-end ">
                    <Button
                      onClick={() => {
                        setApplicantDetail(applicantDetail);
                        setViewApplicantDetail(true);
                      }}
                      aria-label="View Sub menu"
                      className={linkTextPrimary}
                    >
                      <span className="text-primary">
                        <MdRemoveRedEye size={18} />
                      </span>
                    </Button>

                    {((auth.rolename === "Data Entry Operator" ||
                      auth.rolename === "Managing Director" ||
                      auth.rolename === "Super Admin") &&
                      (applicantDetail?.is_presanction_approved !== true ||
                        applicantDetail?.is_postsanction_approved !== true ||
                        applicantDetail?.is_presanction_revert === true ||
                        applicantDetail?.is_postsanction_revert === true)) ||
                    ((auth.rolename === "Assistant Manager" ||
                      auth.rolename === "General Manager" ||
                      auth.rolename === "Deputy General Manager" ||
                      auth.rolename === "Managing Director") &&
                      applicantDetail?.loanee_id <= 7761 &&
                      applicantDetail?.loan_status === "Active") ||
                    applicantDetail?.is_postsanction_approved === true ? (
                      <>
                        <SubMenu
                          applicantData={applicantDetail}
                          onClickEdit={onClickEdit}
                          onClickDocumentEdit={onClickDocumentEdit}
                          onClickAddGuarantor={onClickAddGuarantor}
                          onClickReject={onClickReject}
                          onClickDecreeCase={onClickDecreeCase}
                          onClickDeathCase={onClickDeathCase}
                          onClickOneTimeSettlement={onClickOneTimeSettlement}
                          rolename={auth.rolename}
                          setApplicantDetail={setApplicantDetail}
                          setViewApplicantDetail={setViewApplicantDetail}
                          setShowOTSCalcualtion={setShowOTSCalcualtion}
                          setShowLoaneeDeathCase={setShowLoaneeDeathCase}
                          setShowReject={setShowReject}
                        />
                      </>
                    ) : null}
                  </div>
                </td>
              </tr>
            ))
          ) : (
            <>
              <tr className="m-auto text-center">
                <td colSpan={9} className="py-3">
                  No List Found
                </td>
              </tr>
            </>
          )}
        </tbody>
      </TableWrapper>

      <ConfirmationModal
        open={showReject}
        setOpen={setShowReject}
        message={"The Applicant would be rejected. Are you sure?"}
        btnName={"Confirm"}
        onConfirm={() => rejectApplicantDetail()}
        color="red"
      />

      <ConfirmationModal
        open={showDecree}
        setOpen={setShowDecree}
        message={
          decreeStatus
            ? `Are you sure to convert applicant to DECREE CASE?`
            : `Are you sure to Revert applicant from DECREE CASE?`
        }
        btnName={"Confirm"}
        onConfirm={() => convertToDecree()}
      />

      <ConfirmationModal
        open={showOneTimeSettlement}
        setOpen={setShowOneTimeSettlement}
        message={
          oneTimeSettlementStatus
            ? `Are you sure to convert the loanee for one time settlement with ${waiverPercentage}% wavier off on interest?`
            : `Are you sure to Revert loanee from one time settlement?`
        }
        btnName={showOTSCalcualtion ? "Confirm" : "Calculate"}
        onConfirm={() => convertToOneTimeSettlemant()}
      >
        <>
          {otsLoading ? (
            <SpinnerWithText />
          ) : (
            <section>
              {showOTSCalcualtion ? (
                <OtsCalculation otsCalculation={otsCalculation} />
              ) : (
                <div className="grid grid-flow-row mt-2 text-slate-700">
                  {oneTimeSettlementStatus && (
                    <small
                      className="text-blue-400 cursor-pointer"
                      onClick={() =>
                        setShowChangeWaiverPercentage(
                          !showChangeWaiverPercentage
                        )
                      }
                    >
                      Or change the waiver percentage
                    </small>
                  )}
                  {showChangeWaiverPercentage && (
                    <input
                      className="min-w-min w-1/2 rounded text-sm border border-slate-200"
                      type="number"
                      placeholder="Waiver percentage"
                      onChange={(e) => {
                        setWaiverPercentage(e?.target?.value);
                      }}
                      defaultValue={waiverPercentage}
                    />
                  )}

                  <div className="my-3 flex flex-col">
                    <label htmlFor="otsdate" className="text-sm font-semibold ">
                      {" "}
                      OTS Date{" "}
                    </label>
                    <input
                      id="otsdate"
                      className="min-w-min w-1/2 rounded text-sm border border-slate-200"
                      type="date"
                      placeholder="OTS Date"
                      onChange={(e) => {
                        setOtsDate(e?.target?.value);
                      }}
                      defaultValue={getFormattedDateInputDate(new Date())}
                      max={getFormattedDateInputDate(new Date())}
                    />
                  </div>
                </div>
              )}
              <div>
                {showOTSCalcualtion ? (
                  <>
                    <PrintReport
                      reportTitle={"One Time Settlement"}
                      buttonName={""}
                    >
                      <OtsCalculation otsCalculation={otsCalculation} />
                    </PrintReport>
                  </>
                ) : null}
              </div>
            </section>
          )}
        </>
      </ConfirmationModal>

      <ConfirmationModal
        open={showloaneeDeathCase}
        setOpen={setShowLoaneeDeathCase}
        message={`Are you sure you want to move the Details into Death Case List ?`}
        btnName={"Confirm"}
        onConfirm={() => convertToDeathCase()}
      ></ConfirmationModal>
    </>
  );
}
